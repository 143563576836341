import { Component, Input, ViewChild, HostListener, ElementRef, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface ActionMenuItem {
  handler: (value?) => Observable<unknown>;
  html: string;
  iconType?: string;
  iconColor?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'uc-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent implements OnChanges {
  @ViewChild('dropdown') dropdown: ElementRef;

  @Input() actions: ActionMenuItem[];
  @Input() modelData: unknown;
  @Input() isDisabled = false;

  private log: Logger;
  showSpinner: boolean[] = [];
  open = false;

  constructor(private loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.dropdown && !this.dropdown.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  ngOnChanges(changes) {
    if (changes.actions && changes.actions.length) {
      this.showSpinner = new Array(this.actions.length).fill(false);
    }
  }

  toggleDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.open = !this.open;
  }

  callHandler(index) {
    const selectedAction = this.actions[index];
    if (selectedAction.isDisabled) {
      return;
    }
    this.showSpinner[index] = true;
    selectedAction.handler(this.modelData).subscribe(
      () => {
        this.showSpinner[index] = false;
        this.open = false;
      },
      (err) => {
        this.showSpinner[index] = false;
        this.open = false;
        this.log.error('Error calling handler', err);
      },
    );
  }

  getIconColorForAction(action) {
    if (action.isDisabled) {
      return '$colorRollingStone';
    }
    if (action.iconColor) {
      return action.iconColor;
    }
    return 'currentColor';
  }
}
