<uc-form-template [formGroup]="deferOfferForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints *ngIf="formLoaded" [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-spinner *ngIf="!formLoaded" [useStaticPositioning]="true"></uc-spinner>
  <uc-section *ngIf="formLoaded">
    <uc-subsection>
      <p>{{ strings.currentQuals }}</p>
      <uc-qualification-detail-card
        *ngIf="applicationEnrolment"
        [enrolledQuals]="applicationEnrolment?.enrolledQualifications"
        [applicationYear]="applicationYear"></uc-qualification-detail-card>

      <p>{{ strings.deferGuidance }}</p>
      <h5 class="defer-heading">{{ strings.deferTitle }}</h5>
      <span class="defer-date-guidance" *ngIf="!hasDoctoralCategory" [innerHTML]="strings.deferDateGuidance"></span>
      <span
        class="defer-date-guidance"
        *ngIf="hasDoctoralCategory"
        [innerHTML]="strings.deferDateGuidanceDoctoral"></span>
    </uc-subsection>

    <uc-subsection>
      <uc-subsection-question [required]="true">{{ strings.deferralYear }}</uc-subsection-question>
      <uc-task-hint [task]="task" path="/offer_decision/deferred_qual_occurrence_year"></uc-task-hint>
      <uc-reference-data-selector
        type="application_year"
        [filter]="previousApplicationYears"
        formControlName="deferredQualOccurrenceYear"></uc-reference-data-selector>
      <uc-message-banner type="info" *ngIf="hasYearSelected && noQualOccurrences">
        <span [innerHTML]="strings.noQualOccsBanner"></span>
      </uc-message-banner>
    </uc-subsection>

    <uc-subsection *ngIf="hasYearSelected && !noQualOccurrences">
      <uc-subsection-question [required]="true">{{ strings.deferralDate }}</uc-subsection-question>
      <uc-task-hint [task]="task" path="/offer_decision/deferred_qual_occurrence"></uc-task-hint>
      <uc-reference-data-selector
        formControlName="deferredQualOccurrence"
        [options]="occurrenceOptions"></uc-reference-data-selector>
    </uc-subsection>

    <uc-subsection *ngIf="hasDoctoralCategory">
      <uc-subsection-question>{{ strings.uploadEvidenceTitle }}</uc-subsection-question>
      <uc-task-hint [task]="task" path="/documents/deferral-approval"></uc-task-hint>
      <p class="upload-guidance">{{ strings.uploadEvidenceGuidance }}</p>
      <uc-file-upload
        [required]="true"
        [year]="applicationYear"
        [title]="strings.uploadLabel"
        categoryCode="DEFERRAL_APPROVAL"
        usedInTask="true"></uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>
