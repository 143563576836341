import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export class OnlineAssessment {
  literacyScore: number;
  numeracyScore: number;

  constructor(data) {
    Object.assign(this, data);
  }

  static deserialize(payload) {
    if (payload === null) {
      return null;
    }

    const onlineAssessment = camelizeKeys(payload);
    return new OnlineAssessment(onlineAssessment);
  }

  static serialize(instance: any) {
    const clone = { ...instance };
    return snakeifyKeys(clone);
  }
}
