import { Component } from '@angular/core';

import { ProcessPageComponent } from '@shared/components/templates/process-page/process-page.component';
import { internalUrls } from '@shared/constants/internalUrls';

@Component({
  selector: 'uc-myuc-process-page',
  templateUrl: './myuc-process-page.component.html',
  styleUrls: ['./myuc-process-page.component.scss'],
})
export class MyucProcessPageComponent extends ProcessPageComponent {
  public goToDashboard(message?: string): Promise<boolean> {
    if (message) {
      this.flashMessageService.pushSuccess(message, { countdown: 10 });
    }
    return this.router.navigate(internalUrls.manageMyStudy);
  }

  cancelTask() {
    this.router.navigate(internalUrls.manageMyStudy);
  }
}
