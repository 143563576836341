<label class="radio-input-wrap">
  <input
    [attr.data-cy]="testSelectorId.toLowerCase()"
    [formControl]="radioControl"
    class="radio-input"
    type="radio"
    name="{{ groupName }}"
    [value]="fieldValue" />
  <span class="label-text">
    <span [innerHTML]="labelText"></span>
    <ng-container *ngIf="extraText && extraText.inlineGuidance">
      <p [innerHTML]="extraText.inlineGuidance"></p>
    </ng-container>
    <p [innerHTML]="extraText.description" *ngIf="groupValue === fieldValue && extraText && extraText.description"></p>
  </span>
</label>
