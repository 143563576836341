import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'uc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  showSidebar = false;

  toggleNav() {
    this.showSidebar = !this.showSidebar;
  }

  ngOnInit() {}
}
