{
  "sanctions": [
    {
      "internal_reference": "6598.46426",
      "external_reference": "UaY5lM1iVK7aB8NvQQn0YqBKFkiCYi",
      "type": "Award Exclusion",
      "state": {
        "code": "active"
      },
      "comments": "2016 End-of-Year RAP Qualification Exclusion from the BA. Refer to ASG. Letter sent 14/12/2016",
      "valid_from": "2022-01-19",
      "valid_to": "2022-01-19",
      "actions": [
        {
          "code": "BAD_DEBT",
          "description": "Bad Debt - Refuse enrolment",
          "valid_from": "2005-01-01",
          "valid_to": "2022-01-19"
        }
      ],
      "restrictions": [
        {
          "type": "string",
          "points": "string",
          "qualification": "string",
          "code": "string"
        }
      ]
    },
    {
      "internal_reference": "6598.46426",
      "external_reference": "UaY5lM1iVK7aB8NvQQn0YqBKFkiCYi",
      "type": "Award Exclusion",
      "state": {
        "code": "removed"
      },
      "comments": "2016 End-of-Year RAP Qualification Exclusion from the BA. Refer to ASG. Letter sent 14/12/2016",
      "valid_from": "2022-01-19",
      "valid_to": "2022-01-19",
      "actions": [
        {
          "code": "BAD_DEBTSS",
          "description": "Bad Debt - Refuse enrolment",
          "valid_from": "2005-01-01",
          "valid_to": "2022-01-19"
        }
      ],
      "restrictions": [
        {
          "type": "string",
          "points": "string",
          "qualification": "string",
          "code": "string"
        }
      ]
    },
    {
      "internal_reference": "6598.46426",
      "external_reference": "UaY5lM1iVK7aB8NvQQn0YqBKFkiCYi",
      "type": "Award Exclusion",
      "state": {
        "code": "deleted"
      },
      "comments": "2016 End-of-Year RAP Qualification Exclusion from the BA. Refer to ASG. Letter sent 14/12/2016",
      "valid_from": "2022-01-19",
      "valid_to": "2022-01-19",
      "actions": [
        {
          "code": "BAD_DEBTING",
          "description": "Bad Debt - Refuse enrolment",
          "valid_from": "2005-01-01",
          "valid_to": "2022-01-19"
        }
      ],
      "restrictions": [
        {
          "type": "string",
          "points": "string",
          "qualification": "string",
          "code": "string"
        }
      ]
    }
  ]
}
