import { Component, OnInit } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-course-planner-tile',
  templateUrl: './course-planner-tile.component.html',
  styleUrls: ['./course-planner-tile.component.scss'],
})
export class CoursePlannerTileComponent implements OnInit {
  courseStrings = strings.components.molecules.coursePlannerTile;
  constructor() {}

  ngOnInit() {}
}
