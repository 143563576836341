{
  "enrolments": [
    {
      "academic_year": "2022",
      "internal_reference": "audkier29jk4L09tksdafke84dkOO9",
      "state": {
        "code": "fully_enrolled"
      }
    }
  ]
}
