import { Component, Input } from '@angular/core';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Task } from '@shared/models/task';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-evidence-of-funds',
  templateUrl: './evidence-of-funds.component.html',
  styleUrls: ['./evidence-of-funds.component.scss'],
})
export class EvidenceOfFundsComponent extends AbstractBaseTask {
  @Input() task: Task;
  @Input() applicationYear: string;
  strings = strings.components.tasks.evidenceOfFunds;
  log: Logger;

  constructor(logService: LoggingService) {
    super();
    this.log = logService.createLogger(this);
  }

  public updateFormValidity() {}

  update() {
    this.next.emit();
  }
}
