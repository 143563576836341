import { Component, Input } from '@angular/core';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-online-button',
  template: `
    <div (click)="handlePropagation($event)">
      <button
        [attr.disabled]="disabled ? 'disabled' : null"
        [hidden]="hidden"
        (click)="navigate()"
        [class.has-icon]="iconType"
        [attr.data-cy]="testSelectorId">
        <span class="btn-text" [class.hide]="isLoading"><ng-content></ng-content></span>
        <uc-icon
          *ngIf="iconType"
          [iconType]="iconType"
          [color]="color"
          [iconHeight]="iconHeight"
          [iconWidth]="iconWidth"></uc-icon>
        <uc-loader-blip [showBlips]="isLoading"></uc-loader-blip>
      </button>
    </div>
  `,

  styleUrls: ['./online-button.component.scss'],
})
export class OnlineButtonComponent {
  @Input() hidden = false;
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() externalLink = '';
  @Input() replaceUrl = false;
  @Input() iconType: string;
  @Input() iconWidth = 16;
  @Input() iconHeight = 16;
  @Input() iconFill = 'currentColor';
  @Input() color = 'currentColor';
  @Input() backgroundColor: string;
  @Input() iconTitle: string;
  @Input() testSelector = '';
  log: Logger;
  hasStoppedLoading = false;

  constructor(
    private logging: LoggingService,
    private ws: WindowService,
  ) {
    this.log = logging.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-btn`;
  }

  handlePropagation(e: MouseEvent) {
    const preventButtonClick = this.disabled || this.isLoading;
    if (preventButtonClick) {
      e.stopPropagation();
    }
  }

  navigate() {
    if (this.externalLink !== '') {
      const windowRef = this.ws.nativeWindow;
      const target = this.replaceUrl ? '_self' : '_blank';
      windowRef.open(this.externalLink, target);
    }
  }
}
