import { Component, Input } from '@angular/core';

import { getUIMessage } from '@shared/models/errors';

@Component({
  selector: 'uc-form-control-error',
  template: `
    <p *ngFor="let message of errorMessages" class="error-message">
      {{ message }}
    </p>
  `,
  styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent {
  errorMessages: string[] = [];
  private _errorObj: { string?: any };

  constructor() {}

  @Input()
  set errors(val) {
    this.errorMessages = [];
    if (val) {
      Object.values(val).forEach((error) => {
        if (!!error.code) {
          this.errorMessages.push(getUIMessage(error));
        }
      });
    }
    this._errorObj = val;
  }

  get errors() {
    return this._errorObj;
  }
}
