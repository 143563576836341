import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { DISABILITY_CONSTANTS } from '@shared/components/molecules/fitness-to-teach/fitness-to-teach.component';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  UCElementGroup,
  refDatasToCodeArray,
  controlToRefDataArray,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-teaching-application',
  templateUrl: './teaching-application.component.html',
})
export class TeachingApplicationComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() stage: string;
  strings = strings.components.tasks.teachingApplication;

  teachingApplicationForm: UntypedFormGroup;
  teachingApplication: UCElementGroup;
  isLoading = true;
  log: Logger;

  currentApplicant: Applicant;

  constructor(
    private loggingService: LoggingService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private mapper: FormModelMapperService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get hasDisability(): UntypedFormControl {
    return this.teachingApplicationForm.get('hasDisability') as UntypedFormControl;
  }

  private createUcElementGroup(): UCElementGroup {
    return group({
      disability: control({
        defaultState: [],
        model: 'applicant',
        path: '/disability',
        inMap: refDatasToCodeArray,
        outMap: controlToRefDataArray,
        validators: [],
      }),
      hasDisability: control({
        model: 'applicant',
        path: '/declarationDisability',
        inMap: refDataToValue,
        outMap: valueToRefData,
        validators: [Validators.required],
      }),
      disabilityDetail: control({
        model: 'applicant',
        path: '/disabilityDetail',
      }),

      schoolReport: group({
        type: control({
          model: this.dataModel,
          path: '/associatedPerson/school/type',
          defaultState: new ReferenceData({ code: 'school' }),
        }),
        name: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/school/name',
        }),
        relationship: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/school/relationship',
        }),
        email: control({
          model: this.dataModel,
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/school/email',
        }),
      }),
    });
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);

    observableCombineLatest(this.applicantService.applicant.pipe(filter((a) => !!a)), this.service.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.teachingApplication = this.createUcElementGroup();
        this.teachingApplicationForm = this.teachingApplication.asControl() as UntypedFormGroup;
        this.isLoading = false;
        this.hasDisability.valueChanges
          .pipe(
            takeUntil(this.componentDestroyed),
            filter((v) => !!v),
          )
          .subscribe((val) => {
            if (val === DISABILITY_CONSTANTS.IS_TRUE) {
              this.teachingApplication.controls.disability.control.setValidators([Validators.required]);
              this.teachingApplication.controls.disabilityDetail.control.setValidators([Validators.required]);
            } else {
              this.teachingApplication.controls.disability.control.setValidators([]);
              this.teachingApplication.controls.disabilityDetail.control.setValidators([]);
            }
            this.teachingApplication.controls.disability.control.updateValueAndValidity();
            this.teachingApplication.controls.disabilityDetail.control.updateValueAndValidity();
          });
        this.mapper.updateFormFromModel(this.teachingApplication, applicant, application);
      });
  }

  public updateFormValidity(err: UCError) {
    this.mapper.updateFormValidity(err.data, this.teachingApplication);
  }

  updateChangeOfEnrolment() {
    return new ChangeOfEnrolment({
      associatedPerson: this.currentApplication.associatedPerson,
    });
  }

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    } else if (!this.currentApplication) {
      this.errors.emit();
      return this.log.error('tried to update the application but no application has been loaded yet');
    }

    this.mapper.updateModelFromForm(this.teachingApplication, this.currentApplicant, this.currentApplication);
    const application = this.isCOE ? this.updateChangeOfEnrolment() : this.currentApplication;

    if (this.currentApplicant.declarationDisability.code !== DISABILITY_CONSTANTS.IS_TRUE) {
      this.currentApplicant.disability = [];
      this.currentApplicant.disabilityDetail = null;
    }

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.service.update(application);

    observableCombineLatest(updateApplicant, updateApplication).subscribe(
      // Success
      ([updatedApplicant, updatedApplication]: [Applicant, Application]) => {
        if (updatedApplicant && updatedApplication) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      // Error
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating applica(nt|tion):', err);
      },
    );
  }
}
