<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
    <p class="heading-para" [innerHTML]="strings.fitnessToTeach.teachDescription"></p>
  </uc-form-header>
</uc-form-template>

<uc-form-template *ngIf="!isLoading">
  <uc-section>
    <uc-section-header title="{{ strings.confidentialSchoolReport.title }}">
      <p>{{ strings.confidentialSchoolReport.description[0] }}</p>
      <p [innerHTML]="strings.confidentialSchoolReport.description[1]"></p>
    </uc-section-header>
    <uc-confidential-school-report
      [hintPath]="hintPath"
      [schoolReport]="teachingApplicationForm.get('schoolReport')"></uc-confidential-school-report>
  </uc-section>
</uc-form-template>

<uc-form-template *ngIf="!isLoading">
  <uc-section>
    <uc-section-header title="{{ strings.fitnessToTeach.title }}"></uc-section-header>
    <uc-fitness-to-teach
      [task]="task"
      [fitnessToTeachForm]="teachingApplicationForm"
      [disabilityDetailRequired]="true"></uc-fitness-to-teach>
  </uc-section>
</uc-form-template>
