import { Component, OnInit } from '@angular/core';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';

@Component({
  selector: 'uc-page-not-found-template',
  templateUrl: './page-not-found-template.component.html',
  styleUrls: ['./page-not-found-template.component.scss'],
})
export class PageNotFoundTemplateComponent implements OnInit {
  internalUrls = internalUrls;
  externalUrls = externalUrls;
  strings = strings.components.template.pageNotFoundTemplate;
  appName = strings.labels.appName;

  constructor() {}

  ngOnInit() {}
}
