<div [formGroup]="insuranceProviderFormGroup">
  <uc-section-header title="{{ strings.header }}">
    <div [innerHTML]="strings.description"></div>
  </uc-section-header>
  <uc-subsection>
    <uc-task-hint [task]="task" path="/application/insurance_provider"></uc-task-hint>
    <uc-form-input>
      <uc-radio-input-group
        [label]="strings.insuranceSelector.header"
        [additionalText]="extraDescriptions"
        required="yes"
        formControlName="insuranceProvider"
        type="medical_insurance_providers"
        groupName="medical-providers"></uc-radio-input-group>
      <div [ngSwitch]="selectedInsuranceProviderCode">
        <div *ngSwitchCase="'296'"></div>
        <div *ngSwitchCase="'998'">
          <uc-subsection-question
            [innerHTML]="strings.insuranceSelector.otherCompliantProviderSelected.header"></uc-subsection-question>
          <uc-subsection-text-block
            [innerHTML]="
              strings.insuranceSelector.otherCompliantProviderSelected.description
            "></uc-subsection-text-block>
        </div>
        <div *ngSwitchCase="'999'">
          <uc-subsection-text-block
            [innerHTML]="
              strings.insuranceSelector.otherRequireAssessmentSelected.description
            "></uc-subsection-text-block>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </uc-form-input>
  </uc-subsection>
</div>
