import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { Category } from '@shared/models/uc-file';
import { DocumentService } from '@shared/services/document/document.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss'],
})
export class DocumentTemplateComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewChecked {
  log: Logger;
  categories: Category[];
  strings = strings.components.template.documentPage;
  helpText = strings.components.organisms.fileUpload.helpText;
  hasStudentId = false;
  unreadCategory: string;
  initialViewCheck = true;

  constructor(
    private fb: UntypedFormBuilder,
    private documentService: DocumentService,
    private loggingService: LoggingService,
    private userService: UserService,
    private windowRef: WindowService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.hasStudentId = !!this.userService.user.studentId;

    combineLatest(this.activatedRoute.queryParams.pipe(filter((p) => !!p)), this.documentService.getCategories())
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([params, cats]) => {
        this.categories = cats;
        if (params.category) {
          this.unreadCategory = params.category;
        }
      });
  }

  unreadDocumentEl(id) {
    const { document } = this.windowRef.nativeWindow;
    return document.getElementById(id);
  }

  scrollToCategory(catCode) {
    const window = this.windowRef.nativeWindow;
    const el = this.unreadDocumentEl(catCode);
    const rect = el.getBoundingClientRect();
    window.scrollTo(0, rect.top - 200);
  }

  ngAfterViewChecked() {
    if (this.initialViewCheck && this.unreadCategory && !!this.unreadDocumentEl(this.unreadCategory)) {
      this.scrollToCategory(this.unreadCategory);
      this.initialViewCheck = false;
    }
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
