export const mockAdminViewUser = [
  {
    user: {
      cid: 'canid1',
      student_id: '1234',
      fbid: ['asidfjakldjfasdjf'],
      email_address: 'email@address.com',
    },
    applicant: {
      user_id: 'adsklfjadsf',
      legal_name: {
        title: null,
        type: {
          code: 'PREF',
        },
        first_name: 'marshall',
        middle_name: null,
        surname: 'keyboard',
        validated: null,
      },
      name: [],
      birth_date: '2004-02-03',
      gender: {
        code: 'M',
      },
      language: null,
      other_language: null,
      contact_detail: {
        email_address: 'marshall@keyboard.com',
        mobile_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        landline_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        current_address: {
          line_1: '23 Humphries Drive',
          line_2: null,
          country: {
            code: 'NZL',
          },
          city: null,
          town: 'Christchurch',
          region: 'Canterbury',
          postcode: '8023',
        },
      },
      study_contact_detail: {
        email_address: null,
        mobile_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        landline_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        current_address: {
          line_1: null,
          line_2: null,
          country: null,
          city: null,
          town: null,
          region: null,
          postcode: null,
        },
      },
      emergency_contact_detail: {
        email_address: null,
        mobile_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        landline_number: {
          country: null,
          prefix: null,
          number: null,
          extension: null,
        },
        current_address: {
          line_1: null,
          line_2: null,
          country: null,
          city: null,
          town: null,
          region: null,
          postcode: null,
        },
      },
      emergency_contact_language: null,
      emergency_contact_name: {
        title: null,
        type: null,
        first_name: null,
        middle_name: null,
        surname: null,
        validated: null,
      },
      emergency_contact_relationship: null,
      demographic: {
        language: null,
        language_other: null,
        citizenship: {
          code: 'NZCZ',
        },
        ethnicity: [
          {
            code: '6',
          },
          {
            code: '5',
          },
        ],
        iwi: [],
        passport_country: {
          code: 'NZL',
        },
        validated_citizenship: null,
      },
      identity: {
        staff_number: null,
        nsn_number: null,
        student_number: null,
      },
      disability: [],
      disability_detail: null,
      declaration_disability: null,
      validated_birth_date: null,
      declaration_first_in_family: null,
      declaration_did_family_member_graduate: null,
      first_year_here: null,
      first_year_tertiary: null,
      admission_state: {
        code: 'NONMATRICULATED',
      },
      ueQualification: {
        type: { code: 'UNDERGRADUATE' },
        typeOther: null,
        dateAttained: '2021-03-04',
        dateStarted: '2014',
        country: null,
        source: null,
        source_other: null,
        attained: null,
        result: [
          {
            type: '',
            score: '',
          },
        ],
      },
      secondaryQualification: [
        {
          country: { code: 'NZL' },
        },
      ],

      tertiaryQualification: [
        {
          type: { code: 'UCIC' },
          typeOther: null,
          dateAttained: '2021-03-04',
          dateStarted: '2014',
          country: null,
          source: null,
          source_other: null,
          attained: null,
          result: [
            {
              type: '',
              score: '',
            },
          ],
        },
      ],
      englishQualification: {
        type: { code: 'TOEFL' },
        typeOther: null,
        dateAttained: '2021-03-04',
        dateStarted: '2014',
        country: null,
        source: null,
        source_other: null,
        attained: null,
        result: [
          {
            type: '',
            score: '',
          },
        ],
      },
    },
  },
];
