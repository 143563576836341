<div [formGroup]="termsAndConditionsForm" class="termsAndConditionsContainer">
  <uc-form-input>
    <uc-checkbox-input
      [label]="strings.termsAndConditionsDescription"
      formControlName="acceptedTermsAndPrivacy"
      groupName="termsAndConditions"
      required="true"></uc-checkbox-input>
  </uc-form-input>
  <uc-form-input>
    <uc-checkbox-input
      [label]="strings.declareDescription"
      formControlName="declaredMetRequirements"
      groupName="termsAndConditions"
      required="true"></uc-checkbox-input>
  </uc-form-input>
  <uc-form-input>
    <uc-checkbox-input
      [label]="strings.understandDescription"
      formControlName="acceptedProvideIdentity"
      groupName="termsAndConditions"
      required="true"></uc-checkbox-input>
  </uc-form-input>
  <uc-form-input *ngIf="showOptionalItem">
    <uc-checkbox-input
      [label]="strings.optionalDescription"
      formControlName="uconlineMarketingEmails"
      groupName="termsAndConditions"></uc-checkbox-input>
  </uc-form-input>
</div>
