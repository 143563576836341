<form>
  <mat-form-field>
    <input
      matInput
      [formControl]="control ? control : date"
      [matDatepicker]="myDatePicker"
      [min]="minDate"
      [max]="maxDate"
      disabled />
    <span class="placeholder" *ngIf="datePlaceHolder && !control.value">{{ datePlaceHolder }}</span>
    <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #myDatePicker disabled="false"></mat-datepicker>
  </mat-form-field>
</form>
