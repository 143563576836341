export function dedent(templ) {
  var values = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    values[_i - 1] = arguments[_i];
  }
  var strings = Array.from(typeof templ === 'string' ? [templ] : templ);
  strings[strings.length - 1] = strings[strings.length - 1].replace(/\r?\n([\t ]*)$/, '');
  var indentLengths = strings.reduce(function (arr, str) {
    var matches = str.match(/\n([\t ]+|(?!\s).)/g);
    if (matches) {
      return arr.concat(matches.map(function (match) {
        var _a, _b;
        return (_b = (_a = match.match(/[\t ]/g)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
      }));
    }
    return arr;
  }, []);
  if (indentLengths.length) {
    var pattern_1 = new RegExp("\n[\t ]{" + Math.min.apply(Math, indentLengths) + "}", 'g');
    strings = strings.map(function (str) {
      return str.replace(pattern_1, '\n');
    });
  }
  strings[0] = strings[0].replace(/^\r?\n/, '');
  var string = strings[0];
  values.forEach(function (value, i) {
    var endentations = string.match(/(?:^|\n)( *)$/);
    var endentation = endentations ? endentations[1] : '';
    var indentedValue = value;
    if (typeof value === 'string' && value.includes('\n')) {
      indentedValue = String(value).split('\n').map(function (str, i) {
        return i === 0 ? str : "" + endentation + str;
      }).join('\n');
    }
    string += indentedValue + strings[i + 1];
  });
  return string;
}
export default dedent;
