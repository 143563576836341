<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-online-header-bar hasSideNav="false" class="online-header-bar"></uc-online-header-bar>
<div id="main-content" tabindex="-1" class="dashboard-content">
  <uc-online-dashboard-tiles></uc-online-dashboard-tiles>
  <div class="footer">
    <div class="footer-top"></div>
    <div class="footer-bottom"></div>
  </div>
</div>
