import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { get } from 'lodash-es';
import { of as observableOf, Observable } from 'rxjs';
import { first, switchMap, map, tap } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { User } from '@shared/models/user';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class StudentIdGuard {
  private restrictedTasks = ['course-selection-overview'];

  constructor(
    private userService: UserService,
    private router: Router,
    private flashMessageService: FlashMessageService,
  ) {}

  public checkStudentId(): Observable<boolean> {
    return this.userService.currentUser.pipe(
      first(), // don't keep responding to changes on the currentUser
      switchMap((user: User) => {
        if (get(user, 'studentId')) {
          // if the user already has a studentId, just return them
          return observableOf(user);
        } else {
          // otherwise, refresh token and get updated user
          return this.userService.refreshTokenBeforeNavigate();
        }
      }),
      map((user) => !!get(user, 'studentId')),
      tap((hasStudentId) => {
        if (!hasStudentId) {
          this.flashMessageService.pushError('This page is not accessible without a student ID');
          this.router.navigate(internalUrls.dashboard);
        }
      }),
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // process page tasks need special handling for dynamic segments
    const { process, task } = next.params;
    if (process && task) {
      if (this.restrictedTasks.indexOf(task) >= 0) {
        return this.checkStudentId();
      } else {
        return observableOf(true);
      }
    }

    // any other place we can use the guard for a specific route
    return this.checkStudentId();
  }
}
