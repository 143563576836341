<div class="page__content" [formGroup]="qualSelectorForm">
  <uc-radio-input-group
    testSelector="qual-selector"
    [label]="strings.qualFilterLabel"
    formControlName="qualOptionFilter"
    [options]="qualFilterOptions"
    [displayHorizontally]="true"
    ngDefaultControl></uc-radio-input-group>

  <uc-reference-data-selector
    testSelector="qual-selector"
    class="large-input"
    formControlName="code"
    [useNgSelect]="true"
    [required]="true"
    [labelName]="strings.qualLabel"
    [options]="filteredQualificationOptions"></uc-reference-data-selector>
</div>
