import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  control,
  group,
  refDataToValue,
  UCElementGroup,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-international-student-insurance',
  templateUrl: './international-student-insurance.component.html',
})
export class InternationalStudentInsuranceComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  strings = strings.components.tasks.internationalStudentInsurance;

  insuranceForm: UCElementGroup;
  insurancePage: UntypedFormGroup;

  currentApplication: Application;

  private log: Logger;

  constructor(
    private formMapper: FormModelMapperService,
    private applicationService: ApplicationService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get insuranceProviderGroup(): UntypedFormGroup {
    return this.insurancePage.get('insuranceProviderGroup') as UntypedFormGroup;
  }

  static createForm(): UCElementGroup {
    return group({
      insuranceProviderGroup: group({
        insuranceProvider: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'application',
          path: '/insuranceProvider',
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
      }),
    });
  }

  public updateFormValidity(err: UCError) {
    this.formMapper.updateFormValidity(err.data, this.insuranceForm);
  }

  public ngOnInit() {
    this.insuranceForm = InternationalStudentInsuranceComponent.createForm();
    this.insurancePage = this.insuranceForm.asControl() as UntypedFormGroup;
    this.applicationService.application
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;

        this.formMapper.updateFormFromModel(this.insuranceForm, null, this.currentApplication);
      });
  }

  public update() {
    this.formMapper.updateModelFromForm(this.insuranceForm, null, this.currentApplication);
    this.applicationService.updateApplication(this.currentApplication).subscribe(
      // Success
      (_application) => {
        this.next.emit();
      },
      // Error
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('Error thrown while updating application', err);
      },
    );
  }
}
