<div class="sidenav-trigger" *ngIf="hasSideNav">
  <uc-icon
    (click)="hamburgerClick()"
    iconType="hamburger"
    iconWidth="24"
    iconHeight="24"
    iconFill="$colorMonza"></uc-icon>
</div>
<div class="header-bar-left">
  <a *ngIf="showBackLink" [routerLink]="internalUrls.dashboard.join('/')" class="back-link">
    <uc-icon iconType="back-arrow" iconWidth="22" iconHeight="22"></uc-icon>
    <span class="back-link-text">{{ strings.headerBar.backToDashboard }}</span>
  </a>
  <a *ngIf="!showBackLink && !hideLogo" [routerLink]="internalUrls.dashboard.join('/')" class="back-link">
    <img
      *ngIf="!showBackLink"
      class="uc-online-logo"
      src="assets/img/UC-online-logo-simple-White.svg"
      alt="UC-online-logo-simple-White" />
  </a>

  <div *ngIf="!hideNavItems">
    <a [routerLink]="internalUrls.dashboard.join('/')" class="nav-link">
      <span class="nav-link-text">Dashboard</span>
    </a>
    <a [routerLink]="internalUrls.dashboard.join('/')" class="nav-link">
      <span class="nav-link-text">About</span>
    </a>
    <a [routerLink]="internalUrls.dashboard.join('/')" class="nav-link">
      <span class="nav-link-text">Courses</span>
    </a>
    <a [routerLink]="internalUrls.dashboard.join('/')" class="nav-link">
      <span class="nav-link-text">Help</span>
    </a>
  </div>
</div>
<div class="toolbar">
  <div *ngIf="!hideToolbarItems">
    <div class="toolbar-item hide-xs" *ngIf="showTotaraUrl">
      <a class="header-widget" [href]="strings.headerBar.totaraUrl" target="_blank">
        <span class="icon-label hidden-s hide-s">{{ strings.headerBar.learningPlatform }}</span>
        <div class="header-widget__icon--wrapper">
          <uc-icon
            iconType="cap"
            iconWidth="34"
            iconHeight="34"
            iconFill="$colorWhite"
            lineStyle="stroke:#727779; stroke-width:30; stroke-linecap:round"></uc-icon>
        </div>
      </a>
    </div>
    <div class="toolbar-item hide-xs" *ngIf="showFeedbackIcon">
      <a class="header-widget" href="{{ feedbackUrl }}" target="_blank">
        <span class="icon-label hidden-xs hide-s">{{ strings.headerBar.feedback }}</span>
        <div class="header-widget__icon--wrapper">
          <uc-icon
            iconType="feedback"
            iconWidth="34"
            iconHeight="34"
            iconFill="$colorWhite"
            lineStyle="stroke:#727779; stroke-width:40; stroke-linecap:round"></uc-icon>
        </div>
      </a>
    </div>
    <div *ngIf="showDocLibrary" class="toolbar-item hide-xs">
      <a class="header-widget" [routerLink]="internalUrls.documents.join('/')">
        <span class="icon-label document-library-label hidden-s hide-s">{{ strings.documentLibrary.name }}</span>
        <div class="header-widget__icon--wrapper">
          <span class="document-library__counter" *ngIf="importantDocumentCount > 0">{{ importantDocumentCount }}</span>
          <uc-icon iconType="doc-alt" iconWidth="44" iconHeight="44" iconFill="$colorRollingStone"></uc-icon>
        </div>
      </a>
    </div>
    <uc-online-header-nav-dropdown class="header-nav-dropdown"></uc-online-header-nav-dropdown>
  </div>
</div>
