<div [formGroup]="learningNeedsFormGroup">
  <uc-section-header title="{{ strings.header }}"></uc-section-header>

  <uc-subsection>
    <uc-form-input>
      <uc-task-hint [task]="task" path="/applicant/demographic/language"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.primaryLanguage }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-reference-data-selector
        type="language"
        formControlName="language"
        required="yes"></uc-reference-data-selector>
    </uc-form-input>
    <!-- "05" === "Other" option-->
    <div *ngIf="primaryLanguageCode === '05'">
      <uc-form-input>
        <uc-task-hint [task]="task" path="/applicant/demogrpahic/language_other"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.primaryLanguageOther }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-string-input testSelector="lang-other" formControlName="languageOther"></uc-string-input>
      </uc-form-input>
    </div>
  </uc-subsection>
  <uc-fitness-to-teach
    [task]="task"
    [fitnessToTeachForm]="learningNeedsFormGroup.get('fitnessToTeachGroup')"></uc-fitness-to-teach>

  <uc-form-input>
    <uc-yes-no
      [task]="task"
      path="/applicant/declaration_first_in_family"
      formControlName="declarationFirstInFamily"
      [questionString]="strings.declarationFirstInFamily.question"
      [questionDescription]="strings.declarationFirstInFamily.description"
      required="true"
      testSelector="first-family">
      <uc-no-content>
        <uc-yes-no
          [task]="task"
          path="/applicant/declaration_did_family_member_graduate"
          formControlName="declarationDidFamilyMemberGraduate"
          [questionString]="strings.declarationFamilyMemberGraduated.question"
          [questionDescription]="strings.declarationFamilyMemberGraduated.description"
          required="true"
          testSelector="family-graduated"></uc-yes-no>
      </uc-no-content>
    </uc-yes-no>
  </uc-form-input>
</div>
