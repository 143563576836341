import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, ControlValueAccessor } from '@angular/forms';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-checkbox-input',
  template: `
    <label class="checkbox-input-wrap" *ngIf="!isToggleSwitch">
      <input
        [formControl]="checkControl"
        class="checkbox-input"
        type="checkbox"
        [name]="groupName"
        [value]="fieldValue"
        [attr.data-cy]="testSelectorId" />
      <span class="label-text">
        <span [innerHTML]="label"></span>
        <uc-required-field *ngIf="required"></uc-required-field>
        <button
          *ngIf="labelTooltipContent"
          ucTooltip
          tooltipContent="{{ labelTooltipContent }}"
          (click)="labelClicked($event)"
          class="label-tooltip tooltip-btn">
          <uc-icon
            iconTitle="More info"
            iconType="info"
            iconWidth="16"
            iconHeight="16"
            iconFill="$colorMineShaft"></uc-icon>
        </button>
      </span>
    </label>
    <div class="toggle-wrap" *ngIf="isToggleSwitch">
      <!--
        NOTE: The input and label elements below need to remain siblings for
        the CSS to work...
      -->
      <input
        id="{{ toggleElementID }}"
        class="toggle-checkbox"
        [formControl]="checkControl"
        [name]="groupName"
        [value]="fieldValue"
        type="checkbox"
        [attr.data-cy]="testSelectorId" />
      <label for="{{ toggleElementID }}" class="toggle-label">
        <span class="toggle-label-text">{{ label }}</span>
      </label>
    </div>
  `,
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() groupName: string;
  @Input() fieldValue: string;
  @Input() isToggleSwitch = false;
  @Input() labelTooltipContent: string;
  @Input() required = false;

  toggleElementID = `toggle-switch-${Math.random().toString(36).substring(2)}`;

  log: Logger;
  checkControl: FormControl;

  constructor(logService: LoggingService) {
    this.checkControl = new FormControl({ value: '', disabled: false });
    this.log = logService.createLogger(this);
  }

  @Input() set disableCheckbox(disabled: boolean) {
    if (disabled) {
      this.checkControl.disable({ emitEvent: false });
    } else {
      this.checkControl.enable({ emitEvent: false });
    }
  }

  get testSelectorId() {
    const fieldValue = this.fieldValue ? `${this.fieldValue}-` : '';
    return `${this.groupName}-${fieldValue}checkbox`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private propagateChange = (fn: unknown) => {
    // required
  };

  ngOnInit() {
    this.log.info('ngOnInit');
    this.checkControl.valueChanges.subscribe((val) => {
      if (this.fieldValue) {
        this.propagateChange(this.fieldValue);
      } else {
        this.propagateChange(val);
      }
    });
  }

  /**
   * Prevent click on tooltip trigger selecting checkbox option.
   */
  labelClicked(event) {
    event.preventDefault();
  }

  writeValue(obj: unknown): void {
    this.checkControl.setValue(obj);
  }

  registerOnChange(fn: (unknown) => void): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: unknown): void {
    // required
  }
}
