<label class="label-text">
  {{ label }}
  <uc-required-field *ngIf="isRequired"></uc-required-field>
</label>
<div class="checkbox-input-group" [class.hasValue]="hasValue">
  <div class="fields-wrap clearfix" [formGroup]="checkboxForm">
    <uc-checkbox-input
      *ngFor="let option of options"
      [label]="option.labelText"
      (change)="onCheckChange($event)"
      [groupName]="groupName"
      [formControl]="option.control"
      [fieldValue]="option.value"
      [disableCheckbox]="disableCheckbox"
      [required]="innerInputRequired"></uc-checkbox-input>
    <div class="validation-indicator" *ngIf="showValidator">
      <uc-icon
        class="valid-icon"
        iconTitle="Valid"
        iconType="tick"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
      <uc-icon
        class="invalid-icon"
        iconTitle="Invalid"
        iconType="close"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
    </div>
  </div>
</div>
