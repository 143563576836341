import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Roles } from '@shared/models/user';
import { UserService } from '@shared/services/user/user.service';

@Directive({
  selector: '[ucClaimCheck]',
})
export class ClaimCheckDirective implements OnInit {
  /**
   *  Gives the ability to not render UI elements if user doensn't have required claim
   *  eg: import User model > Roles enum into component and define `roles = Roles` after constructor.
   *  use on element like:
   *  <h1 *ucClaimCheck="[roles.Staff]>Title text</h1>
   **/

  @Input() ucClaimCheck: Roles[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  ngOnInit() {
    if (!Array.isArray(this.ucClaimCheck)) {
      this.ucClaimCheck = [this.ucClaimCheck];
    }

    this.userService.userDetail.subscribe((detail) => {
      const hasAccess = this.ucClaimCheck.some((r) => detail.mainUser.claims.indexOf(r) !== -1);
      if (hasAccess) {
        if (this.viewContainer.length === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
      }
    });
  }
}

@Directive({
  selector: '[ucClaimCheck]',
})
export class MockClaimCheckDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
