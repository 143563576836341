import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-aes-admission',
  templateUrl: './aes-admission.component.html',
  styleUrls: ['./aes-admission.component.scss'],
})
export class AesAdmissionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  strings = strings.components.tasks.aesAdmission;
  fundingOptions: { labelText: string; value: string }[];
  taskForm: UntypedFormGroup;
  log: Logger;

  currentApplication: Application;

  options = ['Private funds', 'A scholarship from my home country', 'Other'];

  constructor(
    private fb: UntypedFormBuilder,
    private applicationService: ApplicationService,
    private loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity(_err: UCError): void {}

  ngOnInit() {
    this.taskForm = this.fb.group({
      fundingRadio: ['', Validators.required],
    });

    this.fundingOptions = this.options.map((key) => {
      return { labelText: key, value: key };
    });

    this.applicationService.application.pipe(takeUntil(this.componentDestroyed)).subscribe((app: Application) => {
      this.currentApplication = app;
      this.updateFormFromApplication(app);
    });

    this.taskForm
      .get('fundingRadio')
      .valueChanges.pipe(takeUntil(this.componentDestroyed))
      .subscribe((val) => this.updateFormControls(val));
  }

  private updateFormControls(val) {
    const [privatePay, home, other] = this.options;
    const form = this.taskForm;
    if (val === home) {
      form.addControl('scholarshipProvider', this.fb.control('', Validators.required));
      form.removeControl('paymentMethodOther');
    }

    if (val === other) {
      form.addControl('paymentMethodOther', this.fb.control('', Validators.required));
      form.removeControl('scholarshipProvider');
    }

    if (val === privatePay) {
      form.removeControl('scholarshipProvider');
      form.removeControl('paymentMethodOther');
    }
  }

  private updateFormFromApplication(app) {
    const { paymentMethod, scholarshipProvider, paymentMethodOther } = app;
    if (paymentMethod && paymentMethod.code) {
      this.updateFormControls(paymentMethod.code);
      this.taskForm.get('fundingRadio').setValue(paymentMethod.code);

      if (scholarshipProvider) {
        this.taskForm.get('scholarshipProvider').setValue(scholarshipProvider);
      }
      if (paymentMethodOther) {
        this.taskForm.get('paymentMethodOther').setValue(paymentMethodOther);
      }
    }
  }

  public update() {
    if (!this.currentApplication) {
      this.errors.emit();
      this.log.error('tried to update application but no application has been loaded yet');
    }

    // update the application
    const { fundingRadio, scholarshipProvider, paymentMethodOther } = this.taskForm.value;
    this.currentApplication.paymentMethod = new ReferenceData({ code: fundingRadio });
    this.currentApplication.scholarshipProvider = scholarshipProvider || null;
    this.currentApplication.paymentMethodOther = paymentMethodOther || null;

    this.applicationService.updateApplication(this.currentApplication).subscribe(
      (app) => {
        this.log.info('updated application successfully', app);
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('could not update application', err);
      },
    );
  }
}
