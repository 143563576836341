import { Component, OnInit } from '@angular/core';

import { internalUrls } from '@constants/internalUrls';
import { links } from '@constants/links.constants';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { User, UserDetail, UserTypes } from '@shared/models/user';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-header-nav-dropdown',
  templateUrl: './header-nav-dropdown.component.html',
  styleUrls: ['./header-nav-dropdown.component.scss'],
})
export class HeaderNavDropdownComponent implements OnInit {
  profilePageLink = links.profilePageLink;
  userInitials = '';
  user: User;
  impersonated: User;
  open: boolean;
  strings = strings.components.organisms;
  env: { [key: string]: boolean } = environment.features || {};
  internalUrls = internalUrls;
  log: Logger;

  constructor(
    public userService: UserService,
    loggingSerivce: LoggingService,
  ) {
    this.log = loggingSerivce.createLogger(this);
  }

  get currentUser() {
    return this.user;
  }

  ngOnInit() {
    this.userService.userDetail.pipe().subscribe((detail) => {
      if (detail) {
        this.updateUser(detail);
      }
    });
  }

  private updateUser(detail: UserDetail) {
    if (environment.scope === UserTypes.staff && detail.impersonated) {
      this.user = detail.student;
    } else {
      this.user = detail.mainUser;
    }
    this.userInitials = this.user.initials(environment.scope);
  }

  logOut() {
    this.userService.logout().catch((err) => this.log.error(err));
  }
}
