import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import {
  QualificationEnrolment,
  QualificationAnswer,
  QualEnrolmentSelectorContext,
  EnrolmentUpdateEvent,
} from '@shared/applicant/qualification/model';
import { QualificationSummary, EnrolledQualification } from '@shared/models/qualification';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-enrolment-selector',
  templateUrl: './enrolment-selector.component.html',
  styleUrls: ['./enrolment-selector.component.scss'],
})
export class EnrolmentSelectorComponent implements OnChanges {
  /**
   * this is the incoming options to support different ruleset for seleting a qualification
   */
  @Input() qualificationOptions: QualificationSummary[];
  /*
   * This lets us load a user's selected qual enrolments
   */
  @Input() qualificationEnrolments: QualEnrolmentSelectorContext[] = [];
  @Input() process: string;
  @Input() year: string;
  @Input() showQualificationSelector = true;
  @Input() selectedQual?;
  @Output() qualificationAnswered = new EventEmitter<EnrolmentUpdateEvent[]>();
  @Output() qualForm = new EventEmitter<UntypedFormGroup>();

  concurrentQualificationOptions: QualificationSummary[][] = [];

  strings = strings.components.organisms.enrolmentSelector;
  orderStrings = ['First', 'Second', 'Third'];
  qualificationOptionsBackup: QualificationSummary[];
  concurrentQualificationOptionsBackup: QualificationSummary[][] = [];
  setupConcurrentList = true;

  private log: Logger;

  constructor(
    private loggingService: LoggingService,
    private qualificationService: QualificationService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  ngOnChanges(ch: SimpleChanges) {
    if (ch.qualificationEnrolments) {
      const qualEnrolments = this.qualificationEnrolments || [];
      this.updateEnrolmentsWithFormControl();

      qualEnrolments.forEach((qual, index) => {
        if (get(qual, 'firstQualificationEnrolment.qualificationAnswer.code')) {
          this.getConcurrentOptions(qual.firstQualificationEnrolment.qualificationAnswer.code, index);
        } else {
          this.concurrentQualificationOptions[index] = null;
        }
      });
    }
  }

  getConcurrentOptions(currentQualCode: string, index: number) {
    if (!currentQualCode) {
      this.qualificationEnrolments[index].isConcurrent.setValue(false);
      return (this.concurrentQualificationOptions[index] = null);
    }
    this.qualificationService
      .getConcurrentQualifications(this.year, this.process, currentQualCode)
      .subscribe((qualOptions) => {
        let selectedQualOptions = qualOptions;
        if (selectedQualOptions && selectedQualOptions.length === 0) {
          this.qualificationEnrolments[index].isConcurrent.setValue(false);
        }
        if (this.selectedQual && this.selectedQual.length) {
          selectedQualOptions = selectedQualOptions.filter((qual) =>
            this.selectedQual.some((code) => code !== qual.code),
          );
        }
        this.concurrentQualificationOptions[index] = selectedQualOptions;
        if (this.setupConcurrentList) {
          this.setupConcurrentOptionsList();
        }
      });
  }

  public onSaveFirst(event: QualificationEnrolment, i: number) {
    event.priority = 1;
    this.qualificationEnrolments[i].firstQualificationEnrolment = event;
    this.emitEnrolments();
  }

  public onSaveSecond(event: QualificationEnrolment, i: number) {
    event.priority = 2;
    this.qualificationEnrolments[i].secondQualificationEnrolment = event;
    this.emitEnrolments();
  }

  public delete(i: number) {
    this.concurrentQualificationOptions[i] = null;
    this.qualificationEnrolments.splice(i, 1);
    this.emitEnrolments();
  }

  private emitEnrolments() {
    const es: EnrolmentUpdateEvent[] = this.qualificationEnrolments.map((e, i) => {
      if (e && e.applicationEnrolment) {
        e.applicationEnrolment.displayOrder = i + 1;
        e.applicationEnrolment.priority = i + 1;
      }
      const currentEnrolledQuals = e.applicationEnrolment.enrolledQualifications;
      const newEnrolledQualAnswers = this.getQualAnswers(e);
      e.applicationEnrolment.enrolledQualifications = newEnrolledQualAnswers.map((el, j) => {
        if (currentEnrolledQuals[j]) {
          if (currentEnrolledQuals[j].code !== el.code) {
            el.qualificationOccurrence = null;
          }
          const newAE = { ...currentEnrolledQuals[j], ...el };
          newAE.priority = currentEnrolledQuals[j].priority || j + 1;
          return newAE;
        } else {
          const newAE = Object.assign(new EnrolledQualification({}), el);
          newAE.priority = j + 1;
          return newAE;
        }
      });
      return { applicationEnrolment: e.applicationEnrolment, isConcurrent: e.isConcurrent.value };
    });
    this.qualificationAnswered.emit(es);
  }
  private getQualAnswers(e: QualEnrolmentSelectorContext): QualificationAnswer[] {
    const fqe = e.firstQualificationEnrolment;
    const firstAnswer = (fqe && fqe.asQualificationAnswer()) || null;
    const sqe = e.secondQualificationEnrolment;
    const secondAnswer = (sqe && sqe.asQualificationAnswer()) || null;
    return [firstAnswer, secondAnswer].filter((v) => !!v);
  }

  public updateEnrolmentsWithFormControl() {
    const qualEnrolments = this.qualificationEnrolments || [];
    // mutate the list of enrolments to include a formControl for toggling state
    qualEnrolments.forEach((e) => {
      const concurrent = e.applicationEnrolment.enrolledQualifications.length === 2;
      const toggleControl = new UntypedFormControl(concurrent);
      toggleControl.valueChanges.subscribe(() => {
        // let QualificationComponent know that we've changed the concurrent status
        this.emitEnrolments();
      });
      e.isConcurrent = toggleControl;
    });
  }

  qualSelectorFormEmitter(qualForm: UntypedFormGroup) {
    this.qualForm.emit(qualForm);
  }

  setupConcurrentOptionsList() {
    this.concurrentQualificationOptionsBackup[0] = this.concurrentQualificationOptions[0];
    if (this.qualificationEnrolments[0]?.secondQualificationEnrolment?.qualificationAnswer) {
      const secondaryQual = this.qualificationEnrolments[0].secondQualificationEnrolment.qualificationAnswer;
      this.removeSelectedQualFromPrimaryOptions(secondaryQual);
    }
    if (this.qualificationEnrolments[0]?.firstQualificationEnrolment?.qualificationAnswer) {
      const primaryQual = this.qualificationEnrolments[0].firstQualificationEnrolment.qualificationAnswer;
      this.removeSelectedQualFromSecondaryOptions(primaryQual);
    }
    this.setupConcurrentList = false;
  }

  removeSelectedQualFromSecondaryOptions(selectedQual) {
    if (this.concurrentQualificationOptionsBackup[0]) {
      this.concurrentQualificationOptions[0] = this.concurrentQualificationOptionsBackup[0];
      this.concurrentQualificationOptions[0] = this.concurrentQualificationOptions[0].filter(
        (qual) => qual.code !== selectedQual.code,
      );
    }
  }

  removeSelectedQualFromPrimaryOptions(selectedQual) {
    if (!this.qualificationOptionsBackup) {
      this.qualificationOptionsBackup = this.qualificationOptions;
    }
    this.qualificationOptions = this.qualificationOptionsBackup;
    this.qualificationOptions = this.qualificationOptions.filter((qual) => qual.code !== selectedQual.code);
  }
}
