<svg
  *ngIf="iconType !== ''"
  viewBox="0 0 512 512"
  tabindex="-1"
  focusable="false"
  xmlns="http://www.w3.org/2000/svg"
  [attr.class]="classes"
  [style.width.px]="iconWidth"
  [style.height.px]="iconHeight"
  [style.background-color]="colors[backgroundColor] || backgroundColor"
  [style.fill]="colors[color] || colors[iconFill] || color || iconFill">
  <title>{{ iconTitle }}</title>
  <g [attr.fill-rule]="fillRule" [attr.style]="lineStyle">
    <path [attr.d]="iconSVG"></path>
  </g>
</svg>
