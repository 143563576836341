import { Component, OnInit } from '@angular/core';

import strings from '@constants/strings.constants';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-social-login',
  template: `
    <div class="social-btns">
      <uc-button (click)="loginSocial('Facebook')" class="large rounded has-icon facebook">
        <uc-icon iconType="facebook" iconWidth="24" iconHeight="24" iconFill="$colorWhite"></uc-icon>
        {{ templateStrings.connect }} {{ templateStrings.facebook }}
      </uc-button>
      <uc-button (click)="loginSocial('Google')" class="large rounded has-icon google">
        <uc-icon iconType="google" iconWidth="24" iconHeight="24" iconFill="$colorWhite"></uc-icon>
        {{ templateStrings.connect }} {{ templateStrings.google }}
      </uc-button>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep uc-button:first-child button {
        margin-bottom: 15px;
      }
    `,
  ],
})
export class SocialLoginComponent implements OnInit {
  templateStrings = strings.components.organisms.socialLogin;

  log: Logger;

  constructor(
    private userService: UserService,
    private loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {}

  loginSocial(type) {
    this.userService.loginFirebase(type);
  }
}
