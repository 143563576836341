import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import strings from '@shared/constants/strings.constants';
import { Application } from '@shared/models/application';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';

@Component({
  selector: 'uc-online-enrolment-success',
  templateUrl: './online-enrolment-success.component.html',
  styleUrls: ['./online-enrolment-success.component.scss'],
})
export class OnlineEnrolmentSuccessComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() isInternational = false;
  @Input() params: IProcessRouteParams;
  @Input() process: string;
  @Input() stageNumber: number;

  strings = strings.components.tasks.onlineEnrolmentSuccess;
  messageBanner: string;
  log: Logger;
  currentApplication: Application;
  courseName;

  constructor(
    private onlineProcessService: OnlineProcessService,
    private applicationService: ApplicationService,
    private onlineCourseService: OnlineCourseService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete(): boolean {
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  ngOnInit() {
    this.applicationService.application
      .pipe(filter((a) => !!a))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
      });

    this.courseName = `${this.onlineCourseService.onlineCourseSelectedCourse.metadata.courseName.toString()}.`;
    this.submitFinalStage();
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  updateFormValidity() {}

  submitEnrolmentsIfRequired(): Observable<Application> {
    return this.onlineProcessService.submitEnrolment(this.onlineCourseService.getCourseYear());
  }

  submitFinalStage() {
    return this.onlineProcessService
      .submitStage(this.params.process, this.params.year, this.params.stage)
      .subscribe((_) => {
        this.log.info('Stopping process evaluation');
        this.onlineProcessService.stopEvaluatingProcess();
      });
  }

  submitEnrolmentOnUpdate() {
    this.submitEnrolmentsIfRequired().subscribe({
      next: (app) => {
        if (app) {
          app.declarationAgree = null;
          this.applicationService.application$.next(app);
        }
        this.next.emit();
      },
      error: (err) => {
        this.log.error(new Error('Error submitting stage'));
        this.errors.emit(err);
      },
    });
  }

  update() {
    this.submitEnrolmentOnUpdate();
  }
}
