<uc-form-template [formGroup]="academicHistory">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header>
      <p>{{ strings.description }}</p>
    </uc-section-header>
    <uc-subsection>
      <uc-subsection-question [required]="true">{{ strings.highestStudyLevelQuestion }}</uc-subsection-question>
      <uc-task-hint [task]="task" path="/applicant/ue_qualification/type"></uc-task-hint>
      <uc-radio-input-group
        type="ue_qualification"
        [additionalText]="highestStudyLevelAdditionalText"
        formControlName="highestStudyLevel"
        groupName="highestStudyLevel"></uc-radio-input-group>
    </uc-subsection>
  </uc-section>
  <uc-tertiary-education
    *ngIf="highestStudyLevelTertiary"
    [applicationYear]="applicationYear"
    [tertiaryType]="highestStudyLevel"
    [tertiaryEducationForm]="academicHistory.get('tertiaryEducation')"
    (addQual)="this.tertiaryEducations.push()"
    (removeQual)="this.tertiaryEducations.removeAt($event)"
    [task]="task"></uc-tertiary-education>

  <uc-secondary-education
    *ngIf="highestStudyLevel"
    [highestStudyLevel]="highestStudyLevel"
    [applicationYear]="applicationYear"
    [secondaryEducationForm]="academicHistory.get('secondaryEducation')"
    [secondaryLocationForm]="secondaryLocationForm"
    (addQual)="addSecondaryQual()"
    (removeQual)="removeSecondaryQual($event)"
    [task]="task"></uc-secondary-education>
</uc-form-template>
