import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';

import { UCValidators } from '@shared/models/validators/validators';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateTimeSelectorComponent implements OnInit {
  @Input() order: string;
  @Input() range: number;

  private log: Logger;
  state: string;
  hasValue = false;

  dateTimeForm = this.fb.group({
    date: ['', UCValidators.validateDate],
    time: ['', UCValidators.validateTime],
  });

  constructor(
    private loggingService: LoggingService,
    private fb: UntypedFormBuilder,
  ) {
    this.log = loggingService.createLogger(this);
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => {};
  private propagateTouch = (_: any) => {};

  setOverrideValidator(date, time) {
    this.state = UCValidators.checkDateFormat(date) && UCValidators.checkTimeFormat(time) ? 'VALID' : 'INVALID';
  }

  ngOnInit() {
    this.dateTimeForm.valueChanges.subscribe((value: { date: string; time: string }) => {
      this.hasValue = true;
      this.setOverrideValidator(value.date, value.time);
      const datetime = DateTime.fromFormat(`${value.date} ${value.time}`, 'yyyy-MM-dd HH:mm').toUTC().toISO();
      this.propagateChange(datetime);
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(value: string): void {
    if (value == null) {
      return;
    }
    this.hasValue = true;

    if (DateTime.fromISO(value).isValid) {
      const datetime = DateTime.fromISO(value);
      this.dateTimeForm.setValue({
        date: datetime.toISODate(),
        time: datetime.toFormat('HH:mm'),
      });
      this.dateTimeForm.updateValueAndValidity();
    } else {
      this.log.warn('should be a valid date but is: ', value);
    }
  }
  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
