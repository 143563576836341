<div class="screen-overlay" [class.show-overlay]="showOverlay">
  <div class="screen-overlay-inner clearfix">
    <div class="screen-message-img">
      <img *ngIf="!iconType && strings.imageUrl" src="{{ strings.imageUrl }}" alt="" />
      <uc-icon
        *ngIf="iconType"
        [iconType]="iconType"
        iconWidth="90"
        iconHeight="90"
        iconFill="$colorFireEngineRed"></uc-icon>
    </div>
    <div class="screen-message">
      <h1 class="screen-message-title">{{ strings.title }}</h1>
      <div class="screen-message-body">
        <span innerHTML="{{ strings.body }}"></span>
      </div>
      <a role="button" class="screen-message-btn" href="javascript:void(0)" (click)="buttonAction()">
        {{ strings.buttonText }}
      </a>
      <a
        *ngIf="showFeedbackButton"
        role="button"
        class="screen-message-btn feedback-btn"
        href="javascript:void(0)"
        (click)="openFeedbackModal()">
        {{ overlayStrings.submitFeedback }}
      </a>
    </div>
  </div>
</div>
