import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { internalUrls } from '@constants/internalUrls';
import { environment } from '@environment';

@Injectable()
export class FeatureGuard {
  constructor(private router: Router) {}

  isAllowedFeature(url): boolean {
    const features = environment.features || {};
    return !Object.keys(internalUrls).some((routeName) => {
      if (url === internalUrls[routeName][0] && !features[routeName]) {
        this.router.navigate(internalUrls.fourOhFour);
        return true;
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAllowedFeature(state.url);
  }
}
