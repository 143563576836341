<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-spinner *ngIf="loading" [useStaticPositioning]="true"></uc-spinner>
  <uc-secondary-education
    *ngIf="!loading"
    [highestStudyLevel]="'SECONDARY'"
    [applicationYear]="applicationYear"
    [secondaryEducationForm]="secondaryEducation"
    [secondaryLocationForm]="secondaryLocationForm"
    (addQual)="addSecondaryQual()"
    (removeQual)="removeSecondaryQual($event)"
    [task]="task"
    [process]="process"></uc-secondary-education>
</uc-form-template>
