<uc-form-template [formGroup]="onlineVerificationPage">
  <uc-form-header [title]="task?.title">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-subsection>
      <p class="header-description">{{ strings.description[0] }}</p>
      <p class="header-description">{{ strings.description[1] }}</p>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="strings.documentUploadTitle"></uc-section-header>
    <uc-task-hint [task]="task" path="/documents/identity"></uc-task-hint>
    <uc-subsection>
      <uc-subsection-question [required]="true">
        {{ strings.passportInfo }}
      </uc-subsection-question>
      <uc-file-upload
        [year]="applicationYear"
        title="Identity document"
        categoryCode="IDENTITY"
        usedInTask="true"></uc-file-upload>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="isConnectedToRealMe">
    <uc-task-hint [task]="task" path="/documents/identity"></uc-task-hint>
    <uc-section-header [title]="strings.realMeConnectTitle"></uc-section-header>
    <uc-subsection>
      <uc-subsection-question [required]="true">
        {{ strings.realMeConnectHelp }}
      </uc-subsection-question>
      <uc-button (click)="connectToRealMe()" class="large has-icon realme" *ngIf="!isRealMeVerification">
        <uc-icon iconType="realme" iconWidth="120" iconHeight="120" iconFill="$colorWhite"></uc-icon>
        Connect my RealMe identity
      </uc-button>
      <div
        class="realMeVerifyResultContainer"
        [class.valid]="isRealMeVerificationSuccessful"
        [class.invalid]="isRealMeVerificationFailed || isRealMeVerificationDisconnected"
        *ngIf="isRealMeVerification">
        <div class="realMeIconContainer">
          <uc-icon iconType="realme" iconWidth="160" iconHeight="160"></uc-icon>
        </div>
        <p *ngIf="isRealMeVerificationSuccessful">{{ strings.realMeVerificationSuccessDesc }}</p>
        <p *ngIf="isRealMeVerificationFailed">
          {{ strings.realMeVerificationFailedDesc }}
        </p>
        <div class="realMeConnectionIssueContainer" *ngIf="isRealMeVerificationDisconnected">
          <p>
            {{ strings.realMeVerificationConnectionIssueDesc }}
          </p>
          <uc-button (click)="tryAgainRealMe()" class="small secondary">Try Again</uc-button>
        </div>
        <div class="validation-indicator validate-options">
          <uc-icon
            iconType="tick"
            class="valid-icon"
            iconTitle="Valid"
            iconWidth="11"
            iconHeight="11"
            iconFill="$colorWhite"
            *ngIf="isRealMeVerificationSuccessful"></uc-icon>
          <uc-icon
            iconType="close"
            class="invalid-icon"
            iconTitle="Invalid"
            iconWidth="11"
            iconHeight="11"
            iconFill="$colorWhite"
            *ngIf="isRealMeVerificationFailed || isRealMeVerificationDisconnected"></uc-icon>
        </div>
      </div>
    </uc-subsection>
  </uc-section>
</uc-form-template>
