<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section [formGroup]="taskForm">
    <uc-section-header [title]="strings.header"></uc-section-header>
    <uc-subsection>
      <uc-spinner *ngIf="isLoading"></uc-spinner>
      <uc-reference-data-selector
        *ngIf="!isLoading"
        class="large-input"
        formControlName="qualificationCode"
        [useNgSelect]="true"
        [required]="true"
        [labelName]="strings.qualLabel"
        [options]="options"></uc-reference-data-selector>
    </uc-subsection>
  </uc-section>
</uc-form-template>
