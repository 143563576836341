import { Component, OnInit } from '@angular/core';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { externalUrls } from '@shared/constants/urls.constants';
import { User, UserDetail, UserTypes } from '@shared/models/user';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-online-dashboard-tiles',
  templateUrl: './online-dashboard-tiles.component.html',
  styleUrls: ['./online-dashboard-tiles.component.scss'],
})
export class OnlineDashboardTilesComponent implements OnInit {
  breakpoints = [
    [550, 1],
    [1024, 2],
    [1920, 4],
  ];
  strings = strings.components.template.dashboard;
  externalUrls = externalUrls;
  user: User;
  userInitials = undefined;
  log: Logger;

  constructor(
    public userService: UserService,
    loggingSerivce: LoggingService,
  ) {
    this.log = loggingSerivce.createLogger(this);
  }

  get currentUser() {
    return this.user;
  }

  ngOnInit() {
    this.userService.userDetail.pipe().subscribe((detail) => {
      if (detail) {
        this.updateUser(detail);
      }
    });
  }

  private updateUser(detail: UserDetail) {
    if (environment.scope === UserTypes.staff && detail.impersonated) {
      this.user = detail.student;
    } else {
      this.user = detail.mainUser;
    }
    this.userInitials = this.user.initials(environment.scope);
  }
}
