/* eslint-disable no-console */
import {
  HttpRequest,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { mergeMap, delay } from 'rxjs/operators';

import { environment } from '@environment';
import { User, UserDetail, Roles } from '@shared/models/user';
import { mockData as mockApplicantResponse } from '@shared/services/applicant/applicant.data.mock';
import { HttpVerbs } from '@shared/services/data-service';
import { qualificationList, baQualificationFull } from '@shared/services/qualification/mock-qualification-response';

// Set this to true to replicate auth server response for unverified Firebase user
const enforceEmailVerification = false;

const deserializeFBUser = (fbToken) => {
  if (!fbToken.name) {
    fbToken.name = 'Anonymous User';
  }

  let [firstName, lastName] = fbToken.name.split(' ');
  if (!lastName) {
    lastName = firstName;
    firstName = '';
  }

  return new User({ firstName, lastName, email: fbToken.email, studentId: 'izstudint', claims: [Roles.Student] });
};

const users = {
  student: {
    maw123: new User({
      firstName: 'Me',
      lastName: 'Mememe',
      email: 'maw123@uclive.ac.nz',
      canonicalId: 'student1',
      claims: [Roles.Student],
    }),
    miw123: new User({
      firstName: 'Me',
      lastName: 'Mememe',
      email: 'mike@uclive.ac.nz',
      canonicalId: 'g4Dgpcn3IUafbcOrEXzJiUsIJjKPMR',
      claims: [Roles.Student],
    }),
    jps123: new User({
      firstName: 'Me',
      lastName: 'Mememe',
      email: 'jps@uclive.ac.nz',
      canonicalId: 'sdfgDSFG34SDFG3tjdfg34DFJ76dgD',
      claims: [Roles.Student],
    }),
    bab123: new User({
      firstName: 'Me',
      lastName: 'Mememe',
      email: 'boo@uclive.ac.nz',
      canonicalId: 'jydfhe32sdfh76rtfg7ewesdfHJFTT',
      claims: [Roles.Student],
    }),
  },
  staff: {
    stf123: new User({ firstName: 'Iz', lastName: 'Staff', email: 'stf123@uclive.ac.nz', claims: [Roles.Staff] }),
    adm123: new User({
      firstName: 'Iz',
      lastName: 'Staff-admin',
      email: 'adm123@uclive.ac.nz',
      claims: [
        Roles.StaffAdmin,
        Roles.RunHydrate,
        Roles.ContactCentre,
        Roles.CanImpersonate,
        Roles.AgencyManager,
        Roles.CanViewApplications,
        Roles.CanMaintainQualOccurrences,
        Roles.CanViewQualOccurrences,
        Roles.CanViewStudent,
        Roles.CanViewDisabilities,
        Roles.CanViewChildrensAct,
        Roles.CanUpdateApplicantStandard,
        Roles.CanViewTeachingApplication,
        Roles.CanUpdateDisabilities,
        Roles.CanEditApplicationLabels,
      ],
    }),
    cc123: new User({
      firstName: 'Iz',
      lastName: 'Contact-centre',
      email: 'cc123@uclive.ac.nz',
      claims: [Roles.ContactCentre],
    }),
  },
  agent: {
    abc12: new User({ firstName: 'Iz', lastName: 'Agent', email: 'abc12@uclive.ac.nz', preferredName: 'A & A Agency' }),
  },
};

const getStudentByCanonical = (id) => {
  const username = Object.keys(users.student).find(
    (key) => id && users.student[key] && users.student[key].canonicalId === id,
  );
  return users.student[username];
};

const detailResponse = (userDetail, timeout?: number) => {
  return {
    timeout: timeout || 1200,
    ...userDetail,
  };
};

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  userDetail: UserDetail;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!Object.keys(environment.useFakeBackend).length) {
      return next.handle(request);
    }

    // configure fake backend
    // wrap in delayed observable to simulate server api call
    return observableOf(true).pipe(
      delay(2000),
      mergeMap(() => {
        // authenticate
        if (environment.useFakeBackend.user) {
          if (request.url.match('/auth/user') && request.method === HttpVerbs.POST) {
            const user = { canonical_id: 'student1' };
            console.log('GET /auth/user 200:', user);
            return observableOf(new HttpResponse({ body: { user: JSON.stringify(user) }, status: 200 }));
          }

          if (request.url.match('/auth/staff/(.+)/aliases') && request.method === HttpVerbs.GET) {
            const aliases = { aliases: ['1234', '5678'] };
            console.log('GET/auth/staff/<cid>/aliases 200:', aliases);
            return observableOf(new HttpResponse({ body: aliases, status: 200 }));
          }

          const queryMatch = request.url.match(/auth\/staff\/admin\/user\?query=(.+)&type=(.+)/);
          if (queryMatch && request.method === HttpVerbs.POST) {
            const basicEmailRegex = /(.+)@(.+){2,}\.(.+){2,}/;
            if (queryMatch[2].match('email') && !queryMatch[1].match(basicEmailRegex)) {
              console.log('POST /auth/staff/admin/user 422:');
              return observableThrowError(
                new HttpErrorResponse({ status: 422, error: { errors: [{ source: { pointer: 'Bad email' } }] } }),
              );
            }

            const adminUser = {
              user: {
                cid: queryMatch[1],
                student_id: '123213',
                fbid: ['canid1'],
              },
              applicant: mockApplicantResponse().applicant,
              aliases: [],
              errors: [],
            };

            console.log('POST /auth/staff/user?query=(.+)&type=(.+)/ 200:', adminUser);
            return observableOf(new HttpResponse({ body: { results: [adminUser] }, status: 200 }));
          }

          if (request.url.match('/auth/me')) {
            const localStorageUser = window.localStorage.getItem('ucdevuser');
            this.userDetail = localStorageUser ? new UserDetail(JSON.parse(localStorageUser)) : null;
            if (this.userDetail) {
              console.log('GET /auth/me 200:', this.userDetail);
              return observableOf(new HttpResponse({ body: this.userDetail, status: 200 }));
            } else {
              const err = new HttpErrorResponse({ status: 401 });
              console.log('GET /auth/me 401:', err);
              return observableThrowError(err);
            }
          }

          const logout = () => {
            this.userDetail = null;
            window.localStorage.removeItem('ucdevuser');
            return observableOf(new HttpResponse({ status: 204 }));
          };

          const isTokenRequest = request.url.match('/auth/token');
          if (isTokenRequest) {
            if (request.method === HttpVerbs.DELETE) {
              console.log('DELETE /auth/token: 204');
              const scopeMatch = request.urlWithParams.match(/auth\/token\?scope=(.+)/);
              if (this.userDetail && this.userDetail.impersonated && scopeMatch) {
                const scope = scopeMatch[1];
                this.userDetail[scope] = null;
              } else {
                logout();
              }
            } else if (request.method === HttpVerbs.POST) {
              // /auth/token/<canonical_id>
              const isImpersonationRequest = request.urlWithParams.match(/auth\/token\/(.+)/);
              if (isImpersonationRequest) {
                const lookupId = isImpersonationRequest[1];
                if (lookupId) {
                  // only staff and agents can impersonate
                  if ((this.userDetail && this.userDetail.staff) || this.userDetail.agent) {
                    const impersonated = getStudentByCanonical(lookupId);
                    this.userDetail.student = impersonated || users.student.miw123;
                    console.log(`POST /auth/token/${this.userDetail.student.canonicalId} 204:`, this.userDetail);
                  } else {
                    console.log(`POST /auth/token/${lookupId} 401:`);
                  }
                }
              }

              // /auth/token?scope=<student|agent|staff>
              const authHeader = request.headers.get('Authorization');
              if (authHeader) {
                if (authHeader.match(/^JWT/)) {
                  // get user from token in headers
                  const token = jwtDecode(authHeader) as { [key: string]: boolean };
                  if (enforceEmailVerification && !token.email_verified) {
                    const err = new HttpErrorResponse({
                      status: 422,
                      error: {
                        errors: [
                          {
                            status: 422,
                            detail: 'Email must be verified',
                            source: { pointer: 'token/email_verified' },
                          },
                        ],
                      },
                    });
                    console.log('POST /auth/token/ 422: Firebase user has not verified their email address');
                    return observableThrowError(err);
                  }

                  this.userDetail = UserDetail.deserialize(detailResponse({ student: deserializeFBUser(token) }));
                  console.log('POST /auth/token (JWT): 204', this.userDetail);
                } else if (authHeader.match(/^Basic/)) {
                  // get user from UC credentials
                  const base64d = authHeader.match(/^Basic (.+)/)[1];
                  const username = atob(base64d).split(':')[0];
                  const scopeMatch = request.urlWithParams.match(/auth\/token\?scope=(.+)/);
                  let scope = 'student';
                  if (scopeMatch) {
                    scope = scopeMatch[1];
                  }
                  const userObj = { [scope]: users[scope][username] };
                  if (!userObj[scope]) {
                    this.userDetail = null;
                  } else {
                    this.userDetail = UserDetail.deserialize(detailResponse(userObj));
                    console.log('POST /auth/token (LDAP): 204', this.userDetail);
                  }
                }
              }
            }

            if (this.userDetail) {
              // if login details are valid return 204
              window.localStorage.setItem('ucdevuser', JSON.stringify(this.userDetail));
              return observableOf(new HttpResponse({ status: 204 }));
            } else {
              window.localStorage.removeItem('ucdevuser');
              const err = new HttpErrorResponse({ status: 403 });
              return observableThrowError(err);
            }
          }
        }

        // applicant
        if (environment.useFakeBackend.applicant && request.url.endsWith('/applicant/')) {
          // Get
          if (request.method === HttpVerbs.GET) {
            return observableOf(
              new HttpResponse({
                status: 200,
                body: mockApplicantResponse(),
              }),
            );
          }

          // Update
          if (request.method === HttpVerbs.PUT) {
            return observableOf(
              new HttpResponse({
                status: 201,
                body: request.body,
              }),
            );
          }

          return;
        }

        // qualification
        const isQualificationRequest = request.url.match(/\/qualification(\/\d+)?(\/.+)?$/);
        if (environment.useFakeBackend.qualification && isQualificationRequest) {
          const code = isQualificationRequest[2];

          // List for year
          if (request.method === HttpVerbs.GET && !code) {
            return observableOf(
              new HttpResponse({
                status: 200,
                body: JSON.stringify(qualificationList),
              }),
            );
          }

          // Specific qual request, with majors and minors
          if (request.method === HttpVerbs.GET && code) {
            return observableOf(
              new HttpResponse({
                status: 200,
                body: JSON.stringify(baQualificationFull),
              }),
            );
          }
          return;
        }

        return next.handle(request);
      }),
    );
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
