<uc-form-template [formGroup]="finalisePage.get('permanentAddressGroup')">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.permanentAddress.header }}"></uc-section-header>
    <uc-subsection>
      <uc-form-input>
        <div class="input">
          <uc-address-selector
            testSelector="permantent"
            [task]="task"
            formControlName="permanentAddress"></uc-address-selector>
        </div>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template [formGroup]="finalisePage.get('studyAddressGroup')">
  <uc-section class="study-address-section">
    <uc-section-header title="{{ strings.studyAddress.header }}">
      <p class="description" [innerHTML]="strings.studyAddress.subHeader"></p>
      <uc-button testSelector="copy-address" class="copy-address" (click)="copyPermanentToStudyAddress()">
        {{ strings.copyAddressButton }}
      </uc-button>
    </uc-section-header>
    <uc-subsection>
      <uc-form-input>
        <div class="input">
          <uc-address-selector
            testSelector="study"
            [required]="false"
            formControlName="studyAddress"></uc-address-selector>
        </div>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-emergency-contact
    [task]="task"
    [emergencyDetails]="finalisePage.get('emergencyDetails')"
    [copyAddressButtonText]="strings.copyAddressButton"
    (copyAddress)="copyPermanentToEmergencyAddress()"></uc-emergency-contact>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-section-header title="{{ strings.ethnicityHeader }}"></uc-section-header>
    <uc-ethnicity-iwi-selectors
      [task]="task"
      [demographicElements]="finaliseForm.controls['citizenship']"></uc-ethnicity-iwi-selectors>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-identity-groups [identityGroups]="identityGroups" [task]="task"></uc-identity-groups>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-learning-needs [task]="task" [learningNeedsFormGroup]="finalisePage.get('learningNeeds')"></uc-learning-needs>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-more-about-your-studies
    [task]="task"
    [maysForm]="finalisePage.get('moreAboutYourStudies')"
    [year]="year"
    [isFirstYear]="isFirstYear"></uc-more-about-your-studies>
</uc-form-template>

<uc-form-template *ngIf="shouldShowUCSAMembership">
  <uc-section>
    <uc-section-header title="{{ strings.UcsaMembership.header }}">
      <p class="description" [innerHTML]="strings.UcsaMembership.subHeader"></p>
      <p class="description" [innerHTML]="strings.UcsaMembership.body[0]"></p>
      <p class="description" [innerHTML]="strings.UcsaMembership.body[1]"></p>
      <p class="description" [innerHTML]="strings.UcsaMembership.body[2]"></p>
    </uc-section-header>
  </uc-section>
</uc-form-template>
