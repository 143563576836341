<ng-container *ngIf="enrolmentChange">
  <ng-container *ngFor="let qualification of enrolmentChange.qualifications; let i = index">
    <div
      class="item-content-section"
      *ngIf="qualification.hasQualChanges || qualification.droppedCourses.length || qualification.addedCourses.length">
      <div *ngIf="qualification.addedQualification">
        <h4 class="section-heading">
          {{ strings.qualAdded }}
        </h4>
      </div>

      <h3 class="qualification-heading">
        {{ enrolmentChange.year }} {{ qualification?.qualificationData?.title }} ({{
          qualification?.qualificationData?.code
        }})
      </h3>
      <div *ngIf="qualification.changedSubjectOptions">
        <h4 class="section-heading">
          {{ strings.subjectChanged }}
        </h4>
      </div>

      <uc-tag-list
        *ngIf="qualification.enrolledQualification.subjectOptions"
        [subjectAnswer]="qualification.enrolledQualification.subjectOptions"
        [cropLongText]="false"
        [subjectOptions]="qualification.qualificationData.subjectOptions"></uc-tag-list>

      <div class="validation-messages" *ngIf="qualification.addedQualification && !qualification.addedCourses.length">
        <h5 class="validation-messages__heading">{{ strings.errorTitle }}</h5>
        <uc-message-banner type="warning" *ngIf="showErrorMsg">
          <span class="validation-messages_message">
            {{ strings.errorDescription }}
          </span>
        </uc-message-banner>
      </div>
      <div *ngIf="qualification.addedCourses.length">
        <h4 class="section-heading section-heading--wide">
          {{ strings.coursesAdded }}
        </h4>
        <ng-container *ngIf="!minimalView">
          <uc-course-view *ngFor="let course of qualification.addedCourses" [course]="course"></uc-course-view>
        </ng-container>
        <ng-container *ngIf="minimalView">
          <uc-course-minimal-view
            *ngFor="let course of qualification.addedCourses"
            [course]="course"
            [showDates]="true"></uc-course-minimal-view>
        </ng-container>
      </div>

      <div *ngIf="qualification.droppedCourses.length">
        <h4 class="section-heading section-heading--wide">
          {{ strings.coursesRemoved }}
        </h4>
        <ng-container *ngIf="!minimalView">
          <uc-course-view *ngFor="let course of qualification.droppedCourses" [course]="course"></uc-course-view>
        </ng-container>
        <ng-container *ngIf="minimalView">
          <uc-course-minimal-view
            *ngFor="let course of qualification.droppedCourses"
            [course]="course"
            [showDates]="true"></uc-course-minimal-view>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
