<uc-subsection *ngIf="conditionItem">
  <div class="document-upload-header">
    <uc-subsection-question>{{ title }}</uc-subsection-question>
    <div *ngIf="documentCategory">
      <uc-tag [class]="status.colour">{{ status.text }}</uc-tag>
    </div>
  </div>
  <uc-subsection-minor-text *ngIf="subHeadingContextHandler" class="subsection-minor-header">
    <span>
      {{ subHeadingContextHandler.code }} - {{ (qualification | async)?.title }} {{ (course | async)?.courseTitle }}
    </span>
  </uc-subsection-minor-text>
  <uc-subsection-minor-text class="condition__description">
    <span [innerHTML]="description"></span>
  </uc-subsection-minor-text>
  <uc-subsection-minor-text class="condition__reason" *ngIf="reason">
    <strong>{{ strings.reasonQuestion }}</strong>
    <br />
    {{ reason }}
  </uc-subsection-minor-text>
  <uc-subsection-minor-text class="condition__next-steps" *ngIf="nextSteps">
    <strong>{{ strings.nextStepsQuestion }}</strong>
    <br />
    <span [innerHTML]="nextSteps"></span>
  </uc-subsection-minor-text>
  <ng-container *ngIf="documentCategory">
    <uc-file-upload
      disableRemove="true"
      [year]="year"
      [title]="documentCategoryDescription"
      [categoryCode]="documentCategory"
      (uploadSuccess)="fileUploaded($event)"
      [hideUploadedFiles]="true"></uc-file-upload>
  </ng-container>
</uc-subsection>
