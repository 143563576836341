import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest, zip, of } from 'rxjs';
import { takeUntil, catchError, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { QualificationAnswer } from '@shared/applicant/qualification/model';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { APPLICATION_CHANGE_STATE, CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { STUDY_LEVELS } from '@shared/constants/study-options.constants';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { QualificationSummary, Qualification } from '@shared/models/qualification';
import { QualificationOccurrence } from '@shared/models/qualification-occurrence';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

// The structure of the data we build for each qual with qual occurrences
interface IOccurrenceSelectOptions {
  code: string;
  title: string;
  occurrences: QualificationOccurrence[];
  meta: IOccurrenceMetaData;
  qualOccurrence?: string;
  options?: {
    value: string;
    labelText: string;
  }[];
}

interface IOccurrenceMetaData {
  qualIndex: number;
  enrolmentIndex: number;
}

const semestersThatNeedDates = [
  STUDY_LEVELS.SUMMER_SCHOOL,
  STUDY_LEVELS.THESIS,
  STUDY_LEVELS.BLOCK,
  STUDY_LEVELS.OTHER,
];

@Component({
  selector: 'uc-study-options',
  templateUrl: './study-options.component.html',
  styleUrls: ['./student-options.component.scss'],
})
export class StudyOptionsComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() process: string;
  @Input() isInternational: string;
  @Input() stage: string;

  log: Logger;

  studyOptionsForm: UCElementGroup;
  studyOptionsPage: UntypedFormGroup;
  qualifications: QualificationSummary[];
  enrolmentFormGroup: UntypedFormGroup;
  qualOccurrenceOptions: IOccurrenceSelectOptions[] = [];
  applicationEnrolments: ApplicationEnrolment[];
  noQualOccurrences = false;
  strings = strings.components.tasks.studyOptions;
  studyIntentionsStrings = strings.components.tasks.applicationToEnrol.studyIntentions;
  shouldShowDateSelector = false;
  loading = true;

  extraDescriptions: { code: string; description: string }[] = Object.keys(
    this.studyIntentionsStrings.descriptions,
  ).map((code) => {
    const description = this.studyIntentionsStrings.descriptions[code];
    return { code, description };
  });

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private formMapper: FormModelMapperService,
    private fb: UntypedFormBuilder,
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get qualsWithOccurrences() {
    return this.qualOccurrenceOptions.filter((qo) => qo.occurrences.length > 0);
  }

  get noActiveOccurrences() {
    return this.qualOccurrenceOptions.filter((qo) => qo.occurrences.length === 0);
  }

  get noOccurrences() {
    return this.noQualOccurrences;
  }

  get occurrenceControls() {
    return this.enrolmentFormGroup.get('qualOccurrences') as UntypedFormArray;
  }

  private createForm(): UCElementGroup {
    return group({
      studyStart: control({
        model: this.dataModel,
        path: '/studyStart',
        validators: [Validators.required],
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      studyStartOther: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: this.dataModel,
        path: '/studyStartOther',
      }),
    });
  }

  public updateFormValidity(err: UCError) {
    this.formMapper.updateFormValidity(err.data, this.studyOptionsForm);
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.studyOptionsForm = this.createForm();
    this.studyOptionsPage = this.studyOptionsForm.asControl() as UntypedFormGroup;

    this.enrolmentFormGroup = this.fb.group({
      qualOccurrences: this.fb.array([]),
    });

    this.service.application
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((app) => !!app),
      )
      .subscribe((app) => {
        this.currentApplication = app;
        if (this.isCOE) {
          this.currentApplication = this.currentApplication as ChangeOfEnrolment;

          this.currentApplication.enrolledQualifications = this.currentApplication.enrolledQualifications.filter(
            (qual) => qual.changeAction === CHANGE_ACTIONS.ADDED,
          );
        }
        this.formMapper.updateFormFromModel(this.studyOptionsForm, null, this.currentApplication);

        const studyStartCode = get(this.currentApplication.studyStart, 'code');
        this.shouldShowDateSelector = semestersThatNeedDates.indexOf(studyStartCode) !== -1;
        if (this.process === PROCESS_NAMES.COE) {
          this.buildQualOccurrenceArraysForCOE();
        }
      });

    if (this.process !== PROCESS_NAMES.COE) {
      this.buildQualOccurrenceArrays();
    }

    this.studyOptionsPage.get('studyStart').valueChanges.subscribe((v) => {
      this.shouldShowDateSelector = semestersThatNeedDates.indexOf(v) !== -1;
    });
  }

  buildQualOccurrenceArrays() {
    const observables = [];

    this.applicationService
      .getApplicationEnrolment(this.applicationYear)
      .subscribe((applicationEnrolments: ApplicationEnrolment[]) => {
        this.applicationEnrolments = applicationEnrolments;
        if (this.process === PROCESS_NAMES.COA) {
          const coaStates = [APPLICATION_CHANGE_STATE.STUDENT_PENDING, APPLICATION_CHANGE_STATE.SHADOW];
          this.applicationEnrolments = applicationEnrolments.filter((ae) => coaStates.indexOf(ae.state.code) !== -1);
        }
        this.applicationEnrolments.forEach((ae, enrolmentIndex) => {
          this.updateEnrolledQual(observables, ae, enrolmentIndex);
        });
        this.updateQualOccurrenceResponse(observables);
      });
  }

  buildQualOccurrenceArraysForCOE() {
    const observables = [];
    this.updateEnrolledQual(observables, this.currentApplication);
    this.updateQualOccurrenceResponse(observables);
  }

  updateQualOccurrenceResponse(observables) {
    zip(...observables).subscribe({
      next: (response: [QualificationOccurrence[], Qualification, QualificationAnswer, IOccurrenceMetaData][]) => {
        response.forEach((res) => {
          const [occurrences, qualData, qualAnswer, meta] = res;
          if (!occurrences) {
            this.noQualOccurrences = true;
            return;
          }

          if (!this.isCOE && !qualAnswer.newInAward) {
            return;
          }

          const options = occurrences.map((qo) => {
            return {
              value: qo.internalReference,
              labelText: qo.intakeName,
            };
          });

          const qualOccurrence: IOccurrenceSelectOptions = {
            code: qualAnswer.code,
            title: qualData.title,
            qualOccurrence: qualAnswer.qualificationOccurrence,
            meta,
            occurrences,
            options,
          };
          this.qualOccurrenceOptions.push(qualOccurrence);

          if (occurrences.length > 0) {
            const occurrence = qualAnswer.qualificationOccurrence || '';
            this.occurrenceControls.push(new UntypedFormControl(occurrence));
          }
        });
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  generateHintPath(metadata: IOccurrenceMetaData) {
    return `/enrolments/${metadata.enrolmentIndex}/enroled_qualification/${metadata.qualIndex}/qualification_occurrence`;
  }

  updateEnrolledQual(observables, ae, enrolmentIndex?) {
    ae.enrolledQualifications.forEach((qual, qualIndex) => {
      // builds up data we need for each qual occurrence form control
      // catches 404 error when no occurrences are available for a qual
      const citizenshipCategory = this.isInternational ? 'International' : 'Domestic';
      const ob = zip(
        this.qualificationService
          .getQualificationOccurrences(this.applicationYear, qual.code, { citizenshipCategory })
          .pipe(catchError(() => of(null))),
        this.qualificationService.getQualification(this.applicationYear, qual.code),
        of(qual),
        enrolmentIndex ? of({ qualIndex, enrolmentIndex }) : of({ qualIndex }),
      );
      observables.push(ob);
    });
  }

  updateQual(ae) {
    let qualCount = 0;
    ae.enrolledQualifications.forEach((qual) => {
      if (!this.isCOE && qual.newInAward && this.qualsWithOccurrences.find((qo) => qual.code === qo.code)) {
        qual.qualificationOccurrence = this.occurrenceControls.at(qualCount).value;
        qualCount++;
      } else if (this.isCOE && this.qualsWithOccurrences.find((qo) => qual.code === qo.code)) {
        qual.qualificationOccurrence = this.occurrenceControls.at(qualCount).value;
        qualCount++;
      }
    });
  }

  update() {
    if (!this.isCOE) {
      this.applicationEnrolments.forEach((ae) => {
        this.updateQual(ae);
      });
    } else {
      this.updateQual(this.currentApplication);
    }

    if (!this.shouldShowDateSelector) {
      this.studyOptionsForm.get('studyStartOther').setValue('');
    }

    if (!this.noOccurrences) {
      this.studyOptionsForm.get('studyStart').setValue({ code: '' });
      this.studyOptionsForm.get('studyStartOther').setValue('');
    }

    this.formMapper.updateModelFromForm(this.studyOptionsForm, null, this.currentApplication);

    if (!this.isCOE) {
      const observables = this.applicationEnrolments.map((ae) => {
        return this.applicationService.updateApplicationEnrolment(this.applicationYear, ae.internalReference, ae);
      });
      observables.push(this.service.update(this.currentApplication));
      this.updateApplicationDetails(observables);
    } else {
      this.currentApplication = this.currentApplication as ChangeOfEnrolment;
      const obs = this.currentApplication.enrolledQualifications.map((qual) => {
        const coe: ChangeOfEnrolment = {
          enrolledQualifications: [qual],
          academicYear: this.currentApplication.academicYear,
          tainted: (this.currentApplication as ChangeOfEnrolment).tainted,
          studentProvidedExemptionReason: (this.currentApplication as ChangeOfEnrolment).studentProvidedExemptionReason,
          declarationAgreed: (this.currentApplication as ChangeOfEnrolment).declarationAgreed,
        };
        return this.coeService.updateQualificationOccurrence(coe, qual.internalReference);
      });
      obs.push(
        this.service.update(
          new ChangeOfEnrolment({
            studyStartOther: this.currentApplication.studyStartOther,
            studyStart: this.currentApplication.studyStart,
          }),
        ),
      );
      this.updateApplicationDetails(obs);
    }
  }

  updateApplicationDetails(observables) {
    combineLatest(observables).subscribe(
      () => {
        this.log.info('updated enrolment and application successfully');
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('error thrown while updating enrolment or application:', err);
      },
    );
  }
}
