<div class="search-filters__wrapper" [formGroup]="searchFiltersForm" *ngIf="showFilters">
  <div class="search-filters__show-text">{{ strings.show }}:</div>
  <select
    [attr.data-cy]="'filter-' + filter"
    class="search-filters__select-content"
    [formControlName]="filter"
    *ngFor="let filter of filterNames">
    <option *ngFor="let datum of options[filter]" [value]="datum.value">
      {{ datum.labelText }}
    </option>
  </select>
</div>
