import { DialogRef, TwoButtonPreset } from '@4sellers/ngx-modialog';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { get, isMatch, set } from 'lodash-es';
import { combineLatest, zip, Observable, of } from 'rxjs';
import { map, takeUntil, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { APPLICATION_CHANGE_STATE } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { QualificationSummary } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { UCErrorCodes } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  booleanToYesNo,
  yesNoToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { IModalOptions, ModalService } from '@shared/services/modal/modal.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

import { QualificationEnrolment, QualEnrolmentSelectorContext, EnrolmentUpdateEvent } from '../../qualification/model';

/*
 * The qualification task allows a user to create or update an application enrolment.
 */
@Component({
  selector: 'uc-qualification-coa',
  templateUrl: './qualification-coa.component.html',
  styleUrls: ['./qualification-coa.component.scss'],
})
export class QualificationCOAComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() processFilter: string;

  strings = strings.components.tasks.qualification;
  altQualString = strings.components.organisms.enrolmentSelector;
  qualificationData: QualificationSummary[];
  log: Logger;

  applicationEnrolments: ApplicationEnrolment[] = [];
  fullAwardEnrolments: QualificationEnrolment[] = [];
  taskForm: UntypedFormGroup;
  changingAEInProgress: ApplicationEnrolment;
  changingQual: ApplicationEnrolment;
  changingSubjectOptions: ApplicationEnrolment;

  isLoading = true;
  applicationYear: string;
  applicationProcess: string;
  showEnrolmentSelector = false;
  showChangeOptions = false;
  hasEnrolledQualChanges = false;
  hasPreviousEnrolledQuals = false;
  enrolmentSelectorLoaded = false;
  viewingSubjectChange = false;
  viewingQualChange = false;
  modalOpen = false;

  currentApplication: Application;
  elementGroup: UCElementGroup;
  formGroup: UntypedFormGroup;

  loadedQualSelectorContext: QualEnrolmentSelectorContext[];

  localLatestEnrolmentState: EnrolmentUpdateEvent[];
  changeQualOptions = [
    {
      labelText: 'Change qualification',
      value: true,
    },
    {
      labelText: 'Change subjects',
      value: false,
    },
  ];

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
    this.applicationYear = applicationService.currentApplicationYear;
  }

  get latestEnrolmentState() {
    return this.localLatestEnrolmentState;
  }

  set latestEnrolmentState(value) {
    this.localLatestEnrolmentState = value;
  }

  get headerText() {
    return this.strings.coaHeader;
  }

  createEnrolmentState(applicationEnrolments: ApplicationEnrolment[]): EnrolmentUpdateEvent[] {
    return applicationEnrolments.map((ae) => {
      return { applicationEnrolment: ae, isConcurrent: ae.enrolledQualifications.length > 1 };
    });
  }

  private createForm(): UCElementGroup {
    return group({
      willCompleteAward: control({
        validators: [Validators.required],
        model: 'application',
        path: '/willCompleteAward',
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
    });
  }

  public updateFormValidity() {
    // no-op
  }

  setupApplicationEnrolmentData(aes) {
    // Gets AE data, sorted by priority and sets up copies of previous and new AEs
    this.applicationEnrolments = aes.sort((a, b) => a.priority - b.priority);

    this.changingQual = this.applicationEnrolments.find(
      (ae) => ae.state.code === APPLICATION_CHANGE_STATE.STUDENT_PENDING,
    );
    this.changingSubjectOptions = this.applicationEnrolments.find(
      (ae) => ae.state.code === APPLICATION_CHANGE_STATE.SHADOW,
    );
    this.changingAEInProgress = this.changingQual || this.changingSubjectOptions;

    this.applicationEnrolments = this.applicationEnrolments.filter((ae) => ae.active);

    const changeStates = [APPLICATION_CHANGE_STATE.STUDENT_PENDING, APPLICATION_CHANGE_STATE.SHADOW];
    this.applicationEnrolments = this.applicationEnrolments
      .filter((ae) => changeStates.indexOf(ae.state.code) === -1)
      .slice(0, 1);

    const hasActiveAEs = this.applicationEnrolments.length > 0;

    if (hasActiveAEs) {
      this.showChangeOptions = true;
      this.hasPreviousEnrolledQuals = true;

      // Gets user's previous AE qual selection to display above radio inputs
      let obs: Observable<QualificationEnrolment>[] = [];
      obs = this.applicationEnrolments[0].enrolledQualifications.map((qualEnrol) => {
        return this.qualificationService
          .getQualification(this.applicationService.currentApplicationYear, qualEnrol.code)
          .pipe(
            map((qual) => {
              return QualificationEnrolment.createFrom(qualEnrol, qual);
            }),
          );
      });
      combineLatest(obs).subscribe((qualEnrolments) => {
        this.fullAwardEnrolments = qualEnrolments;
      });
    } else {
      this.hasPreviousEnrolledQuals = false;
      this.enrolmentSelectorLoaded = true;
      this.showEnrolmentSelector = true;
    }

    this.latestEnrolmentState = this.createEnrolmentState(this.applicationEnrolments);

    if (this.changingAEInProgress) {
      this.taskForm.get('changingQualification').setValue(!!this.changingQual, { emitEvent: false });
      this.showEnrolmentSelector = true;
    }
  }

  setupQualSelectorContextData(): Observable<QualEnrolmentSelectorContext[]> {
    const changeInProgress = this.changingAEInProgress;
    const qualChangeIntended = !!this.taskForm.get('changingQualification').value;
    const loadChangeInProgress =
      (changeInProgress && qualChangeIntended) || (this.changingSubjectOptions && !qualChangeIntended);

    const qualRequests = loadChangeInProgress
      ? [this.changingAEInProgress].map((e) => this.getQualEnrolments(e))
      : this.applicationEnrolments.map((e) => this.getQualEnrolments(e));

    if (qualRequests && qualRequests.length) {
      return zip(...qualRequests);
    } else {
      return of([
        {
          applicationEnrolment: new ApplicationEnrolment({ enrolledQualifications: [] }),
          firstQualificationEnrolment: null,
          secondQualificationEnrolment: null,
          isConcurrent: new UntypedFormControl(),
        },
      ]);
    }
  }

  warningModalConfig(changingQuals = true) {
    const modalStringsKey = changingQuals ? 'qualChange' : 'subjectChange';
    const modalStrings = this.strings.warningModals[modalStringsKey];

    const qualChangeCloseHandler = (dialog: DialogRef<TwoButtonPreset>) => {
      dialog.result
        .then(() => {
          this.loadedQualSelectorContext = [
            {
              applicationEnrolment: new ApplicationEnrolment({ enrolledQualifications: [] }),
              firstQualificationEnrolment: null,
              secondQualificationEnrolment: null,
              isConcurrent: new UntypedFormControl(),
            },
          ];

          this.showEnrolmentSelector = true;
          this.enrolmentSelectorLoaded = true;
          this.viewingSubjectChange = false;
          this.viewingQualChange = true;
          this.modalOpen = false;
        })
        .catch(() => {
          if (this.changingSubjectOptions || this.viewingSubjectChange) {
            this.taskForm.get('changingQualification').setValue(false);
          } else {
            this.showEnrolmentSelector = false;
            this.taskForm.get('changingQualification').reset();
          }
          this.modalOpen = false;
        });
    };

    const subjectChangeCloseHandler = (dialog: DialogRef<TwoButtonPreset>) => {
      dialog.result
        .then(() => {
          this.enrolmentSelectorLoaded = true;
          this.viewingSubjectChange = true;
          this.viewingQualChange = false;
          this.modalOpen = false;
        })
        .catch(() => {
          if (this.changingQual || this.viewingQualChange) {
            this.taskForm.get('changingQualification').setValue(true);
          } else {
            this.showEnrolmentSelector = false;
            this.taskForm.get('changingQualification').reset();
          }
          this.modalOpen = false;
        });
    };

    const closeHandler = changingQuals ? qualChangeCloseHandler : subjectChangeCloseHandler;

    return {
      header: modalStrings.header,
      body: modalStrings.body,
      ok: modalStrings.ok,
      handler: () => {},
      closeHandler,
    };
  }

  private getQualEnrolments(
    e: ApplicationEnrolment,
    awardEnrolments?: QualificationEnrolment[],
  ): Observable<QualEnrolmentSelectorContext> {
    let qualEnrolments;
    if (!awardEnrolments) {
      qualEnrolments = e.enrolledQualifications.map((qualEnrol) => {
        return this.qualificationService
          .getQualification(this.applicationService.currentApplicationYear, qualEnrol.code)
          .pipe(
            map((qual) => {
              return QualificationEnrolment.createFrom(qualEnrol, qual);
            }),
          );
      });
    } else {
      qualEnrolments = awardEnrolments.map((aw) => of(aw));
    }
    if (qualEnrolments.length === 0) {
      return of({
        applicationEnrolment: e,
        firstQualificationEnrolment: null,
        secondQualificationEnrolment: null,
      });
    }
    return combineLatest([...qualEnrolments]).pipe(
      map((qes: QualificationEnrolment[]) => {
        return {
          applicationEnrolment: e,
          firstQualificationEnrolment: qes[0],
          secondQualificationEnrolment: qes[1],
        };
      }),
    );
  }

  ngOnInit() {
    this.stopTaskSubmit = true;

    this.elementGroup = this.createForm();
    this.formGroup = this.elementGroup.asControl() as UntypedFormGroup;

    this.taskForm = this.fb.group({
      changingQualification: [null, Validators.required],
    });

    this.taskForm
      .get('changingQualification')
      .valueChanges.pipe(filter((x) => x != null))
      .subscribe((isChangingQual) => {
        this.showEnrolmentSelector = false;
        this.enrolmentSelectorLoaded = false;
        if (isChangingQual) {
          if (!this.changingQual && !this.modalOpen) {
            this.modalOpen = true;
            this.modalService.showModal(this.warningModalConfig());
          }
        } else if (!this.changingSubjectOptions && !this.modalOpen) {
          this.modalOpen = true;
          this.modalService.showModal(this.warningModalConfig(false));
        }

        this.setupQualSelectorContextData().subscribe(([qualSelectorContext]) => {
          this.loadedQualSelectorContext = [qualSelectorContext];
          this.showEnrolmentSelector = true;
          this.enrolmentSelectorLoaded = true;
        });

        this.latestEnrolmentState = this.createEnrolmentState(this.applicationEnrolments);
      });

    this.setupQualSelectorData();

    this.applicationService.application
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((x) => !!x),
      )
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.elementGroup, null, application);
        this.applicationProcess = application.processName.code;

        this.qualificationService
          .getQualifications(this.applicationService.currentApplicationYear, application.processName.code)
          .subscribe((res) => {
            this.qualificationData = res;
          });
      });
  }

  setupQualSelectorData() {
    this.applicationService.getApplicationEnrolment(this.applicationService.currentApplicationYear).subscribe((aes) => {
      this.setupApplicationEnrolmentData(aes);
      this.setupQualSelectorContextData().subscribe(
        ([qualSelectorContext]) => {
          this.loadedQualSelectorContext = [qualSelectorContext];

          if (this.changingAEInProgress) {
            this.stopTaskSubmit = false;
          }

          this.isLoading = false;
          this.enrolmentSelectorLoaded = true;
        },
        (err) => {
          this.log.error('could not get enrolment data', err);
          if (err.code === UCErrorCodes.E404) {
            this.taskForm.get('changingQualification').setValue(false);
          }
          this.isLoading = false;
        },
      );
    });
  }

  onNewQualificationAnswer(data: EnrolmentUpdateEvent[]) {
    // stop task from being able to be submitted if there are no changes to the enrolled quals
    this.stopTaskSubmit = this.latestEnrolmentState.some((eq, i) => {
      return isMatch(
        eq.applicationEnrolment.enrolledQualifications,
        data[0].applicationEnrolment.enrolledQualifications[i],
      );
    });

    this.latestEnrolmentState = data;
  }

  /**
   * Clean up any concurrent quals that the user has toggled off just before persisting
   *
   * @param enrolmentState
   */
  private removeDisabledConcurrentQuals(enrolmentState: EnrolmentUpdateEvent[]): EnrolmentUpdateEvent[] {
    return enrolmentState.map((enrolmentEvent) => {
      const qualifications = get(enrolmentEvent, 'applicationEnrolment.enrolledQualifications', []);
      if (!enrolmentEvent.isConcurrent && qualifications.length > 1) {
        set(enrolmentEvent, 'applicationEnrolment.enrolledQualifications', qualifications.slice(0, 1));
      }

      return enrolmentEvent;
    });
  }

  update() {
    // if no changes then show alert modal
    if (this.stopTaskSubmit) {
      const modalConfig: IModalOptions = this.strings.noChangesModal;
      return this.modalService.showAlertModal(modalConfig);
    }

    // continue if has changes in progress but haven't made any qual/subject changes to the current task
    if (this.latestEnrolmentState.length === 0) {
      return this.next.emit();
    }
    this.latestEnrolmentState = this.removeDisabledConcurrentQuals(this.latestEnrolmentState);

    this.formModel.updateModelFromForm(this.elementGroup, null, this.currentApplication);

    const ob = [this.applicationService.updateApplication(this.currentApplication)];
    const qualChangeIntended = !!this.taskForm.get('changingQualification').value;

    let newAE = new ApplicationEnrolment(this.latestEnrolmentState[0].applicationEnrolment);
    const createNewQualChange = qualChangeIntended || !this.hasPreviousEnrolledQuals;
    if ((!this.changingAEInProgress || this.changingSubjectOptions) && createNewQualChange) {
      // if qual change (or creating new qual if all withdrawn)
      // then save new AE with state 'student_pending' and clear any leftover subject change AE
      newAE = Object.assign(newAE, {
        state: { code: APPLICATION_CHANGE_STATE.STUDENT_PENDING },
        internalReference: null,
        priority: 1,
      });
      ob.push(this.applicationService.createApplicationEnrolment(this.applicationYear, newAE));

      if (this.changingSubjectOptions) {
        ob.push(
          this.applicationService.deleteApplicationEnrolment(
            this.applicationYear,
            this.changingSubjectOptions.internalReference,
          ),
        );
      }
    } else if ((!this.changingAEInProgress || this.changingQual) && !qualChangeIntended) {
      // if subject changes then create new AE from existing with new subject options and state 'shadow'
      // and clear any leftover qual change AE
      newAE = Object.assign(newAE, {
        state: { code: APPLICATION_CHANGE_STATE.SHADOW },
        internalReference: null,
        priority: 1,
      });
      ob.push(this.applicationService.createApplicationEnrolment(this.applicationYear, newAE));

      if (this.changingQual) {
        ob.push(
          this.applicationService.deleteApplicationEnrolment(this.applicationYear, this.changingQual.internalReference),
        );
      }
    } else if (this.changingAEInProgress) {
      ob.push(
        this.applicationService.updateApplicationEnrolment(
          this.applicationYear,
          this.changingAEInProgress.internalReference,
          this.changingAEInProgress,
        ),
      );
    }

    combineLatest(ob).subscribe(
      () => {
        this.log.info('persisted new application enrolment');
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('error updating application enrolment', err);
      },
    );
  }

  getQualTitle(code: string) {
    return this.qualificationData.find((el) => el.code === code).title;
  }
}
