<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-spinner *ngIf="isLoading" size="40" [useStaticPositioning]="true"></uc-spinner>
  <uc-section *ngIf="!isLoading">
    <uc-subsection class="award__subsection">
      <p class="award__description" [innerHTML]="strings.changeGuidance"></p>

      <div class="award__container" *ngFor="let qualification of fullAwardEnrolments; let i = index">
        <div class="item-wrapper">
          <div class="qual-subject-section">
            <h3 class="qualification-heading">
              {{ qualification.qualificationAnswer.title }} ({{ qualification.qualificationAnswer.code }})
            </h3>
            <div class="enrolment__qual-info-wrapper">
              <div class="enrolment__answers-wrapper">
                <uc-tag-list
                  *ngIf="qualification.subjectAnswer"
                  [subjectAnswer]="qualification.subjectAnswer"
                  [cropLongText]="false"
                  [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"></uc-tag-list>
              </div>
            </div>
          </div>
          <div class="qual-status">
            <ng-container *ngIf="hasChangeAction(qualification)">
              <label class="qual-subject-info change-action">
                {{ strings.status }} - {{ changeActionLabel(qualification) }}
              </label>
            </ng-container>
            <ng-container *ngIf="qualification.qualificationAnswer.subjectOptions.length !== 0">
              <a (click)="changeSubject(i)" class="change-subject-link qual-subject-info">
                {{ strings.changeSubject }}
              </a>
            </ng-container>
            <ng-container *ngIf="hasChangeAction(qualification)">
              <a
                *ngIf="!undoLoading"
                (click)="undoChange(i)"
                class="change-subject-link qual-subject-info change-action">
                {{ strings.undoChange }}
              </a>
              <div *ngIf="undoLoading" class="loading-blip__wrapper">
                <uc-loader-blip [showBlips]="undoLoading" color="$colorMercury"></uc-loader-blip>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="enrolment-choice-wrapper enrolment-wrapper" *ngIf="editMode && showQualEnrolmentSelector[i]">
          <uc-qualification-enrolment-selector
            (firstQualification)="getConcurrentOptions($event, i)"
            (saveQualification)="onSaveFirst($event, i)"
            [qualificationEnrolment]="qualification"
            [qualificationOptions]="qualification.qualificationAnswer"
            [showQualificationSelector]="false"
            [hideTitle]="true"></uc-qualification-enrolment-selector>

          <div class="enrolment-save-action">
            <button [attr.data-cy]="'cancel-changes'" class="cancel-link" (click)="cancelChanges()">
              {{ strings.cancelChanges }}
            </button>
            <uc-button
              [isLoading]="isSubjectOptionLoading"
              [disabled]="!enableSave"
              class="next-task"
              testSelector="save"
              (click)="saveChanges(i)">
              {{ strings.saveChanges }}
            </uc-button>
          </div>
        </div>
      </div>

      <div *ngIf="dataLoading">
        <uc-spinner size="30" [useStaticPositioning]="true"></uc-spinner>
      </div>

      <div *ngIf="!dataLoading && !addQualSelector && !isQualAlreadyAdded()">
        <uc-add-new-button
          (click)="addNewQualification()"
          [buttonHeading]="strings.addQualification"></uc-add-new-button>
      </div>
      <ng-container *ngIf="addQualSelector">
        <div class="enrolment-choice-wrapper qual-wrapper">
          <uc-section-header title="{{ qualStrings.header }}">
            <p>{{ qualStrings.description }}</p>
          </uc-section-header>

          <uc-enrolment-selector
            [process]="process"
            [year]="academicYear"
            [qualificationEnrolments]="enrolments"
            [qualificationOptions]="qualificationData"
            [selectedQual]="selectedQual"
            (qualificationAnswered)="onNewQualificationAnswer($event)"
            (qualForm)="qualSelectorFormEmitter($event)"></uc-enrolment-selector>
        </div>

        <div class="enrolment-save-action">
          <button [attr.data-cy]="'cancel-changes'" class="cancel-link" (click)="cancelQualChanges()">
            {{ strings.cancelChanges }}
          </button>
          <uc-button
            [isLoading]="isQualLoading"
            class="next-task save-btn"
            [disabled]="!enableQualSave"
            testSelector="save"
            (click)="saveQualChanges()">
            {{ strings.saveChanges }}
          </uc-button>
        </div>
      </ng-container>
    </uc-subsection>
  </uc-section>
</uc-form-template>
