import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { APPLICATION_CHANGE_STATE, OFFER_DECISION_STATE } from '@shared/constants/states.constants';
import { AcademicYearEnrolmentSummary, ArchivedEnrolmentSummary } from '@shared/models/enrolment-summary';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { showModalLoadingState } from '@shared/services/modal/modal.service';
import { UserService } from '@shared/services/user/user.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-application-year-list-item',
  templateUrl: './application-year-list-item.component.html',
  styleUrls: ['./application-year-list-item.component.scss'],
})
export class ApplicationYearListItemComponent implements OnInit {
  @Input() year: string;

  applicationSummary: AcademicYearEnrolmentSummary;
  archivedEnrolment: ArchivedEnrolmentSummary;
  isArchived = false;
  isLoading = false;
  baseStrings = strings.components.molecules.applicationYearListItemBaseStrings;
  isImpersonated = false;
  state: string;
  courseState: string;
  private log: Logger;
  private working = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private ws: WindowService,
    public flashMessageService: FlashMessageService,
    public enrolmentSummaryService: EnrolmentSummaryService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get summaryState() {
    return this.isArchived ? this.archivedEnrolment.academicYear : this.applicationSummary.academicYear;
  }

  ngOnInit() {
    this.enrolmentSummaryService.enrolmentSummary.pipe(filter((s) => !!s)).subscribe((summary) => {
      this.applicationSummary = summary.academicYears.find((ay) => ay.academicYear === this.year);
      this.archivedEnrolment = summary.archivedEnrolments.find((ae) => ae.academicYear === this.year);
      this.isArchived = !!this.archivedEnrolment;
      this.state = this.isArchived ? this.archivedEnrolment?.state : this.applicationSummary?.state;
      if (get(this.applicationSummary, 'changeOfApplication')) {
        this.state = APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS;
      }
      if (this.applicationSummary?.offerDecision?.state === OFFER_DECISION_STATE.DRAFT_DEFER) {
        this.state = OFFER_DECISION_STATE.DRAFT_DEFER;
      }
      this.courseState = get(this.applicationSummary, 'courseSelectionState');
    });

    this.userService.userDetail.subscribe((detail) => {
      this.isImpersonated = detail && !!detail.impersonated;
    });
  }

  refreshTokenAndNavigate(url): Promise<boolean> {
    return this.userService
      .refreshTokenBeforeNavigate()
      .toPromise()
      .then(() => {
        this.navExternal(url);
        return Promise.resolve(true);
      })
      .catch(() => {
        return Promise.resolve(true);
      });
  }

  navExternal = (destination: string) => {
    this.ws.nativeWindow.location.href = destination;
  };

  navInternal = (destination: string[]) => {
    return this.router.navigate(destination);
  };

  primaryAction(): Promise<boolean> {
    this.isLoading = true;
    return new Promise((resolve, _reject) => {
      if (this.working) {
        resolve(true);
        return;
      }
      this.working = true;
      showModalLoadingState();

      const { academicYear, processName, actions } = this.applicationSummary;
      if (get(actions.primary, 'href')) {
        this.refreshTokenAndNavigate(actions.primary.href);
        resolve(true);
      } else {
        this.navInternal(internalUrls.processPage(processName, academicYear)).then(() => resolve(false));
      }
    });
  }

  goToManageMyStudy() {
    if (this.isArchived) {
      this.router.navigate(internalUrls.manageMyStudy);
    } else {
      this.router.navigate(internalUrls.manageMyStudy, { queryParams: { year: this.applicationSummary.academicYear } });
    }
  }
}
