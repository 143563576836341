/*jshint maxlen: false*/

var validator = require("validator");
var FormatValidators = {
  "date": function (date) {
    if (typeof date !== "string") {
      return true;
    }
    // full-date from http://tools.ietf.org/html/rfc3339#section-5.6
    var matches = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.exec(date);
    if (matches === null) {
      return false;
    }
    // var year = matches[1];
    // var month = matches[2];
    // var day = matches[3];
    if (matches[2] < "01" || matches[2] > "12" || matches[3] < "01" || matches[3] > "31") {
      return false;
    }
    return true;
  },
  "date-time": function (dateTime) {
    if (typeof dateTime !== "string") {
      return true;
    }
    // date-time from http://tools.ietf.org/html/rfc3339#section-5.6
    var s = dateTime.toLowerCase().split("t");
    if (!FormatValidators.date(s[0])) {
      return false;
    }
    var matches = /^([0-9]{2}):([0-9]{2}):([0-9]{2})(.[0-9]+)?(z|([+-][0-9]{2}:[0-9]{2}))$/.exec(s[1]);
    if (matches === null) {
      return false;
    }
    // var hour = matches[1];
    // var minute = matches[2];
    // var second = matches[3];
    // var fraction = matches[4];
    // var timezone = matches[5];
    if (matches[1] > "23" || matches[2] > "59" || matches[3] > "59") {
      return false;
    }
    return true;
  },
  "email": function (email) {
    if (typeof email !== "string") {
      return true;
    }
    return validator.isEmail(email, {
      "require_tld": true
    });
  },
  "hostname": function (hostname) {
    if (typeof hostname !== "string") {
      return true;
    }
    /*
        http://json-schema.org/latest/json-schema-validation.html#anchor114
        A string instance is valid against this attribute if it is a valid
        representation for an Internet host name, as defined by RFC 1034, section 3.1 [RFC1034].
         http://tools.ietf.org/html/rfc1034#section-3.5
         <digit> ::= any one of the ten digits 0 through 9
        var digit = /[0-9]/;
         <letter> ::= any one of the 52 alphabetic characters A through Z in upper case and a through z in lower case
        var letter = /[a-zA-Z]/;
         <let-dig> ::= <letter> | <digit>
        var letDig = /[0-9a-zA-Z]/;
         <let-dig-hyp> ::= <let-dig> | "-"
        var letDigHyp = /[-0-9a-zA-Z]/;
         <ldh-str> ::= <let-dig-hyp> | <let-dig-hyp> <ldh-str>
        var ldhStr = /[-0-9a-zA-Z]+/;
         <label> ::= <letter> [ [ <ldh-str> ] <let-dig> ]
        var label = /[a-zA-Z](([-0-9a-zA-Z]+)?[0-9a-zA-Z])?/;
         <subdomain> ::= <label> | <subdomain> "." <label>
        var subdomain = /^[a-zA-Z](([-0-9a-zA-Z]+)?[0-9a-zA-Z])?(\.[a-zA-Z](([-0-9a-zA-Z]+)?[0-9a-zA-Z])?)*$/;
         <domain> ::= <subdomain> | " "
        var domain = null;
    */
    var valid = /^[a-zA-Z](([-0-9a-zA-Z]+)?[0-9a-zA-Z])?(\.[a-zA-Z](([-0-9a-zA-Z]+)?[0-9a-zA-Z])?)*$/.test(hostname);
    if (valid) {
      // the sum of all label octets and label lengths is limited to 255.
      if (hostname.length > 255) {
        return false;
      }
      // Each node has a label, which is zero to 63 octets in length
      var labels = hostname.split(".");
      for (var i = 0; i < labels.length; i++) {
        if (labels[i].length > 63) {
          return false;
        }
      }
    }
    return valid;
  },
  "host-name": function (hostname) {
    return FormatValidators.hostname.call(this, hostname);
  },
  "ipv4": function (ipv4) {
    if (typeof ipv4 !== "string") {
      return true;
    }
    return validator.isIP(ipv4, 4);
  },
  "ipv6": function (ipv6) {
    if (typeof ipv6 !== "string") {
      return true;
    }
    return validator.isIP(ipv6, 6);
  },
  "regex": function (str) {
    try {
      RegExp(str);
      return true;
    } catch (e) {
      return false;
    }
  },
  "uri": function (uri) {
    if (this.options.strictUris) {
      return FormatValidators["strict-uri"].apply(this, arguments);
    }
    // https://github.com/zaggino/z-schema/issues/18
    // RegExp from http://tools.ietf.org/html/rfc3986#appendix-B
    return typeof uri !== "string" || RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?").test(uri);
  },
  "strict-uri": function (uri) {
    return typeof uri !== "string" || validator.isURL(uri);
  }
};
module.exports = FormatValidators;