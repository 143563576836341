<uc-form-template [formGroup]="mcedPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-subsection>
      <uc-subsection-question>
        {{ strings.associatedPersonQuestion }}
      </uc-subsection-question>

      <uc-associated-person
        basePath="/application/associated_person/0"
        [task]="task"
        [associatedPersonForm]="mcedPage.get('roleAndRelationship')"
        [strings]="associatedPersonLabels"
        [typeRefdata]="refdataType"
        testSelector="mced-role"></uc-associated-person>
    </uc-subsection>
  </uc-section>
</uc-form-template>
