import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { distinctUntilKeyChanged } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { Qualification, QualificationSummary } from '@shared/models/qualification';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

const qualFilterPostgrad = (qe: QualificationSummary): boolean => {
  return qe.isPostgraduate;
};

const qualFilterUndergrad = (qe: QualificationSummary): boolean => {
  return !qe.isPostgraduate;
};

@Component({
  selector: 'uc-qualification-selector',
  templateUrl: './qualification-selector.component.html',
  styleUrls: ['./qualification-selector.component.scss'],
})
export class QualificationSelectorComponent implements OnInit, OnChanges {
  /**
   * All qualification data for the year passed in from the parent component
   */
  @Input() qualificationOptions: QualificationSummary[];

  /**
   * A user's previously selected qualification
   */
  @Input() selectedQualification: string;

  // Emits an updated qualification code
  @Output() changedQualification = new EventEmitter<{ code: string }>();
  @Output() qualForm = new EventEmitter<UntypedFormGroup>();

  qualSelectorForm = new UntypedFormGroup({
    code: new UntypedFormControl('', Validators.required),
    qualOptionFilter: new UntypedFormControl('undergrad'),
  });

  strings = strings.components.tasks.qualificationSelector;
  currentOption: Qualification;
  filteredQualificationOptions: { labelText: string; value: string }[] = [];

  qualFilterOptions = [
    { labelText: this.strings.undergradLabel, value: 'undergrad', filterFunction: qualFilterUndergrad },
    { labelText: this.strings.postgradLabel, value: 'postgrad', filterFunction: qualFilterPostgrad },
  ];

  private log: Logger;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  private createQualOptions(filterFunction: (QualificationSummary) => boolean): { labelText: string; value: string }[] {
    if (!this.qualificationOptions) {
      return [];
    }

    const filterFn = filterFunction ? filterFunction : () => true;

    return this.qualificationOptions.filter(filterFn).map((e) => {
      if (!e.title) {
        this.log.warn('found data that does not deserialize for qualification options', e);
        return {
          labelText: e.code,
          value: e.code,
        };
      }
      return {
        labelText: e.title,
        value: e.code,
      };
    });
  }

  private setRadioGroup(qualCode: string) {
    const qual = this.qualificationOptions.find((qo) => qo.code === qualCode);
    const value = qual.isPostgraduate ? 'postgrad' : 'undergrad';
    this.qualSelectorForm.get('qualOptionFilter').setValue(value);
  }

  private getQualOptionsFilter(name: string): (QualificationSummary) => boolean {
    // lookup the filter function for the currently chosen qualification filter
    let currentFilter: any = this.qualFilterOptions.find((option) => {
      return option.value === name;
    });
    currentFilter = currentFilter ? currentFilter.filterFunction : (_: QualificationSummary) => true;
    return currentFilter;
  }

  ngOnInit() {
    this.qualSelectorForm.get('qualOptionFilter').valueChanges.subscribe((filterValue) => {
      const latestFilter = this.getQualOptionsFilter(filterValue);
      this.filteredQualificationOptions = this.createQualOptions(latestFilter);
    });

    this.qualSelectorForm.get('code').setValue(this.selectedQualification);
    this.qualForm.emit(this.qualSelectorForm);

    this.qualSelectorForm.valueChanges.pipe(distinctUntilKeyChanged('code')).subscribe((data) => {
      this.qualForm.emit(this.qualSelectorForm);
      this.changedQualification.emit(data);
    });
  }

  ngOnChanges(changes) {
    if (
      changes.qualificationOptions &&
      !!changes.qualificationOptions.currentValue &&
      !changes.qualificationOptions.previousValue
    ) {
      const currentFilter = this.getQualOptionsFilter(this.qualSelectorForm.get('qualOptionFilter').value);
      this.filteredQualificationOptions = this.createQualOptions(currentFilter);

      if (this.selectedQualification) {
        const isPostgrad = this.qualificationOptions.find(
          (qo) => qo.code === this.selectedQualification,
        ).isPostgraduate;
        const filerValue = isPostgrad ? 'postgrad' : 'undergrad';
        const filter = this.getQualOptionsFilter(filerValue);
        this.filteredQualificationOptions = this.createQualOptions(filter);
        this.setRadioGroup(this.selectedQualification);
      }
    }
    if (changes.qualificationOptions) {
      const currentFilter = this.getQualOptionsFilter(this.qualSelectorForm.get('qualOptionFilter').value);
      this.filteredQualificationOptions = this.createQualOptions(currentFilter);
    }
  }
}
