{
  "qualification_occurrence": [
    {
      "code": "DIPTCHLN (EARLY CHILDHOOD) - 22/01/2020 FT DOMESTIC (N)",
      "intake_name": "Nelson - full time",
      "type": {
        "code": "INTAKE"
      },
      "source": {
        "code": "JSMS"
      },
      "academic_year": 2021,
      "order": 1,
      "commencement_date": "2019-01-01",
      "applications_open_date": "2018-06-01",
      "applications_close_date": "2019-06-01",
      "citizenship_category": [{ "code": "domestic" }],
      "site": {
        "code": "Nelson"
      },
      "full_time": true,
      "delivery_mode": "",
      "external_reference": "1234.5678",
      "internal_reference": "foo",
      "start_date": "02/02/2021",
      "qualification": {
        "code": "BCOM"
      }
    },
    {
      "code": "DIPTCHLN (EARLY CHILDHOOD) - 22/01/2022 FT DOMESTIC (N)",
      "intake_name": "Christchurch - full time",
      "type": {
        "code": "INTAKE"
      },
      "source": {
        "code": "JSMS"
      },
      "academic_year": 2021,
      "order": 1,
      "commencement_date": "2019-01-01",
      "applications_open_date": "2018-06-01",
      "applications_close_date": "2019-06-01",
      "citizenship_category": [{ "code": "domestic" }],
      "site": {
        "code": "Christchurch"
      },
      "full_time": true,
      "delivery_mode": "",
      "external_reference": "1234.5678",
      "internal_reference": "fum",
      "qualification": {
        "code": "BA"
      }
    }
  ]
}
