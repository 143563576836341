import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BaseModalComponent } from '@shared/components/atoms/base-modal/base-modal.component';

@Component({
  selector: 'uc-confirmation-modal',
  templateUrl: 'confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements AfterViewInit {
  @Input() title: string;
  @Input() message: string;
  @Input() acceptText = 'OK';
  @Input() acceptType = 'neutral';
  @Input() declineText: string;
  @Input() declineType = 'neutral';
  @Input() loading = false;
  @Input() useUnsetPosition = false;
  @Input() useEmbeddedChildren = false;

  @Output() accepted = new EventEmitter();
  @Output() declined = new EventEmitter();
  @Output() closed = new EventEmitter();

  @ViewChild(BaseModalComponent) baseModal;

  @Input() onAcceptedBeforeClose = () => Promise.resolve();
  @Input() onDeclinedBeforeClose = () => Promise.resolve();

  ngAfterViewInit() {
    this.baseModal.closed.subscribe(this.closed);
  }

  open() {
    this.baseModal.open();
  }

  async accept() {
    this.accepted.emit();
    await this.onAcceptedBeforeClose();
    this.baseModal.close();
  }

  async decline() {
    this.declined.emit();
    await this.onDeclinedBeforeClose();
    this.baseModal.close();
  }
}
