<div
  class="component-wrap clearfix"
  [class.hasValue]="hasValue"
  [class.focused-input]="isFocused"
  [class.hasIcon]="!hideValidationIcon">
  <ng-container *ngIf="!isDisabled">
    <div class="input-wrap">
      <textarea
        [attr.data-cy]="testSelectorId"
        (focus)="isFocused = true"
        (blur)="stripInvalidCharacters()"
        (keypress)="stripInvalidCharacters()"
        (paste)="stripInvalidCharacters()"
        (blur)="isFocused = false"
        class="input-element"
        [class.large-textarea]="isLargeTextarea"
        [formControl]="innerInputControl"></textarea>
    </div>
    <div *ngIf="!hideValidationIcon" class="validation-icon-wrap">
      <uc-icon
        class="valid-icon"
        iconTitle="Valid"
        iconType="tick"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
      <uc-icon
        class="invalid-icon"
        iconTitle="Invalid"
        iconType="close"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
    </div>
  </ng-container>
  <uc-disabled-atom-state
    *ngIf="isDisabled"
    [value]="innerInputControl.value"
    [enableModal]="showDisabledExplanation"></uc-disabled-atom-state>
</div>
