<div [formGroup]="fitnessToTeachForm">
  <uc-form-input>
    <uc-show-hide
      [formControl]="fitnessToTeachForm.get('hasDisability')"
      [refdataOptions$]="declarationOptions$"
      [task]="task"
      taskHintPath="/applicant/declaration_disability"
      [questionString]="strings.disabilityQuestion"
      [questionDescription]="strings.disabilityMinorText"
      [showContentValue]="showContentValue"
      testSelector="disability">
      <uc-subsection>
        <uc-task-hint [task]="task" path="/applicant/disability/0"></uc-task-hint>
        <uc-checkbox-input-group
          label="{{ strings.selectDisabilityLabel }}"
          [formControl]="fitnessToTeachForm.get('disability')"
          type="disability"
          groupName="disability"
          isRequired="true"></uc-checkbox-input-group>
      </uc-subsection>

      <uc-subsection *ngIf="disabilityDetailRequired; else optionalDetails">
        <uc-task-hint [task]="task" path="/applicant/disability_detail"></uc-task-hint>
        <uc-form-field-label
          labelText="{{ strings.furtherDetail }}"
          required="true"
          labelTooltipContent="{{ strings.furtherDetailTooltip }}">
          <uc-text-area testSelector="disability-detail" formControlName="disabilityDetail"></uc-text-area>
        </uc-form-field-label>
        <uc-subsection-text-block>{{ strings.medicalCertificateMinorText }}</uc-subsection-text-block>
      </uc-subsection>

      <ng-template #optionalDetails>
        <uc-subsection>
          <uc-form-field-label labelText="{{ strings.optionalFurtherDetail }}">
            <uc-text-area testSelector="disability-detail-extra" formControlName="disabilityDetail"></uc-text-area>
          </uc-form-field-label>
        </uc-subsection>
      </ng-template>
    </uc-show-hide>
  </uc-form-input>
</div>
