{
  "independent_enrolments": {
    "internal_reference": "5ZLkygqy4N4qPlCfYwikfQngsU72Xb",
    "academic_year": "2023",
    "user_id": "HyDFgBoSfPLzsLM1eq5rEK0zxBLl0L",
    "enrolled_courses": [
      {
        "internal_reference": "slGAU4cwMWaEjOtjzZjjV1PkImAkoi",
        "course_code": "519FX22031",
        "occurrence": "1",
        "state": {
          "code": "created"
        },
        "payment_state": {
          "code": "no_payment_attempted"
        }
      },
      {
        "internal_reference": "fYsF58eEanAtWp6brkw9bTc0QCIe3x",
        "course_code": "MCEN301",
        "occurrence": "1",
        "state": {
          "code": "enrolled"
        },
        "payment_state": {
          "code": "paid"
        },
        "checkout_url": "https://payment.uconline.ac.nz/c/pay/cs_test_a1tge82qmmCWyRY9FUThvYGHbxIG9TPhMZspMSKFWZKi3pRvcMAMp7Grr5#fidkdWxOYHwnPyd1blpxYHZxWjA0SDRDTUdGMUhMdFBmMEo3bV1MN0oxPDNtQHx9aFVBRk19bXJ9PWhtaW5TMWlOMnBwR210VVZ9SEpuNmBTa389bF1gUEBRR3FTMG5PT3J8VF0zS1ZGNF1pNTVUbG9PNkpBbScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
      },
      {
        "internal_reference": "fYsF58eEanAtWp6brkw9bTc0QCIe33",
        "course_code": "MCEN303",
        "occurrence": "1",
        "state": {
          "code": "cancelled"
        },
        "payment_state": {
          "code": "no_payment_attempted"
        },
        "checkout_url": "https://payment.uconline.ac.nz/c/pay/cs_test_a1tge82qmmCWyRY9FUThvYGHbxIG9TPhMZspMSKFWZKi3pRvcMAMp7Grr5#fidkdWxOYHwnPyd1blpxYHZxWjA0SDRDTUdGMUhMdFBmMEo3bV1MN0oxPDNtQHx9aFVBRk19bXJ9PWhtaW5TMWlOMnBwR210VVZ9SEpuNmBTa389bF1gUEBRR3FTMG5PT3J8VF0zS1ZGNF1pNTVUbG9PNkpBbScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
      }
    ]
  }
}
