import { Component, Input, ViewEncapsulation } from '@angular/core';
import Prism from 'prism-es6';
import dedent from 'ts-dedent';

@Component({
  selector: 'uc-code-block',
  templateUrl: './code-block.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./prism-okaidia.css', './code-block.component.scss'],
})
export class CodeBlockComponent {
  @Input() language: string;
  @Input() higlightedCodeHTML: string;

  @Input()
  set content(content: string) {
    this.higlightedCodeHTML = Prism.highlight(dedent(content), this.language ? Prism.languages[this.language] : '');
  }

  get name() {
    return this.higlightedCodeHTML;
  }
}
