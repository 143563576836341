<uc-form-template *ngIf="this.siteOptions">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.sectionTitle(teachingQual?.title) }}"></uc-section-header>

    <uc-subsection *ngIf="siteOptions" [formGroup]="teachingQualificationPage">
      <uc-task-hint [task]="task" path="/application/study_location"></uc-task-hint>
      <uc-subsection-question required="true">{{ strings.whereLabel }}</uc-subsection-question>
      <uc-radio-input-group
        formControlName="studyLocation"
        [options]="siteOptions"
        groupName="study-location"></uc-radio-input-group>
    </uc-subsection>

    <uc-subsection
      *ngIf="siteOptions && teachingQualificationPage.get('studyLocation').value"
      [formGroup]="teachingQualificationPage">
      <uc-task-hint [task]="task" path="/application/study_full_time"></uc-task-hint>
      <uc-subsection-question required="true">{{ strings.howLabel }}</uc-subsection-question>
      <uc-radio-input-group
        formControlName="studyFullTime"
        [options]="fullPartTimeOptions"
        groupName="full-part-time"></uc-radio-input-group>
    </uc-subsection>
  </uc-section>
</uc-form-template>
