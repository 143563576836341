<div class="field-wrap" [formGroup]="form">
  <div class="label-wrap">
    <label>
      <span *ngIf="label">
        {{ label }}
        <uc-required-field *ngIf="required"></uc-required-field>
      </span>
      <span class="show-password" (click)="showPassword = !showPassword">
        <span *ngIf="!showPassword" class="show-hide-wrap">
          <uc-icon iconType="show" iconWidth="12" iconHeight="12"></uc-icon>
          {{ templateStrings.password.show }}
        </span>
        <span *ngIf="showPassword" class="show-hide-wrap">
          <uc-icon iconType="hide" iconWidth="12" iconHeight="12"></uc-icon>
          {{ templateStrings.password.hide }}
        </span>
      </span>
    </label>
  </div>
  <input
    type="password"
    [class.hidden]="showPassword"
    [formControlName]="controlName"
    [attr.data-cy]="testSelectorId" />
  <input
    type="text"
    [class.hidden]="!showPassword"
    value="{{ shadowPassword }}"
    (change)="onShadowChange($event)"
    [attr.data-cy]="testSelectorId + '-visible'" />
  <span class="field-error-text" *ngIf="createAccount && shortPassword && inputControl.touched">
    {{ errorStrings.createAccount.weakPassword }}
  </span>
</div>
