import { cloneDeep } from 'lodash-es';

/* eslint-disable-next-line import/no-restricted-paths */
import data from './payment.mock.json';
/* eslint-disable-next-line import/no-restricted-paths */
import refundData from './payment_refund.mock.json';

export const mockData = () => cloneDeep(data);

export const mockRefundData = () => cloneDeep(refundData);
