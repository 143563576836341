<uc-subsection>
  <uc-task-hint [task]="task" [path]="taskHintPath"></uc-task-hint>
  <uc-subsection-question required="true">
    {{ questionString }}
  </uc-subsection-question>
  <div class="question-description" [innerHTML]="questionDescription"></div>
  <uc-multi-options-selector
    [formControl]="showHideControl"
    [multiOptionsList$]="refdataOptions$"
    [testSelector]="testSelector"></uc-multi-options-selector>
</uc-subsection>
<ng-container *ngIf="showContent">
  <ng-content></ng-content>
</ng-container>
