<div *ngIf="!task.shouldBeHidden">
  <div class="summary-task"></div>
  <a
    class="task task-{{ task.decorator }}"
    [class.active-card]="isActive"
    [routerLink]="isPrevTaskComplete ? task.path : null"
    [queryParams]="queryParams"
    [fragment]="fragment">
    <span class="task-header">
      <span class="task-status-icon {{ task.decorator }}" [class.complete]="task.percentComplete === 100">
        <uc-icon [iconType]="task.decorator" iconWidth="18" iconHeight="13"></uc-icon>
      </span>
      <span class="task-title">{{ task.title }}</span>
    </span>
    <uc-progress-bar
      *ngIf="task.isDefaultDecorator"
      [progressPercentage]="task.percentComplete"
      class="hide-unless-active"></uc-progress-bar>
    <span class="task-arrow">
      <uc-icon iconType="forward-arrow" iconWidth="16" iconHeight="16"></uc-icon>
    </span>
    <span *ngIf="task.isDefaultDecorator" class="percentage-done hide-unless-inactive">
      {{ task.percentComplete }}% Done
    </span>
  </a>
</div>
