/* eslint-disable complexity */
import { IndependentCourseEnrolmentPillState } from './IndependentCourseEnrolmentPillState';

export class IndependentCourseEnrolmentSummary {
  state: string;
  paymentState: string;
  paymentUrl: string;
  courseCode: string;
  occurrenceCode: string;
  academicYear: string;

  STATE_ENROLLED = 'enrolled';
  STATE_CANCELLED = 'cancelled';
  STATE_WAITING_FOR_PAYMENT = 'waiting_for_payment';
  STATE_SUBMITTED = 'submitted';
  STATE_CREATED = 'created';
  STATE_PROVISIONING = 'provisioning';

  PAYMENT_STATE_PAID = 'paid';

  STAGE_DRAFT = 'application';
  STAGE_PAYMENT = 'payment';
  STAGE_PROVISIONING = 'provisioning';
  STAGE_COMPLETE = 'complete';

  ACTIONABLE_STATES = [this.STATE_WAITING_FOR_PAYMENT, this.STATE_SUBMITTED, this.STATE_CREATED];

  PAYMENT_STATES = [this.STATE_WAITING_FOR_PAYMENT];

  NON_ACTIONABLE_STATES = [this.STATE_CANCELLED, this.STATE_ENROLLED, this.STATE_PROVISIONING];

  STAGES = [this.STAGE_DRAFT, this.STAGE_PAYMENT, this.STAGE_PROVISIONING, this.STAGE_COMPLETE];

  PILL_STATES = {
    draft: {
      title: 'Draft',
      code: 'draft',
      css: 'yellow',
    },
    awaiting_payment: {
      title: 'Awaiting Payment',
      code: 'payment',
      css: 'blue',
    },
    provisioning: {
      title: 'Awaiting Information',
      code: 'provisioning',
      css: 'blue',
    },
    enrolled: {
      title: 'Enrolled',
      code: 'enrolled',
      css: 'green',
    },
    cancelled: {
      title: 'Cancelled',
      code: 'cancelled',
      css: 'red',
    },
  };

  constructor(payload) {
    this.state = payload.state.code;
    this.paymentState = payload.payment_state.code;
    this.paymentUrl = payload.checkout_url;
    this.courseCode = payload.course_code;
    this.occurrenceCode = payload.occurrence;
    this.academicYear = payload.academic_year;
  }

  /**
   * Get the stage of an application. For Independent course enrolments
   * we don't have the concept of an 'Application' so it's useful to know
   * if an enrolment is in an 'application' like state or if it's a full
   * enrolment.
   *
   * @returns one of 'draft', 'payment', 'provisioning', 'complete'
   */
  getApplicationStage() {
    if (['waiting_for_payment'].includes(this.paymentState) && this.state === this.STATE_SUBMITTED) {
      return this.STAGE_PAYMENT;
    }

    if ([this.STATE_SUBMITTED, this.STATE_CREATED].includes(this.state)) {
      return this.STAGE_DRAFT;
    }

    if ([this.STATE_PROVISIONING].includes(this.state)) {
      return this.STAGE_PROVISIONING;
    }

    if ([this.STATE_ENROLLED, this.STATE_CANCELLED].includes(this.state)) {
      return this.STAGE_COMPLETE;
    }

    return this.STAGE_DRAFT;
  }

  /**
   * Get whether or not the application is in a state that we consider to be actionable
   * aka where there is something the student can do to progress their application.
   *
   * @returns true if enrolment is in an actionable state
   */
  isInActionableState() {
    return this.ACTIONABLE_STATES.includes(this.state);
  }

  isEnrolled() {
    return this.state === this.STATE_ENROLLED;
  }

  /**
   * Returns whether the enrolment is in a state that would result in its
   * EvaluationContext being closed
   */
  isInFinalisedState() {
    return [this.STATE_CANCELLED, this.STATE_ENROLLED].includes(this.state);
  }

  getResumeEnrolmentLink() {
    return `${window.location.origin}/online-apply/?enrol=${this.courseCode}&occur=${this.occurrenceCode}&resume=TRUE`;
  }

  getPill(): IndependentCourseEnrolmentPillState {
    if (this.isEnrolled()) {
      return this.PILL_STATES.enrolled;
    }

    if (this.getApplicationStage() === this.STAGE_DRAFT) {
      return this.PILL_STATES.draft;
    }

    if (this.getApplicationStage() === this.STAGE_PROVISIONING) {
      return this.PILL_STATES.provisioning;
    }

    if (this.getApplicationStage() === this.STAGE_PAYMENT) {
      return this.PILL_STATES.awaiting_payment;
    }

    if (this.isInActionableState()) {
      return this.PILL_STATES.draft;
    }

    if (this.state === this.STATE_CANCELLED) {
      return this.PILL_STATES.cancelled;
    }
  }

  public static deserialize(payload): IndependentCourseEnrolmentSummary {
    return new IndependentCourseEnrolmentSummary(payload);
  }
}
