import { Injectable } from '@angular/core';

import { environment } from '@environment';

const LOG_VERBOSITY = ['none', 'error', 'info'];
const DEFAULT_LOG_LEVEL = 'error';
const getVerbosity = (level) => {
  return LOG_VERBOSITY.indexOf(level || DEFAULT_LOG_LEVEL);
};

export class Logger {
  lastMessage = [];
  verbosity = getVerbosity(environment.logLevel);

  constructor(private logName: string) {}

  private log(args: IArguments, level: string) {
    this.lastMessage = [this.logName].concat(Array.from(args));

    if (this.verbosity && this.verbosity >= getVerbosity(level)) {
      // eslint-disable-next-line no-console,prefer-spread
      console[level].apply(console, this.lastMessage);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public info(...args: any[]): void {
    // eslint-disable-next-line prefer-rest-params
    this.log(arguments, 'info');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public error(...args: any[]): void {
    // eslint-disable-next-line prefer-rest-params
    this.log(arguments, 'error');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public debug(...args: any[]): void {
    // eslint-disable-next-line prefer-rest-params
    this.log(arguments, 'debug');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public warn(...args: any[]): void {
    // eslint-disable-next-line prefer-rest-params
    this.log(arguments, 'warn');
  }
}

@Injectable()
export class LoggingService {
  public createLogger(object: any): Logger {
    return new Logger(object.constructor.name);
  }
}
