import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { get, set } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { DISABILITY_CONSTANTS } from '@shared/components/molecules/fitness-to-teach/fitness-to-teach.component';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { PREFERRED_CONTACT_METHOD_OPTIONS } from '@shared/constants/preferred-contact-method.constants';
import { ETHNICITIES } from '@shared/constants/reference.constants';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  array,
  UCElementGroup,
  UCElementArray,
  refDataToValue,
  valueToRefData,
  refDatasToCodeArray,
  controlToRefDataArray,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-micro-credential-details',
  templateUrl: './online-micro-credential-details.component.html',
  styleUrls: ['./online-micro-credential-details.component.scss'],
})
export class OnlineMicroCredentialDetailsComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  onlineMicroCredentialDetailsPage: UntypedFormGroup;
  onlineMicroCredentialDetailsForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;
  baseYear: number;

  legalName: Name;
  name: Name[];

  bannerString = strings.components.organisms.profilePage.profileTemplate.mandatoryBanner;
  strings = strings.components.tasks.onlineMicroCredentialDetails;
  phoneStrings = strings.components.molecules.onlinePhoneSelector;
  learningNeedsStrings = strings.components.organisms.learningNeeds;
  termsAndConditionsStrings = strings.components.molecules.onlineTermsAndConditions;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  preferredContactMethodOptions = PREFERRED_CONTACT_METHOD_OPTIONS;

  processRequiringId = [PROCESS_NAMES.STAR, PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private onlineProcessService: OnlineProcessService,
    private onlineCourseService: OnlineCourseService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return [PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL].includes(this.processName);
  }

  get otherNames(): UCElementArray {
    return this.onlineMicroCredentialDetailsForm.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return this.otherNames?.control.length;
  }

  get citizenship() {
    return this.onlineMicroCredentialDetailsForm.controls.citizenship.controls;
  }

  get demographicElements(): UCElementArray {
    return this.onlineMicroCredentialDetailsForm.controls.citizenship;
  }

  get isNZCitizen(): boolean {
    return this.citizenship.citizenCategory?.control?.value?.code === 'NZCZ';
  }

  get isInternational(): boolean {
    return this.citizenship.citizenCategory?.control?.value?.code === 'OTHER';
  }

  get fitnessToTeachGroup() {
    return this.onlineMicroCredentialDetailsForm.controls.fitnessToTeachGroup.controls;
  }

  get hasDisability(): boolean {
    return this.fitnessToTeachGroup.hasDisability?.control?.value?.code === DISABILITY_CONSTANTS.IS_TRUE;
  }

  get identityDocumentRequired(): boolean {
    return this.processRequiringId.includes(this.processName);
  }

  get isStarOrMicrocredentialProcss(): boolean {
    return this.isStarProcess || this.isMCEDProcess;
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm(): UCElementGroup {
    return group({
      birthDate: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: 'applicant',
        path: '/birthDate',
      }),
      names: group({
        legalName: control({
          defaultState: new Name({}),
          validators: [Validators.required],
          model: 'applicant',
          path: '/legalName',
        }),
        name: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/name',
        }),
      }),
      genderGroup: group({
        gender: control({ model: 'applicant', path: '/gender', inMap: refDataToValue, outMap: valueToRefData }),
      }),
      citizenship: group({
        citizenCategory: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/citizenship',
        }),
        citizenCountry: control({ model: 'applicant', path: '/demographic/passportCountry' }),
        nsnNumber: control({ model: 'applicant', path: '/identity/nsnNumber' }),
        studyInNz: control({ model: 'application', path: '/studyInNz', validators: [Validators.required] }),
      }),
      contactDetails: group({
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [
            UCValidators.phonePresenceValidator,
            UCValidators.completePhoneValidator,
            UCValidators.nzMobileValidator,
          ],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        preferredContactMethod: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/preferredContactMethod',
        }),
      }),
      fitnessToTeachGroup: group({
        disability: control({
          defaultState: [],
          model: 'applicant',
          path: '/disability',
          inMap: refDatasToCodeArray,
          outMap: controlToRefDataArray,
          validators: [],
        }),
        hasDisability: control({
          model: 'applicant',
          path: '/declarationDisability',
          inMap: refDataToValue,
          outMap: valueToRefData,
          validators: [Validators.required],
        }),
        disabilityDetail: control({
          model: 'applicant',
          path: '/disabilityDetail',
        }),
      }),
      termsAndConditionsGroup: group({
        acceptedTermsAndPrivacy: control({
          model: 'application',
          path: '/acceptedTermsAndPrivacy',
          validators: [Validators.requiredTrue],
        }),
        declaredMetRequirements: control({
          model: 'application',
          path: '/declaredMetRequirements',
          validators: [Validators.requiredTrue],
        }),
        acceptedProvideIdentity: control({
          model: 'application',
          path: '/acceptedProvideIdentity',
          validators: [Validators.requiredTrue],
        }),
        uconlineMarketingEmails: control({
          model: 'applicant',
          path: '/uconlineMarketingEmails',
        }),
      }),
    });
  }

  private disableVerifiedControls(applicant?: Applicant) {
    this.disableBirthDate(applicant);
    this.disableCitizen(applicant);
    this.disableLegalName(applicant);
  }

  private disableLegalName(applicant: Applicant) {
    if (applicant?.legalName.validated) {
      this.onlineMicroCredentialDetailsPage.get('names.legalName').disable();
    }
  }

  private disableCitizen(applicant: Applicant) {
    if (applicant?.demographic.validatedCitizenship) {
      this.onlineMicroCredentialDetailsPage.get('citizenship.citizenCategory').disable();
      this.onlineMicroCredentialDetailsPage.get('citizenship.citizenCountry').disable();
    }
  }

  private disableBirthDate(applicant: Applicant) {
    if (applicant?.validatedBirthDate) {
      this.onlineMicroCredentialDetailsPage.get('birthDate').disable();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isNzOrAus(val, citizenType?: string): boolean {
    if (!val) {
      return false;
    }

    if (citizenType) {
      return OnlineMicroCredentialDetailsComponent.checkOnWhenHasCitizenType(val, citizenType);
    } else {
      return OnlineMicroCredentialDetailsComponent.checkOnWhenNotHaveCitizenType(val, citizenType);
    }
  }

  static checkOnWhenHasCitizenType(val, citizenType) {
    if (OnlineMicroCredentialDetailsComponent.shouldCheckNZOrAusPR(val, citizenType)) {
      return ['NZPR', 'AUSPR'].includes(val.code);
    }

    if (this.shouldCheckNZAusPROrAusCitizen(val, citizenType)) {
      return ['NZPR', 'AUSPR', 'AUS'].includes(val.code);
    }

    return false;
  }

  static checkOnWhenNotHaveCitizenType(val, citizenType) {
    if (OnlineMicroCredentialDetailsComponent.shouldCheckNZOrAusCitizen(val, citizenType)) {
      return ['NZCZ', 'AUS'].includes(val.code);
    }
    return false;
  }

  static shouldCheckNZOrAusCitizen(val, citizenType) {
    return val.code && !citizenType;
  }

  static shouldCheckNZOrAusPR(val, citizenType) {
    return val.code && citizenType === 'pr';
  }

  static shouldCheckNZAusPROrAusCitizen(val, citizenType) {
    return val.code && citizenType && citizenType !== 'pr';
  }

  ngOnInit() {
    this.onlineMicroCredentialDetailsForm = this.createForm();
    this.onlineMicroCredentialDetailsPage = this.onlineMicroCredentialDetailsForm.asControl() as UntypedFormGroup;
    this.baseYear = new Date().getFullYear() - 10;

    this.setValidatorForCitizenCountry();

    this.setValidatorForDisabilityDetail();

    combineLatest(
      this.applicantService.getApplicant(),
      this.applicationService.application,
      this.applicationService.applicationEnrolments,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(this.subscribeHandler);
  }

  private subscribeHandler = ([applicant, application, applicationEnrolments]: [
    Applicant,
    Application,
    ApplicationEnrolment[],
  ]) => {
    this.currentApplicant = applicant;
    this.currentApplication = application;
    this.log.info(applicationEnrolments);
    this.formModel.updateFormFromModel(this.onlineMicroCredentialDetailsForm, applicant, application);
    if (applicant && !applicant.name.length) {
      this.otherNames.removeAt(0);
    }

    this.disableVerifiedControls(applicant);
  };

  private setValidatorForDisabilityDetail() {
    this.fitnessToTeachGroup.hasDisability.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        const disabilityDetailControl = this.fitnessToTeachGroup.disabilityDetail.control;
        if (val === DISABILITY_CONSTANTS.IS_TRUE) {
          disabilityDetailControl.setValidators([Validators.required, Validators.minLength(1)]);
        } else {
          disabilityDetailControl.setValidators([]);
        }
        disabilityDetailControl.updateValueAndValidity();
      });
  }

  private setValidatorForCitizenCountry() {
    this.citizenship.citizenCategory.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        const citizenCountryControl = this.citizenship.citizenCountry.control;
        if (val.code && !this.isNzOrAus(val)) {
          citizenCountryControl.setValidators([Validators.required]);
        } else {
          citizenCountryControl.setValidators([]);
        }
        citizenCountryControl.updateValueAndValidity();
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineMicroCredentialDetailsForm);
  }

  public checkFormValidity(): string[] {
    this.onlineMicroCredentialDetailsPage.updateValueAndValidity();
    if (this.onlineMicroCredentialDetailsPage.invalid) {
      return this.formModel.getInvalidPathesInElementGroup(this.onlineMicroCredentialDetailsForm);
    } else {
      return [];
    }
  }

  submitStageAndApplicationIfRequired() {
    this.onlineProcessService
      .submitStage(
        PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL,
        this.onlineCourseService.getCourseYear(),
        STAGE_NAMES.UCONLINE_TO_ENROL,
      )
      .pipe(switchMap(() => this.applicationService.getApplication(this.onlineCourseService.getCourseYear())))
      .subscribe({
        next: (app) => {
          this.onSubmitSuccess(app);
        },
        error: (err) => {
          this.onSubmitFailed(err);
        },
      });
  }

  private onSubmitSuccess = (app) => {
    if (app) {
      app.declarationAgree = null;
      this.applicationService.application$.next(app);
    }
    this.next.emit();
  };

  private onSubmitFailed = (err) => {
    this.log.error(new Error('Error submitting stage'));
    this.errors.emit(err);
  };

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    const firstCode = this.getFirstCode();
    const studyFirst = this.currentApplication.studyInNz;

    this.formModel.updateModelFromForm(
      this.onlineMicroCredentialDetailsForm,
      this.currentApplicant,
      this.currentApplication,
    );

    this.prepareCurrentApplication(firstCode, studyFirst);

    this.prepareCurrentApplicant();

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe(this.onUpdateSuccess, this.onUpdateFailed);
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('updated applicant and application successfully');
    }
    this.submitStageAndApplicationIfRequired();
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };

  private prepareCurrentApplicant() {
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);
    if (get(this.currentApplicant, 'uconlineMarketingEmails') === null) {
      set(this.currentApplicant, 'uconlineMarketingEmails', false);
    }

    this.resetIwiForNotMaori();
    this.updatePassportCountry();
  }

  private prepareCurrentApplication(firstCode, studyFirst) {
    if (this.shouldResetStudyFullTimeAndLocation(firstCode, studyFirst)) {
      this.currentApplication.studyFullTime = null;
      this.currentApplication.studyLocation = null;
    }
  }

  private shouldResetStudyFullTimeAndLocation(firstCode, studyFirst) {
    return (
      (firstCode && this.currentApplicant.demographic.citizenship.code !== firstCode) ||
      studyFirst !== this.currentApplication.studyInNz
    );
  }

  private resetIwiForNotMaori() {
    if (
      this.currentApplicant.demographic &&
      !this.currentApplicant.demographic.ethnicity.find((val) => val.code === ETHNICITIES.NEW_ZEALAND_MAORI)
    ) {
      this.currentApplicant.demographic.iwi = undefined;
    }
  }

  private getFirstCode() {
    let firstCode;
    if (this.currentApplicant.demographic && this.currentApplicant.demographic.citizenship) {
      firstCode = this.currentApplicant.demographic.citizenship.code;
    }
    return firstCode;
  }

  private updatePassportCountry() {
    this.updateOnCitizenship();
    if (!this.currentApplicant.demographic.passportCountry || !this.currentApplicant.demographic.passportCountry.code) {
      this.currentApplicant.demographic.passportCountry = null;
    }
  }

  private updateOnCitizenship() {
    if (this.currentApplicant.demographic.citizenship) {
      this.updateForNZorAusCitizen();
    }
  }

  private updateForNZorAusCitizen() {
    if (this.currentApplicant.demographic.citizenship.code === 'NZCZ') {
      this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'NZL' });
    } else if (this.currentApplicant.demographic.citizenship.code === 'AUS') {
      this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'AUS' });
    }
  }
}
