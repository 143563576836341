import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { unset } from 'lodash-es';
import { distinctUntilKeyChanged, filter, switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { Qualification, QualificationSummary, SubjectOptions } from '@shared/models/qualification';
import { ApplicationService } from '@shared/services/application/application.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

import { QualificationAnswer, QualificationEnrolment } from '../model';

@Component({
  selector: 'uc-qualification-enrolment-selector',
  templateUrl: './qualification-enrolment-selector.component.html',
  styleUrls: ['./qualification-enrolment-selector.component.scss'],
})
export class QualificationEnrolmentSelectorComponent implements OnInit, OnChanges {
  /**
   * This is the incoming options to support different ruleset for selecting a qualification
   */
  @Input() qualificationOptions: QualificationSummary[];

  /**
   * This is any user enrolled qualification that is already loaded
   */
  @Input() qualificationEnrolment: QualificationEnrolment;
  @Input() showQualificationSelector = true;

  @Input() hideTitle = false;
  // Emits our updated model
  @Output() saveQualification = new EventEmitter<QualificationEnrolment>();

  @Output() firstQualification = new EventEmitter<string>();

  @Output() qualForm = new EventEmitter<UntypedFormGroup>();
  @Output() selectedQualification = new EventEmitter();

  currentQualForm = this.fb.group({
    code: new UntypedFormControl('', Validators.required),
    subjectOptions: this.fb.group({}),
  });

  strings = strings.components.tasks.qualificationSelector;
  currentQualOption: Qualification;

  private log: Logger;

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private applicationService: ApplicationService,
    private fb: UntypedFormBuilder,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get subjectOptions(): UntypedFormArray {
    return this.currentQualForm.get('subjectOptions') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    // on first non null value passed into the component
    const firstNonNull =
      changes.qualificationEnrolment &&
      changes.qualificationEnrolment.currentValue &&
      !changes.qualificationEnrolment.previousValue;
    if (firstNonNull) {
      this.addSubjectOptions(this.qualificationEnrolment.qualificationAnswer);
      this.updateFormGroup(
        this.qualificationEnrolment.qualificationAnswer,
        this.qualificationEnrolment.asQualificationAnswer(),
      );
    }
  }

  addSubjectOptions(option: Qualification) {
    option.subjectOptions.forEach((q1) => {
      this.currentQualForm.addControl(q1.level.toString(), this.fb.array([]));
    });
  }

  ngOnInit() {
    this.currentQualForm
      .get('code')
      .valueChanges.pipe(
        filter((code) => !!code),
        switchMap((code) =>
          this.qualificationService.getQualification(this.applicationService.currentApplicationYear, code),
        ),
      )
      .subscribe((qual) => {
        this.selectedQualification.emit(qual);
        this.addSubjectOptions(qual);
        this.updateFormGroup(qual);
        const qe = new QualificationEnrolment({
          qualificationAnswer: qual,
          subjectAnswer: {},
        });
        this.firstQualification.emit(qual.code);
        this.saveQualification.emit(qe);
      });

    this.currentQualForm.valueChanges
      .pipe(
        distinctUntilKeyChanged('subjectOptions'),
        filter(() => !!this.currentQualOption),
      )
      .subscribe((data) => {
        const newQual = this.createQualificationAnswerFromFormValue(data);
        const qe = new QualificationEnrolment({
          qualificationAnswer: this.currentQualOption,
          subjectAnswer: newQual.subjectOptions,
        });
        this.saveQualification.emit(qe);
      });
  }

  private createQualificationAnswerFromFormValue(qualFormValue): QualificationAnswer {
    const newQual = { ...qualFormValue };
    unset(newQual, 'code');
    const subjectOptions = {};
    Object.keys(newQual.subjectOptions).forEach((key) => {
      const levelArray = newQual.subjectOptions[key].filter((el) => !!el);
      if (levelArray && levelArray.length > 0) {
        subjectOptions[key] = levelArray.map((opt) => {
          if (opt) {
            return { code: opt };
          }
        });
      }
    });
    const qual = {
      code: qualFormValue.code,
      subjectOptions,
    };
    return new QualificationAnswer(qual);
  }

  private updateFormGroup(option?: Qualification, answer?: QualificationAnswer) {
    option.subjectOptions.map((q1, _i) => {
      this.updateGroupForOptionType(q1.level.toString(), q1, answer);
    });
    if (answer) {
      this.currentQualForm.get('code').setValue(answer.code, { emitEvent: false });
    }
    this.currentQualOption = option;
  }

  private updateGroupForOptionType(name: string, option: SubjectOptions, answer: QualificationAnswer) {
    const subjectOptionsGroup = this.currentQualForm.get('subjectOptions') as UntypedFormGroup;
    if (subjectOptionsGroup.controls[name]) {
      // e.b use this instead of removecontrol because we don't want to trigger valueChanges when changing the formGroup structure
      delete subjectOptionsGroup.controls[name];
    }
    subjectOptionsGroup.registerControl(name, this.createArrayControlForOptionType(name, option, answer));
  }

  private createArrayControlForOptionType(
    name: string,
    option: SubjectOptions,
    answer: QualificationAnswer,
  ): UntypedFormArray {
    if (option.level.toString() !== name) {
      return new UntypedFormArray([]);
    }

    const controls = option.optionLists?.map((opt, i) => {
      const validators = opt.required ? [Validators.required] : [];
      let answerValue: any = '';
      if (answer && answer.subjectOptions[name]) {
        const updatedSubjectOptions = answer.subjectOptions[name].filter((subject) => {
          return subject.hasOwnProperty('changeAction') ? subject.changeAction !== CHANGE_ACTIONS.DROPPED : subject;
        });
        answerValue = updatedSubjectOptions[i] ? updatedSubjectOptions[i].code : '';
      }
      return new UntypedFormControl(answerValue, validators);
    });
    return new UntypedFormArray(controls);
  }

  qualificationUpdated(qual) {
    this.currentQualForm.get('code').setValue(qual.code);
  }

  getSubjectOptionValues(index: number) {
    return (this.currentQualForm.get('subjectOptions') as UntypedFormArray).at(index);
  }

  getControls(level: string) {
    const subjectOptionsForm = this.currentQualForm.get('subjectOptions') as UntypedFormGroup;
    return subjectOptionsForm.get(level.toString()) as UntypedFormArray;
  }

  qualSelectorFormEmitter(qualForm: UntypedFormGroup) {
    this.qualForm.emit(qualForm);
  }
}
