import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { QUAL_CATEGORIES } from '@shared/constants/categories';
import { CHANGE_ACTIONS, ASSOCIATED_PERSON_TYPE, APPLICATION_CHANGE_STATE } from '@shared/constants/states.constants';
import strings from '@shared/constants/strings.constants';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { CACompliance } from '@shared/models/ca-compliance';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { EnrolledQualification } from '@shared/models/qualification';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  booleanToSingleCheckboxValue,
  booleanToYesNo,
  control,
  group,
  singleCheckboxValueToBoolean,
  UCElementGroup,
  yesNoToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-ca-compliance',
  templateUrl: './ca-compliance.component.html',
  styleUrls: ['./ca-compliance.component.scss'],
})
export class CAComplianceComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() fundingType: string;
  @Input() process: string;
  @Input() stage: string;

  pageLoadFlag = false;
  log: Logger;
  strings = strings.components.tasks.caCompliance;
  declarationStrings = strings.components.tasks.declaration;

  caApplicationForm: UntypedFormGroup;
  caApplication: UCElementGroup;
  currentApplicant: Applicant;
  refereeThree = false;
  declarationOptions = [{ labelText: this.declarationStrings.cacDeclaration.header, value: 'declarationCacAgree' }];

  constructor(
    private loggingService: LoggingService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private mapper: FormModelMapperService,
    private coeService: ChangeOfEnrolmentService,
    private qualificationService: QualificationService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  private createUcElementGroup(): UCElementGroup {
    return group({
      firstRefereeReport: group({
        type: control({
          model: this.dataModel,
          path: '/associatedPerson/referee1/type',
          defaultState: new ReferenceData({ code: 'referee1' }),
        }),
        name: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee1/name',
        }),
        relationship: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee1/relationship',
        }),
        email: control({
          model: this.dataModel,
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/referee1/email',
        }),
      }),
      secondRefereeReport: group({
        type: control({
          model: this.dataModel,
          path: '/associatedPerson/referee2/type',
          defaultState: new ReferenceData({ code: 'referee2' }),
        }),
        name: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee2/name',
        }),
        relationship: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee2/relationship',
        }),
        email: control({
          model: this.dataModel,
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/referee2/email',
        }),
      }),
      thirdRefereeReport: group({
        type: control({
          model: this.dataModel,
          path: '/associatedPerson/referee3/type',
          defaultState: new ReferenceData({ code: 'referee3' }),
        }),
        name: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee3/name',
        }),
        relationship: control({
          model: this.dataModel,
          validators: [Validators.required],
          path: '/associatedPerson/referee3/relationship',
        }),
        email: control({
          model: this.dataModel,
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/referee3/email',
        }),
      }),
      hasChildRemoved: control({
        model: 'applicant',
        path: '/caCompliance/hasChildRemoved',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      childRemoved: control({
        model: 'applicant',
        path: '/caCompliance/childRemoved',
        validators: [Validators.required],
      }),
      hasDisciplinaryProceeding: control({
        model: 'applicant',
        path: '/caCompliance/hasDisciplinaryProceeding',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      disciplinaryProceeding: control({
        model: 'applicant',
        path: '/caCompliance/disciplinaryProceeding',
        validators: [Validators.required],
      }),
      hasInvestigationProtectionOrder: control({
        model: 'applicant',
        path: '/caCompliance/hasInvestigationProtectionOrder',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      investigationProtectionOrder: control({
        model: 'applicant',
        path: '/caCompliance/investigationProtectionOrder',
        validators: [Validators.required],
      }),
      hasDeniedEntry: control({
        model: 'applicant',
        path: '/caCompliance/hasDeniedEntry',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      deniedEntry: control({
        model: 'applicant',
        path: '/caCompliance/deniedEntry',
        validators: [Validators.required],
      }),
      declarationCheck: control({
        model: this.dataModel,
        path: '/declarationCacAgree',
        defaultState: false,
        inMap: booleanToSingleCheckboxValue('declarationCacAgree'),
        outMap: singleCheckboxValueToBoolean('declarationCacAgree'),
        validators: [UCValidators.validateAllChecked(this.declarationOptions)],
      }),
    });
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.caApplication = this.createUcElementGroup();
    this.caApplicationForm = this.caApplication.asControl() as UntypedFormGroup;

    observableCombineLatest(this.applicantService.applicant.pipe(filter((a) => !!a)), this.service.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]) => {
        if (!applicant.caCompliance) {
          applicant.caCompliance = new CACompliance();
        }
        this.currentApplicant = applicant;
        this.currentApplication = application;
        if (!this.pageLoadFlag) {
          if (!this.isCOE) {
            const year = (this.currentApplication as Application).academicYear?.code;
            this.applicationService
              .getApplicationEnrolment(year)
              .pipe(take(1))
              .pipe(takeUntil(this.componentDestroyed))
              .subscribe((enrolment: ApplicationEnrolment[]) => {
                if (enrolment) {
                  const changeStates = [APPLICATION_CHANGE_STATE.SHADOW, APPLICATION_CHANGE_STATE.STUDENT_PENDING];
                  const currentEnrol = enrolment.find((ae) => changeStates.indexOf(ae.state.code) !== -1);
                  if (currentEnrol) {
                    this.retrieveQualCategory(currentEnrol?.enrolledQualifications, year);
                  } else {
                    let activeAEs = enrolment.filter((ae) => ae.active);
                    if (activeAEs.some((el) => !el.priority)) {
                      activeAEs = activeAEs.sort((a, b) => a.displayOrder - b.displayOrder);
                    } else {
                      activeAEs = activeAEs.sort((a, b) => a.priority - b.priority);
                    }

                    if (activeAEs.length) {
                      this.retrieveQualCategory(activeAEs[0]?.enrolledQualifications, year);
                    }
                  }
                }
              });
          } else {
            this.currentApplication = this.currentApplication as ChangeOfEnrolment;
            this.currentApplication.enrolledQualifications = this.currentApplication.enrolledQualifications.filter(
              (qual) => qual.changeAction === CHANGE_ACTIONS.ADDED,
            );
            this.retrieveQualCategory(
              (this.currentApplication as ChangeOfEnrolment).enrolledQualifications,
              (this.currentApplication as ChangeOfEnrolment).academicYear.code,
            );
          }
        }
      });
  }

  retrieveQualCategory(enrolledQualifications: EnrolledQualification[], year: string) {
    const qualCodeURIs = enrolledQualifications?.map((qual) => qual.href);
    this.qualificationService
      .getQualificationsByURIs(year, qualCodeURIs)
      .pipe(take(1))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((qualResult) => {
        if (qualResult && qualResult.qualification) {
          this.refereeThree = !!qualResult.qualification.find(
            (qual) => !!qual?.categories.find((cat) => cat.category === QUAL_CATEGORIES.REFEREE_THREE),
          );
          if (!this.refereeThree) {
            this.caApplication.removeControl('thirdRefereeReport');
          }
          this.mapper.updateFormFromModel(this.caApplication, this.currentApplicant, this.currentApplication);
          this.pageLoadFlag = true;
        }
      });
  }

  public updateFormValidity(err: UCError): void {
    this.mapper.updateFormValidity(err.data, this.caApplication);
  }

  cleanUpCurrentApplication() {
    if (this.caApplicationForm.get('hasChildRemoved').value === 'hide') {
      this.caApplicationForm.get('childRemoved').setValue('');
    }
    if (this.caApplicationForm.get('hasDisciplinaryProceeding').value === 'hide') {
      this.caApplicationForm.get('disciplinaryProceeding').setValue('');
    }
    if (this.caApplicationForm.get('hasInvestigationProtectionOrder').value === 'hide') {
      this.caApplicationForm.get('investigationProtectionOrder').setValue('');
    }
    if (this.caApplicationForm.get('hasDeniedEntry').value === 'hide') {
      this.caApplicationForm.get('deniedEntry').setValue('');
    }
  }

  updateChangeOfEnrolmentModel() {
    return new ChangeOfEnrolment({
      associatedPerson: this.currentApplication.associatedPerson,
      declarationCacAgree: this.currentApplication.declarationCacAgree,
    });
  }

  public update() {
    this.cleanUpCurrentApplication();
    if (!this.refereeThree) {
      delete this.currentApplication.associatedPerson[ASSOCIATED_PERSON_TYPE.REFEREE_THREE];
    }
    this.mapper.updateModelFromForm(this.caApplication, this.currentApplicant, this.currentApplication);
    const application = this.isCOE ? this.updateChangeOfEnrolmentModel() : this.currentApplication;
    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.service.update(application);
    observableCombineLatest(updateApplicant, updateApplication).subscribe(
      ([updatedApplicant, updatedApplication]) => {
        if (updatedApplicant && updatedApplication) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating application:', err);
      },
    );
  }
}
