<div [formGroup]="checksFormGroup">
  <div class="item-label">
    <div class="item-tab" *ngIf="countryLabel" [innerHtml]="countryLabel"></div>
  </div>
  <div class="item-content-wrapper">
    <div class="item-content-section">
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        [path]="'/declaration_background_check/' + index + '/country'"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.countryLabel }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-reference-data-selector
        class="country-select"
        formControlName="country"
        type="country"></uc-reference-data-selector>
    </div>

    <div class="item-content-section">
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        [path]="'/declaration_background_check/' + index + '/applied'"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.provideReport }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.provideReportDesc }}</uc-subsection-minor-text>
      <uc-radio-input-group
        formControlName="applied"
        class="police-radio"
        [options]="radioOptions"
        [groupName]="'police-report' + index"></uc-radio-input-group>
      <uc-subsection-text-block *ngIf="checksFormGroup.get('applied').value === 'NONE'">
        <p [innerHTML]="strings.assistance"></p>
      </uc-subsection-text-block>
      <uc-message-banner
        type="info"
        *ngIf="checksFormGroup.get('applied').value === 'HAVE' || checksFormGroup.get('applied').value === 'APPLIED'">
        {{ strings.uploadDesc }}
      </uc-message-banner>
      <button
        [attr.data-cy]="'delete-country'"
        *ngIf="numOfChecks > 1"
        class="delete-btn"
        (click)="deleteThisCountry()">
        <uc-icon
          class="delete-btn-icon"
          iconType="trash"
          iconWidth="16"
          iconHeight="16"
          iconFill="$colorFireEngineRed"></uc-icon>
        <span class="delete-btn-text">{{ strings.removeCountryText }}</span>
      </button>
    </div>

    <div class="item-content-section" *ngIf="checksFormGroup.get('applied').value === 'HAVE'">
      <uc-subsection-question>{{ strings.uploadCheck }}</uc-subsection-question>
      <uc-form-field-label>
        <uc-file-upload title="Overseas police check report" categoryCode="POLICE" usedInTask="true"></uc-file-upload>
      </uc-form-field-label>
    </div>
  </div>
</div>
