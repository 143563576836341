import {
  Component,
  AfterContentInit,
  Input,
  QueryList,
  ContentChild,
  HostListener,
  ContentChildren,
} from '@angular/core';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';

import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-responsive-grid',
  template: `
    <ng-content></ng-content>
  `,
  styles: [],
})
export class ResponsiveGridComponent implements AfterContentInit {
  @ContentChild(MatGridList) gridList: MatGridList;
  @ContentChildren(MatGridTile, { descendants: true }) gridTiles: QueryList<MatGridTile>;

  switchToFourColumns = 1024;
  private _breakpoints: number[][] = [
    [425, 1],
    [1024, 2],
    [1920, 4],
  ];

  private _childTileMap: { originalColSpan: number; tile: MatGridTile }[];

  constructor(private ws: WindowService) {}

  /**
   * Breakpoints is a [[number,number]] which is ordered and configures
   * when the gridlist should change column layout
   */
  @Input()
  get breakpoints(): number[][] {
    return this._breakpoints;
  }

  set breakpoints(val) {
    // make sure the breakpoints array is in ascending order
    if (val) {
      this._breakpoints = val.sort((lhs, rhs) => {
        return lhs[0] - rhs[0];
      });
    }
  }

  @HostListener('window:resize') onResize() {
    const windowRef = this.ws.nativeWindow;
    this.reactToResize(windowRef.innerWidth);
  }

  ngAfterContentInit() {
    this._childTileMap = this.gridTiles.map((tile) => {
      return {
        originalColSpan: tile.colspan,
        tile,
      };
    });

    const windowRef = this.ws.nativeWindow;
    this.reactToResize(windowRef.innerWidth);
  }

  reactToResize(width: number) {
    let newColumnWidth;
    if (!this._breakpoints) {
      throw new Error('ResponsiveGridComponent should have breakpoints');
    }

    newColumnWidth = this._breakpoints.find(([nextWidth, cols], i) => {
      const [previousWidth] = i > 0 ? this._breakpoints[i - 1] : [0, cols];
      return previousWidth < width && width <= nextWidth;
    });

    if (!newColumnWidth) {
      // default to the largest column size
      const l = this._breakpoints.length;
      newColumnWidth = this._breakpoints[l - 1];
    }

    this._childTileMap.forEach(({ originalColSpan, tile }) => {
      if (originalColSpan > newColumnWidth[1]) {
        tile.colspan = newColumnWidth[1];
      } else {
        tile.colspan = originalColSpan;
      }
    });

    this.gridList.cols = newColumnWidth[1];
  }
}
