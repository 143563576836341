import { camelizeKeys } from '@shared/helpers/serialization';

export class CourseSummary {
  public currentPoints: number;
  public maximumPoints: number;
  public minimumPoints: number;
  public totalItems: number;
  public error: string[];

  constructor(data: any) {
    Object.assign(this, data);
  }

  static deserialize(payload: any): CourseSummary {
    if (payload === null) {
      return null;
    }

    return new CourseSummary(camelizeKeys(payload));
  }

  static default(): CourseSummary {
    return new CourseSummary({
      currentPoints: 0,
      maximumPoints: 60,
      minimumPoints: 45,
      totalItems: 0,
      error: [],
    });
  }
}
