<section class="errors">
  <div *ngFor="let err of errorMessages" class="error">
    <uc-message-banner type="error">
      <span [innerHTML]="err | errorMessage"></span>
    </uc-message-banner>
  </div>
</section>
<uc-message-banner type="info" *ngIf="bannerMessage">
  <span [innerHTML]="bannerMessage"></span>
</uc-message-banner>
<form [formGroup]="loginForm">
  <uc-string-input
    testSelector="login-email"
    noIcon="true"
    class="email-input"
    formControlName="email"
    (blur)="stripInvalidCharacters()"
    (keypress)="stripInvalidCharacters()"
    (paste)="stripInvalidCharacters()"
    [label]="labelStrings.emailOrUsername"></uc-string-input>
  <uc-password-input
    testSelector="login"
    controlName="password"
    [label]="labelStrings.password.default"
    [form]="loginForm"></uc-password-input>
  <div class="forgot-password" *ngIf="!ldapOnly">
    <a href="javascript:void(0)" (click)="triggerForgotPassword()">{{ loginFormStrings.forgotQuestion }}</a>
  </div>
  <div class="login-button">
    <uc-button
      testSelector="login"
      [isLoading]="displaySpinner"
      (click)="loginByEmail()"
      [disabled]="loginForm.status !== 'VALID'">
      {{ loginFormStrings.loginButton }}
    </uc-button>
  </div>
</form>

<!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
<!-- prettier-ignore -->
<p class="create-account-prompt" *ngIf="!ldapOnly">{{ loginFormStrings.createAccountPrompt }} <a
  href="javascript:void(0)" (click)="goToAccountCreate()">here</a>.</p>

<uc-confirmation-modal
  #firebaseRestrictedModal
  [title]="loginFormStrings.resetPasswordForm.title"
  [message]="loginFormStrings.resetPasswordForm.restrictedBody"
  [acceptText]="loginFormStrings.resetPasswordForm.close"></uc-confirmation-modal>

<uc-confirmation-modal
  #passwordResetCompleteModal
  [title]="loginFormStrings.confirmReset.title"></uc-confirmation-modal>
