import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: 'monthYearLabel',
  },
};

class PickDateAdapter extends NativeDateAdapter {
  parse(value: string, parseFormat: string): Date {
    const nzParse = DateTime.fromFormat(value, 'd/M/y');
    if (nzParse.isValid) {
      return nzParse.toJSDate();
    } else {
      return super.parse(value, parseFormat);
    }
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd/MM/yyyy', this.locale);
    } else if (displayFormat === 'monthYearLabel') {
      return formatDate(date, 'MMM yyyy', this.locale);
    } else {
      return date.toDateString();
    }
  }
}
@Component({
  selector: 'uc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-NZ' },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  // minDate and maxDate must be in format Date(year, month, day)
  // note: month starts at 0
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() startDate: string = new Date().toISOString();
  @Input() control: UntypedFormControl;
  @Input() datePlaceHolder: string;
  @Output() selectedDate = new EventEmitter();
  date: UntypedFormControl;

  ngOnInit(): void {
    this.date = new UntypedFormControl(this.startDate);

    this.date.valueChanges.subscribe((date) => {
      this.selectedDate.emit(date);
    });
  }
}
