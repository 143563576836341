import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { QualificationEnrolment } from '@shared/applicant/qualification/model';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { APPLICATION_CHANGE_STATE } from '@shared/constants/states.constants';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-confirm-coa-changes',
  templateUrl: './confirm-coa-changes.component.html',
  styleUrls: ['./confirm-coa-changes.component.scss'],
})
export class ConfirmCoaChangesComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() applicationYear: string;

  loading = true;
  newAE: QualificationEnrolment[];
  previousAE: QualificationEnrolment[];
  noChangesMade = false;
  hasPreviousEnrolledQuals = false;
  hasPreviousAE = false;
  strings = strings.components.tasks.confirmCOAChanges;
  log: Logger;

  constructor(
    private applicationService: ApplicationService,
    private qualificationService: QualificationService,
    private processService: ProcessService,
    private router: Router,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete(): boolean {
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  ngOnInit() {
    this.applicationService.getApplicationEnrolment(this.applicationYear).subscribe((aes: ApplicationEnrolment[]) => {
      let activeAEs = aes.filter((ae) => ae.active);
      if (activeAEs.some((el) => !el.priority)) {
        activeAEs = activeAEs.sort((a, b) => a.displayOrder - b.displayOrder);
      } else {
        activeAEs = activeAEs.sort((a, b) => a.priority - b.priority);
      }

      if (activeAEs.length) {
        this.hasPreviousAE = true;
        const previousAEObs = activeAEs[0].enrolledQualifications.map((qualEnrol) => {
          return this.qualificationService
            .getQualification(this.applicationService.currentApplicationYear, qualEnrol.code)
            .pipe(
              map((qual) => {
                return QualificationEnrolment.createFrom(qualEnrol, qual);
              }),
            );
        });

        combineLatest(previousAEObs).subscribe((qualEnrolments) => {
          if (qualEnrolments.length > 0) {
            this.hasPreviousEnrolledQuals = true;
            this.previousAE = qualEnrolments;
          }
        });
      }

      const changeStates = [APPLICATION_CHANGE_STATE.SHADOW, APPLICATION_CHANGE_STATE.STUDENT_PENDING];
      const newAE = aes.find((ae) => changeStates.indexOf(ae.state.code) !== -1);

      if (newAE) {
        const newAEObs = newAE.enrolledQualifications.map((qualEnrol) => {
          return this.qualificationService
            .getQualification(this.applicationService.currentApplicationYear, qualEnrol.code)
            .pipe(
              map((qual) => {
                return QualificationEnrolment.createFrom(qualEnrol, qual);
              }),
            );
        });

        combineLatest(newAEObs).subscribe((qualEnrolments) => {
          this.newAE = qualEnrolments;
          this.loading = false;
        });
      } else {
        this.noChangesMade = true;
        this.loading = false;
      }
    });
  }

  public updateFormValidity(_err: UCError) {}

  update() {
    if (this.stage.isStageComplete) {
      this.processService
        .submitStage(PROCESS_NAMES.COA, this.applicationYear, STAGE_NAMES.CHANGE_OF_APPLICATION)
        .subscribe(
          () => {
            this.next.emit();
          },
          (err) => {
            this.log.error(new Error('Error submitting stage'));
            this.errors.emit(err);
          },
        );
    } else {
      this.router.navigate(internalUrls.dashboard);
    }
  }
}
