import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface IAdditionalText {
  code: string;
  description?: string;
  inlineGuidance?: string;
}

@Component({
  selector: 'uc-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true,
    },
  ],
})
export class RadioInputComponent implements OnInit, ControlValueAccessor {
  @Input() labelText: string;
  @Input() groupName: string;
  @Input() fieldValue: string;
  @Input() groupValue: string;
  @Input() additionalText: IAdditionalText[];
  @Input() testSelector = '';

  log: Logger;
  radioControl = new UntypedFormControl('');

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    const testSelector = !!this.testSelector ? this.testSelector : `${this.groupName}`;
    return `${testSelector}-${this.fieldValue}-radio`;
  }

  get extraText(): IAdditionalText {
    if (this.additionalText) {
      return this.additionalText.find((val) => {
        return val.code === this.fieldValue;
      });
    }
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => {};

  ngOnInit() {
    this.log.info('ngOnInit');

    this.radioControl.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: any): void {
    this.radioControl.setValue(obj);
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(_fn: any): void {}
}
