<div [formGroup]="tertiaryEducationForm">
  <uc-section>
    <uc-section-header [title]="taskTitle">
      <p>{{ strings.description }}</p>
    </uc-section-header>
    <uc-form-input formArrayName="educations">
      <uc-subsection>
        <div
          *ngFor="let education of educations.controls; let last = last; let first = first; let i = index"
          [formGroupName]="i">
          <div *ngIf="tertiaryType === qualificationLevels.FOUNDATION && first">
            <uc-subsection-question>
              {{ strings.foundationQuestion }}
            </uc-subsection-question>
            <uc-subsection-minor-text>
              {{ strings.foundationGuidance }}
            </uc-subsection-minor-text>
            <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/type"></uc-task-hint>
            <uc-form-field-label [labelText]="strings.foundationQual" required="true">
              <uc-reference-data-selector
                class="full-width-selector"
                formControlName="type"
                type="foundation_qualification"></uc-reference-data-selector>
            </uc-form-field-label>

            <div *ngIf="showOtherQualInput(i)">
              <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/type_other"></uc-task-hint>
              <uc-form-field-label [labelText]="strings.programmeOrCourse" required="true">
                <uc-string-input
                  [testSelector]="'tertiary-' + i + '-type-other'"
                  formControlName="typeOther"
                  required="yes"></uc-string-input>
              </uc-form-field-label>

              <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/source_other"></uc-task-hint>
              <uc-form-field-label [labelText]="strings.educationalProvider" required="true">
                <uc-string-input
                  [testSelector]="'tertiary-' + i + '-source-other'"
                  formControlName="sourceOther"
                  required="yes"></uc-string-input>
              </uc-form-field-label>

              <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/country"></uc-task-hint>
              <uc-form-field-label [labelText]="strings.country" required="yes">
                <uc-reference-data-selector formControlName="country" type="country"></uc-reference-data-selector>
              </uc-form-field-label>
            </div>

            <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/date_started"></uc-task-hint>
            <uc-form-field-label [labelText]="strings.startYear" required="true">
              <uc-reference-data-selector
                formControlName="dateStarted"
                type="tertiary_qualification_year_started"
                [params]="{ applicationYear: applicationYear }"
                [ignoreCache]="true"></uc-reference-data-selector>
            </uc-form-field-label>

            <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/attained"></uc-task-hint>
            <uc-radio-input-group
              [formControl]="education.get('attained')"
              [options]="radioOptions"
              [label]="strings.haveYouCompleted"
              [displayHorizontally]="true"
              required="true"
              [groupName]="'completed-' + i"></uc-radio-input-group>

            <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/date_attained"></uc-task-hint>
            <uc-form-field-label *ngIf="hasCompletedQual(i)" [labelText]="strings.completeYear" required="true">
              <uc-year-selector
                [testSelector]="'tert-completed-' + i"
                [baseYear]="applicationYear"
                [range]="71"
                [control]="education.get('dateAttained')"></uc-year-selector>
            </uc-form-field-label>
          </div>

          <div *ngIf="tertiaryType !== qualificationLevels.FOUNDATION">
            <div class="item-label">
              <div class="item-tab" [innerHTML]="strings.qualLabel(i)"></div>
            </div>
            <div class="qualification-wrapper">
              <uc-subsection>
                <button
                  [attr.data-cy]="'remove-tert-qual-' + i"
                  class="delete-btn"
                  *ngIf="i > 0"
                  (click)="removeQualification(i)">
                  <uc-icon iconType="trash" iconWidth="16" iconHeight="16" iconFill="$colorFireEngineRed"></uc-icon>
                </button>

                <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/type_other"></uc-task-hint>
                <uc-form-field-label [labelText]="strings.qualName" required="true">
                  <uc-string-input
                    [testSelector]="'tertiary-' + i + '-type-other'"
                    formControlName="typeOther"
                    required="yes"></uc-string-input>
                </uc-form-field-label>

                <uc-form-field-label [labelText]="strings.startYearQual" required="true">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/tertiary_qualification/{{ i }}/date_started"></uc-task-hint>
                  <uc-reference-data-selector
                    formControlName="dateStarted"
                    type="tertiary_qualification_year_started"
                    [params]="{ applicationYear: applicationYear }"
                    [ignoreCache]="true"></uc-reference-data-selector>
                </uc-form-field-label>

                <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/attained"></uc-task-hint>
                <uc-radio-input-group
                  [formControl]="education.get('attained')"
                  [options]="radioOptions"
                  [label]="strings.haveYouCompletedQual"
                  [displayHorizontally]="true"
                  required="true"
                  [groupName]="'completed-' + i"></uc-radio-input-group>

                <uc-task-hint
                  [task]="task"
                  path="/applicant/tertiary_qualification/{{ i }}/date_attained"></uc-task-hint>
                <uc-form-field-label *ngIf="hasCompletedQual(i)" [labelText]="strings.completeQualYear" required="true">
                  <uc-year-selector
                    testSelector="tert-attained"
                    [baseYear]="applicationYear"
                    [range]="71"
                    [control]="education.get('dateAttained')"></uc-year-selector>
                </uc-form-field-label>

                <uc-task-hint
                  [task]="task"
                  path="/applicant/tertiary_qualification/{{ i }}/source_other"></uc-task-hint>
                <uc-form-field-label [labelText]="strings.educationalProvider" required="true">
                  <uc-string-input
                    [testSelector]="'tertiary-' + i + '-source-other'"
                    formControlName="sourceOther"
                    required="yes"></uc-string-input>
                </uc-form-field-label>

                <uc-task-hint [task]="task" path="/applicant/tertiary_qualification/{{ i }}/country"></uc-task-hint>
                <uc-form-field-label [labelText]="strings.country" required="yes">
                  <uc-reference-data-selector formControlName="country" type="country"></uc-reference-data-selector>
                </uc-form-field-label>
              </uc-subsection>
            </div>

            <div *ngIf="last && educations.length < 6">
              <uc-subsection-question>{{ strings.addQualQuestion }}</uc-subsection-question>
              <uc-add-new-button
                [buttonHeading]="strings.addQualButtonLabel"
                (click)="addQualification()"></uc-add-new-button>
            </div>
          </div>
        </div>
      </uc-subsection>

      <uc-subsection *ngIf="showGpaReportUpload()">
        <uc-subsection-question>
          {{ strings.gpaReport }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.gpaReportHelp"></uc-subsection-minor-text>
        <uc-file-upload
          [year]="applicationYear"
          title="{{ strings.uploadGpaReportLabel }}"
          categoryCode="GPA_VALIDATION_REP"
          usedInTask="true"></uc-file-upload>
      </uc-subsection>

      <uc-subsection *ngIf="showTertiaryResults()">
        <uc-task-hint [task]="task" path="/documents/results_tert"></uc-task-hint>
        <uc-subsection-question required="true">
          {{ strings.uploadResults }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.uploadResultsHelp"></uc-subsection-minor-text>
        <uc-file-upload
          [year]="applicationYear"
          title="{{ strings.uploadResultsLabel }}"
          categoryCode="RESULTS_TERT"
          usedInTask="true"></uc-file-upload>
      </uc-subsection>

      <uc-subsection *ngIf="showGraduateCertificateUpload()">
        <uc-task-hint [task]="task" path="/documents/tert_grad_cert"></uc-task-hint>
        <uc-subsection-question required="true">
          {{ strings.uploadGraduationCertificate }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.uploadGraduationCertificateHelp"></uc-subsection-minor-text>
        <uc-file-upload
          [year]="applicationYear"
          title="{{ strings.uploadGraduationCertificateLabel }}"
          categoryCode="TERT_GRAD_CERT"
          usedInTask="true"></uc-file-upload>
      </uc-subsection>

      <uc-subsection *ngIf="tertiaryType === qualificationLevels.POST_GRADUATE">
        <uc-subsection-question>
          {{ strings.europeanDiplomaTitle }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.europeanDiplomaDescription"></uc-subsection-minor-text>
        <uc-file-upload
          [title]="strings.europeanDiplomaQuestion"
          categoryCode="DIPLOMA_SUPPLEMENT"
          [year]="applicationYear"
          usedInTask="true"></uc-file-upload>
      </uc-subsection>

      <uc-subsection *ngIf="tertiaryType !== qualificationLevels.FOUNDATION">
        <uc-subsection-question>
          {{ strings.transferCredit }}
        </uc-subsection-question>
        <uc-radio-input-group
          [formControl]="tertiaryEducationForm.get('transferCredits')"
          [options]="radioOptions"
          [displayHorizontally]="true"
          groupName="transfer-credits"></uc-radio-input-group>
        <div
          *ngIf="tertiaryEducationForm.get('transferCredits').value"
          [innerHTML]="strings.transferGuidance"
          class="transfer-guidance"></div>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="/application/declaration_been_excluded"></uc-task-hint>
        <uc-subsection-question required="true">
          {{ strings.beenExcluded }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.beenExcludedGuidance"></uc-subsection-minor-text>
        <uc-radio-input-group
          [formControl]="tertiaryEducationForm.get('declarationBeenExcluded')"
          [options]="radioOptions"
          [displayHorizontally]="true"
          groupName="excluded"></uc-radio-input-group>
      </uc-subsection>
    </uc-form-input>
  </uc-section>
</div>
