<section class="errors">
  <uc-message-banner *ngIf="createAccountForm.errors?.passwordMatchError" type="warning">
    {{ createAccountForm.errors.passwordMatchError }}
  </uc-message-banner>

  <uc-message-banner *ngFor="let err of errorMessages" type="error">
    {{ err | errorMessage }}
  </uc-message-banner>

  <uc-message-banner *ngIf="duplicateEmailError" type="error">
    {{ createAccountStrings.duplicateEmail[0] }}
    <a href="javascript:void(0)" (click)="goToLoginClick()" class="login-link">
      {{ createAccountStrings.duplicateEmail[1] }}
    </a>
    {{ createAccountStrings.duplicateEmail[2] }}
  </uc-message-banner>
</section>

<form [formGroup]="createAccountForm">
  <uc-string-input
    testSelector="create-first-name"
    noIcon="true"
    class="first-name"
    formControlName="firstName"
    [label]="nameStrings.firstnameLabel"></uc-string-input>

  <uc-string-input
    testSelector="create-last-name"
    noIcon="true"
    class="last-name"
    formControlName="lastName"
    [label]="lastNameLabel"></uc-string-input>

  <uc-string-input
    testSelector="create-email"
    noIcon="true"
    class="email-input"
    formControlName="email"
    [label]="labelStrings.email"></uc-string-input>

  <uc-password-input
    testSelector="new"
    createAccount="yes"
    [label]="labelStrings.password.new"
    controlName="password"
    [form]="createAccountForm"></uc-password-input>

  <uc-password-input
    testSelector="confirm"
    [label]="labelStrings.password.confirm"
    controlName="passwordConfirm"
    [form]="createAccountForm"></uc-password-input>

  <uc-checkbox-input
    [label]="privacyStatementLabelText"
    class="has-accepted"
    formControlName="hasAcceptedAgreement"
    groupName="accepted"></uc-checkbox-input>

  <div class="create-account">
    <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
    <!-- prettier-ignore -->
    <uc-button testSelector="create" (click)="createAccount()" [isLoading]="displaySpinner"
               [disabled]="createAccountForm.status === 'INVALID'">{{ createAccountStrings.createAccountButton }}</uc-button>
  </div>
</form>

<!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
<!-- prettier-ignore -->
<p class="create-account-prompt">{{ createAccountStrings.accountAlreadyExistsPrompt }} <a href="javascript:void(0)"
                                                                                          (click)="goToLoginClick()">here</a>.
</p>

<p></p>
