<div [formGroup]="fitnessToTeachForm">
  <uc-subsection>
    <uc-task-hint [task]="task" path="/application/experience"></uc-task-hint>
    <uc-form-input>
      <uc-subsection-question>
        {{ strings.experienceQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.lastFiveYears }}</uc-subsection-minor-text>
      <uc-subsection-minor-text>{{ strings.workType }}</uc-subsection-minor-text>
      <uc-subsection-minor-text>{{ strings.max300 }}</uc-subsection-minor-text>
      <uc-text-area testSelector="experience" formControlName="experience"></uc-text-area>
    </uc-form-input>
  </uc-subsection>

  <uc-subsection>
    <uc-task-hint [task]="task" path="/application/other_qualification"></uc-task-hint>
    <uc-form-input>
      <uc-subsection-question>
        {{ strings.relevantQualifications }}
      </uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.qualificationExamples }}</uc-subsection-minor-text>
      <uc-subsection-minor-text>{{ strings.max300 }}</uc-subsection-minor-text>
      <uc-text-area testSelector="other-qual" formControlName="otherQualification"></uc-text-area>
    </uc-form-input>
  </uc-subsection>
</div>
