import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const enum REFDATA_TYPES {
  CONDITION_ITEM = 'condition_item',
  CONDITION_STYLE = 'condition_style',
  CONDITION_REASON = 'condition_reason',
  CONDITION_STATE_STUDENT = 'condition_state_student',
  COURSE_DECLINE_REASON = 'course_decline_reason',
  UCONLINE_COURSE = 'uconline_course',
  GENDER = 'gender',
  IWI = 'iwi',
  ETHNICITY = 'ethnicity',
  CITIZENSHIP_CATEGORY = 'citizenship_category',
  COUNTRY = 'country',
  QUALIFICATION = 'qualification',
  DECLARATION_DISABILITY = 'declaration_disability',
}

export interface IRefdataOptions {
  labelText: string;
  value: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedReferenceData {
  code: string;
  description?: string;
  type?: string;
  metadata?: Record<string, string | boolean>;
  valid_from?: string;
  valid_to?: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class ReferenceData {
  public code: string;
  public description?: string;
  public type?: string;
  public metadata?: Record<string, string | boolean>;
  public validFrom?: string;
  public validTo?: string;

  constructor(attrs: Partial<ReferenceData>) {
    Object.assign(this, attrs);
  }

  static deserialize(payload: Partial<SerializedReferenceData>): ReferenceData {
    if (!payload) {
      return null;
    }

    if (typeof payload.code !== 'string') {
      throw new Error(`Could not deserialize ReferenceData object from: ${JSON.stringify(payload)}`);
    }
    const refData: ReferenceData = camelizeKeys(payload);
    refData.metadata = camelizeKeys(payload.metadata);
    return new ReferenceData(refData);
  }

  static serialize(instance: ReferenceData): SerializedReferenceData {
    const clone = { ...instance };
    return snakeifyKeys(clone);
  }

  static refDataToOptions(data: ReferenceData[]): IRefdataOptions[] {
    if (!data?.length) {
      return [];
    }
    return data.map((el) => {
      return { labelText: el.description, value: el.code };
    });
  }
}
