<form class="search" [formGroup]="formGroup" (submit)="runSearch()">
  <div class="search__fields">
    <label class="search__label">{{ strings.search }}</label>
    <select
      [attr.data-cy]="'search-dropdown-' + dropdownOption.formControlName"
      *ngFor="let dropdownOption of options"
      [formControlName]="dropdownOption.formControlName"
      class="search__input search__input--courses-select">
      <option *ngFor="let datum of dropdownOption.options" [value]="datum.code.code">
        {{ datum.name }}
      </option>
    </select>

    <input
      [attr.data-cy]="'search-course-code'"
      class="search__input search__input--course-code"
      type="text"
      maxlength="30"
      formControlName="query"
      [placeholder]="strings.searchPlaceholder" />
  </div>
  <div class="search__actions">
    <button [attr.data-cy]="'search'" class="search__submit-button" type="submit" (click)="runSearch()">
      <uc-icon class="search__button-icon" iconType="search" iconWidth="15" iconHeight="15"></uc-icon>
      <span [style.visibility]="showBlips ? 'hidden' : 'visible'" class="search__button-text">Search</span>
      <div *ngIf="showBlips" class="search__loading-blip">
        <uc-loader-blip [showBlips]="showBlips"></uc-loader-blip>
      </div>
    </button>
    <a href="javascript:void(0)" class="search__reset" (click)="reset()">{{ strings.reset }}</a>
  </div>
</form>
