import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-ib-admission',
  templateUrl: './ib-admission.component.html',
  styleUrls: ['./ib-admission.component.scss'],
})
export class IbAdmissionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  strings = strings.components.tasks.ibAdmission;
  taskForm: UntypedFormGroup;
  currentApplication: Application;
  log: Logger;

  constructor(
    private fb: UntypedFormBuilder,
    private applicationService: ApplicationService,
    private loggingService: LoggingService,
  ) {
    super();
    this.log = this.loggingService.createLogger(this);
  }

  public updateFormValidity(_err: UCError) {}

  ngOnInit() {
    this.taskForm = this.fb.group({
      yesNo: ['', Validators.required],
    });

    this.taskForm
      .get('yesNo')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((v) => !!v),
      )
      .subscribe((val) => {
        if (val === 'show') {
          this.taskForm.addControl('release', this.fb.control('', Validators.required));
        } else {
          this.taskForm.removeControl('release');
        }
      });

    this.applicationService.application
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.updateFormFromApplication(application);
      });
  }

  private updateFormFromApplication(application: Application) {
    const { ibQualification, ibResultsAvailable } = application;
    if (ibQualification != null) {
      this.taskForm.patchValue({ yesNo: ibQualification ? 'show' : 'hide' });
    }
    if (ibResultsAvailable != null) {
      this.taskForm.patchValue({ release: ibResultsAvailable ? 'show' : 'hide' });
    }
  }

  public update() {
    if (!this.currentApplication) {
      this.errors.emit();
      this.log.error('tried to update application but no application has been loaded');
    }

    const { yesNo, release } = this.taskForm.value;

    this.currentApplication.ibQualification = yesNo === 'show' ? true : false;
    this.currentApplication.ibResultsAvailable = release === 'show' ? true : false;

    this.applicationService.updateApplication(this.currentApplication).subscribe(
      (app) => {
        this.log.info('updated application successfully', app);
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('could not update application', err);
      },
    );
  }
}
