{
  "applicant": {
    "user_id": "jb123",
    "legal_name": {
      "title": null,
      "type": {
        "code": "PREF"
      },
      "first_name": "James",
      "surname": "Brown",
      "middle_name": "Matthew",
      "validated": true
    },
    "validated_residency": true,
    "name": [
      {
        "title": null,
        "first_name": "Mike",
        "surname": "Wazowski",
        "middle_name": "Alan",
        "validated": false
      },
      {
        "title": null,
        "first_name": "",
        "surname": "The Ersin",
        "middle_name": "",
        "validated": false
      }
    ],
    "birth_date": "1998-09-16",
    "gender": {
      "code": "M"
    },
    "contact_detail": {
      "mobile_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "27",
        "number": "234567",
        "extension": ""
      },
      "landline_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "22",
        "number": "039876543",
        "extension": ""
      },
      "current_address": {
        "country": {
          "code": "NZ"
        },
        "line_1": "185 Some St",
        "line_2": "Suburbia",
        "city": "",
        "town": "Metropolis",
        "region": "",
        "postcode": "8061",
        "latitude": null,
        "longitude": null
      },
      "email_address": "Someone@somwhere.com",
      "preferredContactMethod": "email"
    },
    "study_contact_detail": {
      "mobile_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "27",
        "number": "234567",
        "extension": ""
      },
      "landline_number": {
        "country": null,
        "prefix": "",
        "number": "",
        "extension": ""
      },
      "current_address": {
        "country": {
          "code": "NZ"
        },
        "line_1": "185 Some St",
        "line_2": "Suburbia",
        "city": "",
        "town": "Metropolis",
        "region": "",
        "postcode": "8061",
        "latitude": null,
        "longitude": null
      },
      "email_address": "godfather_of_soul@uclive.ac.nz"
    },
    "emergency_contact_detail": {
      "mobile_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "",
        "number": "23456",
        "extension": ""
      },
      "alternate_phone": {
        "country": {
          "code": "+64"
        },
        "prefix": "",
        "number": "272449384",
        "extension": ""
      },
      "current_address": {
        "country": {
          "code": "NZ"
        },
        "line_1": "185 Some St",
        "line_2": "Suburbia",
        "city": "",
        "town": "Metropolis",
        "region": "",
        "postcode": "8061",
        "latitude": null,
        "longitude": null
      },
      "email_address": "mike@me.com"
    },
    "emergency_contact_name": {
      "title": null,
      "first_name": "Mike",
      "surname": "Wazowski",
      "middle_name": "",
      "preferred_name": "",
      "previous_surname": "",
      "validated": false
    },
    "emergency_contact_relationship": {
      "code": "other"
    },
    "demographic": {
      "language": {
        "code": "05"
      },
      "language_other": "Russian",
      "citizenship": {
        "code": "NZCZ"
      },
      "ethnicity": [
        {
          "code": "1"
        },
        {
          "code": "2"
        },
        {
          "code": "10"
        }
      ],
      "iwi": [{ "code": "100" }, { "code": "101" }],
      "passport_country": {
        "code": "NZL"
      },
      "validated_citizenship": false,
      "city_of_birth": "Christchurch",
      "country_of_birth": {
        "code": "NZL"
      },
      "moe_main_activity": { "code": "123" },
      "moe_last_school_attended": { "code": "999" },
      "moe_year_last_attended_school": 2020
    },
    "study_in_nz": false,
    "identity": {
      "staff_number": "",
      "ird_number": "",
      "nsn_number": "23545",
      "student_number": "123456",
      "nz_drivers_license": "NZ000000",
      "nz_passport_number": "LA000000"
    },
    "declaration_disability": { "code": "01" },
    "disability": [{ "code": "DEAF" }, { "code": "BLIND" }],
    "disability_detail": "Mean while, in a waka, Rangi and James and the Giant Peach were up to no good with a bunch of bloody jelly tip icecreams. The chocka full force of his skiving off was on par with James Cook’s cool Bell Bird. Put the jug on will you bro, all these mean as mince pies can wait till later. But, you’re not in Guatemala now, don’t be a egg.",
    "validated_birth_date": true,
    "declaration_first_in_family": false,
    "declaration_did_family_member_graduate": true,
    "university_admission": {
      "year": { "code": "2021" },
      "reason": { "code": "pending" },
      "status": { "code": "being_assessed" },
      "reason_year": { "code": "2021" },
      "notes": "Comments",
      "assessment": "Details on assessment.",
      "gpa_value": "Details on GPA value",
      "english_proficiency": "Details on english proficiency"
    },
    "ue_qualification": {
      "qualification_type": null,
      "internal_reference": "177WGjd9We3BLeDJQR6qpG2LPZ07Q1",
      "type": { "code": "UNDERGRADUATE" },
      "type_other": null,
      "date_attained": "2021-03-04",
      "date_started": "2014",
      "country": null,
      "source": null,
      "source_other": null,
      "attained": null,
      "verified": null,
      "result": [
        {
          "type": "",
          "score": ""
        }
      ]
    },
    "secondary_qualification": [
      {
        "qualification_type": "secondary",
        "internal_reference": "177WGjd9We3BLeDJQR6qpG1LPZ07Q2",
        "type": { "code": "01" },
        "type_other": "Some great BA",
        "date_attained": null,
        "date_started": "2014",
        "country": {
          "code": "NZL"
        },
        "source": { "code": "NCEA" },
        "source_other": "some source",
        "attained": true,
        "verified": true,
        "result": []
      }
    ],
    "secondary_education_last_year_attended": null,
    "secondary_education_last_school": null,
    "tertiary_qualification": [
      {
        "qualification_type": "foundation",
        "internal_reference": "178WGjd9We3BLeDJQR6qpG1LPZ07Q3",
        "type": { "code": "UCIC" },
        "type_other": null,
        "date_attained": null,
        "date_started": "2014",
        "country": {
          "code": "CH"
        },
        "source": { "code": "" },
        "source_other": "some source",
        "attained": true,
        "verified": true,
        "result": []
      }
    ],
    "english_qualification": {
      "qualification_type": null,
      "internal_reference": "177WGjd9We3BLeDKQR6qpG1LPZ07Q4",
      "source": { "code": "Have Sat Test" },
      "source_other": null,
      "type": { "code": "IELTS" },
      "type_other": "learned it on the street.",
      "date_attained": "2021-03-04",
      "date_started": "2019-03-04",
      "country": {
        "code": "GB"
      },
      "attained": true,
      "verified": true,
      "result": [
        {
          "type": "LST",
          "score": "1.5"
        },
        {
          "type": "RED",
          "score": "2.5"
        },
        {
          "type": "WRT",
          "score": "3.5"
        },
        {
          "type": "SPK",
          "score": "4.5"
        },
        {
          "type": "TOT",
          "score": "12"
        },
        {
          "type": "OVR",
          "score": "This will be some big ass text yall"
        }
      ],
      "expiry_date": "2100-01-01"
    },
    "ca_compliance": {
      "has_child_removed": false,
      "child_removed": null,
      "has_disciplinary_proceeding": false,
      "disciplinary_proceeding": null,
      "has_investigation_protection_order": false,
      "investigation_protection_order": null,
      "has_denied_entry": false,
      "denied_entry": null,
      "has_historical_ca_data": true
    },
    "attained_qualification": [
      {
        "qualification_type": "secondary",
        "internal_reference": "177WGjd9We3BLeDJQR6qpG1LPZ07Q5",
        "type": { "code": "01" },
        "type_other": "Some great BA",
        "date_attained": null,
        "date_started": "2014",
        "country": {
          "code": "NZL"
        },
        "source": { "code": "NCEA" },
        "source_other": "some source",
        "attained": true,
        "verified": true,
        "result": []
      },
      {
        "qualification_type": "foundation",
        "internal_reference": "178WGjd9We3BLeDJQR6qpG1LPZ07Q6",
        "type": { "code": "UCIC" },
        "type_other": null,
        "date_attained": null,
        "date_started": "2014",
        "country": {
          "code": "CH"
        },
        "source": { "code": "" },
        "source_other": "some source",
        "attained": true,
        "verified": true,
        "result": []
      }
    ],
    "personal_identifiers": [
      {
        "type": { "code": "rainbow_community" },
        "selected": true,
        "source": null,
        "custom": null
      },
      {
        "type": { "code": "religion" },
        "selected": true,
        "source": { "code": "buddhism" },
        "custom": null
      },
      {
        "type": { "code": "refugee_background" },
        "selected": true,
        "source": null,
        "custom": null
      }
    ],
    "pronouns": [
      {
        "selected": true,
        "source": { "code": "she/her" },
        "custom": null
      }
    ]
  }
}
