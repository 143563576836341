import { Component, Input } from '@angular/core';

import { qualificationUrls, cacQualUrls } from '@shared/constants/qualificationUrls';
import strings from '@shared/constants/strings.constants';

@Component({
  selector: 'uc-qualification-link',
  templateUrl: './qualification-link.component.html',
})
export class QualificationLinkComponent {
  @Input() qualCode;
  @Input() linkText = strings.components.atoms.qualificationLink.viewQualStructure;
  @Input() isCACQual = false;

  strings = strings.components.atoms.qualificationLink;

  get qualUrl() {
    return this.lookupQualUrl(this.qualCode);
  }

  lookupQualUrl(qualCode: string) {
    const qualUrls = !this.isCACQual ? qualificationUrls : cacQualUrls;
    return qualUrls[qualCode] || qualUrls['DEFAULT_QUAL_INFO'];
  }
}
