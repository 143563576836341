import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { DISABILITY_CONSTANTS } from '@shared/components/molecules/fitness-to-teach/fitness-to-teach.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Phone } from '@shared/models/phone';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  array,
  booleanToYesNo,
  control,
  controlToRefDataArray,
  group,
  numberToString,
  refDatasToCodeArray,
  refDataToValue,
  UCElementGroup,
  valueToRefData,
  yesNoToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-finalise-enrolment',
  templateUrl: './finalise-enrolment.component.html',
})
export class FinaliseEnrolmentComponent extends AbstractBaseTask implements OnInit {
  @Input() processName: string;
  @Input() task: Task;
  @Input() applicationYear: string;
  strings = strings.components.tasks.finaliseEnrolment;
  finaliseForm: UCElementGroup;
  log: Logger;
  finalisePage: UntypedFormGroup;

  currentApplicant: Applicant;
  year: string;

  isFirstYear = new UntypedFormControl('');

  constructor(
    private formMapper: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private logService: LoggingService,
  ) {
    super();
    this.log = logService.createLogger(this);
  }

  get hasDisability(): UntypedFormControl {
    return this.finalisePage.get('learningNeeds.fitnessToTeachGroup.hasDisability') as UntypedFormControl;
  }

  get permanentAddressControl(): UntypedFormControl {
    return this.finalisePage.get('permanentAddressGroup.permanentAddress') as UntypedFormControl;
  }

  get studyAddressControl(): UntypedFormControl {
    return this.finalisePage.get('studyAddressGroup.studyAddress') as UntypedFormControl;
  }

  get emergencyAddressControl(): UntypedFormControl {
    return this.finalisePage.get('emergencyDetails.address') as UntypedFormControl;
  }

  get identityGroups(): UntypedFormGroup {
    return this.finalisePage.get('identityGroups') as UntypedFormGroup;
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return this.processName === PROCESS_NAMES.MCED || this.processName === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  get shouldShowUCSAMembership(): boolean {
    return !this.isStarProcess && !this.isMCEDProcess;
  }

  static createForm(): UCElementGroup {
    return group({
      permanentAddressGroup: group({
        permanentAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
      }),
      studyAddressGroup: group({
        studyAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/studyContactDetail/currentAddress',
        }),
      }),
      emergencyDetails: group({
        email: control({
          defaultState: '',
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/emergencyContactDetail/emailAddress',
        }),
        mobileNum: control({
          // eslint-disable-next-line id-blacklist
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/mobileNumber',
        }),
        alternatePhoneNum: control({
          // eslint-disable-next-line id-blacklist
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzAlternatePhoneValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/alternatePhone',
        }),
        firstName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/firstName',
        }),
        lastName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/surname',
        }),
        relationship: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactRelationship',
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/currentAddress',
        }),
      }),
      citizenship: group({
        ethnicities: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/ethnicity',
        }),
        iwi: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/iwi',
        }),
      }),
      learningNeeds: group({
        fitnessToTeachGroup: group({
          disability: control({
            defaultState: [],
            model: 'applicant',
            path: '/disability',
            inMap: refDatasToCodeArray,
            outMap: controlToRefDataArray,
            validators: [],
          }),
          hasDisability: control({
            model: 'applicant',
            path: '/declarationDisability',
            inMap: refDataToValue,
            outMap: valueToRefData,
            validators: [Validators.required],
          }),
          disabilityDetail: control({
            model: 'applicant',
            path: '/disabilityDetail',
          }),
        }),
        language: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/language',
        }),
        languageOther: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/languageOther',
        }),
        declarationFirstInFamily: control({
          model: 'applicant',
          path: '/declarationFirstInFamily',
          validators: [Validators.required],
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
        declarationDidFamilyMemberGraduate: control({
          model: 'applicant',
          path: '/declarationDidFamilyMemberGraduate',
          validators: [Validators.required],
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
      }),
      moreAboutYourStudies: group({
        firstYearHere: control({
          model: 'applicant',
          path: '/firstYearHere',
          validators: [Validators.required],
          inMap: numberToString,
        }),
        firstYearTertiary: control({
          model: 'applicant',
          path: '/firstYearTertiary',
          validators: [Validators.required],
          inMap: numberToString,
        }),
        occupation: control({
          model: 'applicant',
          path: '/demographic/moeMainActivity',
          validators: [Validators.required],
        }),
      }),
      identityGroups: group({
        rainbowCommunityCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/rainbowCommunity/selected',
        }),
        refugeeBackgroundCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/refugeeBackground/selected',
        }),
        religionCheckbox: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/selected',
        }),
        religionDropdown: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/source',
        }),
        religionTextField: control({
          model: 'applicant',
          path: '/personalIdentifiers/religion/custom',
          validators: [Validators.maxLength(200)],
        }),
      }),
    });
  }

  ngOnInit() {
    this.finaliseForm = FinaliseEnrolmentComponent.createForm();
    this.finalisePage = this.finaliseForm.asControl() as UntypedFormGroup;

    observableCombineLatest(
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.year = application.academicYear.code;
        if (this.currentApplicant.firstYearHere === Number(application.academicYear.code)) {
          this.isFirstYear.setValue('show');
        } else if (
          this.currentApplicant.firstYearHere &&
          this.currentApplicant.firstYearHere !== Number(application.academicYear.code)
        ) {
          this.isFirstYear.setValue('hide');
        }

        this.formMapper.updateFormFromModel(this.finaliseForm, this.currentApplicant);
      });
  }

  public updateFormValidity(err: UCError): void {
    this.formMapper.updateFormValidity(err.data, this.finaliseForm);
  }

  public copyPermanentToStudyAddress() {
    const permanentAddress = this.permanentAddressControl.value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.finalisePage.get('studyAddressGroup.studyAddress').patchValue(addressCopy);
  }

  public copyPermanentToEmergencyAddress() {
    const permanentAddress = this.permanentAddressControl.value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.finalisePage.get('emergencyDetails.address').patchValue(addressCopy);
  }

  public update() {
    this.formMapper.updateModelFromForm(this.finaliseForm, this.currentApplicant);

    if (this.currentApplicant.declarationDisability.code !== DISABILITY_CONSTANTS.IS_TRUE) {
      this.currentApplicant.disability = [];
      this.currentApplicant.disabilityDetail = null;
    }

    // Hack to prevent nulls in what should be an array
    this.currentApplicant.demographic.ethnicity = this.currentApplicant.demographic.ethnicity.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);
    // '2' is reference code for "New Zealand Maori"
    const isMaori = this.currentApplicant.demographic.ethnicity.find((e) => e.code === '2');
    if (!isMaori) {
      this.currentApplicant.demographic.iwi = [];
    }

    this.applicantService.updateApplicant(this.currentApplicant).subscribe(
      // Success
      () => {
        this.next.emit();
      },
      // Error
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('Error thrown while updating applicant', err);
      },
    );
  }
}
