import { Component } from '@angular/core';

import { PreProcessPageComponent } from '@shared/components/templates/pre-process-page/pre-process-page.component';
import { internalUrls } from '@shared/constants/internalUrls';

@Component({
  selector: 'uc-myuc-pre-process-page',
  templateUrl: '../myuc-process-page/myuc-process-page.component.html',
  styleUrls: ['../myuc-process-page/myuc-process-page.component.scss'],
})
export class MyucPreProcessPageComponent extends PreProcessPageComponent {
  cancelTask() {
    this.router.navigate(internalUrls.manageMyStudy);
  }
}
