{
  "qualification": [
    {
      "code": "BA",
      "description": "",
      "guidance": "BA students specialise in two areas; either a major and minor subject, or two majors.",
      "includes_thesis": false,
      "is_postgraduate": false,
      "level": "7",
      "organisation_unit": "Arts, College of",
      "paper_form_url": "",
      "title": "Bachelor of Arts"
    },
    {
      "code": "BCOM",
      "description": "",
      "guidance": "",
      "includes_thesis": false,
      "is_postgraduate": false,
      "level": "7",
      "organisation_unit": "Business and Law, College of",
      "paper_form_url": "",
      "title": "Bachelor of Commerce"
    },
    {
      "code": "MBE",
      "description": "",
      "guidance": "",
      "includes_thesis": false,
      "is_postgraduate": true,
      "level": "7",
      "organisation_unit": "Engineering, College of",
      "paper_form_url": "",
      "title": "Masters of business Engineering",
      "categories": [{ "category": "offer_decision_myuc" }]
    },
    {
      "code": "MTCHGLN",
      "description": "",
      "guidance": "",
      "includes_thesis": false,
      "is_postgraduate": true,
      "level": "7",
      "organisation_unit": "Teaching, College of",
      "paper_form_url": "",
      "title": "Masters of teaching and learning"
    }
  ],
  "summary": null
}
