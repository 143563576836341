{
  "waiver_reason": [
    {
      "code": "academic_history",
      "description": "Previous academic history",
      "jade_code": "ACADEMIC HISTORY"
    },
    {
      "code": "hod_approved",
      "description": "HOD approved",
      "jade_code": "HOD APPROVED"
    }
  ],
  "country": [
    {
      "id": 26,
      "code": "CH",
      "description": "China",
      "type": "country"
    },
    {
      "id": 25,
      "code": "GB",
      "description": "United Kingdom",
      "type": "country"
    },
    {
      "id": 26,
      "code": "AZ",
      "description": "Azerbaijan",
      "type": "country"
    },
    {
      "id": 25,
      "code": "ID",
      "description": "Indonesia",
      "type": "country"
    },
    {
      "id": 25,
      "code": "NZ",
      "description": "New Zealand",
      "type": "country"
    }
  ],
  "citizenship_category": [
    {
      "code": "NZCZ",
      "description": "NZ Citizen",
      "type": "citizenship_category"
    },
    {
      "code": "NZPR",
      "description": "NZ Resident Visa",
      "type": "citizenship_category"
    },
    {
      "code": "AUS",
      "description": "Australian Citizen",
      "type": "citizenship_category"
    },
    {
      "code": "AUSPR",
      "description": "Australian Permanent Resident Visa",
      "type": "citizenship_category"
    },
    {
      "code": "OTHER",
      "description": "International",
      "type": "citizenship_category"
    }
  ],
  "phone_area": [
    {
      "id": 26,
      "code": "+64",
      "description": "+64 NZL",
      "type": "phone_area"
    },
    {
      "id": 25,
      "code": "+61",
      "description": "+61 AU",
      "type": "phone_area"
    },
    {
      "id": 26,
      "code": "+994",
      "description": "994 AZE",
      "type": "phone_area"
    },
    {
      "id": 25,
      "code": "+62",
      "description": "+62 IDN",
      "type": "phone_area"
    }
  ],
  "ethnicity": [
    { "code": "1", "description": "NZ European/Pakeha", "type": "ethnicity" },
    { "code": "10", "description": "British/Irish", "type": "ethnicity" },
    { "code": "11", "description": "Dutch", "type": "ethnicity" },
    { "code": "12", "description": "Greek", "type": "ethnicity" },
    { "code": "13", "description": "Polish", "type": "ethnicity" },
    { "code": "14", "description": "South Slav", "type": "ethnicity" },
    { "code": "15", "description": "Italian", "type": "ethnicity" },
    { "code": "16", "description": "German", "type": "ethnicity" },
    { "code": "17", "description": "Australian", "type": "ethnicity" },
    { "code": "18", "description": "Other European", "type": "ethnicity" },
    { "code": "19", "description": "Filipino", "type": "ethnicity" },
    { "code": "2", "description": "New Zealand Maori", "type": "ethnicity" },
    { "code": "20", "description": "Cambodian", "type": "ethnicity" },
    { "code": "21", "description": "Vietnamese", "type": "ethnicity" },
    { "code": "22", "description": "Other Southeast Asian", "type": "ethnicity" },
    { "code": "23", "description": "Chinese", "type": "ethnicity" }
  ],
  "iwi": [
    { "code": "100", "description": "Te Tai Tokerau/Tāmaki Makau Rau (Northland/Auckland region)", "type": "iwi" },
    { "code": "101", "description": "Te Aupöuri", "type": "iwi" },
    { "code": "102", "description": "Ngāti Kahu", "type": "iwi" },
    { "code": "103", "description": "Ngāti Kurï", "type": "iwi" },
    { "code": "104", "description": "Ngāpuhi", "type": "iwi" },
    { "code": "105", "description": "Ngāpuhi ki Whāingaroa-Ngāti Kahu ki Whāingaroa", "type": "iwi" },
    { "code": "106", "description": "Te Rarawa", "type": "iwi" },
    { "code": "107", "description": "Ngāi Takoto", "type": "iwi" },
    { "code": "108", "description": "Ngāti Wai", "type": "iwi" },
    { "code": "109", "description": "Ngāti Whātua", "type": "iwi" },
    { "code": "110", "description": "Te Kawerau", "type": "iwi" },
    { "code": "111", "description": "Te Uri-o Hau", "type": "iwi" },
    { "code": "112", "description": "Te Roroa", "type": "iwi" },
    { "code": "200", "description": "Hauraki (Coromandel region)", "type": "iwi" },
    { "code": "201", "description": "Ngāti Hako", "type": "iwi" },
    { "code": "202", "description": "Ngāti Hei", "type": "iwi" },
    { "code": "203", "description": "Ngāti Maru (Marutuahu)", "type": "iwi" },
    { "code": "204", "description": "Ngāti Paoa", "type": "iwi" },
    { "code": "205", "description": "Patukirikiri", "type": "iwi" },
    { "code": "206", "description": "Ngāti Porou ki Harataunga ki Mataora", "type": "iwi" },
    { "code": "207", "description": "Ngāti Pükenga ki Waiau", "type": "iwi" }
  ],
  "relationship": [
    {
      "description": "Parent/ Guardian/ Caregiver",
      "code": "guardian"
    },
    {
      "description": "Husband/ Wife/ Partner",
      "code": "partner"
    },
    {
      "description": "Sibling/ Family member",
      "code": "sibling"
    },
    {
      "description": "Friend",
      "code": "friend"
    },
    {
      "description": "Other",
      "code": "other"
    }
  ],
  "application_year": [
    {
      "description": "2020",
      "code": "2020"
    },
    {
      "description": "2021",
      "code": "2021"
    },
    {
      "description": "2022",
      "code": "2022"
    },
    {
      "description": "2023",
      "code": "2023"
    }
  ],
  "application_label": [
    {
      "description": "Dean's award recipient",
      "code": "dean_recipient"
    },
    {
      "description": "Undergraduate",
      "code": "undergraduate"
    }
  ],
  "completion_year": [
    {
      "description": "2017",
      "code": "2017"
    },
    {
      "description": "2016",
      "code": "2016"
    },
    {
      "description": "2015",
      "code": "2015"
    },
    {
      "description": "2014",
      "code": "2014"
    },
    {
      "description": "2013",
      "code": "2013"
    },
    {
      "description": "2012",
      "code": "2012"
    }
  ],
  "secondary_school": [
    {
      "description": "This will be a list of Secondary Schools",
      "code": "NCEA"
    },
    {
      "description": "Overseas",
      "code": "999"
    },
    {
      "description": "NZ school not on list",
      "code": "998"
    }
  ],
  "declaration_disability": [
    {
      "code": "01",
      "description": "Yes"
    },
    {
      "code": "00",
      "description": "No"
    },
    {
      "code": "02",
      "description": "Prefer not to say"
    }
  ],
  "disability": [
    {
      "code": "AUTISM",
      "description": "Autism Spectrum Disorder"
    },
    {
      "code": "BLIND",
      "description": "Blind"
    },
    {
      "code": "DEAF",
      "description": "Deaf"
    },
    {
      "code": "HEARING",
      "description": "Hearing Impairment"
    },
    {
      "code": "LEARNING",
      "description": "Specific Learning Disability"
    },
    {
      "code": "MEDICAL",
      "description": "Medical Condition"
    },
    {
      "code": "MENTAL",
      "description": "Mental Health"
    },
    {
      "code": "MOBILITY",
      "description": "Mobility Impairment"
    },
    {
      "code": "OTHER",
      "description": "Other"
    },
    {
      "code": "PSYC",
      "description": "Psychological/Psychiatric disability"
    },
    {
      "code": "SPEECH",
      "description": "Speech Impairment"
    },
    {
      "code": "TEMP",
      "description": "Temporary Impairment (less than 6 months)"
    },
    {
      "code": "VISION",
      "description": "Vision Impairment"
    }
  ],
  "highest_study_level": [
    {
      "code": "SECONDARY",
      "description": "Secondary school"
    },
    {
      "code": "FOUNDATION",
      "description": "University Foundation"
    },
    {
      "code": "UNDERGRADUATE",
      "description": "Undergraduate Degree"
    },
    {
      "code": "POSTGRADUATE",
      "description": "Postgraduate Degree"
    }
  ],
  "ue_qualification": [
    {
      "code": "SECONDARY",
      "description": "Secondary school"
    },
    {
      "code": "FOUNDATION",
      "description": "University Foundation"
    },
    {
      "code": "UNDERGRADUATE",
      "description": "Undergraduate Degree"
    },
    {
      "code": "POSTGRADUATE",
      "description": "Postgraduate Degree"
    }
  ],
  "highest_school_qual": [
    {
      "code": "01",
      "description": "NCEA Level 3"
    },
    {
      "code": "08",
      "description": "Cambridge International Examination"
    },
    {
      "code": "07",
      "description": "International Baccalaureate"
    },
    {
      "code": "01A",
      "description": "Bursary"
    },
    {
      "code": "01B",
      "description": "Scholarship"
    },
    {
      "code": "02",
      "description": "University Entrance (prior to 1986)"
    },
    {
      "code": "03",
      "description": "NCEA Level 2"
    },
    {
      "code": "04",
      "description": "NCEA Level 1"
    },
    {
      "code": "03A",
      "description": "Sixth Form Certificate"
    },
    {
      "code": "04A",
      "description": "School Certificate"
    },
    {
      "code": "05",
      "description": "14 or more credits at any level"
    },
    {
      "code": "09",
      "description": "No formal secondary qualification"
    },
    {
      "code": "10",
      "description": "Other"
    },
    {
      "code": "11",
      "description": "Not known"
    }
  ],
  "foundation_qualification": [
    {
      "code": "CUP",
      "description": "UC Certificate in University Preparation (CUP)"
    },
    {
      "code": "UCIC",
      "description": "UC International College Foundation Studies Certificate"
    },
    {
      "code": "UCICXFER",
      "description": "UC International College University Transfer Programme"
    },
    {
      "code": "OTHER",
      "description": "Other University Preparation Course"
    }
  ],
  "english_language_skill": [
    {
      "description": "English is my first language",
      "code": "Native Speaker"
    },
    {
      "description": "I have completed an academic qualification substantially taught in English in a country where English is the predominant language",
      "code": "Studied in English"
    },
    {
      "description": "I have sat an English Language Proficiency Test",
      "code": "Have Sat Test"
    },
    {
      "description": "I am scheduled to sit an English Language Proficiency Test",
      "code": "Scheduled to Sit Test"
    },
    {
      "description": "I will sit an English Language Proficiency Test",
      "code": "Will Sit Test"
    },
    {
      "description": "None of these describe me",
      "code": "None",
      "valid_to": "2023-12-31"
    }
  ],
  "english_language_test_type": [
    {
      "description": "IELTS Academic - International English Language Testing System",
      "code": "IELTS"
    },
    {
      "description": "TOEFL - Test of English as a Foreign Language (iBT or PBT)",
      "code": "TOEFL"
    },
    {
      "description": "CCEL English for Academic Purposes Level 2",
      "code": "CCEL"
    },
    {
      "description": "Cambridge English - proficiency or advanced (CPE or CAE)",
      "code": "Cambridge"
    },
    {
      "description": "Pearsons Test of English",
      "code": "Pearsons"
    },
    {
      "description": "Other",
      "code": "Other"
    }
  ],
  "qualification_major_guidance": [],
  "qualification_major_label": [],
  "qualification_minor_guidance": [],
  "qualification_minor_label": [],
  "begin_studies": [
    {
      "code": "Semester 1",
      "description": "February (1st Semester)"
    },
    {
      "code": "Semester 2",
      "description": "July (2nd Semester)"
    },
    {
      "code": "Summer school",
      "description": "Summer school (enter start date below):"
    },
    {
      "code": "Thesis",
      "description": "I am applying to do a thesis (enter start date below):"
    },
    {
      "code": "Block",
      "description": "I am applying for a block course (enter start date below):"
    },
    {
      "code": "Other",
      "description": "Other (enter start date below):"
    }
  ],
  "star_begin_studies": [
    {
      "code": "Semester 1",
      "description": "February (1st Semester)"
    },
    {
      "code": "Semester 2",
      "description": "July (2nd Semester)"
    }
  ],
  "SSL_rebate_category": [
    {
      "code": "NOT APPLICABLE",
      "description": "I'm not applying for a rebate, because none of the rebate categories apply to me"
    },
    {
      "code": "DISTANCE",
      "description": "I'm a distance student"
    },
    {
      "code": "EDUCATION",
      "description": "I'm a College of Education, Health and Human Development part-time student working as an educator"
    },
    {
      "code": "RESEARCH",
      "description": "I'm a postgraduate research student studying off campus"
    },
    {
      "code": "LIFETIME CAP",
      "description": "I've reached the lifetime cap"
    },
    {
      "code": "30 POINTS OR LESS",
      "description": "I intend to enrol in 30 points or less in the academic year I am applying for"
    },
    {
      "code": "REGIONAL",
      "description": "I'm studying at a regional campus"
    },
    {
      "code": "OTHER REBATE",
      "description": "Other rebate category"
    }
  ],
  "cup": [
    {
      "code": "Newspaper",
      "description": "Newspaper"
    },
    {
      "code": "Website",
      "description": "Website"
    },
    {
      "code": "Friends / family",
      "description": "Friends / family"
    },
    {
      "code": "Careers advisor",
      "description": "Careers advisor"
    },
    {
      "code": "Hagley",
      "description": "Hagley"
    },
    {
      "code": "UC Liaison",
      "description": "UC Liaison"
    },
    {
      "code": "Other",
      "description": "Other"
    }
  ],
  "language": [
    {
      "code": "01",
      "description": "English"
    },
    {
      "code": "02",
      "description": "Māori"
    },
    {
      "code": "03",
      "description": "Mandarin"
    },
    {
      "code": "04",
      "description": "Other Chinese dialect"
    },
    {
      "code": "05",
      "description": "Other"
    }
  ],
  "mainactivityoccupation": [
    {
      "code": "01",
      "description": "Secondary school student"
    },
    {
      "code": "02",
      "description": "College of Education student"
    },
    {
      "code": "03",
      "description": "Non-employed or beneficiary (excluding retired)"
    },
    {
      "code": "04",
      "description": "House person or retired"
    },
    {
      "code": "05",
      "description": "Wage or salary worker"
    },
    {
      "code": "06",
      "description": "Overseas (irrespective of occupation)"
    },
    {
      "code": "07",
      "description": "Self-employed"
    },
    {
      "code": "08",
      "description": "Wananga student"
    },
    {
      "code": "09",
      "description": "University student"
    },
    {
      "code": "10",
      "description": "Private Training Establishment"
    },
    {
      "code": "11",
      "description": "Polytechnic student"
    }
  ],
  "medical_insurance_providers": [
    {
      "code": "296",
      "description": "Studentsafe University Insurance"
    },
    {
      "code": "998",
      "description": "Other compliant insurance provider"
    },
    {
      "code": "999",
      "description": "Other insurance cover (requires assessment)"
    }
  ],
  "course_available_year": [
    { "code": "2021", "description": "2021-1-26" },
    { "code": "2022", "description": "2021-10-26" }
  ],
  "payment_method": [
    {
      "code": "PAYMENT_PRIVATE",
      "description": "Private funds",
      "metaData": { "label": "Private funds" }
    },
    {
      "code": "SCHOLARSHIP_OFFERED",
      "description": "I have been offered a scholarship (please specify)",
      "metaData": { "label": "Private funds" }
    },
    {
      "code": "PRIVATE_FUNDS_SCHOLARSHIP",
      "description": "I have private funds <strong>and</strong> have been offered a scholarship",
      "metaData": { "label": "Private funds" }
    },
    {
      "code": "PRIVATE_FUNDS",
      "description": "I have private funds <strong>and</strong> I would like to be considered for a UC Doctoral&nbsp;Scholarship",
      "metaData": { "label": "Private funds" }
    },
    {
      "code": "UC_DOCTORAL",
      "description": "I would like to be considered for a UC Doctoral Scholarship",
      "metaData": { "label": "Private funds" }
    },
    {
      "code": "PAYMENT_OTHER",
      "description": "Other (please specify)",
      "metaData": { "label": "Private funds" }
    }
  ],
  "tertiary_qualification_year_started": [
    {
      "code": "2019",
      "description": "2019"
    },
    {
      "code": "2018",
      "description": "2018"
    },
    {
      "code": "2017",
      "description": "2017"
    }
  ],
  "english_language_proficiency_test_year": [
    {
      "code": "2019",
      "description": "2019"
    },
    {
      "code": "2018",
      "description": "2018"
    },
    {
      "code": "2017",
      "description": "2017"
    }
  ],
  "condition_style": [
    {
      "code": "cond_ua",
      "description": "University admission condition",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "incomplete",
      "description": "Incomplete application item",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_defer",
      "description": "Decline deferral",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_defer_next",
      "description": "Decline deferral next steps",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_ua",
      "description": "Decline university admission",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_next",
      "description": "Decline next steps",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "cond_qa",
      "description": "Qualification admission condition",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_qa",
      "description": "Decline qualification admission",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "decline_next_all_courses_declined",
      "description": "Decline all courses next steps",
      "type": "condition_style",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    }
  ],
  "condition_item": [
    {
      "code": "dec_qa_not_degree_equivalent",
      "description": "PG no degree equivalent",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE",
        "document_type_code": "",
        "communication_title": "Do not meet Qualification entry requirements",
        "condition_style_code": "decline_qa",
        "communication_next_steps": "",
        "communication_description": "<div>Your previous tertiary study is not equivalent to a NZ bachelor degree which is a minimum requirement for postgraduate study at UC.</div>"
      }
    },
    {
      "code": "dec_qa_insufficient_GPA",
      "description": "Insufficient GPA for QA",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE",
        "document_type_code": "",
        "communication_title": "Do not meet Qualification entry requirements",
        "condition_style_code": "decline_qa",
        "communication_next_steps": "",
        "communication_description": "<div>Your level of academic achievement in your previous tertiary study does not meet the minimum achievement requirements for the qualification.</div>"
      }
    },
    {
      "code": "dec_ua_not_UE_equivalent",
      "description": "Below UA equivalent",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE",
        "document_type_code": "",
        "communication_title": "Do not meet University Entrance requirements",
        "condition_style_code": "decline_ua",
        "communication_next_steps": "",
        "communication_description": "<div>Your secondary/high school study does not meet <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/enrol/eligibility/#d.en.96627\">NZ University Entrance requirements.</a></div>"
      }
    },
    {
      "code": "dec_ua_insufficient_achievement_HS",
      "description": "Insufficient achievement for UA – High school ",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE",
        "document_type_code": "",
        "communication_title": "Do not meet University Entrance requirements",
        "condition_style_code": "decline_ua",
        "communication_next_steps": "",
        "communication_description": "<div>Your level of academic achievement in secondary/high school study does not meet <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/enrol/eligibility/#d.en.96627\">NZ University Entrance requirements.</a></div>"
      }
    },
    {
      "code": "cond_ua_ncea",
      "description": "Waiting for NCEA results",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "condition_style_code": "cond_ua",
        "communication_title": "Gain University entrance",
        "communication_description": "<div>Before you can start your degree at UC, you must first meet university entrance requirements.<br><br></div><div><strong>Find out more about UE <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/enrol/eligibility/\">here</a></strong></div>",
        "communication_next_steps": "Please wait. Your results will be sent to us directly. We will be in contact with you about next steps.",
        "document_type_code": "",
        "staff_editable": "FALSE"
      }
    },
    {
      "code": "incomplete_name",
      "description": "Name change",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "condition_style_code": "incomplete",
        "communication_title": "Name change",
        "communication_description": "<div>If you have changed your name, please also provide a <strong>certified true copy</strong> of your proof of name change.</div><div><br></div><div>Just a reminder about <a target=\"_blank\" href=\"\">certification</a>.</div>",
        "communication_next_steps": "",
        "document_type_code": "NAMECHANGE",
        "staff_editable": "TRUE"
      }
    },
    {
      "code": "incomplete_tertiary_grad",
      "description": "Graduation certificate",
      "type": "condition_item",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "condition_style_code": "incomplete",
        "communication_title": "Graduation certificate",
        "communication_description": "<div>Please provide a <strong>digital </strong>or <strong>certified true copy </strong>of your graduation or degree certificate.</div><div><br></div><div>Just a reminder about <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/identity-and-citizenship-verification\">certification</a>.</div>",
        "communication_next_steps": "",
        "document_type_code": "TERT_GRAD_CERT",
        "staff_editable": "TRUE"
      }
    }
  ],
  "condition_reason": [
    {
      "code": "copy",
      "description": "Original/Certified document required",
      "type": "condition_reason",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "missing",
      "description": "Not supplied",
      "type": "condition_reason",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    }
  ],
  "condition_state_student": [
    {
      "code": "applied",
      "description": "Required"
    },
    {
      "code": "received",
      "description": "Supplied"
    }
  ],
  "condition_state": [
    {
      "code": "applied",
      "description": "Applied",
      "type": "condition_state",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "FALSE"
      }
    },
    {
      "code": "met",
      "description": "Met",
      "type": "condition_state",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE"
      }
    },
    {
      "code": "pending",
      "description": "Pending",
      "type": "condition_state",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE"
      }
    },
    {
      "code": "received",
      "description": "Received",
      "type": "condition_state",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "FALSE"
      }
    },
    {
      "code": "removed",
      "description": "Removed",
      "type": "condition_state",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {
        "staff_editable": "TRUE"
      }
    }
  ],
  "mced_role_type": [
    {
      "code": "MCED_FAC",
      "description": "Facilitator"
    },
    {
      "code": "MCED_TCHR",
      "description": "Teacher"
    },
    {
      "code": "MCED_TCHRAIDE",
      "description": "Teacher Aide"
    }
  ],
  "business_event_titles": [
    {
      "code": "application_state_change",
      "description": "APPLICATION STATE CHANGE"
    },
    {
      "code": "application_state_submitted",
      "description": "APPROVED UNIVERSITY ADMISSION"
    },
    {
      "code": "declined_university_admission",
      "description": "DECLINED UNIVERSITY ADMISSION"
    }
  ],
  "class_list_year": [
    {
      "code": "2021",
      "description": "2021"
    },
    {
      "code": "2022",
      "description": "2022"
    },
    {
      "code": "2023",
      "description": "2023"
    },
    {
      "code": "2024",
      "description": "2024"
    }
  ],
  "course_result_status": [
    {
      "code": "PENDING",
      "description": "Pending"
    },
    {
      "code": "PASSED",
      "description": "Passed"
    },
    {
      "code": "FAILED",
      "description": "Failed"
    }
  ],
  "course_rule_type": [
    {
      "code": "co_requisites",
      "description": "Co-requisites",
      "metadata": {
        "level": "course",
        "default_decline_reason": "co-requisite_not_met"
      }
    },
    {
      "code": "course_checking",
      "description": "Course checking",
      "metadata": {
        "level": "course",
        "default_decline_reason": "invalid_for_qual"
      }
    },
    {
      "code": "course_reentry",
      "description": "Repeated course",
      "metadata": {
        "level": "course",
        "default_decline_reason": "course_reentry_fail"
      }
    },
    {
      "code": "equivalents",
      "description": "Equivalents",
      "metadata": {
        "level": "course",
        "default_decline_reason": "equivalence_fail"
      }
    },
    {
      "code": "manual_approval",
      "description": "Manual approval required",
      "metadata": {
        "level": "course",
        "default_decline_reason": ""
      }
    },
    {
      "code": "pre_requisites",
      "description": "Prerequisites",
      "metadata": {
        "level": "course",
        "default_decline_reason": "pre_requisite_not_met"
      }
    },
    {
      "code": "restrictions",
      "description": "Restrictions",
      "metadata": {
        "level": "course",
        "default_decline_reason": "restriction_fail"
      }
    },
    {
      "code": "workload",
      "description": "Workload",
      "metadata": {
        "level": "pos",
        "default_decline_reason": ""
      }
    },
    {
      "code": "restricted_points",
      "description": "Restricted points sanction",
      "metadata": {
        "level": "pos",
        "default_decline_reason": ""
      }
    },
    {
      "code": "manual_programme_of_study",
      "description": "Programme of study",
      "metadata": {
        "level": "pos",
        "default_decline_reason": ""
      }
    },
    {
      "code": "jsms_waitlist",
      "description": "Waitlist",
      "metadata": {
        "level": "course",
        "default_decline_reason": ""
      }
    },
    {
      "code": "repeated_course",
      "description": "Repeated course",
      "metadata": {
        "level": "course",
        "default_decline_reason": ""
      }
    },
    {
      "code": "jsms_late_add",
      "description": "Late add",
      "metadata": {
        "level": "course",
        "default_decline_reason": ""
      }
    },
    {
      "code": "jsms_late_wd",
      "description": "Late drop",
      "metadata": {
        "level": "course",
        "default_decline_reason": ""
      }
    },
    {
      "code": "invalid_for_qualification",
      "description": "Invalid for qualification",
      "metadata": {
        "level": "course",
        "default_decline_reason": "invalid_for_qual"
      }
    }
  ],
  "enrolled_course_state": [
    { "code": "closed", "description": "Closed" },
    { "code": "draft", "description": "Draft" },
    { "code": "not_selected", "description": "Not Selected" },
    { "code": "selected", "description": "Selected" },
    { "code": "fully_enrolled", "description": "Fully enrolled" }
  ],
  "grade": [
    {
      "code": "A",
      "description": "A",
      "metadata": {
        "uc_grade": true,
        "minimumMark": "90",
        "maximumMark": "100"
      }
    },
    {
      "code": "B",
      "description": "B",
      "metadata": {
        "uc_grade": true,
        "minimumMark": "80",
        "maximumMark": "89"
      }
    },
    {
      "code": "C",
      "description": "C",
      "metadata": {
        "uc_grade": true,
        "minimumMark": "70",
        "maximumMark": "79"
      }
    },
    {
      "code": "Z",
      "description": "Z",
      "metadata": {
        "uc_grade": false,
        "minimumMark": "1",
        "maximumMark": "69"
      }
    }
  ],
  "on_hold_reason": [
    {
      "code": "I_WANT_TO",
      "description": "I want to"
    }
  ],
  "results_change_reasons": [
    {
      "code": "I_WANT_TO",
      "description": "I want to"
    }
  ],
  "qualification_occurrence_type": [
    {
      "code": "ANY",
      "description": "intakes with any time start"
    },
    {
      "code": "INTAKE",
      "description": "intakes with specific commencement_dates"
    }
  ],
  "qualification_occurrence_study_type": [
    {
      "code": "FULLTIME",
      "description": "Full-time"
    },
    {
      "code": "PARTTIME",
      "description": "Part-time"
    }
  ],
  "qualification_occurrence_citizenship_category": [
    {
      "code": "International",
      "description": "International"
    },
    {
      "code": "Domestic",
      "description": "Domestic"
    }
  ],
  "qualification_occurrence_site": [
    {
      "code": "Rotorua",
      "description": "Rotorua"
    },
    {
      "code": "Nelson",
      "description": "Nelson"
    },
    {
      "code": "New Plymouth",
      "description": "New Plymouth"
    },
    {
      "code": "Arts Centre",
      "description": "The Arts Centre"
    },
    {
      "code": "Christchurch",
      "description": "Christchurch"
    },
    {
      "code": "Distance",
      "description": "Distance"
    }
  ],
  "qualification_occurrence_source": [
    {
      "code": "MYUC",
      "description": "ourUC"
    },
    {
      "code": "JSMS",
      "description": "JSMS"
    }
  ],
  "qualification_occurrence_status": [
    {
      "code": "live",
      "description": "Live"
    },
    {
      "code": "pending",
      "description": "Pending"
    },
    {
      "code": "cancelled",
      "description": "Cancelled"
    }
  ],
  "course_decline_reason": [
    {
      "code": "pre_requisite_not_met",
      "description": "Pre-requisite not met",
      "metadata": {
        "communication_content": "You have not met the pre-requisite requirements for this course"
      }
    }
  ],

  "offer_of_place_decline_reason": [
    {
      "code": "no_scholarship",
      "description": "UC did not offer scholarship"
    },
    {
      "code": "financial_reason",
      "description": "Financial reasons"
    },
    {
      "code": "other",
      "description": "Other"
    }
  ],
  "admission_status": [
    {
      "code": "being_assessed",
      "description": "Being Assessed"
    }
  ],

  "admission_reason": [
    {
      "code": "pending",
      "description": "Pending",
      "metadata": {
        "criteria": "NZ citizen or permanent resident",
        "assessmentType": "manual_approval_in_ourUC"
      }
    }
  ],
  "gender": [
    {
      "code": "X",
      "description": "Diverse",
      "type": "gender",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "F",
      "description": "Female",
      "type": "gender",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    },
    {
      "code": "M",
      "description": "Male",
      "type": "gender",
      "valid_from": "1873-01-01",
      "valid_to": null,
      "metadata": {}
    }
  ],
  "suitability_recommendataion": [
    {
      "code": "recommended",
      "description": "Recommended"
    },
    {
      "code": "recommendedWithReservations",
      "description": "Recommended with reservations"
    },
    {
      "code": "reservations",
      "description": "Reservations"
    },
    {
      "code": "doubtSuitability",
      "description": "Doubt suitability"
    },
    {
      "code": "unsuitable",
      "description": "Unsuitable"
    }
  ],
  "tertiary_study_success_estimate": [
    {
      "code": "highlyLikely",
      "description": "Highly likely"
    },
    {
      "code": "likely",
      "description": "Likely"
    },
    {
      "code": "doubtful",
      "description": "Doubtful"
    },
    {
      "code": "unlikely",
      "description": "Unlikely"
    },
    {
      "code": "notKnown",
      "description": "Not known"
    }
  ],
  "associated_person_type": [
    {
      "code": "referee_one",
      "description": "Referee 1"
    },
    {
      "code": "referee_two",
      "description": "Referee 2"
    },
    {
      "code": "referee_three",
      "description": "Referee 3"
    },
    {
      "code": "school",
      "description": "School Report"
    }
  ],
  "uconline_course": [
    {
      "code": "519FX220311",
      "description": "Academic Writing Basics",
      "type": "uconline_course",
      "valid_from": "2022-10-31",
      "valid_to": "2023-01-26",
      "metadata": {
        "course_code": "519FX22031",
        "course_name": "Academic Writing Basics",
        "course_category": "Short course",
        "course_occurrence": "1",
        "course_tec_funded": "FALSE",
        "course_analysis_code": "51900:1980:519FX22031:SE303:1",
        "course_domestic_price_gst": "100",
        "course_domestic_price_excl_gst": "86.96",
        "course_international_price_gst": "424.35",
        "course_international_price_excl_gst": "369"
      }
    },
    {
      "code": "MCEN3011",
      "description": "Shifting Business to Net Zero",
      "type": "uconline_course",
      "valid_from": "2022-11-14",
      "valid_to": "2023-01-13",
      "metadata": {
        "course_code": "MCEN301",
        "course_name": "Shifting Business to Net Zero",
        "course_category": "Micro-credential",
        "course_occurrence": "1",
        "course_tec_funded": "TRUE",
        "course_analysis_code": "51900:5364:519FG22011:SE303:1",
        "course_domestic_price_gst": "300",
        "course_domestic_price_excl_gst": "260.87",
        "course_international_price_gst": "598",
        "course_international_price_excl_gst": "520"
      }
    },
    {
      "code": "invalid_course01",
      "description": "Invalid Course 01",
      "type": "uconline_course",
      "valid_from": "2022-10-31",
      "valid_to": "2022-12-26",
      "metadata": {
        "course_code": "invalid_course",
        "course_name": "Invalid Course 01",
        "course_category": "Short course",
        "course_occurrence": "01",
        "course_tec_funded": "FALSE",
        "course_analysis_code": "51900:1980:519FX22031:SE303:1",
        "course_domestic_price_gst": "100",
        "course_domestic_price_excl_gst": "86.96",
        "course_international_price_gst": "424.35",
        "course_international_price_excl_gst": "369"
      }
    },
    {
      "code": "valid_course01",
      "description": "Valid Course 01",
      "type": "uconline_course",
      "valid_from": "2022-10-31",
      "valid_to": "2099-12-31",
      "metadata": {
        "course_code": "valid_course",
        "course_name": "Valid Course 01",
        "course_category": "Short course",
        "course_occurrence": "01",
        "course_tec_funded": "FALSE",
        "course_analysis_code": "51900:1980:519FX22031:SE303:1",
        "course_domestic_price_gst": "100",
        "course_domestic_price_excl_gst": "86.96",
        "course_international_price_gst": "424.35",
        "course_international_price_excl_gst": "369"
      }
    }
  ]
}
