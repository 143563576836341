import { HttpErrorResponse } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { throwError, of, BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { AdminViewUser } from '@shared/models/admin-view-user';
import { Applicant } from '@shared/models/applicant';
import { UCError } from '@shared/models/errors';
import { NotificationTypes, AudienceTypes } from '@shared/models/notification';
import { User, UserDetail, UserTypes, Roles } from '@shared/models/user';
import { CacheManagementService } from '@shared/services/cache-management/cache-management.service';
import { DSHttpError } from '@shared/services/data-service';
import { firebaseErrorMap } from '@shared/services/user/firebaseErrors';
import { mockAdminViewUser } from '@shared/services/user/mock-admin-view-user';
import { IUserService } from '@shared/services/user/user.service';

export class MockError extends HttpErrorResponse implements Error {
  error = 'mock http error';
  message = 'a mock http error has happened';
}

export const mockStaffCanonicalId = 'staff123';

/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
// As a mock service it must match the interface but most methods have hard-coded responses and ignore params
export class MockUserService implements IUserService {
  // Hack to get around dependency injection in real UserService
  public cacheService: CacheManagementService;

  public loginCalls = [];
  public user$ = new BehaviorSubject(null);
  public userDetail$ = new BehaviorSubject<UserDetail>(null);
  public newUserState: User;
  public userError$ = new Subject<UCError>();
  public outageNotification$ = new BehaviorSubject(null);
  public firebaseServerURL = `${environment.apiPrefix || ''}/firebase-api`;
  public cookieIdToClear$ = new BehaviorSubject<string>(null);
  public loggedUserCheck$ = new BehaviorSubject<boolean>(null);
  public isFirebaseEmailLogin$ = new BehaviorSubject<boolean>(null);
  public isB2CLogin$ = new BehaviorSubject<boolean>(null);

  public currentUser = this.user$.asObservable();

  public userDetail = this.userDetail$.asObservable();
  public isLoggedIn = this.user$.pipe(map((u) => !!u));

  public ucLoginErrorStatus = null;
  public firebaseLoginIsValid = true;
  public modalServiceShouldNavigate = true;
  public MSAL_INSTANCE: IPublicClientApplication;

  get validationError() {
    return this.userError$.asObservable();
  }

  get user(): User {
    return this.user$.value;
  }

  get userDetailValue(): UserDetail {
    return this.userDetail$.value;
  }

  get cookieIdToClear(): Observable<string> {
    return this.cookieIdToClear$.asObservable();
  }

  get errors(): Observable<UCError> {
    return this.userError$.asObservable();
  }

  get userClaims(): string[] {
    return this.user.claims;
  }

  get isInOutage(): Observable<boolean> {
    return of(false);
  }

  get outageNotification(): Observable<Notification> {
    return this.outageNotification$.asObservable();
  }

  get loggedUserCheck(): Observable<boolean> {
    return this.loggedUserCheck$.asObservable();
  }

  get isFirebaseEmailLogin(): boolean {
    return this.isFirebaseEmailLogin$.value;
  }

  checkFirebaseEmailLogin = (): Promise<boolean> => Promise.resolve(false);

  // eslint-disable-next-line max-lines-per-function
  loginUC(email: string, password: string) {
    const getErrorCode = () => {
      if (this.ucLoginErrorStatus === 403) {
        return 'auth.ucUserNotFound';
      } else {
        return 'auth.generic';
      }
    };

    // eslint-disable-next-line max-lines-per-function
    return new Promise((resolve, reject) => {
      this.loginCalls.push({ email, password });
      if (this.ucLoginErrorStatus) {
        const responseOpts = {
          error: {
            errors: [
              {
                status: this.ucLoginErrorStatus,
                source: { pointer: '/' },
                details: 'generic',
              },
            ],
          },
          status: this.ucLoginErrorStatus,
        };
        const err = new DSHttpError(new MockError(responseOpts), {
          code: getErrorCode(),
        });
        reject(err);
      } else {
        const user = new User({
          firstName: 'mike',
          lastName: 'wazowski',
          email: 'me@me.com',
        });
        this.user$.next(user);
        resolve(user);
      }
    });
  }

  updatePassword(password) {
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  maybeUpdateFirebaseName(applicant: Applicant) {
    // No-op
  }

  updateEmail(email): Promise<void> {
    return new Promise((resolve) => {
      const user = this.user$.value;
      user.email = email;
      this.user$.next(user);
      resolve(user);
    });
  }

  public formatFirebaseError(err) {
    const errorMap = firebaseErrorMap;

    const textMap = [
      {
        pattern: /(not available in|not available from|Iran|Sudan|North Korea|Syria)/i,
        code: 'auth.countryBlocked',
      },
    ];

    const textMatchLookup = (textPatternArr, message) => {
      const match = textPatternArr.find((el) => {
        return message && message.match(el.pattern);
      });
      return match && match.code;
    };

    const ucError = {
      code: errorMap[err.code] || textMatchLookup(textMap, err.message) || 'auth.genericFirebase',
      data: err,
    };

    return ucError;
  }

  revalidate(password): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!password) {
        reject({ code: 'auth/no-password', message: "couldn't resolve password" });
      }
      resolve();
    });
  }

  verifyEmail(): Promise<void> {
    return Promise.resolve();
  }

  resetEmail(email: string): Promise<{ state: string }> {
    return Promise.resolve({ state: 'success' });
  }

  loginFirebaseEmail(email: string, password: string) {
    return new Promise<void>((resolve) => {
      this.loginCalls.push({ email, password });
      if (!this.firebaseLoginIsValid) {
        this.userError$.next({ code: 'auth.generic', data: {} });
        resolve();
      } else {
        this.user$.next(
          new User({
            firstName: 'mike',
            lastName: 'wazowski',
            email,
          }),
        );
        resolve(this.user$.value);
      }
    });
  }

  logout() {
    return new Promise<void>((resolve) => {
      if (this.cacheService) {
        this.cacheService.clearCache();
      }

      this.user$.next(null);
      resolve();
    });
  }

  refreshTokenBeforeNavigate(): Observable<User> {
    return this.getUser();
  }

  createStudentTokenForStaff(canonicalId: string): Observable<null> {
    return of(null);
  }

  destroyStudentTokenForStaff(): Observable<null> {
    return of(null);
  }

  impersonate(canonicalId: string): Observable<User> {
    let userDetail = this.userDetail$.value;
    if (!userDetail) {
      userDetail = new UserDetail({ staff: {}, student: {}, agent: {}, timeout: 60000 });
    }
    const student = new User({ canonicalId, claims: [Roles.Student] });
    userDetail.student = student;
    userDetail.staff = new User({ canonicalId: mockStaffCanonicalId, claims: [Roles.Staff] });
    this.userDetail$.next(userDetail);
    this.user$.next(student);
    return of(student);
  }

  unimpersonate(): Observable<User> {
    const userDetail = this.userDetail$.value;
    const cid = get(this.user$.value, 'canonicalId');
    this.cookieIdToClear$.next(cid);
    userDetail.student = null;
    this.userDetail$.next(userDetail);
    return of(userDetail.staff || userDetail.agent);
  }

  impersonateAndNavigate(canonicalId: string, urlParts: string[]): Observable<boolean> {
    return this.impersonate(canonicalId).pipe(map(() => true));
  }

  staffSearchUser(identifier: string, type: string): Observable<AdminViewUser[]> {
    return of(AdminViewUser.deserialize({ results: mockAdminViewUser }));
  }

  // eslint-disable-next-line complexity
  getUser(status?: number): Observable<User> {
    // Use this to replicate serverside updates to the user model
    // such as getting assigned a student id or name changed
    const user = this.newUserState || this.user;
    if (user) {
      user.partiallyHydrated = status === 206;
      this.user$.next(user);
    }
    this.newUserState = null;

    return this.user$.value ? of(this.user$.value) : throwError('no user');
  }

  public checkClaim(claim) {
    return this.userClaims && this.userClaims.indexOf(claim) !== -1;
  }

  public staffForceHydrateOnLogin(identifier: string, value: boolean) {
    return of({});
  }

  /**
   * Used in tests to register a currentUser to the sessionService.
   * This is necessary to provide a currentUser for the DataService
   * when the DataService is used instead of MockDataService
   */
  public loginTestUser(user?: User, type?: UserTypes): void {
    const userOrFallback =
      user ||
      new User({
        token: 'DEADBEEF',
        uid: '1234',
        firstName: 'mike',
        lastName: 'wazowski',
        email: 'me@me.com',
      });

    this.updateUserDetail({ [type || UserTypes.student]: userOrFallback });
    this.user$.next(userOrFallback);
  }

  public updateUserDetail(users, timeout?) {
    const base = this.userDetail$.value || { timeout: 1200 };
    const detail = new UserDetail(Object.assign(base, users));
    this.userDetail$.next(detail);
    this.user$.next(detail.impersonated || detail.mainUser);
  }

  public logoutTestUser(): void {
    this.logout();
  }

  // eslint-disable-next-line max-lines-per-function
  public notificationListener() {
    of([
      {
        message: 'test notification',
        expiry: new Date(),
        start: new Date(),
        type: NotificationTypes.outage,
        audience: `${AudienceTypes.student}-${environment.envName}`,
      },
    ])
      .pipe(
        map((arr) => arr.filter((not) => not.type === 'outage' && DateTime.fromJSDate(not.expiry) > DateTime.now())),
        map((notification) => notification[0]),
      )
      // eslint-disable-next-line max-lines-per-function, complexity
      .subscribe((notification) => {
        if (notification) {
          const outageNoticeCountdown = DateTime.fromJSDate(notification.start).diff(DateTime.now()).milliseconds;
          if (outageNoticeCountdown) {
            timer(outageNoticeCountdown).subscribe({
              complete: () => {
                this.outageNotification$.next(notification);
              },
            });
          }

          const logoutCountdown = DateTime.fromJSDate(notification.expiry).diff(DateTime.now()).milliseconds;
          if (logoutCountdown > 0) {
            timer(logoutCountdown).subscribe({
              complete: () => {
                if (this.user$.value) {
                  this.logout();
                }
              },
            });
          }
        }
      });
  }

  getAliases(canonicalId: string) {
    return of({ aliases: ['1234'] });
  }

  static msalInstanceFactory = (): IPublicClientApplication => {
    if (MockUserService.hasB2CConfig()) {
      return new PublicClientApplication(environment.azureB2C.msalConfig);
    }
  };

  static hasB2CConfig() {
    return 'azureB2C' in environment;
  }

  async loginB2C() {
    try {
      await MockUserService.msalInstanceFactory().loginRedirect(environment.azureB2C.loginRequest);
    } catch (error) {
      throwError(() => {
        new Error(error);
      });
    }
  }

  public msalInstanceForB2C() {
    if (MockUserService.hasB2CConfig()) {
      this.MSAL_INSTANCE = MockUserService.msalInstanceFactory();
    }
  }

  public saveB2CToken(idToken: string) {
    this.isB2CLogin$.next(true);
    // eslint-disable-next-line no-console
    console.log(idToken);
  }

  public handleRedirectObservable() {
    const accountInfo = {
      homeAccountId: '123',
      environment: 'mock',
      tenantId: 't123456789',
      username: 'b2cuser',
      localAccountId: 'local123456789',
    };
    this.MSAL_INSTANCE.setActiveAccount(accountInfo);
  }
}
