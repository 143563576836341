"use strict";

module.exports = {
  INVALID_TYPE: "Expected type {0} but found type {1}",
  INVALID_FORMAT: "Object didn't pass validation for format {0}: {1}",
  ENUM_MISMATCH: "No enum match for: {0}",
  ENUM_CASE_MISMATCH: "Enum does not match case for: {0}",
  ANY_OF_MISSING: "Data does not match any schemas from 'anyOf'",
  ONE_OF_MISSING: "Data does not match any schemas from 'oneOf'",
  ONE_OF_MULTIPLE: "Data is valid against more than one schema from 'oneOf'",
  NOT_PASSED: "Data matches schema from 'not'",
  // Array errors
  ARRAY_LENGTH_SHORT: "Array is too short ({0}), minimum {1}",
  ARRAY_LENGTH_LONG: "Array is too long ({0}), maximum {1}",
  ARRAY_UNIQUE: "Array items are not unique (indexes {0} and {1})",
  ARRAY_ADDITIONAL_ITEMS: "Additional items not allowed",
  // Numeric errors
  MULTIPLE_OF: "Value {0} is not a multiple of {1}",
  MINIMUM: "Value {0} is less than minimum {1}",
  MINIMUM_EXCLUSIVE: "Value {0} is equal or less than exclusive minimum {1}",
  MAXIMUM: "Value {0} is greater than maximum {1}",
  MAXIMUM_EXCLUSIVE: "Value {0} is equal or greater than exclusive maximum {1}",
  // Object errors
  OBJECT_PROPERTIES_MINIMUM: "Too few properties defined ({0}), minimum {1}",
  OBJECT_PROPERTIES_MAXIMUM: "Too many properties defined ({0}), maximum {1}",
  OBJECT_MISSING_REQUIRED_PROPERTY: "Missing required property: {0}",
  OBJECT_ADDITIONAL_PROPERTIES: "Additional properties not allowed: {0}",
  OBJECT_DEPENDENCY_KEY: "Dependency failed - key must exist: {0} (due to key: {1})",
  // String errors
  MIN_LENGTH: "String is too short ({0} chars), minimum {1}",
  MAX_LENGTH: "String is too long ({0} chars), maximum {1}",
  PATTERN: "String does not match pattern {0}: {1}",
  // Schema validation errors
  KEYWORD_TYPE_EXPECTED: "Keyword '{0}' is expected to be of type '{1}'",
  KEYWORD_UNDEFINED_STRICT: "Keyword '{0}' must be defined in strict mode",
  KEYWORD_UNEXPECTED: "Keyword '{0}' is not expected to appear in the schema",
  KEYWORD_MUST_BE: "Keyword '{0}' must be {1}",
  KEYWORD_DEPENDENCY: "Keyword '{0}' requires keyword '{1}'",
  KEYWORD_PATTERN: "Keyword '{0}' is not a valid RegExp pattern: {1}",
  KEYWORD_VALUE_TYPE: "Each element of keyword '{0}' array must be a '{1}'",
  UNKNOWN_FORMAT: "There is no validation function for format '{0}'",
  CUSTOM_MODE_FORCE_PROPERTIES: "{0} must define at least one property if present",
  // Remote errors
  REF_UNRESOLVED: "Reference has not been resolved during compilation: {0}",
  UNRESOLVABLE_REFERENCE: "Reference could not be resolved: {0}",
  SCHEMA_NOT_REACHABLE: "Validator was not able to read schema with uri: {0}",
  SCHEMA_TYPE_EXPECTED: "Schema is expected to be of type 'object'",
  SCHEMA_NOT_AN_OBJECT: "Schema is not an object: {0}",
  ASYNC_TIMEOUT: "{0} asynchronous task(s) have timed out after {1} ms",
  PARENT_SCHEMA_VALIDATION_FAILED: "Schema failed to validate against its parent schema, see inner errors for details.",
  REMOTE_NOT_VALID: "Remote reference didn't compile successfully: {0}"
};