import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { EnrolmentSelectorComponent } from '@shared/applicant/qualification/enrolment-selector/enrolment-selector.component';
import { QualificationEnrolmentSelectorComponent } from '@shared/applicant/qualification/qualification-enrolment-selector/qualification-enrolment-selector.component';
import { SubjectOptionQuestionComponent } from '@shared/applicant/qualification/subject-option-question/subject-option-question.component';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';

@NgModule({
  declarations: [QualificationEnrolmentSelectorComponent, SubjectOptionQuestionComponent, EnrolmentSelectorComponent],
  imports: [CommonModule, AtomsModule, MoleculesModule, OrganismsModule, SharedTemplatesModule, ReactiveFormsModule],
  exports: [QualificationEnrolmentSelectorComponent, EnrolmentSelectorComponent, SubjectOptionQuestionComponent],
  providers: [],
})
export class QualificationModule {}
