<h4 class="course-view__header">
  <span class="course-view__course-code">
    <a [attr.href]="course.href" target="_blank">{{ course.courseOccurrenceCode }}</a>
  </span>
  <span class="course-view__course-title">{{ course.courseTitle }}</span>
</h4>
<div class="course-view__content-wrapper">
  <div class="course-view__details-wrapper">
    <div>
      <span class="course-view__additional-fields">{{ course.site }}</span>
      <span class="course-view__additional-fields">{{ course.coursePoints }} points</span>
    </div>
    <div>
      <span class="course-view__additional-fields">
        <strong>{{ strings.start }}</strong>
        {{ course.startDate | date: 'dd/MM/yy' }}
      </span>
      <span class="course-view__additional-fields">
        <strong>{{ strings.complete }}</strong>
        {{ course.endDate | date: 'dd/MM/yy' }}
      </span>
    </div>
  </div>
  <span class="course-view__additional-fields course-view__tag-wrapper" *ngIf="showStates && courseState">
    <uc-tag
      *ngIf="(course.declineReason && isDeclined) || isWaitlisted; else notDeclined"
      class="info-modal"
      [class]="tagColour"
      (click)="showStatusInfoModal()">
      {{ courseState }}
      <div class="tag-icon">
        <uc-icon iconType="info-task" iconWidth="10" iconHeight="10"></uc-icon>
      </div>
    </uc-tag>
    <ng-template #notDeclined>
      <uc-tag [class]="tagColour">{{ courseState }}</uc-tag>
    </ng-template>
  </span>
</div>

<uc-confirmation-modal
  #declinedModal
  [title]="strings.declineModal.header(this.courseOccurrenceCode)"
  [acceptText]="strings.declineModal.close"></uc-confirmation-modal>
<uc-confirmation-modal
  #waitlistedModal
  [title]="strings.waitModal.header(this.courseOccurrenceCode)"
  [message]="strings.waitlistDescription"
  [acceptText]="strings.waitModal.close"></uc-confirmation-modal>
