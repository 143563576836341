import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Applicant } from '@shared/models/applicant';
import { EnglishQualification, Result } from '@shared/models/attained-qualification';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-english-proficiency-detail',
  templateUrl: './english-proficiency-detail.component.html',
  styleUrls: ['./english-proficiency-detail.component.scss'],
})
export class EnglishProficiencyDetailComponent extends AbstractBaseTask implements OnInit {
  @Input() applicationYear: string;
  @Input() process: string;
  @Input() stage: string;
  @Input() task: Task;

  proficiencyForm: UntypedFormGroup;
  log: Logger;
  showTestType: boolean;
  strings = strings.components.organisms.englishProficiencyDetail;
  currentApplicant: Applicant;

  constructor(
    private fb: UntypedFormBuilder,
    private applicantService: ApplicantService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity() {
    // No-op
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.proficiencyForm = this.fb.group({
      englishSkills: ['', Validators.required],
    });

    this.proficiencyForm
      .get('englishSkills')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((v) => !!v),
      )
      .subscribe((value) => this.configureFormBasedOnSelections(value));

    this.applicantService.applicant
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((applicant) => this.handleChangesToApplicantData(applicant));
  }

  configureFormBasedOnSelections(englishSkills: string) {
    this.showTestType = this.shouldShowTestType(englishSkills);
    if (this.shouldShowTestForm(englishSkills)) {
      this.configureFormForTests();
    } else if (englishSkills === 'Studied in English') {
      this.configureFormForStudiedInEnglish();
    } else {
      this.configureFormForNotStudiedInEnglish();
    }
    this.disableFieldsWhenVerified(this.currentApplicant.englishQualification.verified);
  }

  shouldShowTestType(englishSkills: string): boolean {
    return !!englishSkills.match(/(Scheduled|Will|None)/);
  }

  shouldShowTestForm(englishSkills: string): boolean {
    return this.shouldShowTestType(englishSkills) || englishSkills === 'Have Sat Test';
  }

  handleChangesToApplicantData(applicant: Applicant) {
    this.currentApplicant = applicant;
    this.updateFormFromApplicant(applicant);
    this.disableFieldsWhenVerified(applicant.englishQualification.verified);
  }

  configureFormForTests() {
    this.proficiencyForm.removeControl('country');
    this.proficiencyForm.addControl('otherControl', this.fb.control('', Validators.required));
  }

  configureFormForStudiedInEnglish() {
    this.proficiencyForm.removeControl('otherControl');
    this.proficiencyForm.setControl(
      'country',
      this.fb.control(this.currentApplicant.englishQualification.country, Validators.required),
    );
  }

  configureFormForNotStudiedInEnglish() {
    this.proficiencyForm.removeControl('otherControl');
    this.proficiencyForm.removeControl('country');
  }

  updateFormFromApplicant(applicant: Applicant) {
    if (!applicant.englishQualification) {
      applicant.englishQualification = new EnglishQualification();
    }
    this.updateForm(applicant.englishQualification);
  }

  updateForm(qualification: EnglishQualification) {
    this.proficiencyForm.patchValue({
      englishSkills: qualification.source?.code,
      country: qualification.country,
      otherControl: {
        testType: qualification.type?.code || 'Other',
        other: qualification.typeOther || null,
        date: qualification.dateAttained,
        scores: this.individualScores(qualification),
        overall: this.overallScore(qualification),
      },
    });
  }

  individualScores(qualification: EnglishQualification): Record<string, number> {
    if (this.hasSatTest(qualification) && this.hasIndividualScores(qualification)) {
      return {
        LST: this.findScore(qualification.result, 'LST'),
        RED: this.findScore(qualification.result, 'RED'),
        WRT: this.findScore(qualification.result, 'WRT'),
        SPK: this.findScore(qualification.result, 'SPK'),
        TOT: this.findScore(qualification.result, 'TOT'),
      };
    }
  }

  overallScore(qualification: EnglishQualification): string {
    if (this.hasSatTest(qualification) && !this.hasIndividualScores(qualification)) {
      return qualification.result.find((r) => r.type === 'OVR').score;
    }
  }

  hasSatTest(qualification: EnglishQualification) {
    return qualification.source.code === 'Have Sat Test';
  }

  hasIndividualScores(qualification: EnglishQualification) {
    return ['IELTS', 'TOEFL', 'Pearsons'].some((c) => c === qualification.type?.code);
  }

  findScore(results: Result[], type: string): number {
    const result = results.find((res) => res.type === type);
    const score = parseFloat(result.score);
    return isNaN(score) ? null : score;
  }

  disableFieldsWhenVerified(verified: boolean) {
    let action: (field: UntypedFormControl) => void;
    if (verified) {
      action = (field) => field.enabled && field.disable();
    } else {
      action = (field) => field.disabled && field.enable();
    }

    Object.values(this.proficiencyForm.controls).forEach(action);
  }

  public update(): Observable<Applicant> {
    if (!this.currentApplicant) {
      this.log.error('tried to update applicant, but no applicant has been loaded yet');
      return throwError(() => new Error('no applicant'));
    }
    const buildScore = (type, score) => {
      let finalScore = score;
      if (!score && score !== 0) {
        finalScore = null;
      }
      return new Result({ type, score: finalScore });
    };
    const { englishQualification } = this.currentApplicant;
    const { englishSkills, country, otherControl } = this.proficiencyForm.value;

    englishQualification.source = { code: englishSkills };
    if (otherControl) {
      // Update otherControl related values
      englishQualification.type = new ReferenceData({ code: otherControl.testType });
      englishQualification.typeOther = otherControl.other || '';
      englishQualification.attained = !!otherControl.scores || !!otherControl.overall;
      englishQualification.dateAttained = otherControl.date;
      englishQualification.result = [buildScore('OVR', otherControl.overall)];
      if (otherControl.scores) {
        const { scores } = otherControl;
        englishQualification.result = Object.keys(scores).map((key) => buildScore(key, scores[key]));
      }
    } else {
      // Clear any existing values
      englishQualification.type = null;
      englishQualification.typeOther = null;
      englishQualification.attained = null;
      englishQualification.dateAttained = null;
      englishQualification.result = [];
    }
    englishQualification.country = country;

    return this.applicantService.updateApplicant(this.currentApplicant);
  }
}
