<uc-form-template [formGroup]="taskForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-task-hint class="sub-section" [task]="task" path="/application/ib_qualification"></uc-task-hint>
    <uc-yes-no
      formControlName="yesNo"
      [questionString]="strings.sittingQuestion"
      required="true"
      testSelector="sitting">
      <uc-yes-content>
        <uc-task-hint class="sub-section" [task]="task" path="/application/ib_results_available"></uc-task-hint>
        <uc-yes-no
          *ngIf="taskForm.get('yesNo').value === 'show'"
          formControlName="release"
          required="true"
          [questionString]="strings.releaseRequest"
          testSelector="release">
          <uc-no-content>
            <uc-subsection>
              <uc-subsection-text-block type="info">{{ strings.releaseBanner }}</uc-subsection-text-block>
            </uc-subsection>
          </uc-no-content>
        </uc-yes-no>
      </uc-yes-content>
    </uc-yes-no>
  </uc-section>
</uc-form-template>
