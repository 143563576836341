<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-online-header-bar
  [hasSideNav]="true"
  (openSidenav)="navBar.isOpen = true"
  [hideLogo]="true"
  [hideNavItems]="true"
  [hideToolbarItems]="navBar.isOpen"
  [showTotaraUrl]="showTotaraLink"></uc-online-header-bar>
<uc-side-nav #navBar></uc-side-nav>
<div id="main-content" tabindex="-1" class="overview__content">
  <div class="overview__inner-content">
    <uc-form-template [class.overview__hide-application-btn]="!canStartNewApplication">
      <uc-form-header [title]="strings.qualificationsTitle">
        <span class="overview__description">{{ strings.qualificationsSubHeading }}</span>
        <button
          [attr.data-cy]="'new-app'"
          *ngIf="canStartNewApplication"
          uc-button
          class="overview__new-application-btn"
          [isLoading]="isLoading"
          (click)="transitionToNewApplication()">
          {{ strings.newApplicationButtonText }}
        </button>
      </uc-form-header>
    </uc-form-template>

    <uc-spinner *ngIf="displaySpinner"></uc-spinner>

    <p
      class="overview__no-applications"
      *ngIf="!applicationSummaries?.length && !archivedEnrolments?.length && !displaySpinner">
      {{ strings.noActiveApplications }}
    </p>

    <div class="overview__active-application" *ngFor="let appYear of applicationYears">
      <uc-application-overview
        [year]="appYear.year"
        [expanded]="appYear.expanded"
        (updateParams)="updateParams($event)"
        (updateViewData)="fetchViewData()"></uc-application-overview>
    </div>
    <uc-form-header [title]="strings.independentEnrolmentsTitle" class="independent__enrolments__form-header">
      <span class="overview__description">{{ strings.independentEnrolmentsSubHeading }}</span>
    </uc-form-header>

    <p class="overview__no-applications" *ngIf="independentCourses.length === 0">
      {{ strings.noIndependentCourses }}
    </p>

    <div class="overview__active_application" *ngFor="let ice of independentCourses">
      <uc-independent-course-enrolment-summary-card (enrolmentUpdated)="enrolmentUpdated()" [enrolment]="ice" />
    </div>

    <uc-studenthub-cta
      [heading]="strings.ctaHeading"
      [subheading]="strings.ctaSubHeading"
      [button]="strings.ctaButton"
      [url]="strings.ctaUrl" />
  </div>

  <uc-online-footer></uc-online-footer>
</div>
