import { Component, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeclarationBase } from '@shared/classes/declaration-base';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-enrolment-change-declaration',
  templateUrl: '../student-declaration/student-declaration.component.html',
  styleUrls: ['../student-declaration/student-declaration.component.scss'],
})
export class EnrolmentChangeDeclarationComponent extends DeclarationBase implements OnInit {
  applicationYear: string;
  enrolmentChange: ChangeOfEnrolment;

  constructor(
    loggingService: LoggingService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
  ) {
    super(loggingService);
    this.hintPath = '/change_of_enrolments/declaration_agreed';
    this.loading = true;
  }

  ngOnInit() {
    this.changeOfEnrolmentService
      .getChangeOfEnrolment()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((coe) => {
        this.setDeclarationStrings();
        this.setupData(of(coe), 'declarationAgreed');
      });
  }

  setDeclarationStrings() {
    const standardDeclaration = this.baseStrings.enrolmentChangeDeclaration;
    const starDeclaration = this.baseStrings.starEnrolmentChangeDeclaration;
    this.declarationStrings = standardDeclaration;

    const qualCode = get(this.enrolmentChange, 'enrolledQualifications[0].code');
    const isStarQual = qualCode && qualCode.toLowerCase() === PROCESS_NAMES.STAR;
    if (isStarQual) {
      this.declarationStrings = starDeclaration;
    }
    this.loading = false;
  }

  update() {
    this.updateDeclarationTask(this.changeOfEnrolmentService, 'updateChangeOfEnrolment');
  }
}
