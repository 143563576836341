import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-permanent-contact',
  templateUrl: './permanent-contact.component.html',
  styleUrls: ['./permanent-contact.component.scss'],
})
export class PermanentContactComponent {
  @Input() permanentDetails: UntypedFormGroup;

  strings = strings.components.organisms.profilePage.permanentContactDetails;
}
