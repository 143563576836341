<uc-subsection class="yes-no-wrap">
  <uc-task-hint *ngIf="task" id="yesnohint" [task]="task" [model]="model" [path]="path"></uc-task-hint>
  <uc-subsection-question class="question">
    {{ questionString }}
    <uc-required-field *ngIf="required"></uc-required-field>
  </uc-subsection-question>
  <div class="question-description" [innerHTML]="questionDescription"></div>
  <div class="answer-group-container fields-wrap">
    <uc-radio-input
      [testSelector]="testSelectorId + '-y'"
      [formControl]="yesNoState"
      [labelText]="yesOptionLabel"
      [groupName]="yesNoGroupName"
      fieldValue="show"></uc-radio-input>
    <uc-radio-input
      [testSelector]="testSelectorId + '-n'"
      [formControl]="yesNoState"
      [labelText]="noOptionLabel"
      [groupName]="yesNoGroupName"
      fieldValue="hide"></uc-radio-input>
    <div class="validation-indicator" [class.hasValue]="hasValue">
      <uc-icon iconType="tick" iconTitle="Valid" iconWidth="11" iconHeight="11" iconFill="$colorWhite"></uc-icon>
    </div>
  </div>
  <div class="question-description" *ngIf="extraDescription && showConditionalDescription">
    {{ extraDescription }}
  </div>
</uc-subsection>
<ng-content></ng-content>
