<!-- Mobile sidebar/stages summary: -->
<div class="stages-summary">
  <button [attr.data-cy]="'side-nav'" class="summary-panel-btn" (click)="toggleNav()">
    <uc-icon iconType="chevron-right" iconWidth="26" iconHeight="26"></uc-icon>
  </button>
  <ul #stepsNav class="stages-summary-list">
    <li
      *ngFor="let stage of process.stages; let stageNumber = index"
      routerLink="{{ stage.path }}"
      [queryParams]="queryParams"
      [fragment]="fragment"
      routerLinkActive
      #rla="routerLinkActive">
      <div
        class="stage-summary-item"
        [class.active]="rla.isActive"
        *ngIf="stageNumber + 1 < process.stages.length"
        (click)="preventNavigation($event)">
        <div class="stage-summary-num">{{ stageNumber + 1 }}</div>
        <uc-online-task
          *ngFor="let task of stage.tasks"
          [attr.data-cy]="'task-' + task.code"
          class="stage-summary-task"
          [task]="task"
          [stage]="process.stages[stageNumber]"
          routerLink="{{ task.path }}"
          [queryParams]="queryParams"
          [fragment]="fragment"
          routerLinkActive="active-card"></uc-online-task>
      </div>
      <div
        class="{{
          stageNumber + 1 === process.stages.length && !process.firstIncompleteStage
            ? 'stage-summary-item last-step-complete'
            : 'stage-summary-item last-step'
        }}"
        *ngIf="stageNumber + 1 === process.stages.length">
        <div class="stage-summary-num">
          <uc-icon iconType="cart" iconWidth="8" iconHeight="8"></uc-icon>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- Sidebar: visible on both mob/desktop. Slides in on mob, fixed sidebar on desktop. -->
<div class="stages-sidebar" [class.show]="showSidebar">
  <nav class="stages-sidebar-content">
    <button [attr.data-cy]="'side-nav-toggle'" class="summary-panel-btn" (click)="toggleNav()">
      <uc-icon iconType="chevron-left" iconWidth="26" iconHeight="26"></uc-icon>
    </button>
    <ul #stepsNav class="stages-list">
      <li
        *ngFor="let stage of process.stages; let stageNumber = index"
        routerLink="{{ stage.path }}"
        [queryParams]="queryParams"
        [fragment]="fragment"
        routerLinkActive
        #rla="routerLinkActive">
        <div
          *ngIf="stageNumber + 1 < process.stages.length"
          [class.active]="rla.isActive"
          [class.clickable]="isImpersonating && stageNumber + 1 <= firstIncompleteStageNumber"
          class="stage-item clearfix"
          (click)="setStageNumber(stageNumber + 1); preventNavigation($event)">
          <div class="stage-num">{{ stageNumber + 1 }}</div>
          <div class="stage-content">
            <h3 class="stage-heading">{{ stage.title }}</h3>
            <uc-online-task
              [attr.data-cy]="'task-' + task.code"
              [task]="task"
              [stage]="process.stages[stageNumber]"
              *ngFor="let task of stage.tasks"
              (triggerClosePanel)="onTaskCardClick()"></uc-online-task>
          </div>
        </div>
        <div
          *ngIf="stageNumber + 1 === process.stages.length"
          [class.active]="rla.isActive"
          [class.clickable]="isImpersonating && stageNumber + 1 <= firstIncompleteStageNumber"
          class="stage-item last-step"
          (click)="setStageNumber(stageNumber + 1); preventNavigation($event)">
          <div class="stage-num">
            <uc-icon iconType="cart" iconWidth="14" iconHeight="14"></uc-icon>
          </div>
          <div class="stage-content">
            <h3 class="stage-heading">{{ stage.title }}</h3>
            <uc-online-task
              [attr.data-cy]="'task-' + task.code"
              [task]="task"
              [stage]="process.stages[stageNumber]"
              *ngFor="let task of stage.tasks"
              (triggerClosePanel)="onTaskCardClick()"></uc-online-task>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>
