import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { captureException } from '@sentry/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { COURSE_STATUS } from '@shared/constants/states.constants';
import { CourseOccurrence } from '@shared/models/course';
import { REFDATA_TYPES } from '@shared/models/reference-data';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-course-minimal-view',
  templateUrl: './course-minimal-view.component.html',
  styleUrls: ['./course-minimal-view.component.scss'],
})
export class CourseMinimalViewComponent implements OnInit {
  @Input() course: CourseOccurrence;
  @Input() showDates = false;
  @Input() showStates = false;

  @ViewChild('declinedModal') declinedModal: ConfirmationModalComponent;
  @ViewChild('waitlistedModal') waitlistedModal: ConfirmationModalComponent;

  strings = strings.components.molecules.courseView;
  courseOccurrenceCode: string;
  log: Logger;

  constructor(
    loggingService: LoggingService,
    private referenceDataService: ReferenceDataService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get courseState() {
    const stateString = this.strings.courseStates[this.course.state];
    return stateString || '';
  }

  get isDeclined() {
    return this.course.state === COURSE_STATUS.DECLINED;
  }

  get isWaitlisted() {
    return this.course.state === COURSE_STATUS.WAITLISTED;
  }

  ngOnInit(): void {
    this.courseOccurrenceCode = this.course.courseOccurrenceCode;
    if (this.isDeclined) {
      this.getDeclineReasonContent();
    }
  }

  getDeclineReasonContent(): Observable<string | boolean> {
    return this.referenceDataService.getByCode(REFDATA_TYPES.COURSE_DECLINE_REASON, this.course.declineReason).pipe(
      map((refData) => {
        if (refData) {
          return refData.metadata.communicationContent;
        } else {
          captureException(new Error(`Unknown decline reason refdata: ${this.course.declineReason}`));
        }
      }),
    );
  }

  showStatusInfoModal() {
    if (this.isDeclined) {
      this.getDeclineReasonContent().subscribe((description) => {
        this.declinedModal.message = String(description);
        this.declinedModal.open();
      });
    } else {
      this.waitlistedModal.open();
    }
  }
}
