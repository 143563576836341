import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, map, of, tap, Subscription, takeUntil, Subject } from 'rxjs';

import strings from '@shared/constants/strings.constants';
import { Roles } from '@shared/models/user';
import { FetchState } from '@shared/services/online-learner/online-learner.service';

/**
 * Exported types and interfaces to be used by callers.
 *
 * See the LearnerSearchTemplateComponent for a complete example being used in production.
 */

export interface TableRow {
  [key: string]: unknown;
}

export interface SelectOption {
  value: unknown;
  labelText: string;
}

export type LoadingFilterValues = BehaviorSubject<SelectOption[]>;

export type FilterValues = SelectOption[];

export type FilterFunction = (value: unknown, row: TableRow) => boolean;

/**
 * Filters is a dictionary whose keys are filter names and whose values are properties of
 * a filter. Three different types of filters are supported:
 * 1) A simple column filter specified like columns = ['name'] whose dropdown
 *   is populated with the values of the column
 * 2) A filter whose labels and values are provided alo
 */
export interface Filters {
  [key: string]: {
    values?: FilterValues;
    loadedValues?: LoadingFilterValues;
    matcher?: FilterFunction;
    column?: string[] | string;
    default?: unknown;
    separator?: string;
    placeholder?: BehaviorSubject<string>;
    applyFormat?: (v: unknown) => unknown;
    ngSelect?: boolean;
    multiple?: boolean;
    /**
     * A function that decorates the whole lists once loaded.
     */
    listDecorator?: (list: FilterValues) => FilterValues;
    dependsOn?: string[];
    selected?: unknown[];
  };
}

export const DEFAULT_FILTER_OPTIONS = {
  applyFormat: (v) => v,
  ngSelect: true,
  multiple: false,
  listDecorator: (list) => list,
};

export type Columns = Column[];

/**
 * Interface for column details
 */
export interface Column {
  name: string;
  label?: string;
  visible?: boolean;
  minWidth?: number;
  sortable?: boolean;
  maxWidth?: number;
  link?: string;
  download?: string;
  style?: string;
  eval?: (row) => unknown;
  applyFormat?: (value: unknown) => unknown;
  format?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkTo?: { url: (...args: any[]) => string; params?: string[] };
}

export enum DownloadOptions {
  ALWAYS = 'always',
  NEVER = 'never',
  IF_VISIBLE = 'if-visible',
}

export interface Selections {
  [key: string]: unknown;
}

const DEFAULT_COLUMN: Column = {
  name: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  sortable: true,
  visible: true,
  applyFormat: (value) => value,
  download: DownloadOptions.IF_VISIBLE,
  format: undefined,
  style: 'value',
};

export type Links = string[];

@Component({
  selector: 'uc-filtered-table',
  templateUrl: './pipeline-filtered-table.component.html',
  styleUrls: ['./pipeline-filtered-table.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FilteredTableComponent implements OnInit, OnDestroy {
  /**
   * The source of rows to be displayed in the table as an Observable.
   */
  @Input() rows$: Observable<TableRow[]> = of([]);

  /**
   * Current state of data loading
   */
  @Input() fetchState$: Observable<FetchState> = of({ status: 'ready', downloaded: 0, totalApplicants: 0 });

  /**
   * An array of `Column` objects that define the columns to be displayed in the table.
   * See the Columns & Column interfaces for more information.
   */
  @Input() columns: Columns = null;

  /**
   * Filters defines the filters that are to be available to the user.
   * See the Filters interface above for more information.
   *
   * There are three types of filters:
   * 1. Simple filters for which just a column name is specified and for which
   *   the filter values are taken from that column of the table data
   * 2. A filter on a named column whose filter values are provided here
   * 3. A filter whose options are provided along with a 'matcher' that selects rows to display.
   */
  @Input() filters: Filters;

  /**
   * Screen text strings
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() strings: any;

  /**
   * Whether filters should be applied instantly vs when Apply button is clicked
   */
  @Input() instantFilter = true;

  /**
   * A boolean indicating whether to display a download button for the table data.
   */
  @Input() download = true;

  /**
   * The maximum number of rows to display at once (page size).
   */
  @Input() limit = 10;

  /**
   * The starting offset for the table data (page start).
   */
  @Input() startOffset = 0;

  /**
   * The name of the key column for the table data for performance.
   */
  @Input() keyColumn = null;

  /**
   * An array of numbers indicating the relative widths of the shadow columns.
   * (A bit of a hack for creating a realistic Loading... shadow that matches
   * the actual data columns. The numbers are CSS flex widths.)
   */
  @Input() shadowFlexWidths = [3, 2, 2, 1, 2, 2, 1];

  /**
   * Value to look for to indicate loading cell
   */
  @Input() loadingIndicator = strings.loading;

  /** *** END INPUTS ***/

  private destroy$: Subject<void> = new Subject<void>();

  showColumns: unknown[] = null;
  showRows$: Observable<unknown[]>;

  filterForm: UntypedFormGroup;

  roles = Roles;
  isCollapsed = false;
  filtered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  offset = this.startOffset;

  rowCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  applicantDownloadsRemaining = 0;

  /**
   * This object is built from the given filter @Input to
   * hold useful Observable values such as the drop down list values
   * to improve user experience.
   */
  loadedFilters: Filters;

  tableLoaded = false;
  inDownload = true;

  /**
   *  A BehaviourSubject replays the last value, which we need to be able to do.
   */
  unfilteredRows$: BehaviorSubject<TableRow[]> = new BehaviorSubject<TableRow[]>([]);

  private activeSubscriptions: Map<string, Subscription> = new Map();

  private filterSelections$: BehaviorSubject<Selections> = new BehaviorSubject<Selections>({});

  constructor(private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({});
  }

  /**
   * Event handlers
   **/
  ngOnInit(): void {
    this.createFilterForm();

    /**
     * Subscribe to full dataset and remember it for later.
     */
    this.rows$.subscribe({
      next: (rows) => {
        this.rowCount$.next(rows.length);
        this.unfilteredRows$.next(rows);
        this.loadDataFilters(rows);
        this.tableLoaded = rows.length > 0;
      },
      complete: () => {
        this.tableLoaded = true;
      },
    });

    this.fetchState$.subscribe((fs) => {
      if (fs.downloaded >= fs.totalApplicants) {
        this.inDownload = false;
      } else {
        this.inDownload = true;
      }

      this.applicantDownloadsRemaining = fs.totalApplicants - fs.downloaded;
    });

    this.activateFilter();
  }

  /**
   * Mostly cleans up explicit subscriptions to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onApplyFilter(useValues: Selections = null): void {
    const filterValues = useValues || this.filterForm.value;
    this.filterSelections$.next(filterValues);
  }

  onResetFilter(): void {
    this.onApplyFilter({});
    this.filterForm.reset();
  }

  onPage(event) {
    this.offset = event.offset;
  }

  /** ********** END EVENT HANDLERS ********** **/

  private createFilterForm() {
    this.loadedFilters = this.prepareFilters();

    for (const key of Object.keys(this.filters)) {
      this.filterForm.addControl(key, this.formBuilder.control(get(this.filters[key], 'default', null)));
    }

    this.subscribeToDependencies();
  }

  /**
   * To optimise user experience, creates a bunch of Observables for holding filter/dropdown
   * lists based on filter instructions that will be updated in the background from table data
   * after/during load.
   */
  prepareFilters(): Filters {
    const { filters } = this;
    return Object.keys(this.filters).reduce((acc, key) => {
      const values = get(filters[key], 'values', []) as SelectOption[];
      acc[key] = {
        ...DEFAULT_FILTER_OPTIONS,
        applyFormat: get(filters[key], 'applyFormat', (v) => v),
        ...filters[key],
        loadedValues: new BehaviorSubject(values || ([] as SelectOption[])),
        placeholder: new BehaviorSubject(values === null ? 'Loading...' : this.strings.filterFields[key].placeholder),
      };
      return acc;
    }, {});
  }

  /**
   * Called after data has been loaded into the table to update dropdowns that depend on column values.
   */
  loadDataFilters(rows): void {
    Object.keys(this.filters)
      .filter((f) => !('values' in this.filters[f]))
      .forEach((filter) => {
        this.loadDropdown(rows, filter);
      });
  }

  /**
   * Load a dropdown from based on the filter's definition and values in the data table (unfilteredRows$).
   * loadDropdown is called for all dropdowns when table data has loaded, and whenever depended-upon
   * filters change after that.
   */
  // eslint-disable-next-line max-lines-per-function
  private loadDropdown(rows: TableRow[], name: string, where: { [key: string]: unknown } = {}): void {
    const values: Set<unknown> = new Set();

    // eslint-disable-next-line complexity
    rows.forEach((row) => {
      const { column: columns, separator } = this.filters[name];
      const cols = Array.isArray(columns) ? columns : [columns];
      const newValue = cols.map((c) => row[c]).join(separator || '_');

      if (Object.keys(where).every((key) => where[key] === row[key])) {
        values.add(newValue);
      }
    });

    const listValues = [...values].map((v) => {
      return { labelText: this.loadedFilters[name].applyFormat(v) as string, value: v };
    });

    (this.loadedFilters[name].loadedValues as BehaviorSubject<SelectOption[]>).next(
      get(this.filters[name], 'listDecorator')?.call(this, listValues) || listValues,
    );

    this.loadedFilters[name].placeholder.next(this.strings.filterFields[name].placeholder);
  }

  /**
   * Subscribes to any values changes in filters that are mentioned in the dependsOn attribute of other filters.
   * At the time of writing, this was to enable the Occurrence dropdown list to reflect currently selected course.
   */
  private subscribeToDependencies() {
    // eslint-disable-next-line complexity
    Object.keys(this.filters).forEach((key) => {
      const f = this.filters[key];
      if (f.dependsOn) {
        for (const dependentControlKey of f.dependsOn) {
          const dependentControl = this.filterForm.get(dependentControlKey);
          if (dependentControl && !this.activeSubscriptions.has(dependentControlKey)) {
            const subscription = dependentControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
              this.updateDependentDropdowns({ [dependentControlKey]: value });
            });

            this.activeSubscriptions.set(dependentControlKey, subscription);
          }
        }
      }
    });
  }

  /**
   *
   * Implements Filter.dependsOn by updating dependent dropdowns based on the current filter values.
   * Method subscribeToDependencies must have been called earlier to watch for changes in filters
   * that are depended upon.
   */
  updateDependentDropdowns(filterValues: Selections): void {
    Object.keys(this.filters)
      .filter((key) => 'dependsOn' in this.filters[key])
      .forEach((key) => {
        const { dependsOn } = this.filters[key];
        this.unfilteredRows$.subscribe((rows) => {
          this.loadDropdown(
            rows,
            key,
            dependsOn?.reduce(
              // Only pass dependencies that are present in the filter so that all values in the table
              // are used otherwise.
              (acc, curr) => (get(filterValues, curr) ? { ...acc, [curr]: filterValues[curr] } : acc),
              {},
            ),
          );
        });
      });
  }

  /**
   * Combine unfiltered rows with the filter selections to create a new Observable.  This observable, showRows$,
   * is used as the actual, current data source for the table.
   */
  activateFilter(): void {
    this.showRows$ = combineLatest([this.unfilteredRows$, this.filterSelections$]).pipe(
      map(([rows, selection]) => {
        return rows.filter((row) => {
          return Object.keys(selection).every((key) => {
            if (!selection[key]) {
              return true;
            }
            if (!(key in row)) {
              return this.filters[key].matcher(selection[key], row);
            }
            return row[key] === selection[key];
          });
        });
      }),
      tap((rows) => this.rowCount$.next(rows.length)),
    );
  }

  // eslint-disable-next-line complexity, class-methods-use-this
  mapColumn(col: Column): unknown {
    const { label, name } = col;
    const prop = name || label;
    const format = col.format || (col.linkTo ? 'link' : undefined);
    return { ...DEFAULT_COLUMN, ...col, name, prop, label, format };
  }

  // eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
  getShowColumns(rows: TableRow[]): any[] {
    if (!this.showColumns) {
      this.showColumns = (this.columns.filter((c) => get(c, 'visible', true)) || Object.keys(rows?.[0] || {})).map(
        (col) => this.mapColumn(col),
      );
    }
    return this.showColumns;
  }

  columnsForDownloadAll = () =>
    this.columns.filter((c) => c.download !== DownloadOptions.NEVER) as { name: string; label: string }[];

  columnsForDownloadCurrent = () => {
    if (!this.showColumns) {
      return this.columnsForDownloadAll();
    }

    const visibleColumns = this.showColumns.map((c: { prop: string }) => c.prop);

    const cols = this.columns
      .filter(
        (c) =>
          c.download === DownloadOptions.ALWAYS ||
          (visibleColumns.includes(c.name) && c.download !== DownloadOptions.NEVER),
      )
      .map((c) => ({ name: c.name, label: c.label }));

    return cols as { name: string; label: string }[];
  };

  /**
   * Pipe function that will preserve dictionary key order in ngFor loops, etc
   */
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  public keepOriginalOrder = (a, b) => a.key;

  // eslint-disable-next-line class-methods-use-this
  pick = (row, keys) => keys.map((k) => row[k]) as unknown[];

  linkUrl = (col, row) => {
    const args = this.pick(row, col.linkTo.params);
    return col.linkTo ? col.linkTo.url(...args) : null;
  };
}
