<div class="dashboard-sidenav" [class.open]="isOpen" [class.default-open]="defaultOpen">
  <uc-icon
    class="icon-close"
    (click)="isOpen = false"
    iconType="close"
    iconWidth="12"
    iconHeight="12"
    iconFill="$colorWhite"></uc-icon>
  <div class="sidenav-content-wrap">
    <h1 *ngIf="!isUcOnlineStudent" class="accent-font" [class.small-title]="useSmallTitle">
      {{ titleString }}
    </h1>
    <h1 *ngIf="isUcOnlineStudent">
      <img class="sidenav-uconline-logo" src="/assets/img/UC-online-logo-simple-White.svg" alt="UC Online Logo" />
    </h1>
    <ul>
      <li>
        <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
        <!-- prettier-ignore -->
        <a [routerLink]="internalUrls.dashboard" routerLinkActive="active-link"
           [routerLinkActiveOptions]="{ exact: true }">{{ strings.homepageLinkText }}</a>
      </li>
      <li *ngIf="studentOrImpersonating && !agentApp && !isUcOnlineStudent">
        <a [routerLink]="internalUrls.manageMyStudy" routerLinkActive="active-link">{{ strings.manageMyStudyText }}</a>
      </li>
      <li *ngIf="!agentApp">
        <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
        <!-- prettier-ignore -->
        <a [routerLink]="internalUrls.sso" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
           target="_blank">{{ strings.scholarship }}</a>
      </li>
      <li class="visible-xs-inline">
        <a [routerLink]="internalUrls.documents" routerLinkActive="active-link">{{ strings.documentLinkText }}</a>
      </li>
      <li *ngIf="!isUcOnlineStudent">
        <a href="{{ externalUrls.ucHomepage }}" target="_blank" rel="noopener">{{ strings.ucLinkText }}</a>
      </li>
      <div *ngIf="isUcOnlineStudent">
        <li>
          <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
          <!-- prettier-ignore -->
          <a href="{{ externalUrls.ucOnlineHomepage }}" target="_blank"
             rel="noopener">{{ strings.ucOnlineLinkText }}</a>
        </li>
        <li>
          <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
          <!-- prettier-ignore -->
          <a href="{{ externalUrls.ucOnlineTotara }}" target="_blank"
             rel="noopener">{{ strings.ucOnlineTotaraLinkText }}</a>
        </li>
      </div>
      <div *ngIf="studentOrImpersonating && !agentApp">
        <li *ngFor="let link of infoRequiredLinks">
          <a [routerLink]="link.href" routerLinkActive="active-link">{{ link.title }}</a>
        </li>
      </div>
      <li *ngIf="showLinks">
        <div *ngFor="let section of sections" class="side-nav-section">
          <h5>{{ section.title }}</h5>
          <div *ngFor="let link of section.links" class="section-links">
            <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
            <!-- prettier-ignore -->
            <a *ngIf="link.routerLink" [routerLink]="link.routerLink"
               routerLinkActive="active-link">{{ link.title }}</a>
            <!-- 2024-09-11 KY: prevent prettier to "fix" this as it breaks the handlebar into new line, adding unwanted whitespace -->
            <!-- prettier-ignore -->
            <a *ngIf="link.href" [attr.href]="link.href || ''"
               [attr.target]="link.newTab ? '_blank' : null">{{ link.title }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
