import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-associated-person',
  templateUrl: './associated-person.component.html',
})
export class AssociatedPersonComponent {
  @Input() associatedPersonForm: UntypedFormGroup;
  @Input() allRequired = true;
  @Input() task: Task;
  @Input() basePath;
  @Input() strings;
  @Input() typeRefdata;
  @Input() testSelector = '';
  @Input() hintPath: string;

  get showEmail() {
    return !!this.associatedPersonForm.contains('email');
  }

  get showType() {
    return !!this.associatedPersonForm.contains('type') && this.typeRefdata;
  }

  get testSelectorId() {
    return `${this.testSelector}-associated-person`;
  }

  getHintPath(prop) {
    return `${this.basePath}/${prop}`;
  }
}
