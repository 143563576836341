<header [class]="{ hidden: !shouldShowTitle }">
  <h2 #title>
    <ng-content select="[title]"></ng-content>
  </h2>
  <button *ngIf="closable" class="close" (click)="close()">
    <uc-icon iconType="close"></uc-icon>
  </button>
</header>
<section [ngStyle]="{ position: useUnsetPosition ? 'unset' : 'relative' }">
  <article>
    <ng-content select="[body]"></ng-content>
  </article>
</section>
<footer #footer [class]="{ hidden: !shouldShowFooter }">
  <ng-content select="[footer]"></ng-content>
</footer>

<div class="loading-overlay">
  <uc-loader-blip [showBlips]="true"></uc-loader-blip>
</div>
