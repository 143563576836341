import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-personal-statement',
  templateUrl: './personal-statement.component.html',
  styleUrls: ['./personal-statement.component.scss'],
})
export class PersonalStatementComponent implements OnInit {
  @Input() fitnessToTeachForm: UntypedFormGroup;
  @Input() task: Task;

  strings = strings.components.tasks.teachingApplication.personalStatement;

  constructor() {}

  ngOnInit() {}
}
