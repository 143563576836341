import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { MCED_QUAL_TYPES } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { control, group, UCElementGroup } from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

const aslaSpecificTaskCode = 'mced-asla-specific';

enum McRefdataTypes {
  asla = 'mced_asla_role_type',
  mced = 'mced_role_type',
}

@Component({
  selector: 'uc-teaching-microcredentials',
  templateUrl: './teaching-microcredentials.component.html',
  styleUrls: ['./teaching-microcredentials.component.scss'],
})
export class TeachingMicrocredentialsComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  mcedPage: UntypedFormGroup;
  mcedForm: UCElementGroup;
  strings: { [key: string]: any };
  associatedPersonLabels = {};
  aslaMced = false;
  refdataType: string;

  applicationEnrolment: ApplicationEnrolment[];
  currentApplication: Application;
  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicationService: ApplicationService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  ngOnInit(): void {
    this.aslaMced = this.task.code === aslaSpecificTaskCode;
    this.refdataType = this.aslaMced ? McRefdataTypes.asla : McRefdataTypes.mced;

    const stringKey = this.aslaMced ? MCED_QUAL_TYPES.ASLA : MCED_QUAL_TYPES.MCED;
    this.strings = strings.components.tasks.teachingMicrocredentials[stringKey];
    this.associatedPersonLabels = this.strings.roleRelationship;

    this.mcedForm = this.createForm();
    this.mcedPage = this.mcedForm.asControl() as UntypedFormGroup;

    combineLatest([
      this.applicationService.application.pipe(filter((a) => !!a)),
      this.applicationService.getApplicationEnrolment(this.applicationYear),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([application, ae]: [Application, ApplicationEnrolment[]]) => {
        this.currentApplication = application;
        this.applicationEnrolment = ae;
        this.formModel.updateFormFromModel(this.mcedForm, null, this.currentApplication);
        const enrolledQualCodes = [];
        ae.forEach((a) => {
          a.enrolledQualifications.forEach((eq) => {
            enrolledQualCodes.push(eq.code);
          });
        });
      });
  }

  private createForm(): UCElementGroup {
    const form = group({
      roleAndRelationship: group({
        type: control({
          model: 'application',
          path: '/associatedPerson/MCED/type',
          validators: [Validators.required],
        }),
        relationship: control({
          model: 'application',
          validators: [Validators.required],
          path: '/associatedPerson/MCED/relationship',
        }),
      }),
    });

    if (!this.aslaMced) {
      form.get('roleAndRelationship').addControl(
        'name',
        control({
          model: 'application',
          validators: [Validators.required],
          path: '/associatedPerson/MCED/name',
        }),
      );
    }

    return form;
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.mcedForm);
  }

  public update() {
    this.formModel.updateModelFromForm(this.mcedForm, null, this.currentApplication);

    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    updateApplication.subscribe({
      next: (application: Application) => {
        if (application) {
          this.log.info('updated application');
          this.next.emit();
        }
      },
      error: (error: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating application:', error);
      },
    });
  }
}
