import { Component, Input } from '@angular/core';

import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { SubjectOptions, SubjectOptionsList } from '@shared/models/qualification';

@Component({
  selector: 'uc-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent {
  @Input() subjectAnswer: SubjectOptionsList;
  @Input() label;
  @Input() cropLongText = true;
  @Input() subjectOptions: SubjectOptions[];

  getSubjectOptions(level) {
    return this.subjectAnswer[level]
      ?.map((e) => {
        if (e.hasOwnProperty('changeAction')) {
          return e.changeAction !== CHANGE_ACTIONS.DROPPED ? e.code : '';
        } else {
          return e.code;
        }
      })
      .join(' \u{2219} ');
  }
}
