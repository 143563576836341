import { Component, Input, HostBinding } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
})
export class CopyComponent {
  @Input() isStaff = false;
  @Input() value: string;
  @Input() stringLength: number;
  @Input() displayValue = true;
  strings = strings.components.molecules.copy;
  showTooltip = false;
  @HostBinding('class.staff-position') get class() {
    return this.isStaff && !this.displayValue;
  }

  get displayString() {
    return this.stringLength ? `${this.value.substring(0, this.stringLength)}...` : this.value;
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.triggerTooltip();
  }

  triggerTooltip() {
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 2000);
  }
}
