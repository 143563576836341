<div class="prediction-element" [formGroup]="addressModel">
  <uc-task-hint *ngIf="task" [task]="task" [path]="getHintPath('line_1')"></uc-task-hint>
  <uc-string-input
    [testSelector]="testSelectorId + '-line-1'"
    (keydown)="toggleFocus($event, true)"
    formControlName="line1"
    id="line1"
    [label]="label"
    [required]="required"></uc-string-input>
  <ul class="prediction-wrapper" [class.extend-predictions]="predictions.length">
    <li class="prediction-item" *ngFor="let prediction of predictions">
      <button
        [attr.data-cy]="'select-prediction'"
        (keydown)="toggleFocus($event)"
        class="address-item"
        tabindex="1"
        (click)="selectPrediction(prediction)">
        {{ prediction.description }}
      </button>
    </li>
  </ul>
</div>
