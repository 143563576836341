{
  "application_enrolments": [
    {
      "active": true,
      "priority": 1,
      "display_order": 1.0,
      "internal_reference": "eidjtyridj48e8ghe8rioIE38I2ldr",
      "external_reference": null,
      "state": {
        "code": "in_progress"
      },
      "offer_decision": {
        "accept_offer": true,
        "decline_reason": { "code": "no_scholarship" },
        "decline_reason_other": "",
        "declaration_funding_acceptance": true,
        "deferred_qual_occurrence": { "code": "foo" },
        "deferred_qual_occurrence_year": { "code": "2022" }
      },
      "student_provided_exemption_reason": "Because I want to, because I want to",
      "did_continue_award": false,
      "enrolled_qualifications": [
        {
          "code": "BCOM",
          "href": "/qualification/2017/BCOM",
          "internal_reference": "abc",
          "priority": 1,
          "deaggregated": false,
          "award_id": null,
          "state": {
            "code": "in_progress"
          },
          "new_in_award": false,
          "subject_options": {
            "1": [
              {
                "code": "Art History and Theory"
              },
              {
                "code": "Accounting"
              }
            ],
            "2": [
              {
                "code": "Economics1"
              },
              {
                "code": "Economics2"
              }
            ]
          },
          "primary_options": [
            {
              "code": "Art History and Theory"
            },
            {
              "code": "Anthropology"
            }
          ],
          "secondary_options": [
            {
              "code": "SECOND A"
            },
            {
              "code": "SECOND B"
            }
          ],
          "tertiary_options": [
            {
              "code": "THIRD A"
            },
            {
              "code": "THIRD B"
            }
          ],
          "enrolled_courses": [
            {
              "code": "COSC101_17S2 (C)",
              "start_date": "2018-07-22",
              "end_date": "2018-11-04",
              "duration": "15.0",
              "state": {
                "code": "approved"
              },
              "active": true,
              "internal_reference": "abc123"
            }
          ]
        },
        {
          "code": "BA",
          "href": "/qualification/2017/BA (PRIMARY)",
          "priority": 1,
          "internal_reference": "def",
          "deaggregated": false,
          "award_id": null,
          "state": {
            "code": "in_progress"
          },
          "new_in_award": false,
          "primary_options": [
            {
              "code": "Art History and Theory"
            },
            {
              "code": "Anthropology"
            }
          ],
          "secondary_options": [
            {
              "code": "SECOND A"
            },
            {
              "code": "SECOND B"
            }
          ],
          "tertiary_options": [
            {
              "code": "THIRD A"
            },
            {
              "code": "THIRD B"
            }
          ],
          "enrolled_courses": [
            {
              "code": "ACCT102_17S1 (C)",
              "start_date": "2018-07-22",
              "end_date": "2018-11-04",
              "duration": "15.0",
              "state": {
                "code": "jsms_waitlist"
              },
              "active": true,
              "internal_reference": "abc123"
            }
          ]
        }
      ]
    },
    {
      "priority": null,
      "display_order": 2.0,
      "internal_reference": "jn1kj2n3123nkjn1k2jnkljn12",
      "external_reference": null,
      "state": {
        "code": "shadow"
      },
      "active": false,
      "student_provided_exemption_reason": null,
      "did_continue_award": false,
      "enrolled_qualifications": [
        {
          "code": "MTCHGLN",
          "href": "/qualification/2017/MTCHGLN",
          "internal_reference": "abc",
          "priority": 1,
          "deaggregated": false,
          "award_id": null,
          "state": {
            "code": "in_progress"
          },
          "new_in_award": false,
          "subject_options": {
            "1": [
              {
                "code": "Art History and Theory"
              },
              {
                "code": "Accounting"
              }
            ]
          },
          "categories": [
            {
              "category": "additional_information_cac"
            },
            {
              "category": "teaching"
            }
          ],
          "primary_options": [
            {
              "code": "Art History and Theory"
            },
            {
              "code": "Anthropology"
            }
          ],
          "secondary_options": [
            {
              "code": "SECOND A"
            },
            {
              "code": "SECOND B"
            }
          ],
          "tertiary_options": [
            {
              "code": "THIRD A"
            },
            {
              "code": "THIRD B"
            }
          ],
          "enrolled_courses": [
            {
              "code": "COSC101_17S2 (C)",
              "start_date": "2018-07-22",
              "end_date": "2018-11-04",
              "duration": "15.0",
              "state": {
                "code": "declined"
              },
              "active": true,
              "internal_reference": "abc123",
              "decline_reason": "pre_requisite_not_met"
            },
            {
              "code": "ACCT102_17S1 (C)",
              "start_date": "2018-07-22",
              "end_date": "2018-11-04",
              "duration": "15.0",
              "state": {
                "code": "in_progress"
              },
              "active": true,
              "internal_reference": "abc123"
            }
          ]
        }
      ]
    }
  ]
}
