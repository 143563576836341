{
  "enrolments": {
    "internal_reference": "audkier29jk4L09tksdafke84dkOO9",
    "state": {
      "code": "fully_enrolled"
    },
    "enrolled_qualifications": [
      {
        "code": "MTCHGLN",
        "priority": 1,
        "internal_reference": "Iasladfsuyaksjduriws98si89UI89",
        "enrolled_qualification": "898f8dksd9DYH9kdDuehcu8erdfXXS",
        "qualification_occurrence": "erdfX9DYH9kdDuehcu8XS898f8dksd",
        "primary_options": [
          {
            "code": "Art History and Theory"
          },
          {
            "code": "Anthropology"
          }
        ],
        "secondary_options": [
          {
            "code": "SECOND A"
          },
          {
            "code": "SECOND B"
          }
        ],
        "tertiary_options": [
          {
            "code": "THIRD A"
          },
          {
            "code": "THIRD B"
          }
        ],
        "subject_options": {
          "1": [
            {
              "code": "Art History and Theory"
            },
            {
              "code": "Accounting"
            }
          ],
          "2": [
            {
              "code": "Economics1"
            },
            {
              "code": "Economics2"
            }
          ]
        },

        "enrolled_courses": [
          {
            "code": "COSC101_17S2 (C)",
            "start_date": "2018-07-22",
            "end_date": "2018-11-04",
            "duration": "15.0",
            "state": {
              "code": "declined"
            },
            "active": false,
            "internal_reference": "abc123",
            "decline_reason": "decline reason in mock-enrolment-response",
            "errors": [
              {
                "internalReference": "123",
                "ruleType": "type",
                "ruleCategory": "rule",
                "message": "This is a message",
                "waivable": true,
                "declinable": true,
                "owner": "Ang",
                "targets": { "code": "123" }
              }
            ]
          },
          {
            "code": "COSC101_17S2 (C)",
            "start_date": "2018-07-22",
            "end_date": "2018-11-04",
            "duration": "15.0",
            "state": {
              "code": "approved"
            },
            "active": false,
            "internal_reference": "abc456"
          },
          {
            "code": "COMP102_18S1 (C)",
            "start_date": "2018-07-22",
            "end_date": "2018-11-04",
            "duration": "15.0",
            "state": {
              "code": "approved"
            },
            "active": true,
            "internal_reference": "ghi789"
          },
          {
            "code": "COMP103_18S2 (C)",
            "start_date": "2018-07-22",
            "end_date": "2018-11-04",
            "duration": "15.0",
            "state": {
              "code": "declined"
            },
            "active": true,
            "internal_reference": "def456"
          },
          {
            "code": "COMP104_18S2 (C)",
            "start_date": "2018-07-22",
            "end_date": "2018-11-04",
            "duration": "15.0",
            "state": {
              "code": "pending"
            },
            "active": true
          }
        ]
      }
    ]
  }
}
