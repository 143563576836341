import { Component, Input } from '@angular/core';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-star-accept-decline',
  templateUrl: './star-accept-decline.component.html',
})
export class StarAcceptDeclineComponent extends AbstractBaseTask {
  @Input() task: Task;
  strings = strings.components.tasks.starAcceptDecline;

  updateFormValidity() {}
}
