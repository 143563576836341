<uc-form-template>
  <uc-section>
    <uc-subsection>
      <uc-form-input>
        <uc-subsection-question required="true">
          {{ strings.header }}
        </uc-subsection-question>
        <uc-reference-data-selector
          type="application_year"
          [filter]="existingApplicationYears"
          [formControl]="applicationYear"></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>
