import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { fourHundredDeserializer, fourTwentyTwoDeserializer } from '@shared/helpers/serialization';
import { UCError } from '@shared/models/errors';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

export enum HttpVerbs {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export enum UCErrorCodes {
  E400 = 'errors.fourHundred',
  E401 = 'errors.fourOhOne',
  E403 = 'errors.fourOhThree',
  E404 = 'errors.fourOhFour',
  E409 = 'errors.fourOhNine',
  E417 = 'errors.fourSeventeen',
  E422 = 'errors.fourTwentyTwo',
  E500 = 'errors.generic',
}

/**
 * Interface for the options passed into a DataService request method
 */
export interface IDSRequestOpts {
  /**
   * If `deserialize` is provided, this receives the result of deserialize.
   * If `deserialize` is not provided, this receives `res.json()`
   *
   * Default: null
   */
  success$?: Subject<any>;

  /**
   * This will receive any errors from the request or that happen during
   * the deserialization of the response.
   *
   * Default: null
   */
  error$?: Subject<DSHttpError | UCError>;

  /**
   * Callback function that allows you to deserialize
   * a response payload.
   *
   * Both the payload and response are provided so that you can check headers
   *
   * @param payload - from res.json()
   * @param res - the raw HttpResponse object
   *
   * Default: null
   */
  deserialize?: (payload: any, res?: HttpResponse<any>) => any;

  /**
   * A POJO of headers that will be merged into the default request headers.
   * Use this to override a specific default header or to include additional headers
   * to a request.
   *
   * To skip the setting of default headers, see the notes on `requestOptions`.
   *
   * Default: null
   */
  includeHeaders?: { [key: string]: string };

  /**
   * Request options that get passed to the Http request method.
   * Use this for fine-grained control over the requests being made.
   *
   * NOTE: If requestOptions.headers is passed, that value will be used
   *       instead of the default headers that the DataService normally sets.
   *       If the headers are set here, `includeHeaders` will be ignored.
   *
   *       If a headers property is provided, it must be an instance of Headers
   *       as per the RequestOptionsArgs interface.
   *
   * Default: null
   */
  requestOptions?: {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | string[] };
    observe?: 'response';
    params?: HttpParams | { [param: string]: string | string[] };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  };

  /**
   * Forces the response handler to throw an error if the request is successful
   * and but the response.status does not equal this number
   *
   * Will do nothing if the value is falsy
   *
   * See: `unexpectedStatusCode`
   *
   * Default: null
   */
  expectStatus?: number;

  /**
   * The error code that should be returned if the request was successful
   * but the response.status does not equal `expectStatus`.
   *
   * This will only be used if `expectStatus` is provided.
   *
   * Codes should be a valid UCError code that
   *  map to string constants in `/src/app/strings.constants.ts`
   *
   * Default: 'errors.generic'
   */
  unexpectedStatusCode?: string;

  /**
   * Override the default error code that gets passed when an unexpected error occurs.
   * Note: this error code is a fallback, so it will not be used if a more specific
   *       error code applies (see: unexpectedStatusCode and errorCodes)
   *
   * Codes should map to string constants in `/src/app/strings.constants.ts`
   *
   * Default: 'errors.generic'
   */
  defaultErrorCode?: string;

  /**
   * Allows you to provide custom error codes for each error status.
   *
   * This will be merged with the default errorCode mapping,
   * so you only need to define the statuses
   *
   * Codes should be a valid UCError code that
   *  map to string constants in `/src/app/strings.constants.ts`
   *
   * Default: (refer to DataService.request for defaults)
   */
  errorCodes?: { [status: number]: string };

  /**
   * By default, all errors that happen during the request in the response handlers
   * are passed to the `DataService.allErrors$` Subject.
   *
   * To prevent this behavior, set this value to `false`
   *
   * NOTE: This only prevent sending errors to `DataService.allErrors$`.
   *       This will not prevent errors being passed to `error$` if it is provided
   *
   * Default: true
   */
  emitErrors?: boolean;

  /**
   * Transient models from the API require an update call to
   * be converted to create. (PUT to POST)
   *
   * Update calls should pass the create url as a fallback.
   *
   * Default: null
   */
  fallbackUrl?: string;

  /**
   * The default behaviour is to trigger a Sentry alert when an error status is received.
   *
   * This allows you to explicitly set which statuses should not trigger an alert.
   */
  ignoredErrorStatuses?: number[];
}

/**
 * Any errors returned/thrown by DataService requests will be an instance of this class.
 * This is backwards compatible with the UCError interface that was previously being used,
 * but it adds addtional functionality and type-safety.
 */
export class DSHttpError extends Error implements UCError {
  readonly res: HttpErrorResponse | HttpResponse<any>;
  code: string;
  data: any;
  status: number;
  name = 'DSHttpError';

  constructor(res: HttpErrorResponse | HttpResponse<any>, other: { code?: string; data?: any } = {}) {
    super(`${res.status}`);
    this.code = other.code || UCErrorCodes.E500;
    this.data = other.data || res;
    this.res = res;
    this.status = res.status;
  }
}

/**
 * Merge any headers provided by the caller with the default headers.
 *
 * additionalHeaders is the object from `IDSRequestOpts.includeHeaders`.
 */
export const compileHeaders = (additionalHeaders?: { [key: string]: any }): HttpHeaders => {
  const basicHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return new HttpHeaders({ ...basicHeaders, ...additionalHeaders });
};

export abstract class AbstractDataService {
  allErrors$ = new Subject<DSHttpError>();

  get allErrors(): Observable<DSHttpError> {
    return this.allErrors$.asObservable();
  }

  protected abstract request(method: HttpVerbs, url: string, options: IDSRequestOpts): Observable<any>;

  /**
   * If the caller has provided headers on the requestOptions, use those.
   * Otherwise, merge any headers in `includeHeaders` into the default headers.
   */
  protected finalizeRequestOptions(reqOpts: IDSRequestOpts): IDSRequestOpts['requestOptions'] {
    reqOpts.requestOptions = reqOpts.requestOptions || { observe: 'response' };
    reqOpts.requestOptions.headers = reqOpts.requestOptions.headers || compileHeaders(reqOpts.includeHeaders);
    reqOpts.requestOptions.observe = 'response';
    reqOpts.ignoredErrorStatuses = [];
    return reqOpts.requestOptions;
  }

  /**
   * Trigger an HTTP GET request.
   *
   * Using the name "fetch" instead of "get" to make this more searchable
   */
  public fetch(url: string, options: IDSRequestOpts): Observable<any> {
    return this.request(HttpVerbs.GET, url, options);
  }

  public post(url: string, body: any, options: IDSRequestOpts): Observable<any> {
    options.requestOptions = {
      body,
      ...options.requestOptions,
    };
    return this.request(HttpVerbs.POST, url, options);
  }

  public put(url: string, body: any, options: IDSRequestOpts): Observable<any> {
    options.requestOptions = {
      body,
      ...options.requestOptions,
    };
    return this.request(HttpVerbs.PUT, url, options);
  }

  public del(url: string, options: IDSRequestOpts): Observable<any> {
    return this.request(HttpVerbs.DELETE, url, options);
  }

  public options(url: string, options: IDSRequestOpts): Observable<any> {
    return this.request(HttpVerbs.OPTIONS, url, options);
  }

  public head(url: string, options: IDSRequestOpts): Observable<any> {
    return this.request(HttpVerbs.HEAD, url, options);
  }

  public patch(url: string, body: any, options: IDSRequestOpts): Observable<any> {
    options.requestOptions = {
      body,
      ...options.requestOptions,
    };
    return this.request(HttpVerbs.PATCH, url, options);
  }
}

@Injectable()
export class DataService extends AbstractDataService {
  private log: Logger;

  constructor(
    private http: HttpClient,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  protected request(method, url, options: IDSRequestOpts): Observable<any> {
    let requestUrl: string = url;
    let requestMethod: string = method;
    const { success$, error$, expectStatus, deserialize, unexpectedStatusCode, ignoredErrorStatuses = [] } = options;
    const defaultErrorCode = options.defaultErrorCode || UCErrorCodes.E500;
    const emitErrors = options.emitErrors == null ? true : options.emitErrors;

    const errorCodesMapping = {
      '400': UCErrorCodes.E400,
      '401': UCErrorCodes.E401,
      '403': UCErrorCodes.E403,
      '404': UCErrorCodes.E404,
      '409': UCErrorCodes.E409,
      '422': UCErrorCodes.E422,
      ...options.errorCodes,
    };

    const requestOpts: IDSRequestOpts['requestOptions'] = this.finalizeRequestOptions(options);

    // Transient model handling
    if (get(requestOpts, 'body.transient') && method === HttpVerbs.PUT) {
      this.log.info('Converted PUT to POST: creating transient model');
      requestMethod = HttpVerbs.POST;
      requestUrl = options.fallbackUrl || url;
    }

    let ignoredError = false;
    return this.http.request(requestMethod, requestUrl, requestOpts).pipe(
      catchError((res: HttpErrorResponse) => {
        const errCode: string = errorCodesMapping[res.status] || defaultErrorCode;
        ignoredError = ignoredErrorStatuses.indexOf(res.status) !== -1;

        let errData: any = res;

        const deserializerMapping = {
          '400': fourHundredDeserializer,
          '422': fourTwentyTwoDeserializer,
        };
        const errDeserializer = deserializerMapping[res.status] || null;
        if (errDeserializer) {
          try {
            errData = errDeserializer(res);
          } catch (e) {
            this.log.error('cant deserialize response', e);
            /*
             Note: this uses the default `errors.generic` code because
             this error is outside of the normal expected errors
             that a user-supplied `defaultErrorCode` is meant to handle

             E.g. if defaultErrorCode is `auth.generic' and the fourTwentyTwoDeserializer throws an error,
             then that's a system-level issue with a malformed error response and not
             an error from authentication
             */
            return observableThrowError(new DSHttpError(res, { data: e }));
          }
        }

        return observableThrowError(new DSHttpError(res, { code: errCode, data: errData }));
      }),
      map((res: HttpResponse<object>): any => {
        if (!res.ok) {
          /*
           The only time this block should be hit is if you use `connection.mockRespond()`
           in a test when the status is not in the 200 range
           For mock responses with 400-500 range responses, use `connection.mockError()`

           If you're reading this, then your test is probably wrong.
           Shame on you, developer error. Shame on you.

           See `src/testing/helpers.ts:setupResponseHandler` for correct usage
           */
          this.log.error(
            'CRITICAL: DataService received error Response in happy-path http response handler',
            res.status,
          );
          return new DSHttpError(res, { code: defaultErrorCode });
        }

        const handleDeserialization = (): any | null => {
          let deserializeBody;
          if (res.status === 204) {
            deserializeBody = null;
          } else if (!res.body) {
            deserializeBody = null;
          } else {
            deserializeBody = res.body;
          }

          if (res.status === 203) {
            // response object is not persisted in the API
            deserializeBody.transient = true;
          }

          return deserialize ? deserialize(deserializeBody, res) : deserializeBody;
        };

        if (expectStatus && res.status !== expectStatus) {
          this.log.error(`Unexpected status: ${res.status} !== ${expectStatus}`);
          return new DSHttpError(res, { code: unexpectedStatusCode });
        }

        let payload;
        try {
          payload = handleDeserialization();
        } catch (err) {
          this.log.error(err);
          return new DSHttpError(res, { code: defaultErrorCode, data: err });
        }
        if (success$) {
          success$.next(payload);
        }
        return payload;
      }),
      catchError((error: DSHttpError) => {
        if (!ignoredError) {
          // Don't display error if it's one we've chosen to explicitly ignore
          this.log.error('Error while making a request or deserializing the response:', error);
        }
        if (error$) {
          error$.next(error);
        }
        if (emitErrors) {
          this.allErrors$.next(error);
        }
        return observableThrowError(error);
      }),
    );
  }
}

/**
 * @deprecated - I think you don't ever need to use the MockDataService.
 * Don't use this in new tests.
 */
export class MockDataService extends AbstractDataService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected request(method, url, options: IDSRequestOpts): any {
    throw new Error('Cannot use DataService directly in tests. To make requests, use DataService with a MockBackend.');
  }
}
