import { Component, OnInit } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss'],
})
export class LoginFooterComponent implements OnInit {
  strings = strings.components.atoms.loginFooter;

  constructor() {}

  ngOnInit() {}
}
