<!-- Sidebar: visible on both mob/desktop. Slides in on mob, fixed sidebar on desktop. -->
<div class="sidebar" [class.show]="showSidebar">
  <button [attr.data-cy]="'sidebar'" class="sidebar-toggle" [class.active]="showSidebar" (click)="toggleNav()">
    <uc-icon iconType="chevron-right" iconWidth="26" iconHeight="26"></uc-icon>
  </button>
  <div class="sidebar-content">
    <div class="sidebar-scroll">
      <ng-content></ng-content>
    </div>
  </div>
</div>
