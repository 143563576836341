<uc-button testSelector="help-modal" class="process-help-button rounded has-icon secondary" (click)="modal.open()">
  <uc-icon iconType="info" iconWidth="21" iconHeight="21"></uc-icon>
  {{ strings.processHelpButtonText }}
</uc-button>

<uc-base-modal #modal>
  <span title>Using myUC</span>

  <div body class="modal-uc-form-template">
    <div class="modal-full-page">
      <div class="modal-form-guide">
        <div class="modal-uc-section">
          <div class="modal-form-section">
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.subheader.header"></h2>
                <div class="modal-section-header-content" [innerHTML]="strings.subheader.description"></div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.navigation.header"></h2>
                <div class="modal-section-header-content">
                  <img
                    class="modal-example-navigation-card"
                    src="assets/img/action-card.png"
                    alt="Example Navigation Card" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.navigation.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.pausing.header"></h2>
                <div class="modal-section-header-content">
                  <img
                    class="modal-save-button"
                    src="assets/img/example-button.png"
                    alt="Example Save and Continue Button" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.pausing.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.timeout.header"></h2>
                <div class="modal-section-header-content">
                  <img class="modal-timeout-clock" src="assets/img/clock.png" alt="60 Minute Timeout Clock" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.timeout.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.validation.header"></h2>
                <div class="modal-section-header-content">
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.validation.description"></div>
                  </div>
                  <img class="modal-example-fields-image" src="assets/img/example-fields.png" alt="Example fields" />
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.mandatoryFields.header"></h2>
                <div class="modal-section-header-content">
                  <img
                    class="modal-mandatory-field-image"
                    src="assets/img/mandatory-field.png"
                    alt="Example mandatory field" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.mandatoryFields.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.processValidation.header"></h2>
                <div class="modal-section-header-content">
                  <img class="modal-process-validation" src="assets/img/validation-hints.png" alt="Validation Hints" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.processValidation.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div body *ngIf="shouldShowStudentHelp()" class="modal-uc-form-template">
    <div class="modal-full-page">
      <div class="modal-form-guide">
        <div class="modal-uc-section">
          <div class="modal-form-section">
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.moreInformation.header"></h2>
                <div class="modal-section-header-content">
                  <img
                    class="modal-more-information"
                    src="assets/img/manage-my-study.png"
                    alt="Link to Manage my study page" />
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.moreInformation.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.withdrawing.header"></h2>
                <div class="modal-section-header-content">
                  <div class="modal-withdraw-images">
                    <div class="withdraw-enrolment-wrapper">
                      <img src="assets/img/withdraw-enrolment1.png" alt="Withdrawing an Enrolment" />
                      <div class="separator__horizontal"></div>
                      <img src="assets/img/withdraw-enrolment2.png" alt="Withdrawing an Enrolment" />
                    </div>
                    <div class="separator"></div>
                    <img
                      class="modal-withdraw-image"
                      src="assets/img/withdraw-application.png"
                      alt="Withdrawing an Application" />
                  </div>
                  <div class="modal-uc-subsection-text-block">
                    <div class="modal-subsection-text-block" [innerHTML]="strings.withdrawing.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-uc-section-header">
              <div class="modal-section-header">
                <h2 class="modal-section-header-title" [innerHTML]="strings.footer.header"></h2>
                <div class="modal-section-header-content">
                  <div [innerHTML]="strings.footer.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button footer class="neutral" (click)="modal.close()">OK</button>
</uc-base-modal>
