<uc-form-header title="{{ strings.title }}">
  <uc-task-hints [task]="task"></uc-task-hints>
</uc-form-header>

<uc-section class="guidance-text-section">
  <uc-subsection>
    <uc-subsection-question>
      {{ strings.guidanceTextStart }}
      <ng-container *ngFor="let qual of filteredQualifications; let i = index">
        <ng-container *ngIf="i !== 0">{{ strings.separator }}</ng-container>
        <uc-qualification-link
          linkText="{{ qual.title }}"
          qualCode="{{ qual.code }}"
          [isCACQual]="true"></uc-qualification-link>
      </ng-container>
      {{ strings.guidanceTextEnd }}
    </uc-subsection-question>
  </uc-subsection>
  <uc-subsection>
    <uc-subsection-question>
      {{ strings.subHeading }}
    </uc-subsection-question>
    <uc-task-hint [task]="task" path="/documents/ADDITIONAL_INFO_CAC"></uc-task-hint>
    <uc-file-upload
      [year]="applicationYear"
      title="{{ strings.title }}"
      categoryCode="ADDITIONAL_INFO_CAC"
      usedInTask="true"
      required="true"></uc-file-upload>
  </uc-subsection>
</uc-section>
