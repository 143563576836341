import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { BackgroundCheck } from '@shared/models/background-check';
import {
  ChangeOfEnrolment,
  ChangeOfEnrolmentCourse,
  ChangeOfEnrolmentAction,
  ChangeOfEnrolmentUpdate,
  ChangeOfEnrolmentSummary,
} from '@shared/models/change-of-enrolment';
import { Condition, ConditionUpdate } from '@shared/models/condition';
import { UCError } from '@shared/models/errors';
import { EnrolledQualification } from '@shared/models/qualification';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { mockData as coeMockData } from './change-of-enrolment.data.mock';
import { AbstractService } from '../application/application.service';
import { CacheManagementService } from '../cache-management/cache-management.service';
import { mockData as conditionsMockData } from '../condition/condition.data.mock';
import { DataService, IDSRequestOpts, DSHttpError, UCErrorCodes } from '../data-service';
import { UserActivityService } from '../user-activity/user-activity.service';

@Injectable()
export class ChangeOfEnrolmentService extends AbstractService {
  private log: Logger;
  protected serviceUrl = `${environment.apiRoot}/change-of-enrolment/`;
  protected staffUrl = `${this.serviceUrl}staff/`;
  public readonly changeOfEnrolment$ = new BehaviorSubject<ChangeOfEnrolment>(null);
  public readonly changeOfEnrolmentStaff$ = new BehaviorSubject<ChangeOfEnrolment>(null);
  protected error$ = new Subject<UCError>();
  public conditions$ = new BehaviorSubject<Condition[]>([]);
  public readonly backgoundChecks$ = new BehaviorSubject<BackgroundCheck[]>([]);

  missingCourses$ = new BehaviorSubject('');
  hasEnrolmentChangeYear: string | null;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get application(): Observable<Application | ChangeOfEnrolment> {
    return this.getChangeOfEnrolment();
  }

  get changeOfEnrolment() {
    return this.changeOfEnrolment$.value;
  }

  get coeError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get enrolmentChange(): Observable<ChangeOfEnrolment> {
    return this.changeOfEnrolment$.asObservable();
  }

  get enrolmentChangeStaff(): Observable<ChangeOfEnrolment> {
    return this.changeOfEnrolmentStaff$.asObservable();
  }

  get staffConditionsForStudent(): Observable<Condition[]> {
    return this.conditions$.asObservable();
  }

  public resetCOEStaff() {
    this.changeOfEnrolmentStaff$.next(null);
  }

  public update(application: Application | ChangeOfEnrolment): Observable<Application | ChangeOfEnrolment> {
    return this.updateChangeOfEnrolment(application as ChangeOfEnrolment);
  }

  getApplicationEnrolment(): Observable<ApplicationEnrolment[] | ChangeOfEnrolment> {
    return this.getChangeOfEnrolment();
  }

  getApplicationForStaff(
    canonicalId: string,
    year: string,
    coeInternalReference: string,
  ): Observable<Application | ChangeOfEnrolment> {
    return this.getChangeOfEnrolmentByInternalReferenceForStaff(canonicalId, coeInternalReference);
  }

  updateCoeForStaff(
    application: ChangeOfEnrolment,
    canonicalId: string,
    coeInternalReference,
  ): Observable<Application> {
    const url = `${this.serviceUrl}staff/${canonicalId}/${coeInternalReference}/`;
    const body = ChangeOfEnrolment.serializeAllForStaff(application);
    return this.dataService.patch(url, body, { emitErrors: false, ignoredErrorStatuses: [422] });
  }

  getConditionsForStaff(canonicalId: string, year: string, coeInternalReference: string): Observable<Condition[]> {
    const url = `${this.staffUrl}${canonicalId}/${coeInternalReference}/conditions`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        ignoredErrorStatuses: [404],
        deserialize: Condition.deserializeAll,
        success$: this.conditions$,
      }),
    );
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  updateConditionForStaff(canonicalId: string, condition: Condition, coeInternalReference?: string) {
    let internalReference;
    if (condition.internalReference && condition.internalReference !== condition.item.style) {
      // eslint-disable-next-line prefer-destructuring
      internalReference = condition.internalReference;
    }
    const baseUrl = `${this.staffUrl}${canonicalId}/${coeInternalReference}/conditions`;
    const url = internalReference ? `${baseUrl}/${internalReference}` : baseUrl;
    const conditionUpdate = ConditionUpdate.createFromCondition(condition);
    const conditionPayload = ConditionUpdate.serialize(conditionUpdate);

    if (internalReference) {
      return this.dataService.put(
        url,
        conditionPayload,
        this.getRequestOptions({
          deserialize: ConditionUpdate.deserialize,
        }),
      );
    } else {
      return this.dataService.post(
        url,
        conditionPayload,
        this.getRequestOptions({
          deserialize: ConditionUpdate.deserialize,
        }),
      );
    }
  }

  getChangeOfEnrolmentByInternalReferenceForStaff(canonicalId, internalReference) {
    const url = `${this.staffUrl}${canonicalId}/${internalReference}`;
    return this.dataService
      .fetch(
        url,
        this.getRequestOptions({
          success$: this.changeOfEnrolmentStaff$,
          ignoredErrorStatuses: [404],
        }),
      )
      .pipe(
        catchError((err: DSHttpError) => {
          if (err.code === UCErrorCodes.E404) {
            this.changeOfEnrolment$.next(null);
            return of(null);
          }
          return throwError(err);
        }),
      );
  }

  getApplicationEnrolmentForStaff(
    canonicalId: string,
    year: string,
    internalReference?: string,
  ): Observable<ApplicationEnrolment[] | ChangeOfEnrolment> {
    return this.getChangeOfEnrolmentByInternalReferenceForStaff(canonicalId, internalReference);
  }

  getChangeOfEnrolmentsForStaff(canonicalId: string): Observable<ChangeOfEnrolmentSummary[]> {
    const url = `${this.serviceUrl}staff/${canonicalId}/list`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        ignoredErrorStatuses: [404],
        deserialize: ChangeOfEnrolmentSummary.deserializeAll,
        success$: null,
      }),
    );
  }

  protected getRequestOptions(options?: Partial<IDSRequestOpts>): IDSRequestOpts {
    return {
      success$: this.changeOfEnrolment$,
      error$: this.error$,
      deserialize: ChangeOfEnrolment.deserialize,
      ...options,
    };
  }

  // eslint-disable-next-line max-lines-per-function
  getChangeOfEnrolment(useCache = false): Observable<ChangeOfEnrolment> {
    if (useCache && this.changeOfEnrolment$.value) {
      return this.enrolmentChange;
    }
    return this.dataService
      .fetch(
        this.serviceUrl,
        this.getRequestOptions({
          ignoredErrorStatuses: [404],
        }),
      )
      .pipe(
        catchError((err: DSHttpError) => {
          if (err.code === UCErrorCodes.E404) {
            this.changeOfEnrolment$.next(null);
            return of(null);
          }
          return throwError(err);
        }),
      );
  }

  updateChangeOfEnrolmentQualification(qualInternalRef: string, changeOfEnrolment: ChangeOfEnrolment) {
    const url = `${this.serviceUrl}qualification/${qualInternalRef}`;
    const body = ChangeOfEnrolment.serialize(changeOfEnrolment);
    return this.dataService.put(url, body, this.getRequestOptions());
  }

  addCOEQualification(changeOfEnrolment: ChangeOfEnrolment) {
    const url = `${this.serviceUrl}qualification/`;
    const body = ChangeOfEnrolment.serialize(changeOfEnrolment);
    return this.dataService.post(url, body, this.getRequestOptions());
  }

  undoCOEQualification(qualInternalRef: string) {
    const url = `${this.serviceUrl}qualification/${qualInternalRef}/undo`;
    return this.dataService.post(url, {}, this.getRequestOptions());
  }

  updateChangeOfEnrolment(changeOfEnrolmentUpdate: ChangeOfEnrolmentUpdate) {
    const body = ChangeOfEnrolment.serialize(changeOfEnrolmentUpdate);
    return this.dataService.put(this.serviceUrl, body, this.getRequestOptions());
  }

  updateChangeOfEnrolmentCourse(qualInternalRef, courseReference, changeAction): Observable<void> {
    const url = `${this.serviceUrl}${qualInternalRef}/${courseReference}`;
    const body = new ChangeOfEnrolmentAction({ changeAction });

    return this.dataService.put(url, body, { error$: this.error$ });
  }

  addChangeOfEnrolmentCourse(qualInternalRef, course: ChangeOfEnrolmentCourse): Observable<ChangeOfEnrolment> {
    const url = `${this.serviceUrl}${qualInternalRef}`;
    const body = ChangeOfEnrolmentCourse.serialize(course);
    return this.dataService.post(url, body, this.getRequestOptions());
  }

  removeChangeOfEnrolmentCourse(qualInternalRef, courseInternalRef): Observable<ChangeOfEnrolment> {
    const url = `${this.serviceUrl}${qualInternalRef}/${courseInternalRef}`;
    return this.dataService.del(url, this.getRequestOptions());
  }

  undoChangeOfEnrolmentCourse(qualInternalRef, courseInternalRef): Observable<void> {
    const url = `${this.serviceUrl}${qualInternalRef}/${courseInternalRef}`;
    return this.dataService.put(url, {}, { error$: this.error$ });
  }

  deleteChangeOfEnrolment(): Observable<null> {
    return this.dataService
      .del(
        this.serviceUrl,
        this.getRequestOptions({
          success$: this.changeOfEnrolment$,
          deserialize: () => null,
        }),
      )
      .pipe(
        tap(() => {
          this.changeOfEnrolment$.next(null);
        }),
      );
  }

  updateQualificationOccurrence(changeOfEnrolment: ChangeOfEnrolment, qualInternalRef: string) {
    const url = `${this.serviceUrl}qualification/${qualInternalRef}/occurrence`;
    const body = ChangeOfEnrolment.serialize(changeOfEnrolment);
    return this.dataService.put(url, body, this.getRequestOptions());
  }

  getChangeOfEnrolmentForStaff(canonicalId: string, year: string): Observable<ChangeOfEnrolment> {
    const url = `${this.staffUrl + canonicalId}/list/${year}`;
    return this.dataService
      .fetch(
        url,
        this.getRequestOptions({
          ignoredErrorStatuses: [404],
        }),
      )
      .pipe(
        catchError((err: DSHttpError) => {
          if (err.code === UCErrorCodes.E404) {
            this.changeOfEnrolment$.next(null);
            return of(null);
          }
          return throwError(err);
        }),
      );
  }

  getActiveChangeOfEnrolmentForStaff(canonicalId: string): Observable<ChangeOfEnrolment> {
    const url = this.staffUrl + canonicalId;
    return this.dataService
      .fetch(
        url,
        this.getRequestOptions({
          success$: this.changeOfEnrolmentStaff$,
          ignoredErrorStatuses: [404],
        }),
      )
      .pipe(
        catchError((err: DSHttpError) => {
          if (err.code === UCErrorCodes.E404) {
            this.changeOfEnrolment$.next(null);
            return of(null);
          }
          return throwError(err);
        }),
      );
  }

  validateChangeOfEnrolment(coe: ChangeOfEnrolment, canonicalId: string, coeInternalReference: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${coeInternalReference}/validate`;
    return this.dataService.post(url, ChangeOfEnrolment.serializeAllForStaff(coe), {
      ignoredErrorStatuses: [422],
      emitErrors: false,
    });
  }

  updateBackgroundChecks(
    backgroundCheck: BackgroundCheck,
    canonicalId: string,
    academicYear: string,
    internalReference?: string,
  ) {
    let url = `${this.serviceUrl}staff/${canonicalId}/${academicYear}/background-checks`;
    const body = { background_checks: BackgroundCheck.serialize(backgroundCheck) };
    if (internalReference) {
      url = `${url}/${internalReference}`;
      return this.dataService.put(
        url,
        body,
        this.getRequestOptions({
          deserialize: BackgroundCheck.deserializeArray,
        }),
      );
    }
    return this.dataService.post(url, body, this.getRequestOptions({ deserialize: BackgroundCheck.deserializeArray }));
  }

  deleteBackgroundChecks(canonicalId: string, academicYear: string, internalReference: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${academicYear}/background-checks/${internalReference}`;
    return this.dataService.del(
      url,
      this.getRequestOptions({
        error$: this.error$,
        deserialize: BackgroundCheck.deserializeArray,
      }),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  updateCOEEnrolledQualification(
    enrolledQualifications: EnrolledQualification[],
    canonicalId: string,
    coeInternalReference: string,
  ): Observable<EnrolledQualification[]> {
    const payload: EnrolledQualification[] = [];
    enrolledQualifications.forEach((eq) => {
      payload.push(EnrolledQualification.serialize(eq));
    });

    const url = `${this.serviceUrl}staff/${canonicalId}/${coeInternalReference}/qual-occurrence/subject-options/`;
    const body = { enrolled_qualifications: payload };

    return this.dataService
      .put(url, body, {
        emitErrors: false,
        ignoredErrorStatuses: [422],
        deserialize: ChangeOfEnrolment.deserialize,
      })
      .pipe(
        map((coe: ChangeOfEnrolment) => {
          return coe.enrolledQualifications;
        }),
      );
  }
}

// Mock classes are mostly stubbed methods, but their method signatures need to stay the same
/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
export class MockChangeOfEnrolmentService extends AbstractService {
  public readonly changeOfEnrolment$ = new BehaviorSubject<ChangeOfEnrolment>(null);
  public readonly changeOfEnrolmentStaff$ = new BehaviorSubject<ChangeOfEnrolment>(null);
  public readonly conditions$ = new BehaviorSubject<Condition[]>([]);
  protected error$ = new Subject<UCError>();

  constructor(
    private mockCoE = ChangeOfEnrolment.deserialize(coeMockData()),
    private mockCoESummary = ChangeOfEnrolmentSummary.deserialize(coeMockData()),
  ) {
    super();
  }

  get enrolmentChangeStaff() {
    return this.getChangeOfEnrolment();
  }

  get application(): Observable<Application | ChangeOfEnrolment> {
    return of(this.mockCoE);
  }

  get coeError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get enrolmentChange(): Observable<ChangeOfEnrolment> {
    return this.changeOfEnrolment$.asObservable();
  }

  get staffConditionsForStudent() {
    return this.conditions$.asObservable();
  }

  public resetCOEStaff() {
    this.changeOfEnrolmentStaff$.next(null);
  }

  getApplicationEnrolment(): Observable<ChangeOfEnrolment | ApplicationEnrolment[]> {
    return this.getChangeOfEnrolment();
  }

  getApplicationForStaff(
    canonicalId: string,
    year: string,
    coeInternalReference: string,
  ): Observable<Application | ChangeOfEnrolment> {
    return this.getChangeOfEnrolmentByInternalReferenceForStaff(canonicalId, coeInternalReference);
  }

  getApplicationEnrolmentForStaff(
    canonicalId: string,
    year: string,
  ): Observable<ApplicationEnrolment[] | ChangeOfEnrolment> {
    return this.getActiveChangeOfEnrolmentForStaff(canonicalId);
  }

  getConditionsForStaff(canonicalId: string, year: string, coeInternalReference: string) {
    const conditions = Condition.deserializeAll(conditionsMockData());
    this.conditions$.next(conditions);
    return of(conditions);
  }

  updateCoeForStaff(
    application: ChangeOfEnrolment,
    canonicalId: string,
    coeInternalReference?: string,
  ): Observable<Application | ChangeOfEnrolment> {
    return of(null);
  }

  updateCOEEnrolledQualification(
    enrolledQualifications: EnrolledQualification[],
    canonicalId: string,
    coeInternalReference: string,
  ): Observable<EnrolledQualification[]> {
    return of([]);
  }

  updateConditionForStaff(canonicalId, conditionUpdate, coeInternalReference) {
    return of(new Condition({ style: { code: 'decline_qa' }, state: { code: 'pending' } }));
  }

  getChangeOfEnrolment(useCache = false): Observable<ChangeOfEnrolment> {
    if (useCache && this.changeOfEnrolment$.value) {
      return this.enrolmentChange;
    }
    // this.changeOfEnrolment$.next(this.mockCoE);
    // return of(this.mockCoE);
    return of(null);
  }

  public update(application: Application | ChangeOfEnrolment): Observable<Application | ChangeOfEnrolment> {
    return of(this.mockCoE);
  }
  updateChangeOfEnrolment(application: ChangeOfEnrolment): Observable<ChangeOfEnrolment> {
    return of(this.mockCoE);
  }

  updateChangeOfEnrolmentQualification(qualInternalRef: string, coe: ChangeOfEnrolment): Observable<ChangeOfEnrolment> {
    return of(this.mockCoE);
  }

  updateChangeOfEnrolmentCourse(): Observable<unknown> {
    return of({});
  }

  addChangeOfEnrolmentCourse(qualInternalRef): Observable<unknown> {
    return of({});
  }

  removeChangeOfEnrolmentCourse(qualInternalRef, courseInternalRef): Observable<unknown> {
    return of({});
  }

  undoChangeOfEnrolmentCourse(qualInternalRef, courseInternalRef): Observable<unknown> {
    return of({});
  }

  deleteChangeOfEnrolment(): Observable<unknown> {
    this.changeOfEnrolment$.next(null);
    return of({});
  }

  addCOEQualification(coe: ChangeOfEnrolment): Observable<ChangeOfEnrolment> {
    return of(this.mockCoE);
  }

  undoCOEQualification(qualInternalRef: string): Observable<ChangeOfEnrolment> {
    return of(this.mockCoE);
  }
  updateQualificationOccurrence(changeOfEnrolment: ChangeOfEnrolment, qualInternalRef: string) {
    return of(this.mockCoE);
  }

  getChangeOfEnrolmentForStaff(canonicalId: string, year: string) {
    return of(this.mockCoE);
  }

  getActiveChangeOfEnrolmentForStaff(canonicalId: string) {
    return of(this.mockCoE);
  }

  getChangeOfEnrolmentByInternalReferenceForStaff(cid, internalRef) {
    return of(this.mockCoE);
  }

  validateChangeOfEnrolment(coe, cid, internalRef) {
    return of(null);
  }

  getChangeOfEnrolmentsForStaff() {
    return of([this.mockCoESummary]);
  }

  updateBackgroundChecks(
    changeOfEnrolment: ChangeOfEnrolment,
    canonicalId: string,
    academicYear,
    internalReference: string,
  ) {
    return of(this.mockCoE);
  }

  deleteBackgroundChecks(canonicalId: string, academicYear, coeInternalReference: string) {
    return of(this.mockCoE);
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars,class-methods-use-this */

export const changeOfEnrolmentServiceFactory = (dataService, loggingService) => {
  if (environment.useFakeBackend.changeOfEnrolment) {
    return new MockChangeOfEnrolmentService();
  } else {
    return new ChangeOfEnrolmentService(dataService, loggingService);
  }
};

export const changeOfEnrolmentServiceProvider = {
  provide: ChangeOfEnrolmentService,
  useFactory: changeOfEnrolmentServiceFactory,
  deps: [DataService, LoggingService, CacheManagementService, UserActivityService],
};
