import { Component, OnInit, Renderer2 } from '@angular/core';
import { captureException, showReportDialog } from '@sentry/angular';
import { get } from 'lodash-es';
import { filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { User } from '@shared/models/user';
import { DataService } from '@shared/services/data-service';
import { UserService } from '@shared/services/user/user.service';

declare global {
  interface Window {
    sentryEmbedCallback: (data: { element: HTMLElement }) => void;
  }
}

interface CrashOverlayStrings {
  title?: string;
  body?: string;
  buttonText?: string;
  imageKey?: string;
  imageUrl?: string;
}

enum ImageMap {
  sadRobot = 'assets/img/sad-robot.png',
}

@Component({
  selector: 'uc-crash-overlay',
  templateUrl: './crash-overlay.component.html',
  styleUrls: ['./crash-overlay.component.scss'],
})
export class CrashOverlayComponent implements OnInit {
  showOverlay = false;
  overlayStrings = strings.components.atoms.crashOverlay;
  iconType: string;
  eventId: string;
  showFeedbackButton = false;
  private _strings = { ...this.overlayStrings.error };
  private user: User;

  constructor(
    private dataService: DataService,
    private userService: UserService,
    private renderer: Renderer2,
  ) {}

  get strings(): CrashOverlayStrings {
    return this._strings;
  }

  set strings(stringObject: CrashOverlayStrings) {
    Object.assign(this._strings, stringObject);
    if (!this.iconType) {
      this._strings.imageUrl = ImageMap[stringObject.imageKey] || ImageMap.sadRobot;
    }
  }

  buttonAction = () => window.location.reload();

  ngOnInit() {
    this.userService.currentUser.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.errorListener();
    this.outageListener();
    this.loggedUserListener();
  }

  beepBoopToSentry(err): string {
    return captureException(`Beep boop :( overlay triggered: ${err.code}`);
  }

  errorListener() {
    const duplicateUser = (err) => err.code.match('auth.fourOhNine') && get(err, 'data.status') === 409;
    this.dataService.allErrors
      .pipe(
        filter((error) => {
          return (
            !error.code.match(/noCrash/) &&
            (!!error.code.match(/(generic)/) || get(error, 'data.status') === 500 || duplicateUser(error))
          );
        }),
      )
      .subscribe((error) => {
        switch (error.code) {
          case 'auth.fourOhNine':
            this.strings = { body: strings.errors.auth.fourOhNine, title: strings.errors.auth.fourOhNineTitle };
            break;
          case 'errors.hydrateFailed':
            this.strings = strings.errors.hydrateFailed;
            break;
          default:
            this.strings = this.overlayStrings.error;
            break;
        }

        this.eventId = this.beepBoopToSentry(error);
        if (this.eventId) {
          this.showFeedbackButton = true;
        }
        this.show();
      });
  }

  outageListener() {
    this.userService.outageNotification.subscribe((notification) => {
      if (notification) {
        const message = notification.message ? `<p>${notification.message}</p>` : this.overlayStrings.outage.message;
        this.strings = Object.assign(this.overlayStrings.outage, { body: message });
        this.iconType = 'clock';
        this.buttonAction = () => this.hide();
        this.show();
      } else {
        this.hide();
      }
    });
  }

  loggedUserListener() {
    this.userService.loggedUserCheck.subscribe((mismatch: boolean) => {
      if (mismatch) {
        this.strings = this.overlayStrings.loggedUserCheck;
        this.show();
      } else {
        this.hide();
      }
    });
  }

  openFeedbackModal() {
    const email = get(this.user, 'email', '');
    const name = `${get(this.user, 'firstName', '')} ${get(this.user, 'lastName', '')}`;
    window.sentryEmbedCallback = (data) => {
      const element = get(data, 'element') as HTMLElement;
      if (!element) {
        return;
      }
      element.classList.add('sentry-custom-styles');

      const target = element.getElementsByClassName('form-submit')[0] as HTMLElement;

      const observer = new MutationObserver((mutationsList, mutationObserver) => {
        mutationsList.forEach((mutation) => {
          const removedNode = mutation.removedNodes[0] as HTMLElement;
          const removedNodeIsButton = removedNode && removedNode.classList.contains('btn');
          if (removedNodeIsButton) {
            const closeButton = target.getElementsByClassName('close')[0] as HTMLElement;
            if (closeButton) {
              closeButton.innerHTML = this.overlayStrings.closeAndReload;
              this.showFeedbackButton = false;

              closeButton.addEventListener('click', () => {
                mutationObserver.disconnect();
                this.hide();
                window.location.reload();
              });
            }
          }
        });
      });

      const config = { attributes: true, childList: true, subtree: true };

      if (target) {
        observer.observe(target, config);
      }
    };

    showReportDialog({
      eventId: this.eventId,
      successMessage: 'Submit and refresh the page',
      user: { email, name },
    });
  }

  show() {
    this.renderer.addClass(document.body, 'no-scroll');
    this.showOverlay = true;
  }

  hide() {
    this.renderer.removeClass(document.body, 'no-scroll');
    this.showOverlay = false;
  }
}
