<uc-section>
  <uc-form-template [formGroup]="filterForm">
    <h3 class="uc-header">{{ strings.filterFields.title }}</h3>
    <div class="filter-controls">
      <div class="filter" *ngFor="let filter of loadedFilters | keyvalue: keepOriginalOrder">
        <uc-reference-data-selector
          [labelName]="strings.filterFields[filter.key].label"
          class="filter-selector"
          [options]="filter.value.loadedValues.value"
          [formControlName]="filter.key"
          [selectPlaceHolder]="filter.value.placeholder.value"
          [useNgSelect]="filter.value.ngSelect"
          [ngSelectAttributes]="{ placeholder: true }"
          [showBlankOption]="false"
          [noIcon]="true"></uc-reference-data-selector>
      </div>
    </div>
    <div class="filter-actions">
      <uc-button (click)="onApplyFilter()" type="button">
        {{ strings.filterActions.apply.label }}
      </uc-button>
      <a (click)="onResetFilter()" class="filter-reset-link">{{ strings.filterActions.reset.label }}</a>
    </div>
  </uc-form-template>
</uc-section>
<hr />
<div class="download-buttons-area">
  <div class="download-buttons" *ngIf="download">
    <div class="data-summary">
      <h3 *ngIf="!filtered.value">All applications</h3>
      <p class="record-count">{{ rowCount$.value }} record{{ rowCount$.value === 1 ? '' : 's' }} found</p>
    </div>
    <uc-download-button
      class="download-all-button"
      [label]="strings.downloadAllButton.label"
      [data]="unfilteredRows$"
      [columns]="columnsForDownloadAll()"
      [disabled]="inDownload"></uc-download-button>
    <uc-download-button
      class="download-current-button"
      [label]="strings.downloadButton.label"
      [data]="showRows$ | async"
      [columns]="columnsForDownloadCurrent()"
      [disabled]="inDownload"></uc-download-button>
  </div>
  <div class="downloading-warning" *ngIf="applicantDownloadsRemaining > 0">
    <i>
      Downloading {{ applicantDownloadsRemaining }} applicants. Some features may not be availabile until all applicants
      have finished loading.
    </i>
  </div>
</div>
<uc-section class="learners-table">
  <div class="gutter">
    <ng-container *ngIf="!tableLoaded">
      <div class="skeleton-row">
        <div class="skeleton-header" *ngFor="let c of shadowFlexWidths; let i = index" [style]="'flex: ' + c">
          {{ columns[i].label }}
        </div>
      </div>
      <div class="skeleton-row" *ngFor="let _ of [].constructor(10)">
        <div class="skeleton" *ngFor="let c of shadowFlexWidths" [style]="'flex: ' + c"></div>
      </div>
    </ng-container>
    <ngx-datatable
      *ngIf="showRows$ | async as rows"
      id="learners-table"
      [rows]="rows"
      class="material"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [limit]="limit"
      [offset]="offset"
      (page)="onPage($event)"
      [scrollbarH]="true"
      [trackByProp]="keyColumn"
      #loadedTable>
      <ngx-datatable-column
        *ngFor="let col of getShowColumns(rows); let i = index"
        [name]="col.label"
        [prop]="col.prop"
        [maxWidth]="col.maxWidth"
        [minWidth]="col.minWidth"
        [sortable]="col.sortable"
        [class.hidden-header]="col.isLink"
        [class.boolean-column]="col.isBoolean">
        <ng-template let-value="value" let-row="row" let-column="column" ngx-datatable-cell-template>
          <ng-container [ngSwitch]="col.format">
            <ng-container *ngSwitchCase="'date'">
              {{ value | date: 'yyyy-MM-dd HH:mm' }}
            </ng-container>
            <ng-container *ngSwitchCase="'yesNo'">
              {{ value ? 'Yes' : 'No' }}
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
              <div>
                <a href="{{ linkUrl(col, row) }}">{{ col.label }}</a>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div
                [class]="[col.prop, col.style].join(' ')"
                [ngClass]="{ 'waiting-for-value': value === loadingIndicator }">
                {{ col.applyFormat(value) }}
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</uc-section>
