<uc-subsection-question>{{ strings.title }}</uc-subsection-question>
<p [innerHTML]="strings.bodyText"></p>
<div [formGroup]="pronounsGroup">
  <uc-form-input>
    <uc-reference-data-selector type="pronouns" formControlName="pronouns"></uc-reference-data-selector>
  </uc-form-input>
  <uc-string-input
    *ngIf="shouldCustomPronounsFieldDisplay"
    [maxLength]="200"
    class="input-medium"
    label="{{ strings.customOption }}"
    formControlName="customPronouns"></uc-string-input>
</div>
