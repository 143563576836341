<!-- Mobile sidebar/stages summary: -->
<div class="stages-summary">
  <button [attr.data-cy]="'side-nav'" class="summary-panel-btn" (click)="toggleNav()">
    <uc-icon iconType="chevron-right" iconWidth="26" iconHeight="26"></uc-icon>
  </button>
  <ul #stepsNav class="stages-summary-list">
    <li
      *ngFor="let stage of process.stages; let stageNumber = index"
      routerLink="{{ stage.path }}"
      routerLinkActive
      #rla="routerLinkActive">
      <div class="stage-summary-item" [class.active]="rla.isActive" (click)="preventNavigation($event)">
        <div class="stage-summary-num">{{ stageNumber + 1 }}</div>
        <uc-task
          [attr.data-cy]="'task-' + task.code"
          class="stage-summary-task"
          [task]="task"
          routerLink="{{ task.path }}"
          routerLinkActive="active-card"
          *ngFor="let task of stage.tasks"></uc-task>
      </div>
    </li>
    <!-- TODO: make final step a dynamic element and style the unlocked view -->
    <li class="stage-summary-item last-step">
      <div class="stage-summary-num">
        <uc-icon iconType="flag" iconWidth="8" iconHeight="8"></uc-icon>
      </div>
    </li>
  </ul>
</div>

<!-- Sidebar: visible on both mob/desktop. Slides in on mob, fixed sidebar on desktop. -->
<div class="stages-sidebar" [class.show]="showSidebar">
  <nav class="stages-sidebar-content">
    <button [attr.data-cy]="'side-nav-toggle'" class="summary-panel-btn" (click)="toggleNav()">
      <uc-icon iconType="chevron-left" iconWidth="26" iconHeight="26"></uc-icon>
    </button>
    <ul #stepsNav class="stages-list">
      <li
        *ngFor="let stage of process.stages; let stageNumber = index"
        routerLink="{{ stage.path }}"
        routerLinkActive
        #rla="routerLinkActive">
        <div
          [class.active]="rla.isActive"
          [class.clickable]="isImpersonating && stageNumber + 1 <= firstIncompleteStageNumber"
          class="stage-item clearfix"
          (click)="setStageNumber(stageNumber + 1); preventNavigation($event)">
          <div class="stage-num">{{ stageNumber + 1 }}</div>
          <div class="stage-content">
            <h3 class="stage-heading">{{ stage.title }}</h3>
            <uc-task
              [attr.data-cy]="'task-' + task.code"
              [task]="task"
              *ngFor="let task of stage.tasks"
              (triggerClosePanel)="onTaskCardClick()"></uc-task>
          </div>
        </div>
      </li>
      <li class="stage-item last-step">
        <div class="stage-num">
          <uc-icon iconType="flag" iconWidth="14" iconHeight="14"></uc-icon>
        </div>
      </li>
    </ul>
  </nav>
</div>
