<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-icon
  [routerLink]="internalUrls.manageMyStudy.join('/')"
  class="back-link"
  iconFill="$colorDustyGrey"
  iconWidth="24"
  iconHeight="24"
  iconType="close"></uc-icon>
<div id="main-content" tabindex="-1" class="splash-content">
  <h3 class="splash-heading accent-font">{{ strings.header }}</h3>
  <uc-spinner *ngIf="loading" [useStaticPositioning]="true"></uc-spinner>
  <uc-responsive-grid [breakpoints]="breakpoints">
    <mat-grid-list cols="12" rowHeight="260px" #grid>
      <mat-grid-tile *ngFor="let process of processes; let i = index" [colspan]="4" rowspan="1">
        <uc-tile
          [attr.data-cy]="'tile-' + process.code"
          #tile
          class="splash-tile subtle"
          [tileLink]="process.link"
          target="_self">
          <h2 class="tile-heading body-font">{{ process.title }}</h2>
          <div class="tile-description" innerHTML="{{ process.description }}"></div>
          <uc-spinner *ngIf="tile.displaySpinner"></uc-spinner>
        </uc-tile>
      </mat-grid-tile>
    </mat-grid-list>
  </uc-responsive-grid>
</div>
