import { Component, OnInit } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-student-loan',
  templateUrl: './student-loan.component.html',
  styles: [
    `
      p {
        line-height: 1.4;
        margin-bottom: 15px;
      }
    `,
  ],
})
export class StudentLoanComponent implements OnInit {
  strings = strings.components.molecules.studentLoan;

  constructor() {}

  ngOnInit() {}
}
