<uc-form-template>
  <uc-form-header [title]="task?.title"></uc-form-header>
  <uc-message-banner *ngIf="stageComplete" type="info">
    <span [innerHTML]="messageBannerText"></span>
  </uc-message-banner>

  <uc-application-hints *ngIf="!stageComplete" [stage]="stage" [task]="task"></uc-application-hints>

  <div class="info-text" [innerHTML]="infoTextBody"></div>

  <uc-section *ngIf="showStarRating" [formGroup]="ratingForm">
    <uc-section-header [title]="strings.rateExperienceTitle">
      <h2 class="section-header-title"></h2>
      <div class="section-header-content">
        <p>
          <strong>{{ strings.rateExperience }}</strong>
        </p>
      </div>
      <uc-star-rating formControlName="npsRating" [max]="5"></uc-star-rating>
    </uc-section-header>
  </uc-section>
</uc-form-template>
