<uc-form-template [formGroup]="onlineStudyHistoryPage">
  <uc-form-header [title]="task?.title">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-online-secondary-education
    highestStudyLevel="SECONDARY"
    [applicationYear]="applicationYear"
    [secondaryEducationForm]="onlineStudyHistoryPage.get('secondaryEducation')"
    [secondaryLocationForm]="secondaryLocationForm"
    (addQual)="addSecondaryQual()"
    (removeQual)="removeSecondaryQual($event)"
    [task]="task"></uc-online-secondary-education>

  <uc-online-more-about-your-studies
    [task]="task"
    [maysForm]="onlineStudyHistoryPage.get('moreAboutYourStudies')"
    [year]="applicationYear"
    [isFirstYear]="isFirstYear"></uc-online-more-about-your-studies>
</uc-form-template>
