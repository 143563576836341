import { Injectable } from '@angular/core';
import { NEVER, of, Observable, Subject, BehaviorSubject, switchMap, map, merge } from 'rxjs';

import { environment } from '@environment';
import { QualificationAnswer } from '@shared/applicant/qualification/model';
import { INDEPENDENT_ENROLMENT_STATES } from '@shared/constants/states.constants';
import { snakeifyKeys } from '@shared/helpers/serialization';
import { mockAwardEnrolments } from '@shared/mocks/mock-award-enrolments';
import {
  Enrolment,
  ENROLMENT_SERVICE_PATH,
  ContinuingEnrolledQualification,
  IndependentCourseEnrolmentList,
  IndependentCourseEnrolment,
} from '@shared/models/enrolment';
import { UCError } from '@shared/models/errors';
import { DataService, IDSRequestOpts } from '@shared/services/data-service';
import { mockData as mockEnrolmentResponse } from '@shared/services/enrolment/enrolment.data.mock';
import { mockData as mockEnrolmentsResponse } from '@shared/services/enrolment/enrolments.data.mock';
import { mockData as mockIndependentEnrolmentsGetResponse } from '@shared/services/enrolment/independent-enrolment-get.data.mock';
import { mockData as mockIndependentEnrolmentPostResponse } from '@shared/services/enrolment/independent-enrolment-post.data.mock';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

/**
 * The Combined IE Course Enrolment is a flattened union of the IndependentCourseEnrolmentList and its enrolledCourses array property.
 *
 * It is mainly used to create the dataset for the UCOnline Pipeline.
 *
 * It also contains fields sourced from the Applicant model and flattens paymentState and State propertied of
 * the Independent Course Enrolment so that they do not need to be referred to as paymentState.code and state.code.
 */
export type CombinedIECourseEnrolment = IndependentCourseEnrolmentList &
  (Omit<IndependentCourseEnrolment, 'internalReference'> &
    Omit<IndependentCourseEnrolment, 'paymentState'> &
    Omit<IndependentCourseEnrolment, 'state'> & {
      internalReferenceOfCourseEnrolment: IndependentCourseEnrolment['internalReference'];
      paymentState: string;
      state: string;
      name: string;
      preferredContactMethod: string;
      email: string;
      mobile: string;
      vCitizen: boolean;
      vDob: boolean;
      vLegal: boolean;
      vAddress: boolean;
      emails: boolean;
      manageStudentUrl: string;
    });

interface GetEnrolmentsForYearOptions {
  includeActions?: boolean;
  includeWithdrawn?: boolean;
  includeCoe?: boolean;
}

@Injectable()
export class EnrolmentService {
  protected serviceUrl: string = environment.apiRoot + ENROLMENT_SERVICE_PATH;
  protected staffUrl = `${environment.apiRoot + ENROLMENT_SERVICE_PATH}/staff`;
  private error$ = new Subject<UCError>();
  private activeQualification$ = new BehaviorSubject<QualificationAnswer>(null);
  private continuingEnrolments$ = new BehaviorSubject<ContinuingEnrolledQualification[]>(null);
  private independentCourseEnrolmentList$ = new BehaviorSubject<IndependentCourseEnrolmentList>(null);
  private independentCourseEnrolmentLists$ = new BehaviorSubject<IndependentCourseEnrolmentList[]>([]);
  public independentCourseEnrolment$ = new BehaviorSubject<IndependentCourseEnrolment>(null);
  protected log: Logger;

  constructor(
    protected dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get activeQualification(): Observable<QualificationAnswer> {
    return this.activeQualification$.asObservable();
  }

  get continuingEnrolments(): Observable<ContinuingEnrolledQualification[]> {
    return this.continuingEnrolments$.asObservable();
  }

  // eslint-disable-next-line class-methods-use-this
  get defaultGetEnrolmentsForYearOptions(): GetEnrolmentsForYearOptions {
    return {
      includeActions: false,
      includeWithdrawn: false,
      includeCoe: false,
    };
  }

  get independentEnrolments(): Observable<IndependentCourseEnrolmentList> {
    return this.independentCourseEnrolmentList$.asObservable();
  }

  get independentCourseEnrolment(): IndependentCourseEnrolment {
    return this.independentCourseEnrolment$.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getRequestOptions(options?: { [key: string]: any }): IDSRequestOpts {
    return {
      error$: this.error$,
      deserialize: Enrolment.deserialize,
      ...options,
    };
  }

  getEnrolmentByPriority(year: string, priority: number): Observable<Enrolment> {
    const url = `${this.serviceUrl}/${year}/${priority}`;
    return this.dataService.fetch(url, this.getRequestOptions({ deserialize: Enrolment.deserialize }));
  }

  getEnrolment(year: string): Observable<Enrolment> {
    const url = `${this.serviceUrl}/${year}/`;
    return this.dataService.fetch(url, this.getRequestOptions({ deserialize: Enrolment.deserialize }));
  }

  getContinuingEnrolments(): Observable<ContinuingEnrolledQualification[]> {
    const url = `${this.serviceUrl}/enrolled-qualifications/continuing`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.continuingEnrolments$,
        deserialize: ContinuingEnrolledQualification.deserialize,
      }),
    );
  }

  withdrawEnrolment(year: string, enrolPriority: number): Observable<Enrolment> {
    const url = `${this.serviceUrl}/${year}/${enrolPriority}`;
    return this.dataService.del(
      url,
      this.getRequestOptions({
        deserialize: Enrolment.deserialize,
        errorCodes: {
          '500': 'noCrash.generic',
        },
        defaultErrorCode: 'noCrash.generic',
      }),
    );
  }

  getEnrolmentsForStaff(canonicalId: string): Observable<Enrolment[]> {
    const url = `${this.staffUrl}/${canonicalId}/`;
    return this.dataService.fetch(url, this.getRequestOptions({ deserialize: Enrolment.deserializeAll }));
  }

  getIndependentEnrolments(academicYear: string): Observable<IndependentCourseEnrolmentList> {
    const url = `${this.serviceUrl}/independent_enrolments/${academicYear}`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.independentCourseEnrolmentList$,
        deserialize: IndependentCourseEnrolmentList.deserializeAll,
      }),
    );
  }

  getIndependentEnrolmentLists(
    academicYear: string | number,
    canonicalId: string = null,
  ): Observable<IndependentCourseEnrolmentList[]> {
    if (!academicYear || Number(academicYear) < 2023) {
      return of([] as IndependentCourseEnrolmentList[]);
    }

    let url = `${this.staffUrl}/${academicYear}/independent_enrolments`;

    if (!!canonicalId) {
      url += `?canonical_id=${canonicalId}`;
    }

    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.independentCourseEnrolmentLists$,
        deserialize: (obj) =>
          obj.independent_enrolment_list_records.map((o) => IndependentCourseEnrolmentList.deserializeAll(o)),
      }),
    );
  }

  getIndependentEnrolment(
    academicYear: string,
    courseCode: string,
    occurrence: string,
    expectCheckoutUrl: boolean = false,
    isRefresh: boolean = false,
  ): Observable<IndependentCourseEnrolment> {
    return of(true).pipe(
      switchMap(async () => {
        const enrolmentList = this.independentCourseEnrolmentList$.value;
        const enrolmentData = this.findByCourseCodeAndOccurrence(enrolmentList, courseCode, occurrence);
        if (this.shouldFindWithLatestIndependentEnrolments(enrolmentData, expectCheckoutUrl, isRefresh)) {
          return await this.findLatestEnrolmentData(academicYear, courseCode, occurrence);
        } else {
          return enrolmentData;
        }
      }),
    );
  }

  private async findLatestEnrolmentData(academicYear, courseCode, occurrence) {
    try {
      const independentEnrolmentsResponse = await this.getIndependentEnrolments(academicYear).toPromise();
      return this.findByCourseCodeAndOccurrence(independentEnrolmentsResponse, courseCode, occurrence);
    } catch (e) {
      this.log.error(e);
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private findByCourseCodeAndOccurrence(independentCourseEnrolmentList, courseCode, occurrence) {
    const enrolmentData = independentCourseEnrolmentList?.enrolledCourses.find(
      (record) => record.courseCode === courseCode && record.occurrence === occurrence,
    );
    return enrolmentData ? enrolmentData : null;
  }

  // eslint-disable-next-line class-methods-use-this
  private shouldFindWithLatestIndependentEnrolments(enrolmentData, expectCheckoutUrl, isRefresh) {
    return (
      !EnrolmentService.isEnrolmentDataExist(enrolmentData) ||
      EnrolmentService.isExpectCheckoutUrl(expectCheckoutUrl, enrolmentData) ||
      isRefresh
    );
  }

  static isEnrolmentDataExist(enrolmentData) {
    return enrolmentData !== null && enrolmentData !== undefined;
  }

  static isExpectCheckoutUrl(expectCheckoutUrl, enrolmentData) {
    return expectCheckoutUrl && (enrolmentData?.checkoutUrl === undefined || enrolmentData?.checkoutUrl === null);
  }

  getIndependentEnrolmentsByStatus(academicYear: string, status: string): Observable<IndependentCourseEnrolment[]> {
    return of(true).pipe(
      switchMap(async () => {
        const independentCourseEnrolmentList = this.independentCourseEnrolmentList$.value;
        if (independentCourseEnrolmentList === null) {
          try {
            const response = await this.getIndependentEnrolments(academicYear).toPromise();
            return this.filterIndependentEnrolmentByStatus(response, status);
          } catch (e) {
            this.log.error(e);
            return [];
          }
        } else {
          return this.filterIndependentEnrolmentByStatus(independentCourseEnrolmentList, status);
        }
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  private filterIndependentEnrolmentByStatus(independentCourseEnrolmentList, status) {
    return independentCourseEnrolmentList.enrolledCourses.filter((courseEnrolment) => {
      if (courseEnrolment.state.code === status) {
        return courseEnrolment;
      }
    });
  }

  getIndependentCourseEnrolmentStatus(
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<string> {
    return this.getIndependentEnrolment(academicYear, courseCode, occurrence).pipe(
      map((enrolment: IndependentCourseEnrolment) => (enrolment !== null ? enrolment.state.code : '')),
    );
  }

  getIndependentCoursePaymentStatus(academicYear: string, courseCode: string, occurrence: string): Observable<string> {
    return this.getIndependentEnrolment(academicYear, courseCode, occurrence).pipe(
      map((enrolment: IndependentCourseEnrolment) => (enrolment !== null ? enrolment.paymentState.code : '')),
    );
  }

  submitIndependentEnrolment(
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<IndependentCourseEnrolment> {
    const url = `${this.serviceUrl}/independent_enrolments/${academicYear}`;
    return this.dataService.post(url, snakeifyKeys({ courseCode, occurrence }), {
      success$: this.independentCourseEnrolment$,
      error$: this.error$,
      deserialize: IndependentCourseEnrolment.deserializePost,
    });
  }

  getAllEnrolmentList(existingApplicationYears: string[]): Observable<IndependentCourseEnrolment[]> {
    const existAppicationRequests = existingApplicationYears.map((year) => {
      return this.getIndependentEnrolmentsByStatus(year, INDEPENDENT_ENROLMENT_STATES.ENROLMENT_STATE_ENROLLED);
    });

    if (existAppicationRequests && existAppicationRequests.length) {
      return merge(...existAppicationRequests);
    } else {
      return of([]);
    }
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
@Injectable()
export class MockEnrolmentService {
  public error$ = new Subject<UCError>();
  public enrolment$ = new BehaviorSubject<Enrolment>(null);

  public mockAwardEnrolments;

  public mockEnrolment: Enrolment;

  public mockEnrolmentData: { [key: string]: Enrolment } = {
    '2020': new Enrolment({
      priority: 1,
      enroledQualification: [
        new QualificationAnswer({
          code: 'MTCHGLN',
          subjectOptions: { '1': [{ code: 'Primary' }] },
          enroledCourses: [{ courseOccurrenceCode: 'COSC101_17S1 (C)' }],
          priority: 1,
          qualificationOccurrence: 'foo',
          newInAward: true,
        }),
        new QualificationAnswer({
          code: 'BA',
          subjectOptions: { '1': [{ code: 'Primary' }] },
          enroledCourses: [{ courseOccurrenceCode: 'COSC101_17S1 (C)' }, { courseOccurrenceCode: 'HIST101_17S1 (C)' }],
          priority: 2,
          newInAward: true,
        }),
      ],
      studentProvidedExemptionReason: 'some reason',
    }),
    '2017': new Enrolment({
      priority: 1,
      enroledQualification: [
        new QualificationAnswer({
          code: 'MTCHGLN',
          subjectOptions: { '1': [{ code: 'Primary' }] },
          enroledCourses: [{ courseOccurrenceCode: 'COSC101_17S1 (C)' }],
          qualificationOccurrence: 'foo',
        }),
      ],
    }),
    '2016': new Enrolment({
      priority: 1,
      enroledQualification: [
        new QualificationAnswer({
          code: 'MTCHGLN',
          subjectOptions: { '1': [{ code: 'Primary' }] },
          enroledCourses: [{ courseOccurrenceCode: 'COSC101_17S1 (C)' }],
        }),
        new QualificationAnswer({
          code: 'BA',
          subjectOptions: { '1': [{ code: 'Art and History' }] },
          enroledCourses: [{ courseOccurrenceCode: 'COSC101_17S1 (C)' }],
        }),
      ],
    }),
  };

  mockEnrolmentChange = null;

  constructor(mockEnrolmentData?, mockAEs?) {
    this.mockEnrolment = Enrolment.deserialize(mockEnrolmentResponse());
    if (mockEnrolmentData) {
      this.mockEnrolmentData = mockEnrolmentData;
    }
    if (mockAEs) {
      this.mockAwardEnrolments = mockAEs;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get defaultGetEnrolmentsForYearOptions(): GetEnrolmentsForYearOptions {
    return {
      includeActions: false,
      includeWithdrawn: false,
      includeCoe: false,
    };
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  // eslint-disable-next-line class-methods-use-this
  private createEmptyMockEnrolment(priority: number): Enrolment {
    return Enrolment.deserialize({
      priority,
    }) as Enrolment;
  }

  updateOrCreateEnrolment(year: string, enrolments: Enrolment): Observable<Enrolment> {
    this.mockEnrolmentData[year] = enrolments;
    return of(this.mockEnrolmentData[year]);
  }

  getEnrolmentByPriority(year: string, priority: number): Observable<Enrolment> {
    const enrolment = this.mockEnrolmentData[year][priority - 1];
    if (!enrolment) {
      return NEVER;
    } else {
      return of(enrolment);
    }
  }

  getEnrolment(year: string): Observable<Enrolment> {
    const enrolment = this.mockEnrolment as Enrolment;
    if (!enrolment) {
      return of(null);
    } else {
      return of(enrolment);
    }
  }

  getContinuingEnrolments(): Observable<ContinuingEnrolledQualification[]> {
    const awardEnrolments: ContinuingEnrolledQualification[] =
      this.mockAwardEnrolments || ContinuingEnrolledQualification.deserialize(mockAwardEnrolments);
    return of(awardEnrolments);
  }

  // eslint-disable-next-line class-methods-use-this
  getEnrolmentsForStaff(canonicalId: string): Observable<Enrolment[]> {
    const enrolment = Enrolment.deserializeAll(mockEnrolmentsResponse());
    if (!enrolment) {
      return of(null);
    } else {
      return of(enrolment);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentEnrolments(academicYear: string): Observable<IndependentCourseEnrolmentList> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    if (!independentEnrolmentList) {
      return of(null);
    } else {
      return of(independentEnrolmentList);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentEnrolment(
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<IndependentCourseEnrolment> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    const enrolment = independentEnrolmentList.enrolledCourses.find((enrol) => enrol.courseCode === courseCode);

    if (!enrolment) {
      return of(independentEnrolmentList.enrolledCourses[0]);
    } else {
      return of(enrolment);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentEnrolmentsByStatus(academicYear: string, status: string): Observable<IndependentCourseEnrolment[]> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    if (!independentEnrolmentList.enrolledCourses) {
      return of([]);
    } else {
      return of(independentEnrolmentList.enrolledCourses.filter((course) => course.state.code === status));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentCourseEnrolmentStatus(
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<string> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    if (!independentEnrolmentList.enrolledCourses[0]) {
      return of(null);
    } else {
      return of(independentEnrolmentList.enrolledCourses[0].state.code);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentCoursePaymentStatus(academicYear: string, courseCode: string, occurrence: string): Observable<string> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    if (!independentEnrolmentList.enrolledCourses[0]) {
      return of(null);
    } else {
      return of(independentEnrolmentList.enrolledCourses[0].paymentState.code);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  submitIndependentEnrolment(
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<IndependentCourseEnrolment> {
    const independentEnrolment = IndependentCourseEnrolment.deserializePost(mockIndependentEnrolmentPostResponse());

    if (!independentEnrolment) {
      return of(null);
    } else {
      return of(independentEnrolment);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAllEnrolmentList(existingApplicationYears: string[]): Observable<IndependentCourseEnrolment[]> {
    const independentEnrolmentList = IndependentCourseEnrolmentList.deserializeAll(
      mockIndependentEnrolmentsGetResponse(),
    );

    const enrolledList = independentEnrolmentList.enrolledCourses.filter(
      (e) => e.state.code === INDEPENDENT_ENROLMENT_STATES.ENROLMENT_STATE_ENROLLED,
    );

    if (!enrolledList) {
      return of([]);
    } else {
      return of(enrolledList);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIndependentEnrolmentsForYears(academicYears: string[] | number[]): Observable<CombinedIECourseEnrolment[]> {
    // Just spyOn this with callThrough
    return of([]);
  }
}

export const enrolmentServiceFactory = (dataService, loggingService): unknown => {
  if (environment.useFakeBackend.enrolment) {
    return new MockEnrolmentService();
  } else {
    return new EnrolmentService(dataService, loggingService);
  }
};

export const enrolmentServiceProvider = {
  provide: EnrolmentService,
  useFactory: enrolmentServiceFactory,
  deps: [DataService, LoggingService],
};
