<uc-section [formGroup]="maysForm">
  <uc-section-header title="{{ strings.header }}"></uc-section-header>

  <uc-yes-no
    [task]="getTaskOrNull"
    path="/applicant/first_year_here"
    questionString="{{ strings.firstYearQuestion(year) }}"
    required="yes"
    [formControl]="isFirstYear"
    testSelector="first-year-here">
    <uc-no-content>
      <uc-subsection>
        <uc-task-hint [task]="task" path="/applicant/first_year_here"></uc-task-hint>
        <uc-subsection-question required="yes">{{ strings.otherFirstYearQuestion }}</uc-subsection-question>
        <uc-year-selector
          testSelector="first-year"
          [baseYear]="firstYear - 1"
          [range]="70"
          [control]="maysForm.get('firstYearHere')"></uc-year-selector>
      </uc-subsection>
    </uc-no-content>
  </uc-yes-no>

  <uc-subsection>
    <uc-task-hint [task]="task" path="/applicant/first_year_tertiary"></uc-task-hint>
    <uc-subsection-question required="yes">{{ strings.firstTertiaryYear }}</uc-subsection-question>
    <uc-subsection-minor-text>
      {{ strings.firstTertiaryYearSubText }}
      <br />
      {{ strings.firstTertiaryYearSubText2(year) }}
    </uc-subsection-minor-text>

    <uc-year-selector
      testSelector="first-year-tert"
      [baseYear]="firstYear"
      [range]="70"
      [control]="maysForm.get('firstYearTertiary')"></uc-year-selector>
  </uc-subsection>

  <uc-subsection>
    <uc-task-hint [task]="task" path="/applicant/demographic/moeMainActivity"></uc-task-hint>
    <uc-subsection-question required="yes">{{ strings.lastYearQ(year) }}</uc-subsection-question>

    <uc-reference-data-selector type="mainactivityoccupation" formControlName="occupation"></uc-reference-data-selector>
  </uc-subsection>
</uc-section>
