import { Qualification } from '@shared/models/qualification';

export class QualificationResult {
  public summary?: any;
  public qualification: Qualification[];

  constructor(data: any) {
    Object.assign(this, data);
  }

  static deserialize(payload: any): QualificationResult {
    if (payload === null) {
      return null;
    }

    if (payload.qualification) {
      payload.qualification = payload.qualification.map((qual) => {
        return Qualification.deserialize(qual);
      });
    }
    return new QualificationResult(payload);
  }
}
