<uc-form-template [formGroup]="taskForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header title="{{ strings.funding }}"></uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/application/payment_method"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.aesFunding.label }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-radio-input-group
        [options]="fundingOptions"
        formControlName="fundingRadio"
        groupName="aes-funding"></uc-radio-input-group>
      <uc-message-banner type="info" *ngIf="taskForm.contains('scholarshipProvider')">
        <span [innerHTML]="strings.fundingDescription[1] | safe"></span>
      </uc-message-banner>
    </uc-subsection>

    <uc-subsection *ngIf="taskForm.contains('scholarshipProvider')">
      <uc-task-hint [task]="task" path="/application/scholarship_provider"></uc-task-hint>
      <uc-form-input>
        <uc-subsection-question>
          {{ strings.aesFunding.homeScholarshipLabel }}
        </uc-subsection-question>
        <uc-string-input
          testSelector="scholarship-provider"
          class="input-medium"
          formControlName="scholarshipProvider"
          required="yes"></uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="taskForm.contains('paymentMethodOther')">
      <uc-task-hint [task]="task" path="/application/payment_method_other"></uc-task-hint>
      <uc-form-input>
        <uc-form-field-label labelText="{{ strings.aesFunding.otherFundingLabel }}" required="yes">
          <uc-text-area testSelector="payment-other" formControlName="paymentMethodOther"></uc-text-area>
        </uc-form-field-label>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.admissionHeader }}"></uc-section-header>
    <uc-subsection>
      <p><span [innerHTML]="strings.admissionContext"></span></p>
    </uc-subsection>
  </uc-section>
</uc-form-template>
