<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-online-header-bar
  [hasSideNav]="false"
  [hideNavItems]="true"
  [hideToolbarItems]="true"
  class="online-header-bar"></uc-online-header-bar>
<div class="page-container">
  <uc-stages-sidebar
    [isImpersonating]="isImpersonating"
    [firstIncompleteStageNumber]="firstIncompleteStageNumber"></uc-stages-sidebar>

  <div id="main-content" class="content" [ngSwitch]="task?.code">
    <div class="content-inner">
      <button
        [attr.data-cy]="'prev-task'"
        class="previous-task"
        (click)="previousTask()"
        [style.display]="isFirstTask ? 'none' : ''">
        <uc-icon iconType="back-arrow" iconWidth="17" iconHeight="17"></uc-icon>
        {{ strings.previousTask }}
      </button>
      <uc-application-to-enrol
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'start-my-application'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-application-to-enrol>
      <uc-university-admission
        [task]="task"
        #currentTask
        *ngSwitchCase="'university-admission'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-university-admission>
      <uc-police-check
        [applicationYear]="applicationYear"
        [process]="process.code"
        [stage]="stage.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'police-check'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-police-check>

      <uc-ca-compliance
        [applicationYear]="applicationYear"
        [process]="process.code"
        [stage]="stage.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'ca-compliance'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-ca-compliance>
      <uc-academic-history
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'academic-history'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-academic-history>
      <uc-academic-history-mc
        [process]="process.code"
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'academic-history-mc'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-academic-history-mc>
      <uc-star
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'star-specific'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-star>
      <uc-doctoral-study
        [applicationYear]="applicationYear"
        [task]="task"
        [process]="process.code"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'doctoral-study'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [isInternational]="process?.isInternational"></uc-doctoral-study>
      <uc-myuc-qualification
        [processFilter]="process.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'qualification'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-myuc-qualification>
      <uc-qualification-coa
        [processFilter]="process.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'qualification-coa'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-qualification-coa>
      <uc-teaching-application
        [task]="task"
        #currentTask
        *ngSwitchCase="'teaching-application'"
        [stage]="stage.code"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-teaching-application>
      <uc-cie-admission
        [task]="task"
        #currentTask
        *ngSwitchCase="'cie-admission'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-cie-admission>
      <uc-ib-admission
        [task]="task"
        #currentTask
        *ngSwitchCase="'ib-admission'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-ib-admission>
      <uc-ue-admission
        [task]="task"
        #currentTask
        *ngSwitchCase="'ue-admission'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-ue-admission>
      <uc-aes-admission
        [task]="task"
        #currentTask
        *ngSwitchCase="'aes-admission'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-aes-admission>
      <uc-english-proficiency
        [applicationYear]="applicationYear"
        [task]="task"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'english-proficiency'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-english-proficiency>
      <uc-ucsw-handoff
        [processFilter]="process.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'course-selection'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-ucsw-handoff>
      <uc-resolve-account
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-account'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-resolve-account>
      <uc-resolve-account
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-account-reenrolled'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-resolve-account>
      <uc-resolve-account
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-application-deferred'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-resolve-account>
      <uc-financial-information
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'financial'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-financial-information>
      <uc-student-declaration
        [applicationYear]="applicationYear"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'declaration'"
        (next)="goToNextTask()"
        [isInternational]="process?.isInternational"
        [processComplete]="stageComplete"
        (errors)="showTaskError($event)"></uc-student-declaration>
      <uc-exchange-study-abroad
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'incoming-student-exchange-study-abroad-details'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-exchange-study-abroad>
      <uc-study-intentions
        [task]="task"
        #currentTask
        *ngSwitchCase="'study-intentions'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-study-intentions>
      <uc-study-options
        [isInternational]="process?.isInternational"
        [process]="process.code"
        [stage]="stage.code"
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'study-options'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-study-options>
      <uc-postgrad-intentions
        [applicationYear]="applicationYear"
        [task]="task"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'postgrad-intentions'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-postgrad-intentions>
      <uc-cup
        [task]="task"
        [process]="process.code"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'cup'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-cup>
      <uc-finalise-enrolment
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'finalise'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-finalise-enrolment>
      <uc-international-student-insurance
        [task]="task"
        #currentTask
        *ngSwitchCase="'int-student-insurance'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-international-student-insurance>
      <uc-resolve-offer
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'accept-or-decline'"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-resolve-offer>
      <uc-review-details
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'review-my-details'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-review-details>
      <uc-myuc-future-year-apply
        [processName]="newProcessName"
        #currentTask
        *ngSwitchCase="'future-year-apply'"
        (errors)="showTaskError($event)"></uc-myuc-future-year-apply>
      <uc-qualification-coe
        [task]="task"
        #currentTask
        *ngSwitchCase="'coe-qualification'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [process]="process.code"></uc-qualification-coe>
      <uc-course-selection-overview
        [task]="task"
        #currentTask
        *ngSwitchCase="'course-selection-overview'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [process]="process.code"></uc-course-selection-overview>
      <uc-courses-closed
        [process]="process.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'courses-closed'"
        [isInternational]="process?.isInternational"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-courses-closed>
      <uc-evidence-of-funds
        [task]="task"
        #currentTask
        *ngSwitchCase="'aes-evidence-of-funds'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-evidence-of-funds>
      <uc-confirm-changes
        [task]="task"
        #currentTask
        *ngSwitchCase="'confirm-coe'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-confirm-changes>
      <uc-confirm-coa-changes
        [task]="task"
        [stage]="stage"
        #currentTask
        *ngSwitchCase="'confirm-coa'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-confirm-coa-changes>
      <uc-enrolment-declaration
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'course-declaration'"
        (next)="goToNextTask()"
        [processComplete]="stageComplete"
        [applicationYear]="applicationYear"
        (errors)="showTaskError($event)"></uc-enrolment-declaration>
      <uc-complete-stage
        [stageNumber]="stageNumber"
        [params]="params"
        [process]="process.code"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'complete-stage'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [isInternational]="process?.isInternational"></uc-complete-stage>
      <uc-enrolment-change-declaration
        [applicationYear]="applicationYear"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'coe-declaration'"
        (next)="goToNextTask()"
        [processComplete]="stageComplete"
        (errors)="showTaskError($event)"></uc-enrolment-change-declaration>
      <uc-star-declaration
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'star-declaration'"
        (next)="goToNextTask()"
        [processComplete]="stageComplete"
        (errors)="showTaskError($event)"></uc-star-declaration>
      <uc-star-accept-decline
        [task]="task"
        #currentTask
        *ngSwitchCase="'star-accept-or-decline'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-star-accept-decline>
      <uc-phd-accept-decline
        [task]="task"
        #currentTask
        *ngSwitchCase="'phd-accept-or-decline'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-phd-accept-decline>
      <uc-teaching-microcredentials
        [task]="task"
        [applicationYear]="applicationYear"
        #currentTask
        *ngSwitchCase="'mced-specific'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-teaching-microcredentials>
      <uc-teaching-microcredentials
        [task]="task"
        [applicationYear]="applicationYear"
        #currentTask
        *ngSwitchCase="'mced-asla-specific'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-teaching-microcredentials>
      <uc-micro-credentials-declaration
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'micro-credentials-declaration'"
        (next)="goToNextTask()"
        [processComplete]="stageComplete"
        (errors)="showTaskError($event)"></uc-micro-credentials-declaration>
      <uc-qualification-mc
        [process]="process.code"
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'qualification-mc'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-qualification-mc>
      <uc-additional-information
        [process]="process.code"
        [applicationYear]="applicationYear"
        [task]="task"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'additional-information-cac'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-additional-information>
      <uc-offer-decision
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'offer-decision'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-offer-decision>
      <uc-defer-offer
        [process]="process.code"
        [applicationYear]="applicationYear"
        [isInternational]="process?.isInternational"
        [task]="task"
        [stage]="stage.code"
        #currentTask
        *ngSwitchCase="'defer-offer'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-defer-offer>
      <p class="missing-task" *ngSwitchDefault>
        <!--TODO e.b 5/5/17 clarify requirements on what this string should be?-->
        {{ strings.missing(task?.title) }}
      </p>
      <div class="page-actions clearfix">
        <div class="errors">
          <uc-message-banner #errorBanner type="error">{{ errorMessage }}</uc-message-banner>
        </div>
        <div class="primary-links" *ngIf="!disableNavigation">
          <button [attr.data-cy]="'cancel-task'" class="cancel-link" (click)="cancelTask()">
            {{ strings.cancelTask }}
          </button>
          <uc-button
            testSelector="next-task"
            [isLoading]="taskIsUpdating"
            class="next-task"
            (click)="triggerTaskUpdate()">
            {{ actionLabel }}
          </uc-button>
        </div>
      </div>
    </div>
    <!-- <uc-footer-minimal [showHelp]="true"></uc-footer-minimal> -->
    <uc-online-footer-minimal></uc-online-footer-minimal>
  </div>
</div>
<uc-tooltip-container></uc-tooltip-container>
