import { Component, OnInit, Input } from '@angular/core';
import { combineLatest } from 'rxjs';

import { Category, importantCategories, UCFile } from '@shared/models/uc-file';
import { DocumentService } from '@shared/services/document/document.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';

@Component({
  selector: 'uc-file-list-container',
  templateUrl: './file-list-container.component.html',
  styleUrls: ['./file-list-container.component.scss'],
})
export class FileListContainerComponent implements OnInit {
  @Input() category: Category;
  documents: UCFile[] | { year: string; files: UCFile[] };
  showList = false;

  constructor(
    private documentService: DocumentService,
    private userActivityService: UserActivityService,
  ) {}

  formatDocumentData(documents: FileUploadServiceEvent[], documentType, viewedDocs?) {
    const files = documents.map((doc) => doc.file);
    if (documentType === 'applicant') {
      return files;
    } else {
      const years = [];
      files.forEach((doc) => {
        if (!!viewedDocs) {
          doc.viewed = viewedDocs.includes(doc.id);
        }

        const arrIndex = years.findIndex((el) => el.year === doc.academicYear);
        if (arrIndex > -1) {
          years[arrIndex].files.push(doc);
        } else {
          years.push({
            year: doc.academicYear,
            files: [doc],
          });
        }
      });
      return years.sort((obj1, obj2) => parseInt(obj2.year, 10) - parseInt(obj1.year, 10));
    }
  }

  ngOnInit() {
    combineLatest(
      this.documentService.getDocumentsForCategory(this.category.code),
      this.userActivityService.getViewedDocuments(),
    ).subscribe(([docs, viewedDocs]) => {
      if (docs && docs.length) {
        this.showList = docs.length > 0;

        if (importantCategories.indexOf(this.category.code) !== -1) {
          this.documents = this.formatDocumentData(docs, this.category.documentType, viewedDocs);
        } else {
          this.documents = this.formatDocumentData(docs, this.category.documentType);
        }
      } else {
        this.documents = [];
        this.showList = false;
      }
    });
  }
}
