import { Component, HostListener, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
  @Input() task: Task;
  @Output() triggerClosePanel: EventEmitter<any> = new EventEmitter();

  get isActive() {
    return this.task && location.pathname.endsWith(this.task.path);
  }

  ngOnInit() {}

  @HostListener('click', ['$event'])
  preventClickThrough(e: MouseEvent) {
    e.stopPropagation();
    this.triggerClosePanel.emit();
  }
}
