import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { ReferenceData } from './reference-data';

export class OfferDecision {
  acceptOffer?: boolean;
  declineReason?: ReferenceData;
  declineReasonOther?: string;
  declarationFundingAcceptance?: boolean;
  deferredQualOccurrence?: string;
  deferredQualOccurrenceYear?: ReferenceData;

  constructor(rawName = {}) {
    Object.assign(this, rawName);
  }

  static deserialize(payload: any): OfferDecision {
    if (payload === null) {
      return null;
    }

    const localPayload = camelizeKeys(payload);

    localPayload.declineReason = ReferenceData.deserialize(localPayload.declineReason);
    localPayload.deferredQualOccurrenceYear = ReferenceData.deserialize(localPayload.deferredQualOccurrenceYear);

    return localPayload;
  }

  static serialize(instance: OfferDecision): any {
    const clone = { ...instance };
    clone.declineReason = ReferenceData.serialize(clone.declineReason);
    clone.deferredQualOccurrenceYear = ReferenceData.serialize(clone.deferredQualOccurrenceYear);

    return { offer_decision: snakeifyKeys(clone) };
  }
}
