import { Directive, Input, OnDestroy, ElementRef, HostListener } from '@angular/core';

import { TooltipService } from '@shared/components/atoms/tooltip/tooltip.service';

@Directive({
  selector: '[ucTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltipContent: any;
  @Input() tooltipTime: number;
  private id: any;
  private isThisTooltipOpen = false;

  constructor(
    private tooltipService: TooltipService,
    private element: ElementRef,
  ) {
    const el = element.nativeElement as HTMLElement;
    el.style.cursor = 'pointer';
  }

  @HostListener('click')
  onClick(): void {
    if (this.isThisTooltipOpen) {
      this.isThisTooltipOpen = false;
      this.tooltipService.destroyTooltip(this.id);
      return;
    }
    this.isThisTooltipOpen = true;
    this.id = Math.random();
    this.tooltipService.components.push({
      id: this.id,
      ref: this.element,
      title: this.tooltipContent,
      time: this.tooltipTime,
    });

    if (this.tooltipTime) {
      setTimeout(() => {
        this.isThisTooltipOpen = false;
        this.tooltipService.destroyTooltip(this.id);
      }, this.tooltipTime * 1000);
    }
  }

  ngOnDestroy(): void {
    this.tooltipService.destroyTooltip(this.id);
  }
}
