<uc-section [formGroup]="permanentDetails">
  <uc-section-header title="{{ strings.sectionTitle }}">
    <p class="description">
      {{ strings.description }}
    </p>
  </uc-section-header>
  <uc-subsection>
    <uc-form-input>
      <div class="input">
        <uc-phone-selector
          formControlName="landlineNum"
          labelText="{{ strings.landlineLabel }}"
          testSelector="landline-permanent"></uc-phone-selector>
      </div>

      <div class="input">
        <uc-address-selector testSelector="permanent-contact" formControlName="address"></uc-address-selector>
      </div>
    </uc-form-input>
  </uc-subsection>
</uc-section>
