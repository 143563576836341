import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

export enum AudienceTypes {
  student = 'student',
  agent = 'agent',
  staff = 'staff',
  all = 'all',
}

export enum NotificationTypes {
  outage = 'outage',
  warn = 'warn',
  info = 'info',
}

export class Notification {
  id?: string;
  message: string;
  start: Date;
  expiry: Date;
  type: NotificationTypes;
  audience: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Notification[] {
    if (payload === null) {
      return null;
    }
    const localPayload = payload.notifications || payload.notification || payload;

    return localPayload.map((notification: Notification) => {
      const localNotification = camelizeKeys(notification);
      localNotification.start = new Date(localNotification.start);
      localNotification.expiry = new Date(localNotification.expiry);
      return new Notification(localNotification);
    });
  }

  static serialize(instance: any): any {
    const clone = { ...instance };

    clone.state = ReferenceData.serialize(clone.state);

    return { notification: snakeifyKeys(clone) };
  }
}
