{
  "independent_enrolment": {
    "internal_reference": "slGAU4cwMWaEjOtjzZjjV1PkImAkoi",
    "course_code": "519FX22031",
    "occurrence": "1",
    "state": {
      "code": "created"
    },
    "payment_state": {
      "code": "no_payment_attempted"
    }
  }
}
