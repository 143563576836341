import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { of, Observable } from 'rxjs';
import { switchMap, takeUntil, filter } from 'rxjs/operators';

import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { environment } from '@environment';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import strings from '@shared/constants/strings.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { UserTypes } from '@shared/models/user';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';

@Component({
  selector: 'uc-online-complete-stage',
  templateUrl: './online-complete-stage.component.html',
  styleUrls: ['./online-complete-stage.component.scss'],
})
export class OnlineCompleteStageComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() isInternational = false;
  @Input() params: IProcessRouteParams;
  @Input() process: string;
  @Input() stageNumber: number;

  strings = strings.components.tasks.completeStage;
  enrolmentChange: ChangeOfEnrolment;
  ratingForm = new UntypedFormGroup({
    npsRating: new UntypedFormControl(0),
  });
  infoText: { complete: string; incomplete: string };
  messageBanner: string;
  log: Logger;
  currentApplication: Application;

  constructor(
    private onlineProcessService: OnlineProcessService,
    private applicationService: ApplicationService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete(): boolean {
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  get infoTextBody(): string {
    return this.stageComplete ? this.infoText.complete : this.infoText.incomplete;
  }

  get messageBannerText(): string {
    return this.messageBanner;
  }

  // eslint-disable-next-line complexity
  get showStarRating(): boolean {
    const hideStarProcesses = [PROCESS_NAMES.OFFER_DECISION, PROCESS_NAMES.DEFER_OFFER];
    const hideForCurrentProcess = !!hideStarProcesses.find((el) => el === this.process);
    const isAgent = environment.scope === UserTypes.agent;
    const showForCurrentStage = this.stageNumber === 1 && this.stageComplete;

    return !isAgent && !hideForCurrentProcess && showForCurrentStage && !this.hasEnrolmentChange;
  }

  get hasEnrolmentChange(): boolean {
    return this.enrolmentChange && this.enrolmentChange?.academicYear?.code === this.params.year;
  }

  ngOnInit() {
    const infoStrings = this.getTextConfig();
    this.infoText = infoStrings.infoText({ isInternational: this.isInternational, process: this.process });
    this.messageBanner = infoStrings.messageBanner(this.task.title);
    this.applicationService.application
      .pipe(filter((a) => !!a))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.ratingForm.get('npsRating').setValue(application.npsRating);
      });

    this.changeOfEnrolmentService.getChangeOfEnrolment().subscribe((coe) => {
      this.enrolmentChange = coe;
    });
  }

  getTextConfig() {
    const textForProcess = this.strings.processStrings[this.process] || this.strings.processStrings.default;
    return { ...textForProcess[`stage${this.stageNumber}`] };
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  updateFormValidity() {}

  submitEnrolmentsIfRequired(): Observable<unknown> {
    if (this.params && this.params.stage === STAGE_NAMES.SELECT_COURSES) {
      return this.onlineProcessService.submitEnrolment(this.params.year);
    } else {
      return of(null);
    }
  }

  submitStageAndApplicationIfRequired(stageComplete: boolean): Observable<Application> {
    if (this.shouldSubmitStage(stageComplete)) {
      return this.onlineProcessService
        .submitStage(this.params.process, this.params.year, this.params.stage)
        .pipe(switchMap(() => this.applicationService.getApplication(this.params.year)));
    } else {
      return of(null);
    }
  }

  private shouldSubmitStage(stageComplete) {
    return stageComplete && this.stage.requiresExplicitSubmit && this.params;
  }

  submitEnrolmentOnUpdate() {
    const stageComplete = get(this.task, 'actionLabel') === ACTION_LABELS.SAVE_AND_SUBMIT;

    this.submitEnrolmentsIfRequired()
      .pipe(switchMap(() => this.submitStageAndApplicationIfRequired(stageComplete)))
      .subscribe({
        next: (app) => {
          if (app) {
            app.declarationAgree = null;
            this.applicationService.application$.next(app);
          }
          this.next.emit();
        },
        error: (err) => {
          this.log.error(new Error('Error submitting stage'));
          this.errors.emit(err);
        },
      });
  }

  update() {
    if (this.showStarRating) {
      this.currentApplication.npsRating = this.ratingForm.get('npsRating').value;
      this.applicationService.updateApplication(this.currentApplication).subscribe(
        () => this.submitEnrolmentOnUpdate(),
        (err) => {
          this.log.error('Error updating npsRating on application model', err);
        },
      );
    } else {
      this.submitEnrolmentOnUpdate();
    }
  }
}
