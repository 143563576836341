import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { ENV_NAMES } from '@shared/constants/app-names.constants';
import { REFDATA_TYPES, ReferenceData } from '@shared/models/reference-data';
import { ApplicationService } from '@shared/services/application/application.service';
import { DataService } from '@shared/services/data-service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { mockData as mockReferenceData } from '@shared/services/reference-data/reference-data.mock';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

export enum CourseType {
  SC = 'SC',
  MC = 'MC',
}

export interface CourseSearchOptions {
  includeAll?: boolean;
}

@Injectable()
export class OnlineCourseService {
  private serviceUrl = `${environment.apiRoot}/online-course`;
  private log: Logger;
  public onlineCourseType$ = new BehaviorSubject<string>(null);
  public onlineCourseCode$ = new BehaviorSubject<string>(null);
  public onlineCourseOccur$ = new BehaviorSubject<string>(null);
  public isResumingApplication$ = new BehaviorSubject<boolean>(false);
  // Spike: add a temp place to hold process query parameters
  public programme$ = new BehaviorSubject<string>(null);
  public onlineCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(null);
  public onlineCourseIsFirstTimeToThisCourseType$ = new BehaviorSubject<boolean>(false);
  public onlineCourseIsMCEnrolledBefore$ = new BehaviorSubject<boolean>(false);
  public onlineCourseEnrolmentContextIsBuilt$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dataService: DataService,
    private refDataService: ReferenceDataService,
    private enrolmentService: EnrolmentService,
    private applicationService: ApplicationService,
    private router: Router,
    private loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get onlineCourseType(): string {
    return this.onlineCourseType$.value;
  }

  set onlineCourseType(value) {
    this.onlineCourseType$.next(value);
  }

  get onlineCourseCode(): string {
    return this.onlineCourseCode$.value;
  }

  set onlineCourseCode(value) {
    this.onlineCourseCode$.next(value);
  }

  get isResumingApplication(): boolean {
    return this.isResumingApplication$.value;
  }

  get onlineCourseOccur(): string {
    return this.onlineCourseOccur$.value;
  }

  set onlineCourseOccur(value) {
    this.onlineCourseOccur$.next(value);
  }

  get programme(): string {
    return this.programme$.value;
  }

  set programme(value) {
    this.programme$.next(value);
  }

  get onlineCourseSelectedCourse(): ReferenceData {
    return this.onlineCourseSelectedCourse$.value;
  }

  set onlineCourseSelectedCourse(value) {
    this.onlineCourseSelectedCourse$.next(value);
  }

  get onlineCourseIsFirstTimeToThisCourseType(): boolean {
    return this.onlineCourseIsFirstTimeToThisCourseType$.value;
  }

  set onlineCourseIsFirstTimeToThisCourseType(value) {
    this.onlineCourseIsFirstTimeToThisCourseType$.next(value);
  }

  get onlineCourseIsMCEnrolledBefore(): boolean {
    return this.onlineCourseIsMCEnrolledBefore$.value;
  }

  set onlineCourseIsMCEnrolledBefore(value) {
    this.onlineCourseIsMCEnrolledBefore$.next(value);
  }

  get onlineCourseEnrolmentContextIsBuilt(): boolean {
    return this.onlineCourseEnrolmentContextIsBuilt$.value;
  }

  set onlineCourseEnrolmentContextIsBuilt(value) {
    this.onlineCourseEnrolmentContextIsBuilt$.next(value);
  }

  clearCache() {
    this.onlineCourseCode$.next(null);
    this.onlineCourseOccur$.next(null);
    this.isResumingApplication$.next(false);
    this.onlineCourseType$.next(null);
    this.programme$.next(null);
    this.onlineCourseSelectedCourse$.next(null);
    this.onlineCourseIsFirstTimeToThisCourseType$.next(false);
    this.onlineCourseIsMCEnrolledBefore$.next(false);
    this.onlineCourseEnrolmentContextIsBuilt$.next(false);
  }

  getCourseYear() {
    return (
      this.onlineCourseSelectedCourse?.validFrom !== undefined
        ? new Date(this.onlineCourseSelectedCourse?.validFrom)
        : new Date()
    )
      .getFullYear()
      .toString();
  }

  isProd(): boolean {
    return environment.envName === ENV_NAMES.PROD;
  }
  isCourseValid(course: ReferenceData): boolean {
    const isTestCourse = course?.metadata?.testCourse === 'TRUE';
    const isProd = this.isProd();
    if (isProd && isTestCourse) {
      return false;
    }
    return new Date(course?.validTo) > new Date();
  }

  async getCourseInfoByQueryParams(queryParams) {
    this.onlineCourseCode = queryParams.enrol;
    this.onlineCourseOccur = queryParams.occur;
    this.isResumingApplication$.next(queryParams.resume && queryParams.resume === 'TRUE');
    const targetRefDataCode = this.onlineCourseCode + this.onlineCourseOccur;
    const refData = ReferenceData.deserialize(
      await this.refDataService.getByCode(REFDATA_TYPES.UCONLINE_COURSE, targetRefDataCode).toPromise(),
    );
    this.onlineCourseSelectedCourse = refData;
    this.onlineCourseType =
      refData?.metadata?.courseCategory.toString() === 'Short course' ? CourseType.SC : CourseType.MC;
  }

  async getEnrolledCourseTypes(existingApplicationYears: string[]) {
    const enrolmentList = await firstValueFrom(this.enrolmentService.getAllEnrolmentList(existingApplicationYears));
    const enrolledCourseTypes = [];

    for (const enrolledCourse of enrolmentList) {
      const targetRefDataCode = enrolledCourse.courseCode + enrolledCourse.occurrence;
      const refData = ReferenceData.deserialize(
        await this.refDataService.getByCode(REFDATA_TYPES.UCONLINE_COURSE, targetRefDataCode).toPromise(),
      );
      let courseType = CourseType.SC;
      if (refData?.metadata?.courseCategory?.toString() === 'Micro-credential') {
        courseType = CourseType.MC;
      }
      enrolledCourseTypes.push(courseType);
    }

    return enrolledCourseTypes;
  }

  async isFirstTimeToThisCourseType(onlineCourseType: string, existingApplicationYears: string[]) {
    let checkResult = false;
    const enrolledCourseTypes = await this.getEnrolledCourseTypes(existingApplicationYears);
    if (onlineCourseType === CourseType.SC) {
      checkResult = !enrolledCourseTypes.includes(CourseType.SC);
    } else {
      checkResult = !enrolledCourseTypes.includes(CourseType.MC);
    }
    this.onlineCourseIsFirstTimeToThisCourseType = checkResult;

    this.log.info('>>> Is First Time To This Course');
    this.log.info(this.onlineCourseIsFirstTimeToThisCourseType);

    return checkResult;
  }

  async isMCEnrolledBefore(existingApplicationYears: string[]) {
    const enrolledCourseTypes = await this.getEnrolledCourseTypes(existingApplicationYears);
    this.onlineCourseIsMCEnrolledBefore = enrolledCourseTypes.includes(CourseType.MC);

    this.log.info('>>> Is MC Enrolled Before');
    this.log.info(this.onlineCourseIsMCEnrolledBefore);

    return this.onlineCourseIsMCEnrolledBefore;
  }

  async buildOnlineCourseEnrolmentContext(queryParams) {
    // eslint-disable-next-line no-console
    console.log('>>> start of inside build context method');
    // Exist when context already built
    if (this.onlineCourseEnrolmentContextIsBuilt) {
      return;
    }

    const applicationSumms = await this.applicationService.getApplications().toPromise();
    const existingApplicationYears = applicationSumms.map((summary) => summary.academicYear);

    // bulid 1. Take course code and occur from queryParams and get courseType from reference data
    await this.getCourseInfoByQueryParams(queryParams);

    // build 2. Check whether it is first time to enrol courseType from build 1
    //          together [isMCEnrolledBefore] to decide how frontend manipulate fixture data to render a specific enrol process
    await this.isFirstTimeToThisCourseType(this.onlineCourseType, existingApplicationYears);

    // build 3. Check whether it enrolled a MC course before.
    //          together [isFirstTimeToThisCourseType] to decide how frontend manipulate fixture data to render a specific enrol process
    await this.isMCEnrolledBefore(existingApplicationYears);

    // Set context is built
    this.onlineCourseEnrolmentContextIsBuilt = true;

    this.log.info('>>> end of inside build context method');
  }
}

@Injectable()
export class MockOnlineCourseService {
  public onlineCourseType$ = new BehaviorSubject<string>(CourseType.SC);
  public onlineCourseCode$ = new BehaviorSubject<string>('519FX22031');
  public onlineCourseOccur$ = new BehaviorSubject<string>('1');
  public programme$ = new BehaviorSubject<string>('master');
  // TODO: make change according to the final online course reference data attributes
  public onlineCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(
    ReferenceData.deserialize({
      code: '519FX220311',
      description: 'Academic Writing Basics',
      type: 'uconline_course',
      valid_from: '2018-10-31',
      valid_to: '2023-01-26',
      metadata: {
        course_code: '519FX22031',
        course_name: 'Academic Writing Basics',
        course_category: 'Short course',
        course_occurrence: '1',
        course_tec_funded: 'FALSE',
        course_analysis_code: '51900:1980:519FX22031:SE303:1',
        course_domestic_price_gst: '100',
        course_domestic_price_excl_gst: '86.96',
        course_international_price_gst: '424.35',
        course_international_price_excl_gst: '369',
      },
    }),
  );
  public onlineTestCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(
    ReferenceData.deserialize({
      code: 'TESTSC01',
      description: 'Academic Writing Basics',
      type: 'uconline_course',
      valid_from: '2018-10-31',
      valid_to: '2030-11-11',
      metadata: {
        test_course: 'TRUE',
        course_code: 'TESTSC',
        course_name: 'first TEstcourse',
        course_category: 'Short course',
        course_occurrence: '01',
        course_tec_funded: 'FALSE',
        course_analysis_code: '51900:1980:519FX22031:SE303:1',
        course_domestic_price_gst: '100',
        course_domestic_price_excl_gst: '86.96',
        course_international_price_gst: '424.35',
        course_international_price_excl_gst: '369',
      },
    }),
  );
  public onlineCourseIsFirstTimeToThisCourseType$ = new BehaviorSubject<boolean>(false);
  public onlineCourseIsMCEnrolledBefore$ = new BehaviorSubject<boolean>(false);
  public onlineCourseEnrolmentContextIsBuilt$ = new BehaviorSubject<boolean>(false);

  get onlineCourseType(): string {
    return this.onlineCourseType$.value;
  }

  set onlineCourseType(value) {
    this.onlineCourseType$.next(value);
  }

  get onlineCourseCode(): string {
    return this.onlineCourseCode$.value;
  }

  set onlineCourseCode(value) {
    this.onlineCourseCode$.next(value);
  }

  get onlineCourseOccur(): string {
    return this.onlineCourseOccur$.value;
  }

  set onlineCourseOccur(value) {
    this.onlineCourseOccur$.next(value);
  }

  get programme(): string {
    return this.programme$.value;
  }

  set programme(value) {
    this.programme$.next(value);
  }

  get onlineCourseSelectedCourse(): ReferenceData {
    return this.onlineCourseSelectedCourse$.value;
  }

  set onlineCourseSelectedCourse(value) {
    this.onlineCourseSelectedCourse$.next(value);
  }

  get onlineCourseIsFirstTimeToThisCourseType(): boolean {
    return this.onlineCourseIsFirstTimeToThisCourseType$.value;
  }

  set onlineCourseIsFirstTimeToThisCourseType(value) {
    this.onlineCourseIsFirstTimeToThisCourseType$.next(value);
  }

  get onlineCourseIsMCEnrolledBefore(): boolean {
    return this.onlineCourseIsMCEnrolledBefore$.value;
  }

  set onlineCourseIsMCEnrolledBefore(value) {
    this.onlineCourseIsMCEnrolledBefore$.next(value);
  }

  get onlineCourseEnrolmentContextIsBuilt(): boolean {
    return this.onlineCourseEnrolmentContextIsBuilt$.value;
  }

  set onlineCourseEnrolmentContextIsBuilt(value) {
    this.onlineCourseEnrolmentContextIsBuilt$.next(value);
  }

  clearCache() {
    this.onlineCourseCode$.next(null);
    this.onlineCourseOccur$.next(null);
    this.onlineCourseType$.next(null);
    this.onlineCourseSelectedCourse$.next(null);
    this.onlineCourseIsFirstTimeToThisCourseType$.next(false);
    this.onlineCourseIsMCEnrolledBefore$.next(false);
    this.onlineCourseEnrolmentContextIsBuilt$.next(false);
  }

  getCourseYear() {
    return (new Date(this.onlineCourseSelectedCourse?.validFrom) || new Date()).getFullYear().toString();
  }

  isProd(): boolean {
    return environment.envName === ENV_NAMES.PROD;
  }
  isCourseValid(course: ReferenceData): boolean {
    const isTestCourse = course?.metadata?.testCourse === 'TRUE';
    const isProd = this.isProd();
    if (isProd && isTestCourse) {
      return false;
    }
    return new Date(course?.validTo) > new Date();
  }

  // eslint-disable-next-line class-methods-use-this
  getCourseInfoByQueryParams(queryParams) {
    this.onlineCourseCode = queryParams?.enrol;
    this.onlineCourseOccur = queryParams?.occur;
    const refData = ReferenceData.deserialize(
      mockReferenceData()
        .uconline_course.filter((course) => course.code === queryParams?.enrol + queryParams?.occur)
        .pop(),
    );

    this.onlineCourseSelectedCourse$.next(refData);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  getEnrolledCourseTypes(existingApplicationYears: string[]) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  isFirstTimeToThisCourseType(existingApplicationYears: string[]) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  isMCEnrolledBefore(existingApplicationYears: string[]) {
    return false;
  }

  buildOnlineCourseEnrolmentContext(queryParams) {
    const existingApplicationYears = [];
    this.getCourseInfoByQueryParams(queryParams);
    this.isFirstTimeToThisCourseType(existingApplicationYears);
    this.isMCEnrolledBefore(existingApplicationYears);
  }
}
