import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator } from '@angular/forms';
import { switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { Phone } from '@shared/models/phone';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-phone-selector',
  templateUrl: './phone-selector.component.html',
  styleUrls: ['./phone-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneSelectorComponent),
      multi: true,
    },
  ],
})
export class PhoneSelectorComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() labelText: string;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() task?: Task;
  @Input() path?: string;
  @Input() testSelector = '';

  static PHONE_LOOKUP_CODE = 'phone_area';
  strings = strings.components.molecules.phoneSelector;
  urls = externalUrls;

  countryCodeControl = new UntypedFormControl('');
  numberControl = new UntypedFormControl('');
  options: ReferenceData[];
  hasValue = false;
  private phoneModel: Phone;
  log: Logger;
  hasFocus = false;

  constructor(
    loggingService: LoggingService,
    private referenceData: ReferenceDataService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-phone`;
  }

  focusField() {
    this.hasFocus = true;
  }

  removeFieldFocus() {
    this.hasFocus = false;
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange = (_: any) => {};
  private propagateTouch = (_: any) => {};
  private maybePropagateTouch = () => {
    if (this.phoneModel.country && this.phoneModel.number) {
      this.hasValue = true;
      this.propagateTouch(true);
    } else {
      this.hasValue = false;
    }
  };

  public validate(_c: UntypedFormControl) {
    const controlsTouched = this.countryCodeControl.touched && this.numberControl.touched;
    const hasCountryValue = !!this.countryCodeControl.value;
    const hasNumberValue = !!this.numberControl.value;
    if (controlsTouched && hasCountryValue && !hasNumberValue) {
      return {
        didFailCompletePhone: true,
      };
    }
    return null;
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    this.referenceData.getByType(PhoneSelectorComponent.PHONE_LOOKUP_CODE).subscribe((codes) => {
      this.options = codes;
    });
    this.countryCodeControl.valueChanges
      .pipe(switchMap((refCode) => this.referenceData.getByCode(PhoneSelectorComponent.PHONE_LOOKUP_CODE, refCode)))
      .subscribe((refData: ReferenceData) => {
        this.phoneModel.country = refData;
        this.propagateChange(this.phoneModel);
        this.maybePropagateTouch();
      });

    this.numberControl.valueChanges.subscribe((number: string) => {
      this.phoneModel.number = number;
      this.propagateChange(this.phoneModel);
      this.maybePropagateTouch();
    });
  }

  stripInvalidCharacters(_event: any) {
    setTimeout(() => {
      const val = this.numberControl.value;
      if (val && val.match(/[^ -~]/gi)) {
        this.numberControl.setValue(val.replace(/[^ -~]/gi, ''));
      }
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: Phone): void {
    this.phoneModel = obj;
    if (obj.country) {
      this.countryCodeControl.setValue(obj.country.code, { emitEvent: false });
    }
    if (obj.country && obj.number) {
      this.hasValue = true;
    }
    this.numberControl.setValue(obj.number, { emitEvent: false });
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
