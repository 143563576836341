<div [formGroup]="englishTest">
  <div *ngIf="condition === 'Have Sat Test'">
    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/english_qualification/type"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.whichTest }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-radio-input-group
        groupName="test-type"
        formControlName="testType"
        [showDisabledExplanation]="!verified"
        [options]="englishTestType"></uc-radio-input-group>

      <uc-task-hint
        *ngIf="englishTest.get('testType') && englishTest.get('testType').value === 'Other'"
        [task]="task"
        path="/applicant/english_qualification/type_other"></uc-task-hint>
      <uc-string-input
        testSelector="test-other"
        *ngIf="englishTest.get('testType') && englishTest.get('testType').value === 'Other'"
        formControlName="other"
        [showDisabledExplanation]="!verified"
        label="{{ strings.otherLabel }}"
        required="yes"></uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-subsection-question>{{ strings.whenQuestion }}</uc-subsection-question>
      <uc-date-selector
        [restrictedValues]="validTestYearValues"
        formControlName="date"
        [showDisabledExplanation]="!verified"
        testSelector="test-year"></uc-date-selector>
    </uc-subsection>

    <uc-subsection *ngIf="shouldShowScoreList" [formGroup]="englishTest.get('scores')">
      <uc-subsection-question>{{ strings.resultsQuestion }}</uc-subsection-question>
      <uc-string-input
        testSelector="score-lst"
        [isNumeric]="true"
        [step]="0.5"
        [min]="0"
        [max]="9"
        label="{{ strings.listening }}"
        formControlName="LST"
        [showDisabledExplanation]="!verified"></uc-string-input>
      <uc-string-input
        testSelector="score-red"
        [isNumeric]="true"
        [step]="0.5"
        [min]="0"
        [max]="9"
        label="{{ strings.reading }}"
        formControlName="RED"
        [showDisabledExplanation]="!verified"></uc-string-input>
      <uc-string-input
        testSelector="score-wrt"
        [isNumeric]="true"
        [step]="0.5"
        [min]="0"
        [max]="9"
        label="{{ strings.writing }}"
        formControlName="WRT"
        [showDisabledExplanation]="!verified"></uc-string-input>
      <uc-string-input
        testSelector="score-spk"
        [isNumeric]="true"
        [step]="0.5"
        [min]="0"
        [max]="9"
        label="{{ strings.speaking }}"
        formControlName="SPK"
        [showDisabledExplanation]="!verified"></uc-string-input>
      <uc-string-input
        testSelector="score-tot"
        [isNumeric]="true"
        [step]="0.5"
        [min]="0"
        [max]="9"
        label="{{ strings.overall }}"
        formControlName="TOT"
        [showDisabledExplanation]="!verified"></uc-string-input>
    </uc-subsection>

    <uc-subsection *ngIf="!shouldShowScoreList">
      <uc-subsection-question>{{ strings.resultsQuestion }}</uc-subsection-question>
      <uc-text-area
        testSelector="results-overall"
        formControlName="overall"
        [showDisabledExplanation]="!verified"></uc-text-area>
    </uc-subsection>

    <uc-subsection *ngIf="!verified">
      <uc-subsection-question>{{ strings.uploadResults }}</uc-subsection-question>
      <uc-file-upload
        [year]="applicationYear"
        title="English language test certificate"
        categoryCode="ENGLISH"
        usedInTask="true"></uc-file-upload>
    </uc-subsection>
  </div>

  <div *ngIf="condition !== 'Have Sat Test'">
    <uc-subsection>
      <uc-subsection-question>{{ strings.whichTestWill }}</uc-subsection-question>
      <uc-radio-input-group
        groupName="test-type"
        formControlName="testType"
        [showDisabledExplanation]="!verified"
        type="english_language_test_type"></uc-radio-input-group>
      <uc-string-input
        testSelector="test-other-sat"
        *ngIf="englishTest.get('testType') && englishTest.get('testType').value === 'Other'"
        formControlName="other"
        [showDisabledExplanation]="!verified"
        label="{{ strings.otherLabel }}"
        required="yes"></uc-string-input>
    </uc-subsection>

    <uc-subsection *ngIf="condition === 'Scheduled to Sit Test'">
      <uc-subsection-question>
        {{ strings.whenScheduled }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-date-selector
        order="ascending"
        [range]="3"
        formControlName="date"
        [showDisabledExplanation]="!verified"
        testSelector="scheduled"></uc-date-selector>
    </uc-subsection>
  </div>
</div>
