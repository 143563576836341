import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { IAdditionalText } from '@shared/components/atoms/radio-input/radio-input.component';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-radio-input-group',
  templateUrl: './radio-input-group.component.html',
  styleUrls: ['./radio-input-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputGroupComponent),
      multi: true,
    },
  ],
})
export class RadioInputGroupComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() displayHorizontally: boolean;
  @Input() groupName: string;
  @Input() options: { labelText: string; value: string }[];
  @Input() type: string;
  @Input() label?: string;
  @Input() required: boolean;
  @Input() additionalText: IAdditionalText[];
  @Input() filterOutNonValidOptions = false;
  @Input() validateOptions = false;
  @Input() showDisabledExplanation = true;
  @Input() testSelector = '';

  hasValue = false;
  radioGroupControl = new UntypedFormControl('');
  log: Logger;
  isDisabled: boolean;

  private propagateChange: (_: string) => void;
  private propagateTouch: (_: boolean) => void;

  constructor(
    loggingService: LoggingService,
    private refData: ReferenceDataService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get stringValue(): string {
    if (this.options && this.options.length) {
      const stringVal = this.options.find((x) => this.radioGroupControl.value === x.value);
      if (stringVal) {
        return stringVal.labelText;
      }
    }
    return this.radioGroupControl.value;
  }

  get hasAdditionalText(): boolean {
    return !!this.additionalText;
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    if (!this.groupName) {
      this.groupName = (Math.random() * 1000).toString();
    }
    if (this.type) {
      this.updateOptionsFromRefData();
    }
    this.radioGroupControl.valueChanges.subscribe((text) => {
      this.updateValueAndPropagate(text);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue) {
      this.updateOptionsFromRefData();
    }
    if (changes.options?.currentValue) {
      this.checkIfWriteValueIsValid(this.radioGroupControl.value);
    }
  }

  updateOptionsFromRefData() {
    this.refData.getByType(this.type, {}, this.filterOutNonValidOptions).subscribe((refs) => {
      this.options = refs.map((ref) => {
        return {
          labelText: ref.description,
          value: ref.code,
        };
      });
      if (this.radioGroupControl.value) {
        this.updateValueAndPropagate(this.radioGroupControl.value);
      }
    });
  }

  checkIfWriteValueIsValid(value) {
    this.hasValue = !!this.options && !!this.options.find((o) => o.value === value);
  }

  writeValue(obj: string): void {
    this.radioGroupControl.setValue(obj);
  }

  registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  updateValueAndPropagate(value: string) {
    this.propagateChange?.(value);
    this.checkIfWriteValueIsValid(value);
  }
}
