import { OnDestroy, Directive } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/**
 * Component base class that allows you to keep track of subscriptions
 *
 * @see: https://stackoverflow.com/a/41177163/8093350
 *
 * Usage:
 *    class MyComponent extends UnsubscribeOnDestroy implements OnInit {
 *      someSubjectSub: Subject;
 *      constructor () {
 *        super();
 *      }
 *      ngOnInit () {
 *        // this subscription will automatically be unsubscribed when the component is destroyed
 *        // because of the `.takeUntil(this.componentDestroyed)` clause.
 *        this.someService.someSubject
 *          .takeUntil(this.componentDestroyed)
 *          .subscribe(() => {});
 *
 *        // can still manually unsubscribe too - see `this.someEvent` method
 *        this.someSubjectSub = this.someOtherService.someSubject
 *          .takeUntil(this.componentDestroyed)
 *          .subscribe(() => {});
 *      }
 *
 *      someEvent () {
 *        if (this.someSubjectSub) {
 *          this.someSubjectSub.unsubscribe();
 *        }
 *      }
 *    }
 */
@Directive()
export abstract class UnsubscribeOnDestroy implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject<void>();

  /**
   * Make sure that ngOnDestroy always gets called even if the subclass overrides the method
   */
  constructor() {
    const parentNgOnDestroy = this.ngOnDestroy.bind(this);
    this.ngOnDestroy = () => {
      parentNgOnDestroy();
      this.componentDestroyed$.next(null);
      this.componentDestroyed$.complete();
    };
  }

  protected get componentDestroyed(): Observable<any> {
    return this.componentDestroyed$.asObservable();
  }

  protected get isAlreadyDestroyed(): boolean {
    return this.componentDestroyed$.isStopped;
  }

  ngOnDestroy() {}
}
