import { Observable, of } from 'rxjs';

import { ActivityStrategy, UserActivity } from './user-activity.service';
import { IProcessRouteParams } from '../resolvers/process-resolver/process-resolver.service';

export class MockUserActivityService implements ActivityStrategy {
  constructor(private activity: UserActivity = { taskSubmissions: [], viewedDocuments: [] }) {}

  public hasTask(task: IProcessRouteParams): Observable<boolean> {
    const matchingSubmissions = this.activity.taskSubmissions.filter((existingTask) =>
      ['year', 'process', 'task', 'stage'].every((key) => existingTask[key] === task[key]),
    );
    return of(matchingSubmissions.length >= 1);
  }

  public addTask(task: IProcessRouteParams): Promise<void> {
    this.hasTask(task).subscribe((hasTask) => {
      if (!hasTask) {
        this.activity.taskSubmissions.push(task);
      }
    });
    return Promise.resolve();
  }

  public removeAllTasksFor(process: string, year: string): Promise<void> {
    if (this.activity.taskSubmissions) {
      this.activity.taskSubmissions = this.activity.taskSubmissions.filter(
        (a) => a.process !== process || a.year !== year,
      );
    }
    return Promise.resolve();
  }

  public addViewedDocument(id: string): Promise<void> {
    if (!this.activity.viewedDocuments.includes(id)) {
      this.activity.viewedDocuments.push(id);
    }

    return Promise.resolve();
  }

  getViewedDocuments(): Observable<string[]> {
    return of(this.activity.viewedDocuments);
  }

  replaceActivity(activity: UserActivity): Promise<void> {
    this.activity = activity;
    return Promise.resolve();
  }
}
