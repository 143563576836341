import { Component } from '@angular/core';

import { TooltipService } from '@shared/components/atoms/tooltip/tooltip.service';

@Component({
  selector: 'uc-tooltip-container',
  templateUrl: './tooltip-container.component.html',
})
export class TooltipContainerComponent {
  constructor(public tooltipService: TooltipService) {}
}
