{
  "application": {
    "npsRating": "4",
    "academic_year": {
      "code": "2018"
    },
    "state": {
      "code": "in_progress"
    },
    "process_name": {
      "code": "new_student"
    },
    "admission_reason": {
      "code": "CODE"
    },
    "insurance_provider": {
      "code": "298"
    },
    "funding_type": null,

    "declaration_tertiary_study": true,
    "other_qualification": "",
    "ib_qualification": false,
    "cie_qualification": true,
    "experience": "Sup bro! Take the piss, this carked it cuzzie is as shithouse as a sweet as housie. Mean while, in the bushes, Fred Dagg and Tama were up to no good with a bunch of hard yakka troties. The rip-off force of his chundering was on par with The Topp Twins's kiwi as kea. Put the jug on will you bro, all these nuclear-free Swanndris can wait till later. The first prize for wobbling goes to... Dr Ropata and his tip-top jersey, what a ankle biter. Bro, whitebait fritters are really pretty suss good with sweet as hangis, aye. Pavalova is definitely not Australian, sink some piss, good on ya, mate. You have no idea how tapu our pretty suss boxes of fluffies were aye. Every time I see those sweet lamingtons it's like the tinny house all over again aye, true that. Anyway, Sir Edmond Hillary is just Jim Hickey in disguise, to find the true meaning of life, one must start making scones with the jelly tip icecream, mate. After the pohutukawa is flogged, you add all the beautiful toasted sandwiches to the wet blanket you've got yourself a meal. Technology has allowed dodgy pukekos to participate in the global conversation of rough as guts Bell Birds. Fair suck of the sav, mean while, at the black singlet woolshed party. Good afterble constanoon, can't handle the jandle. The next Generation of pearler stink buzzes have already packed a sad over at the sausage sizzle.",
    "payment_method": {
      "code": "Private funds"
    },
    "scholarship_provider": "",
    "payment_method_other": "",
    "career_knowledge": "This is some example text for career knowledge",
    "rationale": "A slice of heaven. Always blow on the pie, this cracker kai moana is as naff as a hard case scarfie. Mean while, in the wop wops, Fred Dagg and Cardigan Bay were up to no good with a bunch of primo lamingtons. The buzzy force of his chundering was on par with Hercules Morse, as big as a horse's kiwi as Jafa. Put the jug on will you bro, all these stink craft suppliess can wait till later. The first prize for pashing goes to... Sir Edmond Hillary and his random Hei-tiki, what a manus. Bro, chilly bins are really cool good with chronic kais, aye. You have no idea how sweet as our rip-off mince pies were aye. Every time I see those solid rimu Longest Drinks in Town it's like the sausage sizzle all over again aye, you don't know his story, bro. Anyway, James and the Giant Peach is just The Hungery Caterpilar in disguise, to find the true meaning of life, one must start packing a sad with the can of Watties Baked Beans, mate. After the Bell Bird is skived off, you add all the nuclear-free herd of sheep to the toasted sandwich you've got yourself a meal. Technology has allowed beautiful kumaras to participate in the global conversation of crook pair of slippers. The next Generation of chocka full ankle biters have already munted over at the tinny house. What's the hurry the Armed Offenders Squad? There's plenty of kiwis in a waka. Mt Cook holds the most mean as community in the country..",
    "study_in_nz": null,
    "cup_study_plan_after": null,
    "cup_how_did_you_hear_about": {
      "code": "Other"
    },
    "study_full_time": null,
    "study_start": {
      "code": "CODE"
    },
    "study_start_other": null,
    "study_location": {
      "code": "Christchurch"
    },
    "associated_person": [
      {
        "type": {
          "code": "referee_one"
        },
        "name": "james",
        "relationship": "intern",
        "email": "jim@123.com",
        "report": ""
      },
      {
        "type": {
          "code": "referee_two"
        },
        "name": "Other",
        "relationship": "something",
        "email": "other@321.com",
        "report": ""
      }
    ],
    "declaration_agree": false,
    "declaration_has_agent": true,
    "declaration_teaching_agree": false,
    "declaration_been_excluded": true,
    "declaration_emergency_contact_information_correct": false,
    "police_check": {
      "has_charges_pending": true,
      "has_convictions": true,
      "charges_pending_description": "Charges",
      "convictions_description": "Convictions",
      "has_background_check": true,
      "declaration_police_agree": false,
      "nz_police_check_state": { "code": "RETURNED" },
      "id_checks": [
        {
          "type": { "code": "BIRTH_CERT" },
          "value": "Birth Certificate"
        }
      ],
      "comment": "This is a comment",
      "vetting_form_received_date": "2023-01-01",
      "nz_police_check_returned_date": "2023-01-01",
      "ca_safety_check_date": "2023-01-01",
      "has_criminal_record": true,
      "criminal_record_details": "Record",
      "overseas_police_check_state": { "code": "RETURNED" }
    },
    "transfer_credits": true,
    "declaration_background_check": [
      {
        "country": {
          "code": "NZ"
        },
        "applied": {
          "code": "NONE"
        }
      }
    ],
    "background_checks": [
      {
        "country": {
          "code": "NZL"
        },
        "applied": {
          "code": "HAVE"
        },
        "type": {
          "code": "POLICE_REP"
        },
        "internal_reference": "string",
        "received_date": "2022-06-01",
        "report_date": "2022-06-01",
        "owner": "string"
      }
    ],
    "agent_name": "im an agent",
    "agent_contact_details": {
      "mobile_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "27",
        "number": "234567",
        "extension": ""
      },
      "landline_number": {
        "country": {
          "code": "+64"
        },
        "prefix": "22",
        "number": "0229876543",
        "extension": ""
      },
      "current_address": {
        "country": {
          "code": "NZ"
        },
        "line_1": "185 Some St",
        "line_2": "Suburbia",
        "city": "",
        "town": "Metropolis",
        "region": "",
        "post_code": "8061"
      },
      "email_address": "agent101@imanagent.com"
    },
    "agent_agency": "Some agency",
    "cie_center_number": "123",
    "cie_candidate_number": "321",
    "ib_results_available": false,
    "postgrad_intentions": {
      "why_qualification_chosen": "a really thoughtful reason for choosing this qualification, it's very important that I study it further.",
      "what_are_your_goals": "my goals are tied with that of the community, synergy and enterprisation",
      "area_of_research": "Ancient egyptian hieroglyphics",
      "supervisor_name": "Sarah Personson",
      "study_department": "Hard Knocks",
      "supervisor_department": "Harder Knocks",
      "has_contacted_supervisor": true,
      "begin_study_away": true,
      "move_to_christchurch_after": "I guess",
      "require_study_away": true
    },
    "date_submitted": "2021-06-05",
    "referee_comments": "This is a test comment",
    "online_assessment": {
      "literacy_score": 1,
      "numeracy_score": 1
    },

    "teachingAssessment": {
      "experiences": 0,
      "currentInvolvement": 1,
      "transferrableSkills": 0,
      "leadershipAndOrganisation": 1,
      "collaborativeProfession": 1,
      "societyEducationalCommunities": 1,
      "tangataWhenua": 1,
      "ethicalResponsibilities": 0,
      "presentingIdeas": 1,
      "spokenLanguage": 1,
      "bodyLanguage": 1,
      "messageSubstance": 1,
      "responsiveness": 1,
      "academicBackground": 1,
      "literacyAssessmentScore": 1,
      "numeracyAssessmentScore": 1,
      "ref1Receptiveness": 1,
      "ref1FosterMaintain": 1,
      "ref1DiversityHeritageLanguage": 1,
      "ref1TeamMember": 1,
      "ref2Receptiveness": 1,
      "ref2FosterMaintain": 1,
      "ref2DiversityHeritageLanguage": 1,
      "ref2TeamMember": 1,
      "totalScore": 19
    }
  }
}
