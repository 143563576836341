import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-police-record',
  templateUrl: './police-record.component.html',
  styleUrls: ['./police-record.component.scss'],
})
export class PoliceRecordComponent implements OnInit {
  @Input() policeRecord: UntypedFormGroup;
  @Input() task: Task;
  @Input() hintPath: string;
  strings = strings.components.tasks.policeCheck.policeRecord;

  constructor() {}

  ngOnInit() {}
}
