<span class="tooltip" [class.show]="showTooltip" [class.show-staff]="showTooltip && isStaff">
  {{ strings.copied }}
</span>
<ng-container *ngIf="isStaff && displayValue">
  {{ displayString }}
</ng-container>
<button [attr.data-cy]="'copy'" class="copy-button" (click)="copyMessage(value)">
  <uc-icon iconType="copy" iconWidth="14" iconHeight="14"></uc-icon>
</button>

<ng-container *ngIf="!isStaff">
  {{ displayString }}
</ng-container>
