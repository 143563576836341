<uc-form-template>
  <uc-form-header [title]="task.title">
    <uc-message-banner *ngIf="stageComplete" type="info">
      <span [innerHTML]="strings.infoMessage"></span>
    </uc-message-banner>

    <uc-application-hints *ngIf="!stageComplete" [stage]="stage" [task]="task"></uc-application-hints>

    <div [innerHTML]="strings.assistance"></div>
  </uc-form-header>
  <uc-spinner *ngIf="loading" size="40" [useStaticPositioning]="true"></uc-spinner>

  <uc-section *ngIf="!loading">
    <uc-section-header [title]="'New qualification selection'"></uc-section-header>
    <uc-subsection class="diff-subsection">
      <ng-container *ngIf="!noChangesMade">
        <div class="award__container" *ngFor="let qualification of newAE">
          <h3 class="qualification-heading">
            {{ qualification?.qualificationAnswer?.title }} ({{ qualification?.qualificationAnswer?.code }})
          </h3>

          <div class="award__qual-tag--wrapper">
            <uc-tag-list
              *ngIf="qualification.subjectAnswer"
              [subjectAnswer]="qualification.subjectAnswer"
              [cropLongText]="false"
              [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"></uc-tag-list>
          </div>
        </div>
      </ng-container>

      <div *ngIf="noChangesMade" class="no-changes-warning">
        {{ strings.noChangesMade }}
      </div>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="!loading && hasPreviousAE && hasPreviousEnrolledQuals">
    <uc-section-header [title]="'Previous qualification selection'"></uc-section-header>
    <uc-subsection class="diff-subsection">
      <div class="award__container" *ngFor="let qualification of previousAE">
        <h3 class="qualification-heading">
          {{ qualification?.qualificationAnswer?.title }} ({{ qualification?.qualificationAnswer?.code }})
        </h3>

        <div class="award__qual-tag--wrapper">
          <uc-tag-list
            *ngIf="qualification.subjectAnswer"
            [subjectAnswer]="qualification.subjectAnswer"
            [cropLongText]="false"
            [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"></uc-tag-list>
        </div>
      </div>
    </uc-subsection>
  </uc-section>
</uc-form-template>
