import { Component, Input } from '@angular/core';

// eslint-disable-next-line import/no-restricted-paths
import colors from '@shared/../global-styles/1-variables/_colors.json';

@Component({
  selector: 'uc-loader-blip',
  templateUrl: './loader-blip.component.html',
  styleUrls: ['./loader-blip.component.scss'],
})
export class LoaderBlipComponent {
  @Input() showBlips = false;
  @Input() color = colors.$colorWhite;

  colors = colors;
}
