<div [attr.class]="changeActionClass">
  <ng-container *ngIf="!course.activeCourse && otherQualCode">
    <div class="card__header-bar">
      <span class="card__header-bar-text">{{ strings.selectedFor }} {{ otherQualCode }}</span>
    </div>
  </ng-container>

  <button
    [attr.data-cy]="'expand-' + course.courseCode.toLowerCase()"
    class="card__toggle-view-btn"
    (click)="toggleExpandableContent()">
    <uc-icon class="card__toggle-view-btn-icon" iconType="chevron-down" iconWidth="8" iconWidth="8"></uc-icon>
  </button>

  <div class="card__content">
    <h3 class="card__title" (click)="toggleExpandableContent()">{{ course.courseCode }}-{{ course.occurrenceCode }}</h3>

    <div *ngIf="hasChangeAction" class="card__undo-wrapper">
      <div class="change-action-label">{{ changeActionLabel }}</div>
      <div
        [attr.data-cy]="'undo-' + course.courseCode.toLowerCase()"
        *ngIf="course.activeCourse"
        class="undo-action-wrapper"
        (click)="undoChange()">
        <uc-icon iconType="undo" iconWidth="19" iconHeight="21" class="card__remove-course"></uc-icon>
        <div class="undo-label">Undo</div>
      </div>
    </div>

    <div class="card__expandable-content">
      <div class="card__expandable-content-inner">
        <div class="card__course-site">{{ course.site }}</div>
        <a [attr.href]="course.href" target="_blank" class="card__course-link">{{ strings.viewDetails }}</a>
        <div class="card__course-desc">{{ course.courseTitle }}</div>

        <ng-container *ngIf="course.startDate && course.endDate">
          <div class="card__dates">
            <div class="card__start-date">
              <strong>{{ strings.start }}</strong>
              {{ course.startDate | date: 'dd/MM/yy' }}
            </div>
            <div class="card__complete-date">
              <strong>{{ strings.complete }}</strong>
              {{ course.endDate | date: 'dd/MM/yy' }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="card__points">{{ course.coursePoints }} {{ strings.points }}</div>

    <div class="card__footer">
      <uc-course-level-pill class="card__level-tag" [course]="course"></uc-course-level-pill>

      <uc-icon
        *ngIf="course.activeCourse && !hasChangeAction"
        (click)="removeACourse()"
        iconType="trash"
        iconWidth="17"
        iconHeight="19"
        iconFill="$colorMonza"
        class="card__remove-course"></uc-icon>
    </div>
  </div>
</div>
