import { deepClone } from '@shared/helpers/general';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { Page } from './page';
import { ReferenceData } from './reference-data';

export class QualificationOccurrence {
  public code: string;
  public intakeName: string;
  public type: ReferenceData;
  public source: ReferenceData;
  public academicYear: string;
  public startDate: string;
  public applicationsCloseDate: string;
  public citizenshipCategory: { code: string };
  public site: ReferenceData;
  public fullTime: boolean;
  public externalReference: string;
  public internalReference?: string;
  public qualification: ReferenceData;
  public state: ReferenceData;

  constructor(data: any) {
    Object.assign(this, data);
  }

  static deserialize(payload: any): QualificationOccurrence[] {
    const data = payload.qualification_occurrence || payload;
    if (data == null) {
      return null;
    }
    if (Array.isArray(data)) {
      return data.map((qo) => camelizeKeys(qo));
    } else {
      return camelizeKeys(data);
    }
  }

  public static serialize(instance: QualificationOccurrence[]): any {
    let cloned = deepClone(instance);
    cloned = cloned.map((qual) => {
      qual.source = ReferenceData.serialize(qual.source);
      qual.type = ReferenceData.serialize(qual.type);
      qual.qualification = ReferenceData.serialize(qual.qualification);
      qual.site = ReferenceData.serialize(qual.site);
      qual.status = ReferenceData.serialize(qual.status);
      return snakeifyKeys(qual);
    });
    return { qualification_occurrence: cloned };
  }
}

export class PagedQualificationOccurrenceList {
  qualificationOccurrence: QualificationOccurrence[];
  page: Page;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  public static deserialize(obj: any): PagedQualificationOccurrenceList {
    if (obj == null) {
      return null;
    }

    const qualificationOccurrence = obj.qualification_occurrence.map((qual) => {
      return QualificationOccurrence.deserialize(qual);
    });
    const page = Page.deserialize(obj.page);

    return new PagedQualificationOccurrenceList({ qualificationOccurrence, page });
  }
}
