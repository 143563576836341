<uc-tag *ngIf="statusLabel" [attr.class]="classes" (click)="showStatusInfoModal()">
  {{ statusLabel }}
  <div class="tag-icon" *ngIf="showInfoModal">
    <uc-icon iconType="info-task" iconWidth="10" iconHeight="10"></uc-icon>
  </div>
</uc-tag>

<uc-confirmation-modal
  *ngIf="statusLabel"
  [title]="modalStrings?.modalHeader(academicYear)"
  [message]="modalStrings?.modalBody(academicYear, isImpersonated, isInternational)"
  [acceptText]="modalStrings?.acceptButtonText || baseStrings.close"
  (accepted)="infoModalAcceptHandler()"
  [declineText]="modalStrings?.declineButtonText"
  declineType="diminished"
  (declined)="log.info('modal dismissed')"></uc-confirmation-modal>
