<uc-form-input *ngIf="!isStaffTask; else staffTaskTemplate">
  <p>{{ label }}</p>

  <div class="major-selectors" *ngFor="let question of subjectQuestions; let i = index">
    <uc-form-field-label [required]="question.required" [labelText]="getLabelText(i)">
      <uc-reference-data-selector
        [testSelector]="getTestSelector(i)"
        [formControl]="controls.at(i)"
        [filter]="filt"
        class="item-selector"
        [options]="question.asDataSelectorOptions()"></uc-reference-data-selector>
    </uc-form-field-label>
  </div>
</uc-form-input>
<ng-template #staffTaskTemplate>
  <uc-form-input>
    <div class="flex-container">
      <div class="major-selectors flex-wrap" *ngFor="let question of subjectQuestions; let i = index">
        <label>
          {{ getLabelText(i) }}
          <span *ngIf="question.required" class="required-asterisk">*</span>
          <select [formControl]="controls.at(i)">
            <option *ngFor="let opt of question.asDataSelectorOptions()" [value]="opt.value">
              {{ opt.value }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </uc-form-input>
</ng-template>
