<uc-form-template>
  <div class="content-container">
    <img src="assets/img/payment_failed.svg" alt="payment-failed" class="failed-icon" />

    <div class="title-container">
      <h4>{{ strings.title }}</h4>
    </div>

    <div class="description-container">
      <p>
        {{ strings.description[0] }}
      </p>
      <p>
        {{ strings.description[1] }}
      </p>
    </div>
  </div>
</uc-form-template>
