<div *ngIf="user" class="card">
  <uc-icon
    iconType="user"
    color="$colorWhite"
    backgroundColor="$colorBlueRibbon"
    iconHeight="32"
    iconWidth="32"></uc-icon>
  <div class="card__content">
    <h2 class="card__title">
      {{ strings.heading }}
    </h2>

    <label>{{ strings.studentId }}</label>
    <p>
      <strong>{{ user.user.studentId || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.firstName }}</label>
    <p>
      <strong>{{ user.applicant.legalName.firstName || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.lastName }}</label>
    <p>
      <strong>{{ user.applicant.legalName.surname || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.email }}</label>
    <p>
      <strong>{{ user.applicant.contactDetail.emailAddress || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.usercode }}</label>
    <p>
      <strong>{{ userCode || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.firebase }}</label>
    <p>
      <strong>{{ user.firebaseID || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.canonical }}</label>
    <p>
      <strong>{{ user.user.cid || strings.notApplicable }}</strong>
    </p>

    <label>{{ strings.login }}</label>
    <p>
      <strong>{{ user.loginType || strings.notApplicable }}</strong>
    </p>
  </div>
</div>
