<uc-form-input [formGroup]="ueForm">
  <uc-subsection>
    <uc-task-hint [task]="task" path="/applicant/ue_qualification/type"></uc-task-hint>
    <uc-subsection-question>
      {{ strings.ueQuestion }}
      <uc-required-field></uc-required-field>
    </uc-subsection-question>
    <uc-radio-input-group type="ue_qualification" groupName="UE" formControlName="type"></uc-radio-input-group>
  </uc-subsection>

  <uc-subsection *ngIf="ueForm.get('type').value !== 'NONE'">
    <uc-task-hint [task]="task" path="/applicant/ue_qualification/date_attained"></uc-task-hint>
    <uc-subsection-question>
      {{ strings.whenCompleteLabel }}
      <uc-required-field></uc-required-field>
    </uc-subsection-question>
    <uc-year-selector
      testSelector="date-attained"
      [baseYear]="maximumAttained"
      [range]="attainedRange"
      [control]="ueForm.get('dateAttained')"></uc-year-selector>
  </uc-subsection>
</uc-form-input>
