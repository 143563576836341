import { Component, OnInit, Input, OnChanges, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { Task } from '@shared/models/task';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';

@Component({
  selector: 'uc-task-hint',
  templateUrl: './task-hint.component.html',
  styleUrls: ['./task-hint.component.scss'],
})
export class TaskHintComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  // A pointer to the field that this should show for
  @Input() task: Task;
  @HostBinding('attr.hint-path') @Input() path: string;
  @Input() model?: string;
  message = '';
  fragment: string;
  shouldDisplay: boolean;

  constructor(
    private route: ActivatedRoute,
    private userActivity: UserActivityService,
  ) {
    super();
  }

  ngOnInit() {
    this.userActivity
      .hasTask(this.route.snapshot.params as IProcessRouteParams)
      .pipe(take(1))
      .subscribe((hasVisited) => {
        this.shouldDisplay = hasVisited;
        if (this.model) {
          this.path = this.model + this.path;
        }
        this.updateMessage();
      });
  }

  ngOnChanges() {
    this.updateMessage();
  }

  private updateMessage() {
    if (this.task && this.task.hints && this.path) {
      const hints = this.task.hints.filter((hint) => {
        return hint.source.pointer === this.path;
      });
      if (hints && hints.length) {
        this.message = hints[0].hintLabel;
        this.fragment = hints[0].fragment;
      }
    }
  }
}
