<div [formGroup]="nameForm">
  <div class="full-name-label">
    <div class="full-name-tab" *ngIf="nameLabel" [innerHtml]="nameLabel"></div>
    <uc-checkbox-input
      *ngIf="shouldShowPreferred"
      class="full-name-preferred-check"
      label="Preferred name"
      formControlName="preferred"
      (change)="setNameAsPreferred(index)"
      groupName="set-preferred"></uc-checkbox-input>
  </div>
  <div class="full-name-wrapper">
    <uc-form-input>
      <div>
        <uc-task-hint [task]="task" path="{{ pathPrefix + 'first_name' }}"></uc-task-hint>
        <uc-string-input
          testSelector="first-name"
          class="input-medium"
          formControlName="firstName"
          label="{{ strings.firstnameLabel }}"
          required="yes"
          [testSelector]="testSelectorId + '-first-name'"
          [maxLength]="30"></uc-string-input>
        <uc-string-input
          testSelector="middle-name"
          class="input-medium"
          formControlName="middleName"
          label="{{ strings.middlenameLabel }}"
          [testSelector]="testSelectorId + '-middle-name'"
          [maxLength]="60"></uc-string-input>
        <uc-task-hint [task]="task" path="{{ pathPrefix + 'surname' }}"></uc-task-hint>
        <uc-string-input
          testSelector="surname"
          class="input-medium"
          formControlName="surname"
          label="{{ strings.surnameLabel }}"
          required="yes"
          [testSelector]="testSelectorId + '-last-name'"
          [maxLength]="50"></uc-string-input>
      </div>
    </uc-form-input>
    <button
      [attr.data-cy]="testSelector + '-delete'"
      class="delete-name-btn"
      *ngIf="nameLabel !== 'Legal Name'"
      (click)="deleteThisName()">
      <uc-icon
        class="delete-name-btn-icon"
        iconType="trash"
        iconWidth="16"
        iconHeight="16"
        iconFill="$colorFireEngineRed"></uc-icon>
      <span class="delete-name-btn-text">{{ strings.removeNameLabel }}</span>
    </button>
  </div>
</div>
