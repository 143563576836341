<div [formGroup]="policeRecord">
  <uc-yes-no
    [task]="task"
    [model]="hintPath"
    path="/police_check/has_charges_pending"
    formControlName="chargesControl"
    questionString="{{ strings.pending }}"
    required="yes"
    testSelector="pending">
    <uc-yes-content>
      <uc-subsection>
        <uc-task-hint [task]="task" [model]="hintPath" path="/police_check/charges_description"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.detailCharges }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-text-area testSelector="police-charges" formControlName="declarationPoliceCharges"></uc-text-area>
      </uc-subsection>
    </uc-yes-content>
  </uc-yes-no>

  <uc-yes-no
    [task]="task"
    [model]="hintPath"
    path="/police_check/has_convictions"
    formControlName="convictionControl"
    questionString="{{ strings.convicted }}"
    required="yes"
    testSelector="convicted">
    <uc-yes-content>
      <uc-subsection>
        <uc-task-hint [task]="task" [model]="hintPath" path="/police_check/convictions_description"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.detailConvictions }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-subsection-minor-text>{{ strings.excludeMinor }}</uc-subsection-minor-text>
        <uc-text-area testSelector="police-convictions" formControlName="declarationPoliceConvictions"></uc-text-area>
      </uc-subsection>
    </uc-yes-content>
  </uc-yes-no>
</div>
