import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { UCProcess } from '@shared/models/process';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-myuc-applicant-splash-page',
  templateUrl: './myuc-applicant-splash-page.component.html',
  styleUrls: ['./myuc-applicant-splash-page.component.scss'],
})
export class MyucApplicantSplashPageComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('grid', { static: true }) grid: any;

  internalUrls = internalUrls;
  processes: UCProcess[] = [];
  breakpoints = [
    [500, 4],
    [1024, 8],
    [1920, 12],
  ];
  strings = strings.components.template.applicantSplashPage;
  loading = true;

  UCO_ALLOWED_PROCESS_IDS = ['new_student', 'returning'];

  constructor(
    private ucoProcessService: ProcessService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getSplashProcesses();
  }

  getSplashProcesses() {
    // @ts-ignore
    this.ucoProcessService.getProcesses().subscribe(
      (ps) => {
        this.processes = ps
          .filter((x) => !!x.displayOnSplash)
          .filter((x) => this.UCO_ALLOWED_PROCESS_IDS.includes(x.code));

        if (this.processes.length === 1) {
          // @ts-ignore

          this.router.navigate([this.processes[0].link]);
        } else {
          this.loading = false;
        }
      },
      () => {
        this.loading = false;
      },
    );
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
