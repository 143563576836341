<uc-task-hint *ngIf="task && path" [task]="task" [path]="path"></uc-task-hint>
<label *ngIf="labelText">
  {{ labelText }}
  <uc-required-field *ngIf="required"></uc-required-field>
</label>
<div class="fields-wrap clearfix" [class.has-focus]="hasFocus" [class.hasValue]="hasValue">
  <select [formControl]="countryCodeControl" [attr.data-cy]="testSelectorId + '-code'">
    <option value=""></option>
    <option *ngFor="let datum of options" [value]="datum.code">
      {{ datum.description }}
    </option>
  </select>
  <input
    [formControl]="numberControl"
    type="text"
    name="phone-number"
    (blur)="stripInvalidCharacters($event)"
    (paste)="stripInvalidCharacters($event)"
    (keypress)="stripInvalidCharacters($event)"
    (focus)="focusField()"
    (focusout)="removeFieldFocus()"
    [attr.data-cy]="testSelectorId + '-number'" />
  <div class="validation-indicator">
    <uc-icon
      class="valid-icon"
      iconTitle="Valid"
      iconType="tick"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
    <uc-icon
      class="invalid-icon"
      iconTitle="Invalid"
      iconType="close"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
  </div>
</div>
<div>
  <a href="{{ urls.countryCode }}" target="_blank" rel="noopener" class="help-link">{{ strings.whatsMyCountryCode }}</a>
</div>
