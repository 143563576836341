import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-agent-details',
  templateUrl: './agent-details.component.html',
})
export class AgentDetailsComponent implements OnInit {
  @Input() agentDetails: UntypedFormGroup;
  @Input() task: Task;
  strings = strings.components.tasks.applicationToEnrol.agentDetails;

  constructor() {}

  ngOnInit() {}
}
