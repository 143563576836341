import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[uc-button]',
  template: `
    <uc-icon
      *ngIf="iconType"
      [iconType]="iconType"
      [color]="color"
      [iconHeight]="iconHeight"
      [iconWidth]="iconWidth"></uc-icon>
    <span class="btn-text" [class.hide]="isLoading">
      <ng-content></ng-content>
    </span>
    <uc-loader-blip [showBlips]="isLoading"></uc-loader-blip>
  `,
})
export class ButtonAttrComponent implements OnInit {
  @Input() isLoading = false;
  @Input() externalLink = '';
  @Input() replaceUrl = false;
  @Input() iconType: string;
  @Input() color = 'currentColor';
  @Input() iconHeight = 16;
  @Input() iconWidth = 16;
  @Input() type: string;
  @Input() size: string;
  @Input() padding: string;
  @Input() class = '';

  @HostBinding('class') classList: string;
  @HostBinding('disabled') hostDisabled = null;
  @HostBinding('hidden') hostHidden = null;

  log: Logger;

  constructor(
    logging: LoggingService,
    private ws: WindowService,
  ) {
    this.log = logging.createLogger(this);
  }

  @Input()
  set disabled(value: boolean) {
    this.hostDisabled = value ? true : null;
  }

  @Input()
  set hidden(value: boolean) {
    this.hostHidden = value ? true : null;
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    const preventButtonClick = this.disabled || this.isLoading;
    if (preventButtonClick) {
      e.stopPropagation();
    }
    if (this.externalLink !== '') {
      const windowRef = this.ws.nativeWindow;
      const target = this.replaceUrl ? '_self' : '_blank';
      windowRef.open(this.externalLink, target);
    }
  }

  ngOnInit() {
    this.addClasses();
  }

  addClasses() {
    const classes = this.class ? this.class.split(' ') : [];
    classes.push('button');
    if (this.type) {
      classes.push(`type-${this.type}`);
    }
    if (this.size) {
      classes.push(`size-${this.size}`);
    }
    if (this.padding) {
      classes.push(`padding-${this.padding}`);
    }
    if (this.iconType) {
      classes.push('has-icon');
    }
    this.classList = classes.join(' ');
  }
}
