import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { flatten } from 'lodash-es';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { switchMap, takeUntil, filter, map } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { Qualification } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import {
  UCElementGroup,
  control,
  group,
  valueToRefData,
  refDataToValue,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-teaching-qualification',
  templateUrl: './teaching-qualification.component.html',
  styleUrls: ['./teaching-qualification.component.scss'],
})
export class TeachingQualificationComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() fundingType: string;
  @Input() process: string;

  strings = strings.components.tasks.teachingQualification;
  teachingQualificationForm: UCElementGroup;
  teachingQualificationPage: UntypedFormGroup;
  log: Logger;
  siteOptions;
  fullPartTimeOptions = [];
  qualType: string;
  teachingQual: Qualification;
  studyInNz: boolean;
  currentApplicant: Applicant;

  qualOptions = {
    'BTCHLN (EARLY CHILDHOOD)': {
      Domestic: [
        {
          Christchurch: ['Full time', 'Part time'],
        },
        {
          Distance: ['Full time', 'Part time'],
        },
      ],
      International: [
        {
          Christchurch: ['Full time'],
        },
      ],
    },
    'BTCHLN (PRIMARY)': {
      Domestic: [
        {
          Christchurch: ['Full time'],
        },
        {
          Nelson: ['Full time'],
        },
        {
          Rotorua: ['Full time'],
        },
        {
          Distance: ['Full time', 'Part time'],
        },
      ],
      International: [
        {
          Christchurch: ['Full time'],
        },
      ],
    },
    GRADDIPECTCH: {
      Domestic: [
        {
          Distance: ['Full time', 'Part time'],
        },
      ],
      International: [
        {
          Distance: ['Full time'],
        },
      ],
    },
    'GRADDIPTCHLN (PRIMARY)': {
      Domestic: [
        {
          Christchurch: ['Full time'],
        },
        {
          Distance: ['Full time', 'Part time'],
        },
      ],
      International: [
        {
          Christchurch: ['Full time'],
        },
      ],
    },
    'GRADDIPTCHLN (SECONDARY)': {
      Domestic: [
        {
          Christchurch: ['Full time'],
        },
      ],
      International: [
        {
          Christchurch: ['Full time'],
        },
      ],
    },
    MTCHGLN: {
      Domestic: [
        {
          Christchurch: ['Full time'],
        },
      ],
      International: [
        {
          Christchurch: ['Full time'],
        },
      ],
    },
  };

  constructor(
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private enrolmentService: EnrolmentService,
    private qualificationService: QualificationService,
    private loggingService: LoggingService,
    private coeService: ChangeOfEnrolmentService,
    private formModel: FormModelMapperService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  private createForm(): UCElementGroup {
    const stringToBoolean = (c: UntypedFormControl) => {
      switch (c.value) {
        case 'Full time':
          return true;
        case 'Part time':
          return false;
        default:
          return null;
      }
    };
    const booleanToString = (bool: boolean) => {
      if (bool == null) {
        return '';
      }
      return bool ? 'Full time' : 'Part time';
    };

    return group({
      studyLocation: control({
        model: this.dataModel,
        path: '/studyLocation',
        validators: [Validators.required],
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),

      studyFullTime: control({
        model: this.dataModel,
        path: '/studyFullTime',
        validators: [Validators.required],
        outMap: stringToBoolean,
        inMap: booleanToString,
      }),
    });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.teachingQualificationForm);
  }

  private arrayToOptions = (array) => {
    return array.map((name) => {
      return {
        labelText: name,
        value: name,
      };
    });
  };

  ngOnInit() {
    this.setProcessCode(this.process);
    this.getService(this.applicationService, this.coeService);
    this.teachingQualificationForm = this.createForm();
    this.teachingQualificationPage = this.teachingQualificationForm.asControl() as UntypedFormGroup;

    this.teachingQualificationForm.controls.studyLocation.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((v) => !!v),
      )
      .subscribe((val) => {
        this.fullPartTimeOptions = this.arrayToOptions(
          this.qualOptions[this.qualType][this.fundingType].find((x) => Object.keys(x).find((key) => key === val))[val],
        );

        this.teachingQualificationForm.controls.studyFullTime.control.reset();
      });

    observableCombineLatest([
      this.applicantService.applicant.pipe(filter((v) => !!v)),
      this.service.application.pipe(filter((v) => !!v)),
      this.isCOE ? [] : this.applicationService.getApplicationEnrolment(this.applicationYear),
    ])
      .pipe(
        takeUntil(this.componentDestroyed),
        switchMap(([applicant, application, enrolments]) => {
          this.qualType = this.findTeachingQual(enrolments as ApplicationEnrolment[]);
          return this.qualificationService.getQualification(this.applicationYear, this.qualType).pipe(
            map((qual) => {
              this.teachingQual = qual;
              return [applicant, application, enrolments];
            }),
          );
        }),
      )
      .subscribe(([applicant, application]) => {
        this.currentApplicant = applicant as Applicant;
        this.currentApplication = application as Application;

        if (!this.qualOptions[this.qualType]) {
          this.log.info('qualification is not supported in ui options', this.qualType);
          return;
        }

        this.siteOptions = this.qualOptions[this.qualType][this.fundingType].map((l) => {
          const locationKeys = Object.keys(l);
          return this.arrayToOptions(locationKeys)[0];
        });

        this.formModel.updateFormFromModel(this.teachingQualificationForm, null, this.currentApplication);
      });
  }

  findTeachingQual(enrolments: ApplicationEnrolment[]) {
    const teachingQuals = Object.keys(this.qualOptions);
    let allQuals: string[];
    if (this.isCOE) {
      this.currentApplication = this.currentApplication as ChangeOfEnrolment;
      allQuals = this.currentApplication.enrolledQualifications.map((eq) => eq.code);
    } else {
      allQuals = flatten(enrolments.map((enrolment) => enrolment.enrolledQualifications.map((eq) => eq.code)));
    }
    const qual = allQuals.find((q) => teachingQuals.find((tq) => q === tq) as any);
    return qual;
  }

  public update() {
    if (!this.currentApplication || !this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update application, but no application or applicant has been loaded yet');
    }

    const application = this.isCOE ? this.updateChangeOfEnrolmentModel() : this.currentApplication;

    this.formModel.updateModelFromForm(this.teachingQualificationForm, null, application);
    this.service.update(application).subscribe({
      next: (app) => {
        if (app) {
          this.log.info('updated the application successfully');
          this.next.emit();
        }
      },
      error: (error) => {
        this.errors.emit();
        this.log.error('failed to update the application', error);
      },
    });
  }

  updateChangeOfEnrolmentModel(): ChangeOfEnrolment {
    return new ChangeOfEnrolment({
      studyLocation: this.currentApplication.studyLocation,
      studyFullTime: this.currentApplication.studyFullTime,
    });
  }
}
