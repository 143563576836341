import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

import { Application } from './application';
import { ChangeOfEnrolment } from './change-of-enrolment';

export class BackgroundCheck {
  public country: ReferenceData;
  public applied: ReferenceData;
  public type: ReferenceData;
  public internalReference: string;
  public receivedDate: string;
  public reportDate: string;
  public owner: string;

  constructor(rawName: any) {
    Object.assign(this, rawName);
  }

  static deserializeArray(payload: Application | ChangeOfEnrolment): BackgroundCheck[] {
    return camelizeKeys(payload)?.backgroundChecks?.map(BackgroundCheck.deserialize);
  }

  static deserialize(payload: any): BackgroundCheck {
    if (payload === null) {
      return null;
    }
    const bc: BackgroundCheck = camelizeKeys(payload);
    if (bc.type) {
      bc.type = ReferenceData.deserialize(bc.type);
    } else if (bc.country) {
      bc.country = ReferenceData.deserialize(bc.country);
    } else if (bc.applied) {
      bc.applied = ReferenceData.deserialize(bc.applied);
    }
    return new BackgroundCheck(bc);
  }

  static serialize(instance: BackgroundCheck): any {
    const clone = { ...instance };
    clone.country = ReferenceData.serialize(clone.country);
    clone.applied = ReferenceData.serialize(clone.applied);

    if (clone.type) {
      clone.type = ReferenceData.serialize(clone.type);
    }

    return snakeifyKeys(clone);
  }
}
