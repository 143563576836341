<div [class]="classes">
  <div class="br-units" (mouseleave)="reset()">
    <div
      class="br-unit"
      *ngFor="let unit of contexts"
      [class.br-selected]="unit.selected"
      [class.br-active]="unit.active"
      (click)="unit.click($event)"
      (mouseenter)="unit.enter()"></div>
  </div>
</div>
