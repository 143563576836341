<span [formGroup]="dateTimeForm">
  <uc-date-selector
    [order]="order"
    [range]="range"
    formControlName="date"
    [noIcon]="true"
    testSelectorId="time"></uc-date-selector>
  <span class="string-icons" [class.hasValue]="hasValue">
    <uc-string-input
      testSelector="time"
      [noIcon]="true"
      formControlName="time"
      label="Time"
      placeholder="24:00"
      testSelector="time-selector"></uc-string-input>
    <uc-icon
      class="valid-icon"
      iconTitle="Valid"
      iconType="tick"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
    <uc-icon
      class="invalid-icon"
      iconTitle="Invalid"
      iconType="close"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
  </span>
</span>
