import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-studenthub-cta',
  template: `
    <div class="cta">
      <div class="content">
        <div class="content-heading">
          <h2>{{ heading }}</h2>
        </div>
        <div class="content-sub">
          <p>{{ subheading }}</p>
          <div>
            <a [href]="url" target="_blank">{{ button }}</a>
          </div>
        </div>
      </div>
      <div class="wananga"></div>
    </div>
  `,
  styleUrls: ['./studenthub-cta.component.scss'],
})
export class StudentHubCTAComponent {
  @Input() heading = '';
  @Input() subheading = '';
  @Input() button = '';
  @Input() url = '';
}
