<div class="reference-data-selector">
  <label *ngIf="labelName">
    {{ labelName }}
    <uc-required-field *ngIf="required"></uc-required-field>
  </label>
  <div *ngIf="!isDisabled" class="select-content" [class.hasValue]="hasValue" [class.no-icon]="noIcon">
    <div
      *ngIf="enableClearFieldButton && innerControl.value"
      class="clear-field-button"
      (click)="resetFormControlValue()">
      <uc-icon id="close-icon" iconType="close" [iconWidth]="11" [iconHeight]="11"></uc-icon>
    </div>
    <ng-select
      [attr.data-cy]="testSelectorId | async"
      *ngIf="useNgSelect"
      [formControl]="innerControl"
      [placeholder]="ngSelectAttributes?.placeholder ? selectPlaceHolder : null">
      <ng-option *ngIf="(extraOptions$ | async).length === 0 && showBlankOption" [value]="null"></ng-option>
      <ng-option *ngFor="let datum of processedOptions$ | async" [value]="datum.value">
        {{ datum.labelText }}
      </ng-option>
    </ng-select>
    <select
      [attr.data-cy]="testSelectorId | async"
      *ngIf="!useNgSelect"
      [formControl]="innerControl"
      [id]="inputID"
      [ngClass]="{ 'hide-dropdown-icon': enableClearFieldButton && innerControl.value }">
      <option *ngIf="selectPlaceHolder && !innerControl.value" [ngValue]="null" hidden disabled>
        {{ selectPlaceHolder }}
      </option>
      <option *ngIf="(extraOptions$ | async).length === 0 && showBlankOption" [ngValue]="null"></option>
      <option *ngFor="let datum of processedOptions$ | async" [ngValue]="datum.value">
        {{ datum.labelText }}
      </option>
    </select>

    <uc-icon
      *ngIf="!noIcon"
      class="valid-icon"
      iconType="tick"
      [iconWidth]="11"
      [iconHeight]="11"
      iconFill="$colorWhite"></uc-icon>
    <uc-icon
      *ngIf="!noIcon"
      class="invalid-icon"
      iconType="close"
      [iconWidth]="11"
      [iconHeight]="11"
      iconFill="$colorWhite"></uc-icon>
  </div>
  <uc-disabled-atom-state
    *ngIf="isDisabled"
    [enableModal]="showDisabledExplanation"
    [value]="stringValue | async"></uc-disabled-atom-state>
</div>
