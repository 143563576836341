<label *ngIf="label" class="label-text">
  {{ label }}
  <uc-required-field *ngIf="required"></uc-required-field>
</label>
<div class="radio-input-group" [class.hasValue]="hasValue" [class.isDisabled]="isDisabled">
  <div
    *ngIf="!isDisabled"
    class="fields-wrap clearfix"
    [class.horizontal-group]="displayHorizontally"
    [class.has-additional-text]="hasAdditionalText">
    <uc-radio-input
      *ngFor="let option of options"
      [labelText]="option.labelText"
      [formControl]="radioGroupControl"
      [groupValue]="radioGroupControl.value"
      [additionalText]="additionalText"
      [groupName]="groupName"
      [fieldValue]="option.value"
      [testSelector]="testSelector"></uc-radio-input>
    <div class="validation-indicator validate-options" *ngIf="validateOptions">
      <uc-icon
        iconType="tick"
        class="valid-icon"
        iconTitle="Valid"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
      <uc-icon
        iconType="close"
        class="invalid-icon"
        iconTitle="Invalid"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"></uc-icon>
    </div>
    <div class="validation-indicator" *ngIf="!validateOptions">
      <uc-icon iconType="tick" iconTitle="Valid" iconWidth="11" iconHeight="11" iconFill="$colorWhite"></uc-icon>
    </div>
  </div>
  <uc-disabled-atom-state
    *ngIf="isDisabled"
    [value]="stringValue"
    [enableModal]="showDisabledExplanation"></uc-disabled-atom-state>
</div>
