import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-go-back-link',
  templateUrl: './go-back-link.component.html',
  styleUrls: ['./go-back-link.component.scss'],
})
export class GoBackLinkComponent implements OnInit {
  @Input() customText?: string;
  @Input() targetPath?: string;
  strings = strings.components.atoms.goBackLink;
  backLinkText: string;

  constructor(
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    this.backLinkText = this.customText || this.strings.back;
  }

  goBackLink() {
    if (this.targetPath) {
      this.router.navigateByUrl(this.targetPath);
    } else {
      this.location.back();
    }
  }
}
