import { Component, Input } from '@angular/core';
import { snakeCase } from 'lodash-es';
import { Observable, map } from 'rxjs';

import strings from '@shared/constants/strings.constants';

@Component({
  selector: 'uc-download-button',
  template: '<uc-button  (click)="downloadData()" type="button" [disabled]="disabled">{{ label }}</uc-button>',
})
export class DownloadButtonComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: Observable<any[]> | any[];
  @Input() label = 'Download';
  @Input() columns: { name: string; label: string }[] = null;
  @Input() fileName: string;
  @Input() disabled = false;

  downloadData() {
    if (this.data instanceof Observable) {
      this.data.pipe(map((data) => this.convertToCSV(data))).subscribe((csvData) => this.triggerDownload(csvData));
    } else {
      this.triggerDownload(this.convertToCSV(this.data));
    }
  }

  // eslint-disable-next-line complexity
  private convertToCSV(data: Array<unknown>): string {
    const csvRows = [];

    const headers: string[] = this.columns?.map((c) => c.label) || Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = this.columns ? this.columns?.map((c) => this.escape(row[c.name])) : Object.values(row);
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any, complexity
  private escape(field: any): string {
    if (field == null || field === strings.loading) {
      return '';
    }
    if (typeof field === 'string') {
      return `"${String(field).replace(/\"/g, '""')}"`;
    }
    return field;
  }

  // eslint-disable-next-line class-methods-use-this
  private triggerDownload(csvData: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', this.fileName || `${snakeCase(this.label)}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
