<div class="404-wrapper">
  <div class="uc-banner">
    <a class="banner-logo" [href]="externalUrls.ucHomepage">
      <img class="banner-logo-image" src="assets/img/uc-logo@2x.png" alt="UC Logo" />
    </a>
    <h1 class="myuc-logo accent-font">{{ appName }}</h1>
  </div>

  <div class="screen-message">
    <div class="screen-message-content">
      <div class="screen-message-img">
        <img src="assets/img/socks.png" alt="" />
      </div>
      <div class="screen-text">
        <h1 class="screen-message-title">{{ strings.notFound }}</h1>
        <div class="screen-message-body" [innerHTML]="strings.messageText"></div>
        <a role="button" class="screen-message-btn" href="javascript:void(0)" routerLink="">{{ strings.backToDash }}</a>
      </div>
    </div>
  </div>

  <uc-footer></uc-footer>
</div>
