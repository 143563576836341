import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { UCElementGroup, UCElementArray } from '@shared/services/form-model-mapper/form';

@Component({
  selector: 'uc-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  @Input() personalDetails: UntypedFormGroup = new UntypedFormGroup({});
  @Input() personalDetailsEl: UCElementGroup;

  strings = strings.components.organisms.profilePage.personalDetails;
  dateOfBirthYear: number;

  get otherNames(): UCElementArray {
    return this.personalDetailsEl.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return get(this.otherNames, 'control.length');
  }

  get demographicElements(): UCElementArray {
    return this.personalDetailsEl.controls.citizenship;
  }

  ngOnInit() {
    this.dateOfBirthYear = new Date().getFullYear() - 10;
  }
}
