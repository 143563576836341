import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import {
  QUALIFICATION_LEVELS,
  COUNTRY_OPTIONS,
  SECONDARY_QUAL_CODES,
  SCHOOLS,
} from '@shared/constants/academic-history';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  array,
  UCElementArray,
  numberToString,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-academic-history',
  templateUrl: './academic-history.component.html',
  styleUrls: ['./academic-history.component.scss'],
})
export class AcademicHistoryComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  strings = strings.components.tasks.academicHistory;
  log: Logger;

  academicHistoryForm: UCElementGroup;
  academicHistory: UntypedFormGroup;
  secondaryLocationForm = new UntypedFormArray([]);
  highestStudyLevelAdditionalText = [];

  tertiaryStudyLevels = [
    QUALIFICATION_LEVELS.FOUNDATION,
    QUALIFICATION_LEVELS.POST_GRADUATE,
    QUALIFICATION_LEVELS.UNDER_GRADUATE,
  ];

  private currentApplicant: Applicant;
  currentApplication: Application;

  constructor(
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    ls: LoggingService,
  ) {
    super();
    this.log = ls.createLogger(this);
  }

  get highestStudyLevel() {
    return get(this.academicHistory.get('highestStudyLevel'), 'value');
  }

  get highestStudyLevelTertiary() {
    return this.highestStudyLevel && this.tertiaryStudyLevels.indexOf(this.highestStudyLevel) !== -1;
  }

  get secondaryEducations() {
    return this.academicHistoryForm.get('secondaryEducation').get('educations') as UCElementArray;
  }

  get tertiaryEducations() {
    return this.academicHistoryForm.get('tertiaryEducation').get('educations') as UCElementArray;
  }

  private addSecondaryLocation(): void {
    this.secondaryLocationForm.controls.push(new UntypedFormControl('', Validators.required));
  }

  private setupSecondaryStudyLocations(secondaryQuals: AttainedQualification[]): void {
    const controls = secondaryQuals.map((qual) => {
      let location = qual.country?.code;
      if (location) {
        location = qual.country?.code === COUNTRY_OPTIONS.NZL ? qual.country.code : COUNTRY_OPTIONS.OTHER;
      }
      return new UntypedFormControl(location, Validators.required);
    });
    this.secondaryLocationForm = new UntypedFormArray(controls);
    if (!controls.length) {
      this.addSecondaryLocation();
    }
  }

  addSecondaryQual() {
    this.addSecondaryLocation();
    this.secondaryEducations.push();
  }

  removeSecondaryQual(index) {
    this.secondaryLocationForm.removeAt(index);
    this.secondaryEducations.removeAt(index);
  }

  // Form relies on there being at least one of these, even if they are dummy objects
  // They get cleaned up before persisting to API if no values set on them
  private setupAttainedQualifications(applicant: Applicant) {
    if (!applicant.secondaryQualification.length) {
      applicant.secondaryQualification.push(new AttainedQualification());
    }
    if (!applicant.tertiaryQualification.length) {
      applicant.tertiaryQualification.push(new AttainedQualification());
    }
    if (!applicant.ueQualification) {
      applicant.ueQualification = new AttainedQualification();
    }
  }

  private createUcElementGroup(): UCElementGroup {
    const refDataToString = (c) => get(c, 'value.code');
    const stringToRefData = (v: string) => new ReferenceData({ code: v });

    return group({
      highestStudyLevel: control({
        model: 'applicant',
        path: '/ueQualification/type',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      secondaryEducation: group({
        nsnNumber: control({ model: 'applicant', path: '/identity/nsnNumber' }),
        lastSchoolAttended: control({
          model: 'applicant',
          path: '/demographic/moeLastSchoolAttended',
          validators: [Validators.required],
        }),
        lastYearAttended: control({
          model: 'applicant',
          path: '/demographic/moeYearLastAttendedSchool',
          validators: [Validators.required],
          inMap: numberToString,
        }),
        wasLastYearAtHighSchool: control({
          model: 'application',
          path: '/wasLastYearAtHighSchool',
          validators: [Validators.required],
        }),
        educations: array({
          model: 'applicant',
          path: '/secondaryQualification',
          group: group({
            type: control({ validators: [Validators.required], model: 'applicant', path: '/type' }),
            typeOther: control({ validators: [Validators.required], model: 'applicant', path: '/typeOther' }),
            source: control({
              validators: [Validators.required],
              model: 'applicant',
              path: '/source',
            }),
            dateAttained: control({ validators: [Validators.required], model: 'applicant', path: '/dateAttained' }),
            attained: control({ validators: [Validators.required], model: 'applicant', path: '/attained' }),
            sourceOther: control({ validators: [Validators.required], model: 'applicant', path: '/sourceOther' }),
            country: control({
              validators: [Validators.required],
              model: 'applicant',
              path: '/country',
            }),
          }),
        }),
      }),
      tertiaryEducation: group({
        declarationBeenExcluded: control({
          model: 'application',
          path: '/declarationBeenExcluded',
          validators: [Validators.required],
        }),
        transferCredits: control({ model: 'application', path: '/transferCredits', validators: [Validators.required] }),
        educations: array({
          model: 'applicant',
          path: '/tertiaryQualification',
          group: group({
            type: control({ validators: [Validators.required], model: 'applicant', path: '/type' }),
            typeOther: control({ validators: [Validators.required], model: 'applicant', path: '/typeOther' }),
            sourceOther: control({ validators: [Validators.required], model: 'applicant', path: '/sourceOther' }),
            dateStarted: control({
              validators: [Validators.required],
              model: 'applicant',
              path: '/dateStarted',
              outMap: refDataToString,
              inMap: stringToRefData,
            }),
            dateAttained: control({ validators: [Validators.required], model: 'applicant', path: '/dateAttained' }),
            attained: control({ validators: [Validators.required], model: 'applicant', path: '/attained' }),
            country: control({
              validators: [Validators.required],
              model: 'applicant',
              path: '/country',
            }),
          }),
        }),
      }),
    });
  }

  ngOnInit() {
    Object.keys(QUALIFICATION_LEVELS).forEach((level) => {
      const code = QUALIFICATION_LEVELS[level];
      const inlineGuidance = this.strings.studyLevelAdditionalText[code];
      this.highestStudyLevelAdditionalText.push({ code, inlineGuidance });
    });

    this.academicHistoryForm = this.createUcElementGroup();
    this.academicHistory = this.academicHistoryForm.asControl() as UntypedFormGroup;

    // this.createMapping(this.academicHistory, this.formModelMap);
    observableCombineLatest(
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application.pipe(filter((a) => !!a)),
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.setupAttainedQualifications(applicant);
        this.setupSecondaryStudyLocations(applicant.secondaryQualification);
        this.formModel.updateFormFromModel(this.academicHistoryForm, this.currentApplicant, this.currentApplication);
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.academicHistoryForm);
  }

  resetTypeField(tertiaryEds: UntypedFormArray) {
    tertiaryEds.controls.forEach((education: UntypedFormArray) => {
      education.get('type').reset();
    });
  }

  update() {
    const getLocation = (index) => this.secondaryLocationForm.at(index).value;
    const secondaryEducations = this.academicHistory.get('secondaryEducation').get('educations') as UntypedFormArray;
    secondaryEducations.controls.forEach((secondary: UntypedFormArray, index) => {
      const location = getLocation(index);
      if (location === COUNTRY_OPTIONS.OTHER) {
        secondary.get('attained').reset();
        secondary.get('source').reset();
        secondary.get('type').setValue({ code: SECONDARY_QUAL_CODES.OVERSEAS });
      }
      if (location === COUNTRY_OPTIONS.NZL) {
        if (get(secondary.get('type').value, 'code') === SECONDARY_QUAL_CODES.OVERSEAS) {
          secondary.get('type').reset();
        }
        if (get(secondary.get('type').value, 'code') !== SECONDARY_QUAL_CODES.OTHER) {
          secondary.get('typeOther').reset();
        }
        secondary.get('country').setValue({ code: COUNTRY_OPTIONS.NZL });
      }
    });

    // Ensure lastYearAttended is same as first qual date attained if they said it was
    const secondaryEducation = this.academicHistory.get('secondaryEducation') as UntypedFormGroup;
    const firstSecondaryQual = secondaryEducations.at(0);
    if (secondaryEducation.get('wasLastYearAtHighSchool').value) {
      const firstSecondaryEdDateAttained = firstSecondaryQual.get('dateAttained').value;
      secondaryEducation.get('lastYearAttended').setValue(firstSecondaryEdDateAttained);
    }

    // Set lastSchoolAttended to school of first qual
    const isNZQual = getLocation(0) === COUNTRY_OPTIONS.NZL;
    const lastSchoolAttended = isNZQual
      ? firstSecondaryQual.get('source').value
      : new ReferenceData({ code: SCHOOLS.OVERSEAS });
    secondaryEducation.get('lastSchoolAttended').setValue(lastSchoolAttended);

    const tertiaryEducations = this.academicHistory.get('tertiaryEducation').get('educations') as UntypedFormArray;
    const studyLevel = this.academicHistory.get('highestStudyLevel').value;
    switch (studyLevel) {
      case QUALIFICATION_LEVELS.SECONDARY:
        this.academicHistory.get('tertiaryEducation').reset();
        break;
      case QUALIFICATION_LEVELS.FOUNDATION:
        tertiaryEducations.controls.forEach((education: UntypedFormArray) => {
          const qualType = get(education.get('type').value, 'code');
          if (qualType !== 'OTHER') {
            education.get('country').reset();
            education.get('sourceOther').reset();
          }
        });
        break;
      case QUALIFICATION_LEVELS.POST_GRADUATE:
        this.resetTypeField(tertiaryEducations);
        break;
      case QUALIFICATION_LEVELS.UNDER_GRADUATE:
        this.resetTypeField(tertiaryEducations);
        break;
      default:
        break;
    }

    this.formModel.updateModelFromForm(this.academicHistoryForm, this.currentApplicant, this.currentApplication);

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    observableCombineLatest(updateApplicant, updateApplication).subscribe(
      // Success
      ([applicant, application]: [Applicant, Application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      // Error
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating applica(nt|tion):', err);
      },
    );
  }
}
