<uc-form-template [formGroup]="onlineShortCourseDetailsPage" class="uconline-profile">
  <uc-form-header title="Your details">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header [title]="strings.identificationDetailsSection.header">
      <p>{{ strings.identificationDetailsSection.description[0] }}</p>
      <p>{{ strings.identificationDetailsSection.description[1] }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/birth_date"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.identificationDetailsSection.birthDateQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-date-selector
          [baseYear]="baseYear"
          [range]="85"
          formControlName="birthDate"
          required="true"
          testSelector="dob"></uc-date-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-task-hint class="sub-section" [task]="task" path="/applicant/legal_name"></uc-task-hint>

    <uc-legal-name-declaration
      [task]="task"
      [applicationYear]="applicationYear"
      (nameAdd)="this.otherNames.push()"
      (nameRemove)="this.otherNames.removeAt($event)"
      [otherNamesLength]="otherNamesLength"
      [namesModel]="onlineShortCourseDetailsPage.get('names')"></uc-legal-name-declaration>

    <uc-subsection>
      <uc-gender-selector
        [task]="task"
        [genderForm]="onlineShortCourseDetailsPage.get('genderGroup')"></uc-gender-selector>
    </uc-subsection>
  </uc-section>

  <uc-section [formGroup]="onlineShortCourseDetailsPage.get('citizenship')">
    <uc-section-header [title]="strings.citizenshipSection.header"></uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/demographic/citizenship"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.citizenshipSection.categoryQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-reference-data-selector
          type="citizenship_category"
          formControlName="citizenCategory"
          required="true"></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="!isNzOrAus(this.onlineShortCourseDetailsPage.get('citizenship.citizenCategory').value)">
      <uc-form-input>
        <uc-task-hint [task]="task" path="/applicant/demographic/passport_country"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.citizenshipSection.countryQuestion }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-reference-data-selector
          type="country"
          [filter]="['NZL', 'AUS']"
          formControlName="citizenCountry"
          required="true"></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/application/study_in_nz"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.citizenshipSection.liveInNzQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.citizenshipSection.liveInNzDescription }}
      </uc-subsection-minor-text>

      <uc-form-input>
        <uc-radio-input-group
          formControlName="studyInNz"
          [options]="radioOptions"
          label=""
          [displayHorizontally]="true"
          groupName="study-nz"></uc-radio-input-group>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section [formGroup]="onlineShortCourseDetailsPage.get('contactDetails')">
    <uc-section-header [title]="strings.contactDetailsSection.header">
      <p>{{ strings.contactDetailsSection.description }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-form-input>
        <uc-subsection-question>{{ strings.contactDetailsSection.emailSubsectionQuestion }}</uc-subsection-question>
        <uc-task-hint [task]="task" path="/applicant/contact_detail/email_address"></uc-task-hint>
        <uc-string-input
          testSelector="email"
          class="input-medium"
          [label]="strings.contactDetailsSection.emailLabel"
          formControlName="email"
          required="true"></uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-form-input>
        <uc-task-hint [task]="task" path="/applicant/contact_detail/contact_number"></uc-task-hint>
        <uc-subsection-question>{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
        <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          [required]="true"
          formControlName="mobileNum"
          [labelText]="phoneStrings.mobileLabel"
          path="/applicant/contact_detail/mobile_number"
          [task]="task"
          testSelector="contact-mobile"></uc-phone-selector>
      </uc-form-input>
    </uc-subsection>
    <uc-section-header [title]="strings.contactDetailsSection.preferredContactMethodLabel">
      <uc-form-input>
        <uc-radio-input-group
          groupName="preferred-contact-method"
          class="preferredContactMethod"
          formControlName="preferredContactMethod"
          ngDefaultControl
          [options]="preferredContactMethodOptions"
          [displayHorizontally]="true"
          label=""></uc-radio-input-group>
      </uc-form-input>
    </uc-section-header>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ learningNeedsStrings.header }}"></uc-section-header>
    <uc-fitness-to-teach
      [task]="task"
      [fitnessToTeachForm]="onlineShortCourseDetailsPage.get('fitnessToTeachGroup')"
      [disabilityDetailRequired]="true"></uc-fitness-to-teach>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ termsAndConditionsStrings.header }}"></uc-section-header>
    <uc-online-terms-and-conditions
      [task]="task"
      [termsAndConditionsForm]="
        onlineShortCourseDetailsPage.get('termsAndConditionsGroup')
      "></uc-online-terms-and-conditions>
  </uc-section>
</uc-form-template>
