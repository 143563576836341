{
  "application_enrolment": [
    {
      "active": true,
      "priority": 1,
      "display_order": 1.0,
      "internal_reference": "eidjtyridj48e8ghe8rioIE38I2ldr",
      "external_reference": null,
      "state": {
        "code": "in_progress"
      },
      "offer_decision": {
        "accept_offer": true,
        "decline_reason": { "code": "no_scholarship" },
        "decline_reason_other": "",
        "declaration_funding_acceptance": true,
        "deferred_qual_occurrence": { "code": "foo" },
        "deferred_qual_occurrence_year": { "code": "2022" }
      },
      "student_provided_exemption_reason": "Because I want to, because I want to",
      "did_continue_award": false,
      "enrolled_qualifications": [
        {
          "code": "BCOM",
          "href": "/qualification/2017/BCOM",
          "internal_reference": "abc",
          "priority": 1,
          "deaggregated": false,
          "award_id": null,
          "state": {
            "code": "in_progress"
          },
          "new_in_award": false,
          "subject_options": {
            "1": [
              {
                "code": "Art History and Theory"
              },
              {
                "code": "Accounting"
              }
            ],
            "2": [
              {
                "code": "Economics1"
              },
              {
                "code": "Economics2"
              }
            ]
          },
          "primary_options": [
            {
              "code": "Art History and Theory"
            },
            {
              "code": "Anthropology"
            }
          ],
          "secondary_options": [
            {
              "code": "SECOND A"
            },
            {
              "code": "SECOND B"
            }
          ],
          "tertiary_options": [
            {
              "code": "THIRD A"
            },
            {
              "code": "THIRD B"
            }
          ],
          "enrolled_courses": [
            {
              "code": "COSC101_17S2 (C)",
              "start_date": "2018-07-22",
              "end_date": "2018-11-04",
              "duration": "15.0",
              "state": {
                "code": "approved"
              },
              "active": true,
              "internal_reference": "abc123"
            }
          ]
        }
      ]
    }
  ]
}
