import { Component, Input } from '@angular/core';

import { CourseOccurrence } from '@shared/models/course';

@Component({
  selector: 'uc-course-level-pill',
  templateUrl: './course-level-pill.component.html',
})
export class CourseLevelPillComponent {
  @Input() course: CourseOccurrence;

  get tagColour() {
    const levelColours = {
      '100': 'blue',
      '200': 'yellow',
      '300': 'red',
      '400': 'purple',
      other: 'black',
    };

    return levelColours[this.course.level] || levelColours.other;
  }
}
