import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'uc-subsection-question',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="subsection-question">
      <ng-content></ng-content>
      <span *ngIf="required" class="required-asterisk">*</span>
    </div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSubsectionQuestionComponent {
  @Input() required;
}

@Component({
  selector: 'uc-form-input',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="form-input">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormInputComponent {}

@Component({
  selector: 'uc-subsection',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="subsection">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSubsectionComponent {}

@Component({
  selector: 'uc-subsection-minor-text',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSubsectionMinorTextComponent {}

@Component({
  selector: 'uc-subsection-text-block',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="subsection-text-block">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSubsectionTextBlockComponent {}

@Component({
  selector: 'uc-section-header',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="section-header">
      <h2 class="section-header-title">
        {{ title }}
        <button class="tooltip-btn" *ngIf="titleTooltipContent" ucTooltip tooltipContent="{{ titleTooltipContent }}">
          <uc-icon
            iconTitle="More info"
            iconType="info"
            iconWidth="16"
            iconHeight="16"
            iconFill="$colorMineShaft"></uc-icon>
        </button>
      </h2>
      <div class="section-header-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSectionHeaderComponent {
  @Input() title = '';
  @Input() titleTooltipContent;
}

@Component({
  selector: 'uc-section',
  template: `
    <div class="form-section"><ng-content></ng-content></div>
  `,
  styleUrls: ['./form-subsection.component.scss'],
})
export class FormSectionComponent {}
