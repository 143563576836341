{
  "application": [
    {
      "academic_year": "2021",
      "process_name": "first_year",
      "state": "in_progress"
    },
    {
      "academic_year": "2022",
      "process_name": "first_year",
      "state": "fully_enrolled"
    }
  ]
}
