<uc-form-template>
  <uc-form-header [title]="task.title">
    <uc-message-banner type="info">
      <span *ngIf="!hasEnrolmentChange" [innerHTML]="strings.submitCoursesRequiredNotice"></span>
      <span *ngIf="hasEnrolmentChange" [innerHTML]="strings.submitChangeOfEnrolmentRequiredNotice"></span>
    </uc-message-banner>
    <uc-change-qualification
      *ngIf="!hasEnrolmentChange && !isMicroCredentialProcess"
      [applicationYear]="applicationYear"></uc-change-qualification>
    <uc-message-banner *ngIf="hasEnrolmentChange" type="info">
      {{ strings.coeCourseChange }}
    </uc-message-banner>
    <uc-task-hints [class.hide]="hasEnrolmentChange" #taskHints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-message-banner *ngIf="missingCourses" [type]="warning" class="missing-courses">
    <uc-icon class="caution-icon" iconType="caution" iconWidth="22" iconHeight="22" iconFill="$colorAbbey"></uc-icon>
    <span class="validation-text" [innerHTML]="strings.missingCoursesError(missingCourses)"></span>
  </uc-message-banner>

  <uc-message-banner *ngIf="noEnrolments" type="warning" class="no-enrolments">
    <span [innerHTML]="strings.noEnrolments"></span>
  </uc-message-banner>

  <uc-spinner *ngIf="loading" size="40" [useStaticPositioning]="true"></uc-spinner>

  <span *ngFor="let fullEnrolment of fullEnrolments; let i = index">
    <uc-enrolment-view
      [task]="task"
      [enrolment]="fullEnrolment"
      [teachingPeriods]="fullEnrolmentData?.teachingPeriods"
      [showAppSummaryTab]="fullEnrolmentData?.fullEnrolments.length > 1"
      [applicationYear]="applicationYear"
      [exemptionReasonControl]="exemptionReasons.controls[i]"
      [hasEnrolmentChange]="hasEnrolmentChange"
      [enrolmentPriority]="fullEnrolment.priority"
      [enrolmentNumber]="i"></uc-enrolment-view>
  </span>

  <span *ngIf="!loading">
    <ul class="red-list" [innerHTML]="helpText.helpInfo"></ul>
  </span>
</uc-form-template>
