import { Component, OnInit } from '@angular/core';

import { ONLINE_QUALIFICATION_OPTIONS } from '@constants/online-qualification-options.constants';
import { QualificationComponent } from '@shared/applicant/tasks/qualification/qualification.component';

@Component({
  selector: 'uc-myuc-qualification',
  templateUrl: './myuc-qualification.component.html',
  styleUrls: ['./myuc-qualification.component.scss'],
})
export class MyucQualificationComponent extends QualificationComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    this.qualificationData$.subscribe((qualificationData) => {
      this.qualificationData = qualificationData.filter((qual) => ONLINE_QUALIFICATION_OPTIONS.includes(qual.code));
    });
  }
}
