import { Modal } from '@4sellers/ngx-modialog';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { flatten, get, flattenDeep } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { switchMap, takeUntil, take } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { TaskHintsComponent } from '@shared/components/molecules/task-hints/task-hints.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment, ChangeOfEnrolmentUpdate } from '@shared/models/change-of-enrolment';
import { Enrolment, FullEnrolment } from '@shared/models/enrolment';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { FullEnrolmentService, FullEnrolmentData } from '@shared/services/full-enrolment/full-enrolment.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-course-selection-overview',
  templateUrl: './course-selection-overview.component.html',
  styleUrls: ['./course-selection-overview.component.scss'],
})
export class CourseSelectionOverviewComponent extends AbstractBaseTask implements OnInit {
  @ViewChild('taskHints') taskHints: TaskHintsComponent;

  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() process: string;

  fullEnrolments: FullEnrolment[];
  fullEnrolmentsLength: number;
  loading = true;
  qualURIs;
  strings = strings.components.tasks.courseSelectionOverview;
  helpText = strings.components.organisms.coursePlanner.helpText;
  missingCourses: string;
  enrolledCoursesCount$ = new BehaviorSubject(0);
  changedCoursesCount$ = new BehaviorSubject(0);
  exemptionReasons = new UntypedFormArray([]);
  exemptionReasonsCache = [];
  enrolments: Enrolment[];
  noEnrolments = false;
  fullEnrolmentData: FullEnrolmentData;
  enrolmentChange: ChangeOfEnrolment;
  exemptionReasonChanged = false;
  applicationEnrolments: ApplicationEnrolment[] = [];

  constructor(
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private modal: Modal,
    private applicationService: ApplicationService,
    private modalService: ModalService,
    private fullEnrolmentService: FullEnrolmentService,
    private processService: ProcessService,
  ) {
    super();
  }

  get hasEnrolmentChange(): boolean {
    return this.enrolmentChange && this.enrolmentChange?.academicYear?.code === this.applicationYear;
  }

  get enrolledCoursesCount(): Observable<number> {
    return this.enrolledCoursesCount$.asObservable();
  }

  get changedCoursesCount(): Observable<number> {
    return this.changedCoursesCount$.asObservable();
  }

  get isMicroCredentialProcess(): boolean {
    return this.process === PROCESS_NAMES.MCED || this.process === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  ngOnInit() {
    // Check for the a CoE and, if so build a full enrolment from that
    // Otherwise, build full enrolments from the application enrolment
    this.changeOfEnrolmentService
      .getChangeOfEnrolment()
      .pipe(take(1))
      .subscribe(
        (coe) => {
          if (!!coe && coe.academicYear.code === this.applicationYear) {
            this.getFullEnrolmentDataFromCoE(coe);
          } else {
            this.getFullEnrolmentDataFromApplicationEnrolment();
          }
        },
        () => {
          this.loading = false;

          return [];
        },
        () => {
          if (get(this, 'taskHints.updateHints$')) {
            this.taskHints.updateHints$.next('update');
          }
        },
      );
  }

  getFullEnrolmentDataFromApplicationEnrolment() {
    this.applicationService
      .getApplicationEnrolment(this.applicationYear)
      .subscribe((applicationEnrolments: ApplicationEnrolment[]) => {
        const activeEnrolments = applicationEnrolments.filter((ae) => get(ae, 'state.code') !== 'withdrawn');
        const enrolledQuals = flattenDeep(activeEnrolments.map((e) => e.enrolledQualifications));

        if (!enrolledQuals || enrolledQuals.length === 0) {
          this.noEnrolments = true;
          this.loading = false;
          return;
        }

        this.applicationEnrolments = activeEnrolments;
        combineLatest([
          this.fullEnrolmentService.getEnrolledQualData(this.applicationYear, enrolledQuals),
          this.fullEnrolmentService.validateApplicationEnrolments(activeEnrolments, this.applicationYear),
        ])
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(([enrolmentData, validationMessages]) => {
            const [qualifications, courseOccurrences] = enrolmentData;
            const fullEnrolments = FullEnrolment.createFrom(
              activeEnrolments,
              qualifications.qualification,
              courseOccurrences,
              validationMessages,
            );

            activeEnrolments.map((ae) => {
              const reason = ae.studentProvidedExemptionReason;
              this.exemptionReasons.push(this.createExemptionFormControl(reason));
              this.exemptionReasonsCache.push(reason || '');
            });

            this.setupOverviewData(enrolmentData, fullEnrolments, activeEnrolments[0]);
          });
      });
  }

  getFullEnrolmentDataFromCoE(coe: ChangeOfEnrolment) {
    const enrolledQuals = coe.enrolledQualifications;
    this.noEnrolments = !enrolledQuals.length;
    this.enrolmentChange = coe;
    combineLatest([
      this.fullEnrolmentService.getEnrolledQualData(this.applicationYear, enrolledQuals),
      this.processService.validateCoe(),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([enrolmentData, validationMessage]) => {
        const [qualifications, courseOccurrences] = enrolmentData;
        const fullEnrolment = FullEnrolment.createFromCoe(
          coe,
          qualifications.qualification,
          courseOccurrences,
          validationMessage,
        );

        const reason = coe.studentProvidedExemptionReason;
        this.exemptionReasons.push(this.createExemptionFormControl(reason));
        this.exemptionReasonsCache.push(reason || '');

        this.setupOverviewData(enrolmentData, [fullEnrolment], coe);
      });
  }

  createExemptionFormControl = (exemptionReason: string): UntypedFormControl => {
    return new UntypedFormControl(exemptionReason || '', UCValidators.createWordCountValidator(500));
  };

  setupOverviewData = (
    enrolmentData,
    fullEnrolments: FullEnrolment[],
    firstEnrolment: ApplicationEnrolment | ChangeOfEnrolment,
  ) => {
    const [qualifications, courseOccurrences, teachingPeriods] = enrolmentData;
    this.fullEnrolmentData = {
      teachingPeriods,
      qualifications,
      courseOccurrences,
      fullEnrolments,
    };

    this.missingCourses = this.fullEnrolmentService.missingCourses;
    this.fullEnrolments = this.fullEnrolmentData.fullEnrolments.filter((el) => el.state.code !== 'withdrawn');

    // Order application enrolment by either priority order or display order.
    // Priority is set when quals are added in UCSW, and the api only sets a display order if both are created in myUC.
    // This means we need to check the presence of a priority on both AEs, and if not,
    // assume both were added in myUC and so will have display order.
    if (this.fullEnrolmentData.fullEnrolments.some((el) => !el.priority)) {
      this.fullEnrolments = this.fullEnrolmentData.fullEnrolments.sort((a, b) => a.displayOrder - b.displayOrder);
    } else {
      this.fullEnrolments = this.fullEnrolmentData.fullEnrolments.sort((a, b) => a.priority - b.priority);
    }

    const firstEnrolmentCourses = firstEnrolment.enrolledQualifications.map((q) => q.enrolledCourses);
    const firstEnrolmentCoursesLength = flatten(firstEnrolmentCourses).length;
    this.enrolledCoursesCount$.next(firstEnrolmentCoursesLength);

    this.exemptionReasons.valueChanges.subscribe((formValue) => {
      this.exemptionReasonChanged = this.exemptionReasonsCache.toString() !== formValue.toString();
      this.modalService.shouldNavigate = !this.exemptionReasonChanged;
    });

    this.loading = false;
  };

  updateEnrolmentAndContinue(): Promise<any> {
    return new Promise<void>((resolve) => {
      this.updateExemptionReasons();
      resolve();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public updateFormValidity(err) {}

  updateExemptionReasons() {
    const setExemptionReason = (enrolment: ApplicationEnrolment | ChangeOfEnrolment, index = 0) => {
      enrolment.studentProvidedExemptionReason = this.exemptionReasons.value[index] || '';
    };

    const updateSuccess = () => {
      this.modalService.shouldNavigate = true;
      this.next.emit();
    };
    const updateFailure = (err) => this.errors.emit(err);

    if (this.hasEnrolmentChange) {
      setExemptionReason(this.enrolmentChange);

      const coeUpdate = new ChangeOfEnrolmentUpdate({
        studentProvidedExemptionReason: this.exemptionReasons.value[0],
        declarationAgreed: false,
      });
      this.changeOfEnrolmentService.updateChangeOfEnrolment(coeUpdate).subscribe(updateSuccess, updateFailure);
    } else {
      this.applicationEnrolments = this.applicationEnrolments.map((ae, index) => {
        setExemptionReason(ae, index);
        return ae;
      });

      const observables = this.applicationEnrolments.map((ae) => {
        return this.applicationService.updateApplicationEnrolment(this.applicationYear, ae.internalReference, ae);
      });
      const updateProcessObservables = this.applicationEnrolments.map((ae) => {
        return this.processService.updateExemptionReason(this.applicationYear, ae.internalReference);
      });
      combineLatest(observables)
        .pipe(switchMap(() => combineLatest(updateProcessObservables)))
        .subscribe(() => {
          updateSuccess();
        }, updateFailure);
    }
  }

  continueToNextTask() {
    if (this.loading) {
      return;
    }
    if (this.exemptionReasonsCache.toString() !== this.exemptionReasons.value.toString()) {
      this.updateExemptionReasons();
    } else {
      this.next.emit();
    }
  }

  update() {
    this.enrolledCoursesCount.subscribe((coursesCount) => {
      if (coursesCount === 0 && !this.applicationService.currentApplication.declarationAgree) {
        const modal = this.modal
          .confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(false)
          .title(this.strings.warningModal.title)
          .body(this.strings.warningModal.body)
          .okBtn(this.strings.warningModal.saveText)
          .cancelBtn(this.strings.warningModal.closeText)
          .cancelBtnClass('cancel-btn')
          .open();

        modal.onDestroy.subscribe(() => {
          modal.result
            .then(() => {
              this.continueToNextTask();
            })
            .catch(() => {
              // Gracefully continue
            })
            .then(() => document.body.classList.remove('modal-open'));
        });
      } else {
        this.continueToNextTask();
      }
    });
  }
}
