import { Injectable } from '@angular/core';

@Injectable()
export class PositionService {
  constructor() {}

  getBoundingRect(el: HTMLElement) {
    return el.getBoundingClientRect();
  }

  getOffsets(el: HTMLElement) {
    return {
      height: el.offsetHeight,
      width: el.offsetWidth,
    };
  }
}

export class MockPositionService {
  public mockRect;
  public mockOffsets;
  private realService = new PositionService();

  constructor() {}

  getBoundingRect(el: HTMLElement) {
    if (!this.mockRect) {
      return this.realService.getBoundingRect(el);
    }
    return this.mockRect;
  }

  getOffsets(el: HTMLElement) {
    if (!this.mockOffsets) {
      return this.realService.getOffsets(el);
    }
    return this.mockOffsets;
  }
}
