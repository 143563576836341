import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';

import strings from '@constants/strings.constants';
import { IRefdataOptions, ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-gender-selector',
  templateUrl: './gender-selector.component.html',
  styleUrls: ['./gender-selector.component.scss'],
})
export class GenderSelectorComponent implements OnInit {
  @Input() genderForm: UntypedFormGroup;
  @Input() task: Task;
  gender$: Observable<IRefdataOptions[]>;
  genderLabel = strings.components.molecules.genderSelector.genderLabel;

  constructor(private refDataService: ReferenceDataService) {}

  ngOnInit() {
    this.gender$ = this.refDataService.getByType('gender').pipe(
      map((data: ReferenceData[]) =>
        data.map(
          (item: ReferenceData) =>
            ({
              labelText: item.description ?? '',
              value: item.code,
            }) as IRefdataOptions,
        ),
      ),
      map((data: IRefdataOptions[]) => data.reverse()),
    );
  }
}
