{
  "enrolment_summary": {
    "academic_years": [
      {
        "academic_year": "2020",
        "state": "fully_enrolled",
        "courseSelectionState": "closed",
        "process_name": "change_of_enrolment",
        "actions": {
          "primary": {
            "label": "Resume Change of Enrolment",
            "action": "RESUME"
          },
          "secondary": [
            {
              "label": "Cancel Change of Enrolment",
              "action": "CANCEL_COE"
            }
          ]
        },
        "application": {
          "href": "/application/2020",
          "state": "fully_enrolled"
        },
        "application_enrolments": [
          {
            "href": "/application/2020/enrolments/kriudjdghsudjd",
            "state": "finished",
            "can_withdraw": false
          }
        ],
        "enrolment": {
          "href": "/enrolment/2020",
          "state": "fully_enrolled"
        },
        "change_of_enrolment": null
      },
      {
        "academic_year": "2021",
        "state": "being_assessed",
        "courseSelectionState": "selected",
        "process_name": "new_student",
        "actions": {
          "primary": {
            "label": "Payments Due",
            "action": "PAY",
            "href": "UCSW.PAYDUE"
          },
          "secondary": [
            {
              "label": "Change qualifications / subjects",
              "action": "CHANGE_QUALIFICATION"
            },
            {
              "label": "Change courses",
              "action": "CHANGE_COURSE"
            }
          ]
        },
        "application": {
          "href": "/application/2021",
          "state": "in_progress"
        },
        "application_enrolments": [
          {
            "href": "/application/2021/enrolments/alksdjfasdlkjf",
            "state": "in_progress",
            "can_withdraw": true
          }
        ],
        "enrolment": null,
        "change_of_enrolment": null
      },
      {
        "academic_year": "2022",
        "state": "full_offer_of_place_can_process",
        "courseSelectionState": "selected",
        "process_name": "offer-decision",
        "actions": {
          "primary": {
            "label": "Offer Available",
            "action": "OFFER_AVAILABLE"
          },
          "secondary": []
        },
        "application": {
          "href": "/application/2022",
          "state": "in_progress"
        },
        "application_enrolments": [
          {
            "href": "/application/2022/enrolments/alksdjfasdlkjf",
            "state": "in_progress",
            "can_withdraw": true
          }
        ],
        "enrolment": null,
        "change_of_enrolment": null
      }
    ],
    "archived_enrolments": [
      {
        "href": null,
        "state": "all_withdrawn",
        "academic_year": "2012",
        "enrolled_qualifications": [
          {
            "code": "BA"
          },
          {
            "code": "MTCHGLN"
          }
        ]
      },
      {
        "href": null,
        "state": "all_withdrawn",
        "academic_year": "2013",
        "enrolled_qualifications": [
          {
            "code": "BA"
          },
          {
            "code": "MTCHGLN"
          }
        ]
      }
    ]
  }
}
