{
  "qualification": {
    "code": "BA",
    "description": "",
    "guidance": "BA students specialise in two areas; either a major and minor subject, or two majors.",
    "includes_thesis": false,
    "is_postgraduate": false,
    "force_continue": false,
    "categories": [
      {
        "category": "new_student"
      },
      {
        "category": "returning"
      },
      {
        "category": "has_qualification_occurrences"
      },
      {
        "category": "our_uc_auto_approve"
      },
      {
        "category": "aes_assessment_ouruc"
      },
      {
        "category": "course_admission_ouruc"
      },
      {
        "category": "undergrad"
      },
      {
        "category": "our_uc_coe_assessment"
      }
    ],
    "level": {
      "code": "7"
    },
    "organisation_unit": {
      "code": "Arts, College of"
    },
    "paper_form_url": "",
    "subject_options": [
      {
        "label": "Major",
        "level": "1",
        "option_lists": [
          {
            "required": true,
            "order": 1,
            "options": [
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              }
            ]
          },
          {
            "required": true,
            "order": 1,
            "options": [
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              }
            ]
          }
        ]
      },
      {
        "label": "Major",
        "level": "2",
        "option_lists": [
          {
            "required": true,
            "order": 1,
            "options": [
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              }
            ]
          },
          {
            "required": true,
            "order": 1,
            "options": [
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              },
              {
                "code": "Art History and Theory",
                "description": "",
                "effective_from_date": "1873-01-01",
                "effective_level": 1,
                "effective_to_date": "2999-01-01",
                "title": "Art History and Theory",
                "type": "MAJOR"
              }
            ]
          }
        ]
      }
    ],
    "title": "Bachelor of Arts"
  }
}
