import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { flatten } from 'lodash-es';
import { DateTime } from 'luxon';
import { of as observableOf, combineLatest as observableCombineLatest, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { Notification, NotificationTypes, AudienceTypes } from '@shared/models/notification';
import { UserTypes } from '@shared/models/user';
import { DataService } from '@shared/services/data-service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Injectable()
export class NotificationService {
  private collectionName = '/notifications';
  private serviceUrl = `${environment.apiRoot}/auth/firebase/notification`;
  private log: Logger;
  private notifications$ = new BehaviorSubject<Notification[]>(null);
  private allNotifications$ = new BehaviorSubject<Notification[]>(null);
  private notificationCollection: AngularFirestoreCollection<Notification>;

  constructor(
    private dataService: DataService,
    firestoreDb: AngularFirestore,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
    this.notificationCollection = firestoreDb.collection<Notification>(this.collectionName);

    if (environment.scope === UserTypes.staff) {
      const notifications = this.notificationCollection.snapshotChanges();
      this.notificationsWithDocId(notifications).subscribe(
        (res) => {
          this.allNotifications$.next(Notification.deserialize(flatten(res)));
        },
        (err) => {
          this.log.error('Notification Error:', err);
        },
      );
    }

    const notificationReqs = [
      firestoreDb
        .collection<Notification>(this.collectionName, (ref) =>
          ref.where('audience', '==', `all-${environment.envName}`),
        )
        .valueChanges(),
      firestoreDb
        .collection<Notification>(this.collectionName, (ref) => {
          return ref.where('audience', '==', `${environment.scope}-${environment.envName}`);
        })
        .valueChanges(),
    ];

    observableCombineLatest(notificationReqs).subscribe(
      (res) => {
        this.notifications$.next(Notification.deserialize(flatten(res)));
      },
      (err) => {
        this.log.error('Notification Error:', err);
      },
    );
  }

  get notifications(): Observable<Notification[]> {
    return this.notifications$.asObservable();
  }

  get allNotifications(): Observable<Notification[]> {
    return this.allNotifications$.asObservable();
  }

  createNotification(item: Notification): Observable<Notification> {
    item.audience += `-${environment.envName}`;
    return this.dataService.post(this.serviceUrl, { notification: item }, {});
  }

  deleteNotification(id): Observable<unknown> {
    return this.dataService.del(`${this.serviceUrl}/${id}`, {});
  }

  notificationsWithDocId(notifications) {
    return notifications.pipe(
      map((actions: DocumentChangeAction<Notification>[]) => {
        return actions.map((a: DocumentChangeAction<Notification>) => {
          return { ...a.payload.doc.data(), id: a.payload.doc.id };
        });
      }),
    );
  }
}

export class MockNotificationService {
  notifications$ = new BehaviorSubject<Notification[]>(null);
  allNotifications$ = new BehaviorSubject<Notification[]>(null);

  mockNotifications: Notification[] = [
    {
      message: 'test notification',
      start: DateTime.now().minus({ minutes: 12 }).toJSDate(),
      expiry: DateTime.now().minus({ minutes: 11 }).toJSDate(),
      type: NotificationTypes.outage,
      audience: `${AudienceTypes.student}-${environment.envName}`,
      id: 'abc123',
    },
  ];

  constructor(mockData?: Notification[]) {
    if (mockData) {
      this.mockNotifications = mockData;
    }
    if (environment.scope === UserTypes.staff) {
      this.allNotifications$.next(this.mockNotifications);
    }

    const appNots = this.mockNotifications.filter((n) => `${n.audience}` === environment.scope || n.audience === 'all');
    this.notifications$.next(appNots);
  }

  get notifications(): Observable<Notification[]> {
    return this.notifications$.asObservable();
  }

  get allNotifications(): Observable<Notification[]> {
    return this.allNotifications$.asObservable();
  }

  createNotification(item: Notification): Observable<Notification> {
    item.id = Math.floor(Math.random() * 100 * 100).toString();
    const current = this.notifications$.value.slice();
    current.push(Notification.deserialize([item])[0]);
    this.notifications$.next(current);
    return observableOf(item);
  }

  deleteNotification(id): Observable<boolean> {
    const current = this.allNotifications$.value;
    const pos = current.findIndex((el) => {
      return el.id === id;
    });
    current.splice(pos, 1);
    this.allNotifications$.next(new Array(...current));
    return observableOf(true);
  }
}

export const notificationServiceFactory = (
  dataService: DataService,
  fireStoreDb: AngularFirestore,
  loggingService: LoggingService,
) => {
  if (environment.useFakeBackend.notification) {
    return new MockNotificationService();
  } else {
    return new NotificationService(dataService, fireStoreDb, loggingService);
  }
};

export const notificationServiceProvider = {
  provide: NotificationService,
  useFactory: notificationServiceFactory,
  deps: [DataService, AngularFirestore, LoggingService],
};
