import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class AuthOnloadGuard {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  requestUser(): Observable<boolean> {
    return this.userService.getUser().pipe(
      map(() => {
        this.router.navigate(internalUrls.dashboard);
        return false;
      }),
      catchError(() => {
        return observableOf(true);
      }),
    );
  }

  // This guard is designed to disallow access to the login route if the /auth/me call succeeds
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.requestUser();
  }
}
