{
  "conditions": [
    {
      "state": {
        "code": "applied"
      },
      "reason": {
        "code": "copy"
      },
      "additional_text": "Please supply a digital or certified true copy of your graduation or degree certificate.\f\r\n\r\nJust a reminder about <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/identity-and-citizenship-verification\">certification</a>.",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": null,
      "internal_reference": "RqDyAFFzfSVha6tgiOascUHbxFpf4O",
      "item": {
        "code": "incomplete_tertiary_grad",
        "style": "incomplete"
      },
      "qualification": { "code": "BA" }
    },
    {
      "state": {
        "code": "received"
      },
      "reason": {
        "code": "missing"
      },
      "additional_text": "If you have changed your name, please also supply a certified true copy of your proof of name change\n\nJust a reminder about <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/identity-and-citizenship-verification\">certification</a>.",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": "2020-01-30T15:47:05.635026+13:00",
      "internal_reference": "Vha6tgiOascUHbxFpRqDyAFFzfSf4O",
      "item": {
        "code": "incomplete_name",
        "style": "incomplete"
      },
      "qualification": { "code": "BA" }
    },
    {
      "state": {
        "code": "met"
      },
      "reason": {
        "code": "missing"
      },
      "additional_text": "If you have changed your name, please also supply a certified true copy of your proof of name change\n\nJust a reminder about <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment/enrolment-topics/identity-and-citizenship-verification\">certification</a>.",
      "date_met_removed": "2020-01-31T15:47:05.635026+13:00",
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": "2020-01-30T15:47:05.635026+13:00",
      "internal_reference": "qDyAFFzfSf4OVha6tgiOascUHbxFpR",
      "item": {
        "code": "incomplete_name",
        "style": "incomplete"
      },
      "qualification": { "code": "BA" }
    },
    {
      "state": {
        "code": "applied"
      },
      "reason": {
        "code": "missing"
      },
      "additional_text": "Before you can start your degree at UC, you must first meet university entrance requirements. Find out more about UE <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/enrol/eligibility/\">here</a>.",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": null,
      "internal_reference": "a6tgiOascUHbxFpVhRqDyAFFzfSf4O",
      "item": {
        "code": "cond_ua_ncea",
        "style": "cond_ua"
      },
      "qualification": { "code": "BA" }
    },
    {
      "state": {
        "code": "removed"
      },
      "reason": {
        "code": "missing"
      },
      "additional_text": "Before you can start your degree at UC, you must first meet university entrance requirements. Find out more about UE <a target=\"_blank\" href=\"https://www.canterbury.ac.nz/enrol/eligibility/\">here</a>.",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": null,
      "internal_reference": "DJcuyFafGMtnrVlIhbqXYHwAdzeOjx",
      "item": {
        "code": "cond_ua_ncea",
        "style": "cond_ua"
      },
      "qualification": { "code": "BA" }
    },
    {
      "state": {
        "code": "applied"
      },
      "reason": {
        "code": "copy"
      },
      "additional_text": "PG <strong>no degree equivalent.</strong>",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": null,
      "internal_reference": "RqDyAFFzfSVha6tgiOascUHbxFpf4O",
      "item": {
        "code": "dec_qa_not_degree_equivalent",
        "style": "decline_qa"
      },
      "qualification": {
        "code": "BA"
      }
    },
    {
      "state": {
        "code": "removed"
      },
      "reason": {
        "code": "copy"
      },
      "additional_text": "I am am <strong>REMOVED</strong> condition",
      "date_met_removed": null,
      "date_applied": "2020-01-29T15:47:05.635026+13:00",
      "date_supplied": null,
      "internal_reference": "RqDyAFFzfSVha6tgiOascUHbxFpf4O",
      "item": {
        "code": "dec_qa_not_degree_equivalent",
        "style": "decline_qa"
      },
      "qualification": {
        "code": "BCOM"
      }
    }
  ]
}
