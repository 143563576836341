import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uc-static-link-dashboard-tile',
  templateUrl: './static-link-dashboard-tile.component.html',
  styleUrls: ['./static-link-dashboard-tile.component.scss'],
})
export class StaticLinkDashboardTileComponent implements OnInit {
  @Input() icon;
  @Input() header;
  @Input() testSelector = '';

  ngOnInit() {}
}
