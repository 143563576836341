import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { IRefdataOptions } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-show-hide',
  templateUrl: './show-hide.component.html',
  styleUrls: ['./show-hide.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShowHideComponent),
      multi: true,
    },
  ],
})
export class ShowHideComponent implements OnInit, ControlValueAccessor {
  @Input() questionString: string;
  @Input() questionDescription: string;
  @Input() refdataOptions$: Observable<IRefdataOptions[]>;
  @Input() task: Task;
  @Input() taskHintPath: string;
  @Input() showContentValue: string;
  @Input() testSelector = '';

  showHideControl = new UntypedFormControl('');
  showContent = false;

  private propagateChange: (_: string) => void;
  private propagateTouch: (_: boolean) => void;

  constructor() {}

  ngOnInit(): void {
    this.showHideControl.valueChanges.subscribe((value) => {
      this.showContent = value === this.showContentValue;

      this.propagateChange?.(value);
    });
  }

  writeValue(value: string): void {
    this.showHideControl.setValue(value);
  }

  registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }
}
