<div class="toggle__wrapper">
  <span class="toggle__label">{{ customStrings.display }}</span>
  <uc-icon
    class="toggle__icon"
    iconType="grid"
    iconWidth="16"
    iconHeight="16"
    [iconFill]="getColour(displayTypes.GRID)"
    (click)="toggleView(displayTypes.GRID)"></uc-icon>
  <uc-icon
    class="toggle__icon"
    iconType="table"
    iconWidth="16"
    iconHeight="16"
    [iconFill]="getColour(displayTypes.TABLE)"
    (click)="toggleView(displayTypes.TABLE)"></uc-icon>
</div>
