import { Injectable } from '@angular/core';
import { of, Observable, Subject, BehaviorSubject, catchError, throwError } from 'rxjs';

import { environment } from '@environment';
import { ValidationMessage } from '@shared/models/enrolment';
import { UCError } from '@shared/models/errors';
import { CustomerId, PAYMENT_SERVICE_PATH, Payment, PaymentRefundResponse } from '@shared/models/payment';
import { DataService, DSHttpError, IDSRequestOpts, UCErrorCodes } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { mockData as mockPaymentResponse, mockRefundData } from '@shared/services/payment/payment.data.mock';

interface PaymentRefundData {
  occurrence: string;
  courseCode: string;
  reason: string;
  paymentIntentId: string;
}

@Injectable()
export class PaymentService {
  private serviceUrl: string = environment.apiRoot + PAYMENT_SERVICE_PATH;
  private error$ = new Subject<UCError>();
  public payment$ = new BehaviorSubject<Payment>(null);
  public paymentRefund$ = new BehaviorSubject<PaymentRefundResponse>(null);
  private log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get payment(): Payment {
    return this.payment$.value;
  }

  get paymentRefundResponse(): PaymentRefundResponse {
    return this.paymentRefund$.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getRequestOptions(options?: { [key: string]: any }): IDSRequestOpts {
    return {
      error$: this.error$,
      deserialize: Payment.deserialize,
      ...options,
    };
  }

  getPaymentLink(process: string, academicYear: string, courseCode: string, occurrence: string): Observable<Payment> {
    const url = `${this.serviceUrl}/${academicYear}/${process}/${courseCode}/${occurrence}/payment_link`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.payment$,
      }),
    );
  }

  generatePaymentLinkForStudent(
    cid: string,
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<Payment> {
    const url = `${this.serviceUrl}/staff/${academicYear}/${cid}/${courseCode}/${occurrence}/staff_create_payment_link`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.payment$,
      }),
    );
  }

  getPaidAmount(paymentIntentId: string): Observable<PaymentRefundResponse> {
    const url = `${this.serviceUrl}/staff/payment_intent/${paymentIntentId}`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.paymentRefund$,
      }),
    );
  }

  refundIndependentCourseEnrolment(
    applicationYear: string,
    canonicalId: string,
    data: PaymentRefundData,
  ): Observable<DSHttpError> {
    const url = `${this.serviceUrl}/staff/${applicationYear}/${canonicalId}/independent_enrolments/refund`;
    return this.dataService
      .post(
        url,
        data,
        this.getRequestOptions({
          expectStatus: 200,
          errorCodes: {
            '400': UCErrorCodes.E400,
            '404': UCErrorCodes.E404,
            '422': UCErrorCodes.E422,
          },
        }),
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  getCustomerIdAsStaffForUser(user: string): Observable<CustomerId> {
    const url = `${this.serviceUrl}/staff/${user}/stripe-customer-id/`;
    return this.dataService.fetch(url, {
      error$: this.error$,
      deserialize: CustomerId.deserialize,
      errorCodes: {
        '417': UCErrorCodes.E417,
        '404': UCErrorCodes.E404,
      },
    });
  }
}

/* eslint-disable */
export class MockPaymentService {
  public error$ = new Subject<UCError>();
  public payment$ = new BehaviorSubject<Payment>(null);
  public paymentRefund$ = new BehaviorSubject<PaymentRefundResponse>(null);

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  getPaymentLink(process: string, academicYear: string, courseCode: string, occurrence: string): Observable<Payment> {
    const payment = Payment.deserialize(mockPaymentResponse());
    this.payment$.next(payment);

    if (payment.manifest.find((data) => data.courseCode === courseCode)) {
      return of(payment);
    } else {
      payment.link = undefined;
      return of(payment);
    }
  }

  generatePaymentLinkForStudent(
    cid: string,
    academicYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<PaymentRefundResponse> {
    const refund = PaymentRefundResponse.deserialize(mockRefundData());
    this.paymentRefund$.next(refund);

    if (
      refund.intent.courseCode === courseCode &&
      refund.intent.occurrenceCode === occurrence
    ) {
      return of(refund);
    } else {
      refund.charge.amount = undefined;
      return of(refund);
    }
  }

  getPaidAmount(paymentIntentId: string): Observable<PaymentRefundResponse> {
    const refund = PaymentRefundResponse.deserialize(mockRefundData());
    this.paymentRefund$.next(refund);

    if (paymentIntentId !== null) {
      return of(refund);
    } else {
      refund.intent.amount = undefined;
      return of(refund);
    }
  }

  refundIndependentCourseEnrolment(
    applicationYear: string,
    canonicalId: string,
    data: PaymentRefundData,
  ): Observable<ValidationMessage> {
    const mockValidationMessages = {
      validation: {
        enrolment_error: [
          {
            message: `Error message for Refund an Independent Course Enrolment`,
          },
        ],
        withdraw_warnings: [
          {
            message: `Warrning message for refund a withdrawn Independent Course Enrolment`,
          },
        ],
      },
    };

    return of(ValidationMessage.deserialize(mockValidationMessages));
  }
}
