import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-confidential-referee-report',
  templateUrl: './confidential-referee-report.component.html',
})
export class ConfidentialRefereeReportComponent implements OnInit {
  @Input() firstRefereeReport: UntypedFormGroup;
  @Input() secondRefereeReport: UntypedFormGroup;
  @Input() thirdRefereeReport?: UntypedFormGroup;
  @Input() task: Task;
  @Input() hintPath: string;
  strings = strings.components.tasks.caCompliance;

  constructor() {}

  ngOnInit() {}
}
