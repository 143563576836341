import { Component } from '@angular/core';

import strings from '@constants/strings.constants';
import promotionalTileMetadata from '@shared/components/molecules/promotional-tile/promotional-tile.constants';

@Component({
  selector: 'uc-dashboard-tiles',
  templateUrl: './dashboard-tiles.component.html',
  styleUrls: ['./dashboard-tiles.component.scss'],
})
export class DashboardTilesComponent {
  displayPromo = promotionalTileMetadata.enablePromo;
  breakpoints = [
    [550, 1],
    [1024, 2],
    [1920, 4],
  ];
  strings = strings.components.template.dashboard;
}
