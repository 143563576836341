import { Component, OnInit, Input } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-footer-minimal',
  templateUrl: './footer-minimal.component.html',
  styleUrls: ['./footer-minimal.component.scss'],
})
export class FooterMinimalComponent implements OnInit {
  @Input() fullPageView = false;
  @Input() showHelp = false;

  footerStrings = strings.components.molecules.footer;

  constructor() {}

  ngOnInit() {}
}
