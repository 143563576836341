import { DateTime } from 'luxon';

import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export class Waiver {
  public course: string;
  public createdBy: string;
  public dateCreated: Date;
  public internalReference: string;
  public qualification: string;
  public reason: string;
  public ruleType: string;
  public state: { code: string };
  public targets: { code: string };
  public notes: string;

  constructor(obj: Partial<Waiver>) {
    Object.assign(this, obj);
  }

  public static deserialize(payload: Partial<Waiver>): Waiver {
    if (payload === null) {
      return null;
    }

    const clone = camelizeKeys(payload);
    clone.dateCreated = DateTime.fromISO(clone.dateCreated).toJSDate();

    return new Waiver(clone);
  }

  public static serialize(instance: Partial<Waiver>) {
    const clone = { ...instance };
    return snakeifyKeys(clone);
  }
}
