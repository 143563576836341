import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-learning-needs',
  templateUrl: './learning-needs.component.html',
  styleUrls: ['./learning-needs.component.scss'],
})
export class LearningNeedsComponent implements OnInit, OnDestroy {
  @Input() learningNeedsFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() task: Task;

  private languageSubscriber: Subscription;
  private firstInFamilySubscriber: Subscription;

  strings = strings.components.organisms.learningNeeds;

  constructor() {}

  get primaryLanguageCode() {
    const { value } = this.learningNeedsFormGroup.get('language');
    return value && value.code;
  }

  ngOnInit() {
    this.languageSubscriber = this.learningNeedsFormGroup.get('language').valueChanges.subscribe(() => {
      this.learningNeedsFormGroup.get('languageOther').reset();
    });
    this.firstInFamilySubscriber = this.learningNeedsFormGroup
      .get('declarationFirstInFamily')
      .valueChanges.subscribe(() => {
        this.learningNeedsFormGroup.get('declarationDidFamilyMemberGraduate').reset();
      });
  }

  ngOnDestroy() {
    if (this.languageSubscriber) {
      this.languageSubscriber.unsubscribe();
    }
    if (this.firstInFamilySubscriber) {
      this.firstInFamilySubscriber.unsubscribe();
    }
  }
}
