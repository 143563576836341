<uc-form-template [formGroup]="exchangeStudyAbroadPage">
  <div>
    <uc-form-header title="{{ task?.title }}">
      <uc-task-hints [task]="task"></uc-task-hints>
    </uc-form-header>

    <uc-section>
      <uc-section-header [title]="strings.yourStudyAtUC">
        <p>{{ strings.copGuidance }}</p>
      </uc-section-header>
      <uc-subsection>
        <uc-task-hint [task]="task" path="{{ basePath }}/study_type"></uc-task-hint>
        <uc-form-input>
          <uc-subsection-question required="yes">
            {{ strings.studyType }}
          </uc-subsection-question>
          <uc-radio-input-group
            [options]="studyTypeOptions"
            [additionalText]="studyTypeGuidance"
            formControlName="studyType"
            groupName="study-type"></uc-radio-input-group>
        </uc-form-input>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="{{ basePath }}/home_university"></uc-task-hint>
        <uc-form-input>
          <uc-subsection-question required="yes">
            {{ strings.homeUniversity }}
          </uc-subsection-question>
          <uc-string-input testSelector="home-uni" formControlName="homeUniversity"></uc-string-input>
        </uc-form-input>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="{{ basePath }}/major_subject"></uc-task-hint>
        <uc-form-input>
          <uc-subsection-question required="yes">
            {{ strings.majorSubject }}
          </uc-subsection-question>
          <uc-string-input testSelector="major-subject" formControlName="majorSubject"></uc-string-input>
        </uc-form-input>
      </uc-subsection>

      <uc-subsection>
        <uc-form-input>
          <uc-subsection-question>
            {{ strings.transcriptUpload }}
          </uc-subsection-question>
          <uc-subsection-minor-text [innerHTML]="strings.transcriptUploadGuidance"></uc-subsection-minor-text>
          <uc-file-upload
            [year]="applicationYear"
            [title]="strings.transcriptUploadTitle"
            categoryCode="RESULTS_TERT"
            usedInTask="true"></uc-file-upload>
        </uc-form-input>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="/applicant/demographic/moe_year_last_attended_school"></uc-task-hint>
        <uc-subsection-question [required]="true">
          {{ strings.lastYearAttended }}
        </uc-subsection-question>
        <uc-year-selector
          [baseYear]="applicationYear"
          [range]="71"
          [control]="exchangeStudyAbroadPage.get('lastYearAttended')"
          testSelector="app-year"></uc-year-selector>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="/application/study_start"></uc-task-hint>
        <uc-subsection-question required="yes">
          {{ strings.studyStart }}
        </uc-subsection-question>
        <uc-radio-input-group
          type="star_begin_studies"
          groupName="study-intentions"
          formControlName="studyStart"></uc-radio-input-group>
      </uc-subsection>

      <uc-subsection>
        <uc-task-hint [task]="task" path="{{ basePath }}/length_of_study"></uc-task-hint>
        <uc-subsection-question required="yes">
          {{ strings.lengthOfStudy }}
        </uc-subsection-question>
        <uc-radio-input-group
          [options]="lengthOfStudyOptions"
          formControlName="lengthOfStudy"
          groupName="study-length"></uc-radio-input-group>
      </uc-subsection>

      <uc-subsection>
        <uc-form-input>
          <uc-subsection-question>
            {{ strings.studyPlan }}
          </uc-subsection-question>
          <uc-subsection-minor-text [innerHTML]="strings.studyPlanGuidance"></uc-subsection-minor-text>
          <uc-text-area testSelector="study-plan" formControlName="proposedStudyPlan"></uc-text-area>
        </uc-form-input>
      </uc-subsection>
    </uc-section>
  </div>
</uc-form-template>
