import { Input, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment, ChangeOfEnrolmentUpdate } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Directive()
export class DeclarationBase extends AbstractBaseTask {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() processComplete = false;
  @Input() isInternational: boolean;
  @Input() applicationYear?: string;

  log: Logger;
  baseStrings = strings.components.tasks.declaration;
  declarationStrings = this.baseStrings.applicationDeclaration;
  showApplicationHints = true;
  declarationOptions = [];
  declarationCheck: UntypedFormControl;
  model: ChangeOfEnrolment | Application | ChangeOfEnrolmentUpdate;
  hintPath: string;
  declarationName: string;
  disableCheckbox = false;
  loading = false;

  constructor(loggingService: LoggingService) {
    super();
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity(_err: UCError) {}

  setupDeclarationForm(declarationName: string) {
    this.declarationOptions = [{ labelText: this.declarationStrings.header, value: declarationName }];
    this.declarationCheck = new UntypedFormControl('', UCValidators.validateAllChecked(this.declarationOptions));
  }

  setupData(model: Observable<ChangeOfEnrolment | Application | ChangeOfEnrolmentUpdate>, declarationName: string) {
    this.declarationName = declarationName;
    this.setupDeclarationForm(declarationName);

    model
      .pipe(
        filter((mdl) => !!mdl),
        take(1),
      )
      .subscribe((mdl) => {
        this.model = mdl;
        const values = this.declarationOptions.filter((opt) => !!this.model[opt.value]).map((opt) => opt.value);
        this.declarationCheck.patchValue(values);
        this.declarationCheck.markAsDirty();

        if (!!this.model[declarationName]) {
          this.disableCheckbox = true;
        }
      });
  }

  updateDeclarationTask(
    service: EnrolmentService | ApplicationService | ChangeOfEnrolmentService,
    updateModelFn: string,
  ) {
    // This can be simplified as the declaration pages that extend this base only have one checkbox
    const declarationValue = get(this.declarationCheck, 'value[0]') ? true : null;
    if (this.model instanceof ChangeOfEnrolment) {
      this.model = new ChangeOfEnrolment({});
      this.model[this.declarationName] = declarationValue;
    }
    this.model[this.declarationName] = declarationValue;

    service[updateModelFn]
      .call(service, this.model)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (mdl) => {
          this.log.info('updated model successfully', mdl);
          this.next.emit();
        },
        (err) => {
          const error = err && err.code ? err : { code: 'errors.unknown' };
          this.errors.emit(error);
          this.log.error('could not update model', err);
        },
      );
  }
}
