<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header>
      <p><span [innerHTML]="strings.toGainAdmission"></span></p>
      <uc-message-banner *ngIf="(applicant$ | async).englishQualification.verified" type="info">
        <span [innerHTML]="strings.disabledDueToVerificationMessage"></span>
      </uc-message-banner>
    </uc-section-header>
    <uc-english-proficiency-detail
      [task]="task"
      [stage]="stage"
      [applicationYear]="applicationYear"></uc-english-proficiency-detail>
  </uc-section>
</uc-form-template>
