<div class="application-summary-row">
  <div class="application-summary-row-inner">
    <div class="application-summary-row-left">
      <uc-icon
        class="application-summary__chevron"
        (click)="toggleCompactView()"
        *ngIf="compact && allowExpansion"
        iconType="chevron-down"
        iconWidth="15"
        iconHeight="15"></uc-icon>
      <uc-icon
        class="application-summary__chevron"
        (click)="toggleCompactView()"
        *ngIf="!compact && allowExpansion"
        iconType="chevron-up"
        iconWidth="15"
        iconHeight="15"></uc-icon>
      <span
        class="application-year"
        [class.clickable]="allowExpansion"
        [class.no-icon]="!allowExpansion"
        (click)="toggleCompactView()">
        {{ year }} {{ baseStrings.application }}
      </span>
      <div class="status-pill-wrapper">
        <uc-status-pill
          [academicYear]="year"
          [summaryState]="state"
          [isImpersonated]="isImpersonated"
          [showInfoModal]="!hideOfferStatusModal"
          [isInternational]="isInternational"
          (triggerAction)="triggerActionFromStatusPill($event)"></uc-status-pill>
        <uc-status-pill
          *ngIf="courseState"
          [summaryState]="courseState"
          [academicYear]="year"
          [isImpersonated]="isImpersonated"
          [courseStatus]="true"></uc-status-pill>
      </div>
      <div *ngIf="compact && allowExpansion" class="application-summary__info-link--wrapper">
        <a class="application-summary__info-link" (click)="compact = !compact" href="javascript:void(0)">
          {{ baseStrings.showMoreInfo }}
        </a>
      </div>
    </div>
    <div class="application-summary-row-right">
      <div class="section section-primary-action">
        <a *ngIf="primaryAction && !isLoading" href="javascript:void(0)" (click)="primaryActionHandler()" class="link">
          {{ primaryAction.label }}
        </a>
        <div *ngIf="isLoading" class="loading-blip__wrapper">
          <uc-loader-blip [showBlips]="isLoading" color="$colorMercury"></uc-loader-blip>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!compact">
  <div class="application-summary__buttons buttons tny-stacked sml-flat" *ngIf="shouldShowActionButtons">
    <ng-container *ngFor="let action of configForActionButtons">
      <button
        [attr.data-cy]="'secondary-action-' + action?.action?.toLowerCase()"
        uc-button
        class="inline-icon-btn action-button"
        type="secondary"
        padding="sml"
        (click)="handleAction(action)"
        [iconType]="secondaryActionIcon(action)">
        {{ action.label }}
      </button>
    </ng-container>
  </div>

  <uc-message-banner *ngIf="noActiveEnrolments" class="no-active-enrolments">
    <h5 class="no-active-enrolments__heading">{{ baseStrings.noActiveEnrolments.header }}</h5>
    <p [innerHTML]="baseStrings.noActiveEnrolments.body"></p>
  </uc-message-banner>

  <uc-process-info-panel
    [applicationSummary]="applicationSummary"
    (refreshAndNavigate)="refreshTokenAndNavigate($event)"
    (cancelInProgressProcess)="cancelInProgressProcess()"></uc-process-info-panel>

  <div *ngIf="!hideManageEnrolmentView && activeFullEnrolments">
    <ng-container *ngFor="let enrolment of activeFullEnrolments; let i = index">
      <uc-manage-enrolment-view
        *ngIf="i <= 5"
        [teachingPeriods]="teachingPeriods"
        [minimalView]="true"
        [enrolment]="enrolment"
        [applicationYear]="year"
        (enrolmentWithdrawn)="withdrawEnrolment($event)"
        [secondaryActions]="secondaryActions"
        [enrolmentNumber]="i"></uc-manage-enrolment-view>
    </ng-container>
    <div *ngIf="isArchived && archivedQuals">
      <div class="item-content-section" *ngFor="let qualification of archivedQuals; let i = index">
        <h3 class="qualification-heading">
          {{ year }} {{ qualification.title }}
          <span *ngIf="qualification.qualificationAnswer">({{ qualification.qualificationAnswer.code }})</span>
        </h3>
      </div>
    </div>
  </div>
  <div class="item-content-wrapper withdrawn__wrapper" *ngIf="shouldShowWithdrawn && withdrawnEnrolments">
    <h4 class="withdrawn__heading">{{ baseStrings.inactive }}</h4>
    <ul class="withdrawn__list">
      <ng-container *ngFor="let withdrawnEnrolment of withdrawnEnrolments; let i = index">
        <li *ngIf="i < 3">
          {{ withdrawnEnrolment }}
        </li>
        <li *ngIf="i >= 3 && showAllWithdrawn">
          {{ withdrawnEnrolment }}
        </li>
      </ng-container>
    </ul>
    <a
      href="javascript:void(0)"
      *ngIf="withdrawnEnrolments.length > 3 && !showAllWithdrawn"
      (click)="showAllWithdrawn = !showAllWithdrawn">
      {{ baseStrings.showAll }}
    </a>
  </div>
</div>
