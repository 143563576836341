import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export class TeachingAssessment {
  experiences: number;
  currentInvolvement: number;
  transferrableSkills: number;
  leadershipAndOrganisation: number;
  collaborativeProfession: number;
  societyEducationalCommunities: number;
  tangataWhenua: number;
  ethicalResponsibilities: number;
  presentingIdeas: number;
  spokenLanguage: number;
  bodyLanguage: number;
  messageSubstance: number;
  responsiveness: number;
  academicBackground: number;
  literacyAssessmentScore: number;
  numeracyAssessmentScore: number;
  ref1Receptiveness: number;
  ref1FosterMaintain: number;
  ref1DiversityHeritageLanguage: number;
  ref1TeamMember: number;
  ref2Receptiveness: number;
  ref2FosterMaintain: number;
  ref2DiversityHeritageLanguage: number;
  ref2TeamMember: number;
  totalScore: number;

  constructor(data) {
    Object.assign(this, data);
  }

  static deserialize(payload) {
    if (payload === null) {
      return null;
    }

    const teachingAssessment = camelizeKeys(payload);
    return new TeachingAssessment(teachingAssessment);
  }

  static serialize(instance: any) {
    const clone = { ...instance };

    return snakeifyKeys(clone);
  }
}
