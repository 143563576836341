<div [formGroup]="associatedPersonForm">
  <ng-container *ngIf="showType">
    <uc-task-hint [task]="task" [model]="hintPath" [path]="getHintPath('type')"></uc-task-hint>
    <uc-form-field-label labelText="{{ strings?.type }}" [required]="allRequired">
      <uc-reference-data-selector
        [testSelector]="testSelectorId + '-type'"
        [type]="typeRefdata"
        formControlName="type"></uc-reference-data-selector>
    </uc-form-field-label>
  </ng-container>

  <div *ngIf="associatedPersonForm.get('name')">
    <uc-task-hint [task]="task" [model]="hintPath" [path]="getHintPath('name')"></uc-task-hint>
    <uc-form-field-label labelText="{{ strings?.name }}" [required]="allRequired">
      <uc-subsection-minor-text *ngIf="strings?.helpText?.name">{{ strings.helpText.name }}</uc-subsection-minor-text>
      <uc-string-input [testSelector]="testSelectorId + '-name'" formControlName="name"></uc-string-input>
    </uc-form-field-label>
  </div>

  <div *ngIf="associatedPersonForm.get('relationship')">
    <uc-task-hint [task]="task" [model]="hintPath" [path]="getHintPath('relationship')"></uc-task-hint>
    <uc-form-field-label labelText="{{ strings?.relationship }}" [required]="allRequired">
      <uc-string-input
        [testSelector]="testSelectorId + '-relationship'"
        formControlName="relationship"></uc-string-input>
    </uc-form-field-label>
  </div>

  <ng-container *ngIf="showEmail">
    <uc-task-hint [task]="task" [model]="hintPath" [path]="getHintPath('email')"></uc-task-hint>
    <uc-form-field-label labelText="{{ strings?.email }}" [required]="allRequired">
      <uc-string-input [testSelector]="testSelectorId + '-email'" formControlName="email"></uc-string-input>
    </uc-form-field-label>
  </ng-container>
</div>
