import { Component, Input } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { UCFile } from '@shared/models/uc-file';
import { UserService } from '@shared/services/user/user.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';

@Component({
  selector: 'uc-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent extends UnsubscribeOnDestroy {
  @Input() files: UCFile[];
  @Input() dateFormat: string;
  strings = strings.components.molecules.fileList;

  constructor(
    private userService: UserService,
    private userActivityService: UserActivityService,
  ) {
    super();
  }

  updateViewedDocs(documentId: string) {
    this.userService.userDetail
      .pipe(
        filter((user) => !!user),
        takeUntil(this.componentDestroyed),
      )
      .subscribe(async (user) => {
        if (!user.impersonated) {
          await this.userActivityService.addViewedDocument(documentId);
        }
      });
  }

  newDocumentDownloaded(documentId: string) {
    this.userActivityService.getViewedDocuments().subscribe((docIds) => {
      if (!docIds.includes(documentId)) {
        this.updateViewedDocs(documentId);

        const fileIndex = this.files.findIndex((file) => file.id === documentId);
        if (fileIndex > -1) {
          this.files[fileIndex].viewed = true;
        }
      }
    });
  }
}
