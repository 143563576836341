import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { links } from '@constants/links.constants';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { Applicant } from '@shared/models/applicant';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { DocumentService } from '@shared/services/document/document.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-resolve-offer',
  templateUrl: './resolve-offer.component.html',
  styleUrls: ['./resolve-offer.component.scss'],
})
export class ResolveOfferComponent implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  strings = strings.components.tasks.resolveOffer;

  acceptOfferLink = links.resolveOfferLink;
  offerAvailable = false;
  noOfferYet = false;

  currentApplicant: Observable<Applicant>;

  constructor(
    private ws: WindowService,
    private applicantService: ApplicantService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    // get applicant, so that we can show the user email address
    this.currentApplicant = this.applicantService.applicant;

    if (environment.features.offerLetter) {
      this.documentService.getDocumentsForCategory('OFFER_LETTER').subscribe((x) => {
        this.offerAvailable = !!x.length && !!x.find((doc) => doc.file.academicYear === this.applicationYear);
        this.noOfferYet = !this.offerAvailable;
      });
    }
  }

  navigateToUCSW() {
    if (!this.acceptOfferLink) {
      throw new Error('bad config no accept offer ucsw link');
    }
    this.ws.nativeWindow.open(this.acceptOfferLink, '_self');
  }
}
