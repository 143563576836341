<uc-section [formGroup]="studyContactDetails">
  <uc-section-header title="{{ strings.sectionTitle }}">
    <p class="description">
      {{ strings.description }}
    </p>
  </uc-section-header>
  <uc-subsection>
    <uc-form-input>
      <div class="input">
        <uc-string-input
          testSelector="current-email"
          formControlName="email"
          label="{{ strings.emailLabel }}"
          required="yes"></uc-string-input>
      </div>

      <div class="input">
        <uc-phone-selector
          testSelector="current-mobile"
          formControlName="mobileNum"
          labelText="{{ strings.mobileLabel }}"></uc-phone-selector>
      </div>

      <div class="input">
        <uc-phone-selector
          testSelector="current-landline"
          formControlName="landlineNum"
          labelText="{{ strings.landlineLabel }}"></uc-phone-selector>
      </div>

      <div class="input">
        <uc-address-selector testSelector="current-contact" formControlName="address"></uc-address-selector>
      </div>
    </uc-form-input>
  </uc-subsection>
</uc-section>
