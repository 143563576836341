<div class="course-card__action-container">
  <span class="tooltip" [class.show]="showTooltip">
    {{ tooltipMessage }}
  </span>
  <div *ngIf="!hasChangeAction">
    <button
      [attr.data-cy]="'add-course-' + testSelector"
      *ngIf="!selectedCourse"
      class="course-card__action"
      (click)="addACourse()">
      <uc-icon
        *ngIf="!showSpinner"
        iconType="plus"
        iconWidth="18"
        iconHeight="18"
        iconFill="$colorFireEngineRed"
        class="course-card__action-icon"></uc-icon>
      <uc-spinner *ngIf="showSpinner"></uc-spinner>
    </button>
    <button
      [attr.data-cy]="'remove-course-' + testSelector"
      *ngIf="selectedCourse"
      disabled
      class="course-card__action course-card__action--added">
      <uc-icon
        *ngIf="!showRemoveSpinner"
        iconType="tick"
        iconWidth="18"
        iconHeight="18"
        iconFill="$colorWhite"
        class="course-card__action-icon"></uc-icon>
    </button>
  </div>

  <div [attr.class]="changeActionClass" *ngIf="hasChangeAction">
    {{ changeActionLabel }}
    <button
      [attr.data-cy]="'tooltip'"
      class="tooltip-btn"
      ucTooltip
      tooltipContent="You can undo this action in the My Course Selection panel.">
      <uc-icon
        iconTitle="More info"
        iconType="info"
        iconWidth="18"
        iconHeight="18"
        iconFill="$colorMineShaft"></uc-icon>
    </button>
  </div>
</div>
