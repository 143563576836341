import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import strings from '@constants/strings.constants';
import { IRefdataOptions, ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

export const DISABILITY_CONSTANTS = {
  IS_TRUE: '01', // "Yes"
  IS_FALSE: '00', // "No"
  IS_PREFER_NOT_TO_SAY: '02', // "Prefer not to say"
};

@Component({
  selector: 'uc-fitness-to-teach',
  templateUrl: './fitness-to-teach.component.html',
  styleUrls: ['./fitness-to-teach.component.scss'],
})
export class FitnessToTeachComponent implements OnInit {
  @Input() task: Task;
  @Input() fitnessToTeachForm: UntypedFormGroup;
  @Input() disabilityDetailRequired: boolean;

  strings = strings.components.tasks.teachingApplication.fitnessToTeach;
  showContentValue = DISABILITY_CONSTANTS.IS_TRUE;
  declarationOptions$: Observable<IRefdataOptions[]>;

  constructor(private refdataService: ReferenceDataService) {}

  ngOnInit() {
    this.refdataService.getByType('declaration_disability').subscribe((declarationData: ReferenceData[]) => {
      this.declarationOptions$ = of(ReferenceData.refDataToOptions(declarationData));
    });
  }
}
