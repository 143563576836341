<div class="minimal-footer" [class.dashboard]="fullPageView">
  <div class="footer-help-text">{{ footerStrings.needHelpText }}</div>
  <div *ngIf="showHelp" class="footer-help-text">
    <uc-process-help-modal-button></uc-process-help-modal-button>
  </div>
  <div class="minimal-footer-btm">
    <div class="minimal-footer-block">
      <div class="minimal-footer-block-line">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L10.53 8.67C10.21 8.87 9.79 8.87 9.47 8.67L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
            fill="#CE3E2E" />
        </svg>
        <a href="mailto:{{ footerStrings.infoEmail }}" class="minimal-footer-link">
          {{ footerStrings.infoEmail }}
        </a>
      </div>
      <div class="minimal-footer-block-line">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.2037 12.2681L13.6637 11.9781C13.0537 11.9081 12.4537 12.1181 12.0237 12.5481L10.1837 14.3881C7.35367 12.9481 5.03367 10.6381 3.59367 7.79807L5.44367 5.94807C5.87367 5.51807 6.08367 4.91807 6.01367 4.30807L5.72367 1.78807C5.60367 0.778067 4.75367 0.0180664 3.73367 0.0180664H2.00367C0.873674 0.0180664 -0.0663265 0.958066 0.00367348 2.08807C0.533673 10.6281 7.36367 17.4481 15.8937 17.9781C17.0237 18.0481 17.9637 17.1081 17.9637 15.9781V14.2481C17.9737 13.2381 17.2137 12.3881 16.2037 12.2681Z"
            fill="#CE3E2E" />
        </svg>

        <a href="tel:{{ footerStrings.infoPhone }}" class="minimal-footer-tel-link">
          {{ footerStrings.infoPhone }}
        </a>
      </div>
    </div>
  </div>
</div>
