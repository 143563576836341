{
  "change_of_enrolments": {
    "academic_year": "2020",
    "creation_date": "2020-07-18",
    "date_submitted": "2021-06-05",
    "state": { "code": "in_progress" },
    "status": {
      "code": "draft"
    },
    "declaration_agreed": false,
    "errors": [
      {
        "dateCreated": "2023-02-13T13:48:52.690611+13:00",
        "internalReference": "678",
        "ruleType": "workload",
        "ruleCategory": "jsms_workload",
        "message": "The programme of study is likely to to create undue workload",
        "waivable": true,
        "declinable": true,
        "owner": "Ang",
        "targets": { "code": "123" }
      },
      {
        "dateCreated": "2023-02-12T13:48:52.690611+13:00",
        "internalReference": "789",
        "ruleType": "restricted_points",
        "ruleCategory": "jsms_opm",
        "message": "Restriction rule 'Cannot exceed 45 points ' failed  - Enrolled in 75 points across all faculties",
        "waivable": false,
        "declinable": true,
        "owner": "Ang",
        "targets": { "code": "123" }
      }
    ],
    "enrolled_qualifications": [
      {
        "priority": 2,
        "code": "BA",
        "internal_reference": "ceuDrhkRsdfkd82dPOlI0ldiuekd3F",
        "subject_options": { "1": [{ "code": "Art History and Theory", "change_action": "none" }] },
        "new_in_award": true,
        "href": "/BA",
        "errors": [
          {
            "dateCreated": "2023-02-13T13:48:52.690611+13:00",
            "internalReference": "567",
            "ruleType": "manual_programme_of_study",
            "ruleCategory": "student_first",
            "message": "The BA qualification requires overall programme of study approval",
            "waivable": true,
            "declinable": true,
            "owner": "Ang",
            "targets": { "code": "123" }
          }
        ],
        "enrolled_courses": [
          {
            "code": "COSC101_17S2 (C)",
            "internal_reference": "aeuDrhkRsdfkd82dPOlI0ldiuekd3a",
            "duration": "18.0",
            "start_date": "2017-07-17",
            "end_date": "2017-11-19",
            "change_action": "add",
            "active": true,
            "state": { "code": "draft" },
            "errors": [
              {
                "dateCreated": "2023-02-13T13:48:52.690611+13:00",
                "internalReference": "123",
                "ruleType": "manual_approval",
                "ruleCategory": "rule",
                "message": "The qualification is likely to to create undue workload",
                "waivable": true,
                "declinable": true,
                "owner": "Ang",
                "targets": { "code": "123" }
              }
            ]
          },
          {
            "code": "ACCT102_17S1 (C)",
            "internal_reference": "beuDrhkRsdfkd82dPOlI0ldiuekd3h",
            "duration": "18.0",
            "start_date": "2017-07-17",
            "end_date": "2017-11-19",
            "change_action": "drop",
            "active": true,
            "state": { "code": "not_selected" },
            "errors": [
              {
                "dateCreated": "2023-02-13T13:48:52.690611+13:00",
                "internalReference": "123",
                "ruleType": "manual_approval",
                "ruleCategory": "rule",
                "message": "The qualification is likely to to create undue workload",
                "waivable": true,
                "declinable": true,
                "owner": "Ang",
                "targets": { "code": "123" }
              }
            ]
          },
          {
            "internal_reference": "R93kd82dPOlI0ldiukeuDrhdkekd3F",
            "code": "COSC101_17S1 (C)",
            "change_action": "none",
            "duration": 15.0,
            "start_date": "2020-06-05",
            "end_date": "2020-10-27",
            "active": true,
            "state": { "code": "selected" },
            "errors": [
              {
                "dateCreated": "2023-02-13T13:48:52.690611+13:00",
                "internalReference": "123",
                "ruleType": "manual_approval",
                "ruleCategory": "rule",
                "message": "The qualification is likely to to create undue workload",
                "waivable": true,
                "declinable": true,
                "owner": "Ang",
                "targets": { "code": "123" }
              }
            ]
          }
        ],
        "deaggregated": false,
        "change_action": "none"
      },
      {
        "priority": 1,
        "code": "BCOM",
        "internal_reference": "ceuDrhkRsdfkd82dPOlI0ldiuekd3F",
        "subject_options": { "1": [{ "code": "Macro Economics", "change_action": "none" }] },
        "new_in_award": true,
        "href": "/BA",
        "errors": [
          {
            "dateCreated": "2023-02-13T13:48:52.690611+13:00",
            "internalReference": "123",
            "ruleType": "manual_programme_of_study",
            "ruleCategory": "student_first",
            "message": "The BCOM qualification requires overall programme of study approval",
            "waivable": true,
            "declinable": true,
            "owner": "Ang",
            "targets": { "code": "123" }
          }
        ],
        "enrolled_courses": [
          {
            "code": "COSC101_17S2 (C)",
            "internal_reference": "aeuDrhkRsdfkd82dPOlI0ldiuekd3a",
            "duration": "18.0",
            "start_date": "2017-07-17",
            "end_date": "2017-11-19",
            "change_action": "add",
            "active": true,
            "state": { "code": "selected" },
            "errors": [
              {
                "dateCreated": "2023-02-13T13:48:52.690611+13:00",
                "internalReference": "123",
                "ruleType": "workload",
                "ruleCategory": "rule",
                "message": "The qualification is likely to to create undue workload",
                "waivable": true,
                "declinable": true,
                "owner": "Ang",
                "targets": { "code": "123" }
              }
            ]
          },
          {
            "internal_reference": "R93kd82dPOlI0ldiukeuDrhdkekd3F",
            "code": "COSC101_17S1 (C)",
            "change_action": "none",
            "duration": 15.0,
            "start_date": "2020-06-05",
            "end_date": "2020-10-27",
            "active": true,
            "state": { "code": "not_selected" }
          }
        ],
        "deaggregated": false,
        "change_action": "Qualification added"
      }
    ],
    "student_provided_exemption_reason": "",
    "funding_type": null,
    "study_location": {
      "code": "Christchurch"
    },
    "study_start": {
      "code": "Semester 1"
    },
    "study_start_other": null,
    "payment_method": {
      "code": "Private funds"
    },
    "scholarship_provider": "",
    "payment_method_other": "",
    "scholarship_name": "",
    "declaration_uds_information_read": "",
    "police_check": {
      "has_charges_pending": true,
      "has_convictions": true,
      "charges_pending_description": "Charges",
      "convictions_description": "Convictions",
      "has_background_check": true,
      "declaration_police_agree": false,
      "id_checks": []
    },
    "declaration_background_check": [
      {
        "country": {
          "code": "NZ"
        },
        "applied": {
          "code": "NONE"
        }
      }
    ],
    "associatedPerson": [
      {
        "type": {
          "code": "referee1"
        },
        "name": "Mr Rick Sanchez",
        "email": "coord@email.com",
        "relationship": "professor"
      },
      {
        "type": {
          "code": "referee1"
        },
        "name": "Ms Summer Smith",
        "email": "support@email.com",
        "relationship": "friend",
        "declarationTeachingAgree": null,
        "experience": "Sup bro, mad experience"
      }
    ],

    "cup_study_plan_after": null,
    "cup_how_did_you_hear_about": {
      "code": "Other"
    },
    "rationale": "Always blow on the pie",
    "career_knowledge": "This is some example text for career knowledge",
    "online_assessment": {
      "numeracy_score": "69",
      "literacy_score": "69"
    }
  }
}
