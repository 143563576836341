import { Component, Input, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import {
  UCElementGroup,
  group,
  control,
  valueToRefData,
  refDataToValue,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-cup',
  templateUrl: './cup.component.html',
  styleUrls: ['./cup.component.scss'],
})
export class CupComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() stage: string;
  strings = strings.components.tasks.cup;
  cupForm: UCElementGroup;
  cupPage: UntypedFormGroup;
  log: Logger;

  constructor(
    private formMapper: FormModelMapperService,
    private logService: LoggingService,
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = logService.createLogger(this);
  }

  private createForm(): UCElementGroup {
    return group({
      cupHowDidYouHearAbout: control({
        model: this.dataModel,
        path: '/cupHowDidYouHearAbout',
        outMap: valueToRefData,
        inMap: refDataToValue,
        validators: [Validators.required],
      }),
      rationale: control({
        model: this.dataModel,
        path: '/rationale',
        validators: [Validators.required, UCValidators.createWordCountValidator(250)],
      }),
    });
  }

  public updateFormValidity(err: UCError) {
    this.formMapper.updateFormValidity(err.data, this.cupForm);
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.cupForm = this.createForm();
    this.cupPage = this.cupForm.asControl() as UntypedFormGroup;

    this.service.application.pipe(takeUntil(this.componentDestroyed)).subscribe((app) => {
      this.formMapper.updateFormFromModel(this.cupForm, null, app);
      this.currentApplication = app;
    });
  }

  update() {
    if (!this.currentApplication) {
      this.errors.emit();
      return this.log.error('tried to update application, but no application has been loaded yet');
    }
    this.formMapper.updateModelFromForm(this.cupForm, null, this.currentApplication);
    const application = this.isCOE
      ? new ChangeOfEnrolment({
          cupStudyPlanAfter: this.currentApplication.cupStudyPlanAfter,
          cupHowDidYouHearAbout: this.currentApplication.cupHowDidYouHearAbout,
          rationale: this.currentApplication.rationale,
        })
      : this.currentApplication;

    this.service.update(application).subscribe(
      (updatedApplication) => {
        if (updatedApplication) {
          this.log.info('updated application successfully');
          this.next.emit();
        }
      },
      (err) => {
        this.errors.emit();
        this.log.error('error thrown while updating the application', err);
      },
    );
  }
}
