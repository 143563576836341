import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  get nativeWindow(): Window {
    return window;
  }
}

export class WindowServiceMock {
  private mockWindow = {
    innerWidth: 1440,
    open() {
      return { opener: null, location: null };
    },
    location: {
      reload: () => {},
      assign: () => {},
      href: null,
    },
    history: {
      length: 0,
      back: () => {},
    },
  };

  setInnerWidth(width: number) {
    this.mockWindow.innerWidth = width;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  get nativeWindow(): any {
    return this.mockWindow;
  }
}
