import { Injectable } from '@angular/core';
import { DefaultWidgetRegistry } from 'ngx-schema-form';

import { UcRadioGroupWidgetComponent } from '@shared/components/widgets/radio-group-widget/radio-group.widget';
import { UcReferenceDataWidgetComponent } from '@shared/components/widgets/reference-data-widget/reference-data.widget';
import { UcStringWidgetComponent } from '@shared/components/widgets/string-widget/string.widget';

@Injectable()
export class UcWidgetRegistry extends DefaultWidgetRegistry {
  constructor() {
    super();

    this.register('string', UcStringWidgetComponent);
    this.register('reference_data', UcReferenceDataWidgetComponent);
    this.register('radio_group', UcRadioGroupWidgetComponent);
  }
}
