import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { zip as observableZip, combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import stringConsts from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { DISABILITY_CONSTANTS } from '@shared/components/molecules/fitness-to-teach/fitness-to-teach.component';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  array,
  UCElementArray,
  refDatasToCodeArray,
  controlToRefDataArray,
  singleCheckboxValueToBoolean,
  booleanToSingleCheckboxValue,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-review-details',
  templateUrl: './review-details.component.html',
})
export class ReviewDetailsComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  strings = stringConsts.components.tasks.reviewDetails;
  phoneStrings = stringConsts.components.molecules.phoneSelector;

  a2eStrings = stringConsts.components.tasks.applicationToEnrol;

  showDesc = false;
  reviewForm: UntypedFormGroup;
  ucReviewForm: UCElementGroup;
  dateOfBirthYear: number;
  currentApplicant: Applicant;
  currentApplication: Application;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  private log: Logger;

  constructor(
    logService: LoggingService,
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
  ) {
    super();
    this.log = logService.createLogger(this);
  }

  get otherNames(): UCElementArray {
    return this.ucReviewForm.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return get(this.otherNames, 'control.length');
  }

  private createForm(): UCElementGroup {
    return group({
      birthDate: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: 'applicant',
        path: '/birthDate',
      }),
      names: group({
        legalName: control({
          defaultState: new Name({}),
          validators: [Validators.required],
          model: 'applicant',
          path: '/legalName',
        }),
        // assumption is made that array objects have all the same type
        name: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/name',
        }),
      }),
      genderGroup: group({
        gender: control({ model: 'applicant', path: '/gender', inMap: refDataToValue, outMap: valueToRefData }),
      }),
      citizenship: group({
        citizenCategory: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/citizenship',
        }),
        citizenCountry: control({ model: 'applicant', path: '/demographic/passportCountry' }),
        studyInNz: control({ model: 'application', path: '/studyInNz', validators: [Validators.required] }),
      }),
      contactDetails: group({
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        landlineNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzLandlineValidator],
          model: 'applicant',
          path: '/contactDetail/landlineNumber',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
        studyAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/studyContactDetail/currentAddress',
        }),
      }),
      emergencyDetails: group({
        email: control({
          defaultState: '',
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/emergencyContactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/mobileNumber',
        }),
        alternatePhoneNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzAlternatePhoneValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/alternatePhone',
        }),
        firstName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/firstName',
        }),
        lastName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/surname',
        }),
        relationship: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactRelationship',
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/currentAddress',
        }),
        declarationEmergencyContactInformationCorrect: control({
          model: 'application',
          path: '/declarationEmergencyContactInformationCorrect',
          defaultState: false,
          inMap: booleanToSingleCheckboxValue('declarationEmergencyContactInformationCorrect'),
          outMap: singleCheckboxValueToBoolean('declarationEmergencyContactInformationCorrect'),
          validators: [
            UCValidators.validateAllChecked([
              { labelText: 'test', value: 'declarationEmergencyContactInformationCorrect' },
            ]),
          ],
        }),
      }),
      learningNeeds: group({
        disability: control({
          defaultState: [],
          model: 'applicant',
          path: '/disability',
          inMap: refDatasToCodeArray,
          outMap: controlToRefDataArray,
          validators: [],
        }),
        hasDisability: control({
          model: 'applicant',
          path: '/declarationDisability',
          inMap: refDataToValue,
          outMap: valueToRefData,
          validators: [Validators.required],
        }),
        disabilityDetail: control({
          model: 'applicant',
          path: '/disabilityDetail',
        }),
      }),
    });
  }

  private disableVerifiedControls(applicant?: Applicant) {
    if (get(applicant, 'validatedBirthDate')) {
      this.reviewForm.get('birthDate').disable();
    }

    if (get(applicant, 'demographic.validatedCitizenship')) {
      this.reviewForm.get('citizenship.citizenCategory').disable();
      this.reviewForm.get('citizenship.citizenCountry').disable();
    }

    if (get(applicant, 'legalName.validated')) {
      this.reviewForm.get('names.legalName').disable();
    }
  }

  public updateFormValidity(): void {
    throw new Error('update form validity not yet implemented.');
  }

  ngOnInit() {
    this.ucReviewForm = this.createForm();
    this.reviewForm = this.ucReviewForm.asControl() as UntypedFormGroup;
    this.dateOfBirthYear = new Date().getFullYear() - 10;

    this.reviewForm
      .get('citizenship.citizenCategory')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        const countryControl = this.reviewForm.get('citizenship.citizenCountry');
        if (val.code && !this.isNzOrAus(val)) {
          countryControl.setValidators([Validators.required]);
        } else {
          countryControl.setValidators([]);
          countryControl.setValue({ code: null });
        }
        countryControl.updateValueAndValidity();
      });

    this.reviewForm
      .get('learningNeeds.hasDisability')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((v) => !!v),
      )
      .subscribe((val) => {
        if (val === DISABILITY_CONSTANTS.IS_TRUE) {
          this.reviewForm.get('learningNeeds.disability').setValidators([Validators.required]);
          this.reviewForm.get('learningNeeds.disabilityDetail').setValidators([Validators.required]);
        } else {
          this.reviewForm.get('learningNeeds.disability').setValidators([]);
          this.reviewForm.get('learningNeeds.disabilityDetail').setValidators([]);
        }
        this.reviewForm.get('learningNeeds.disability').updateValueAndValidity();
        this.reviewForm.get('learningNeeds.disabilityDetail').updateValueAndValidity();
      });

    this.reviewForm
      .get('citizenship')
      .valueChanges.pipe(takeUntil(this.componentDestroyed))
      .subscribe((val) => {
        if (val.studyInNz === false) {
          this.showDesc = !!this.isNzOrAus(this.reviewForm.get('citizenship.citizenCategory').value, 'not-pr');
        } else {
          this.showDesc = false;
        }
      });

    observableCombineLatest(this.applicantService.applicant, this.applicationService.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.ucReviewForm, applicant, application);
        if (applicant && !applicant.name.length) {
          this.otherNames.removeAt(0);
        }

        this.disableVerifiedControls(applicant);
      });
  }

  isNzOrAus(val, citizenType?: string): boolean {
    if (!val) {
      return false;
    }

    if (val.code && !citizenType) {
      return !!val.code.match(/^(NZCZ|AUS)$/);
    }

    if (val.code && citizenType === 'pr') {
      return !!val.code.match(/^(NZPR|AUSPR|)$/);
    }

    if (val.code && citizenType && citizenType !== 'pr') {
      return !!val.code.match(/^(NZPR|AUSPR|AUS)$/);
    }
  }
  copyPermanentToEmergencyAddress() {
    const permanentAddress = this.reviewForm.get('contactDetails.address').value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.reviewForm.get('emergencyDetails.address').patchValue(addressCopy);
  }

  public copyPermanentToStudyAddress() {
    const permanentAddress = this.reviewForm.get('contactDetails.address').value;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.reviewForm.get('contactDetails.studyAddress').patchValue(addressCopy);
  }

  update() {
    if (!this.currentApplicant || !this.currentApplication) {
      this.errors.emit();
      return this.log.error('no current applicant/application');
    }

    this.formModel.updateModelFromForm(this.ucReviewForm, this.currentApplicant, this.currentApplication);

    // Hack to prevent nulls in the name array
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);

    // logic copied from application-To-Enrol task
    if (get(this.currentApplicant, 'demographic.citizenship')) {
      if (this.currentApplicant.demographic.citizenship.code === 'NZCZ') {
        this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'NZL' });
      } else if (this.currentApplicant.demographic.citizenship.code === 'AUS') {
        this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'AUS' });
      }
    }

    if (this.currentApplicant.declarationDisability.code !== DISABILITY_CONSTANTS.IS_TRUE) {
      this.currentApplicant.disability = [];
      this.currentApplicant.disabilityDetail = null;
    }

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    observableZip(updateApplicant, updateApplication).subscribe({
      next: ([applicant, application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      error: (err: DSHttpError) => {
        this.errors.emit(err);
        this.log.error('error thrown while updating applica(nt|tion):', err);
      },
    });
  }
}
