import { Component } from '@angular/core';

import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';

import promotionalTileMetadata from './promotional-tile.constants';

@Component({
  selector: 'uc-promotional-tile',
  templateUrl: './promotional-tile.component.html',
  styleUrls: ['./promotional-tile.component.scss'],
})
export class PromotionalTileComponent extends UnsubscribeOnDestroy {
  metadata = promotionalTileMetadata;
}
