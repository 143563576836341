import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-current-contact-details',
  templateUrl: './current-contact-details.component.html',
  styleUrls: ['./current-contact-details.component.scss'],
})
export class CurrentContactDetailsComponent {
  @Input() studyContactDetails: UntypedFormGroup;

  strings = strings.components.organisms.profilePage.currentContactDetails;
}
