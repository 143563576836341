import { Component, Input, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { EnglishProficiencyDetailComponent } from '@shared/components/organisms/english-proficiency-detail/english-proficiency-detail.component';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-english-proficiency',
  templateUrl: './english-proficiency.component.html',
  styleUrls: ['./english-proficiency.component.scss'],
})
export class EnglishProficiencyComponent extends AbstractBaseTask {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() stage: string;

  @ViewChild(EnglishProficiencyDetailComponent) englishProficiencyDetail: EnglishProficiencyDetailComponent;

  applicant$: ApplicantService['applicant'];
  strings = strings.components.tasks.englishProficiency;
  log: Logger;

  constructor(applicantService: ApplicantService, loggingService: LoggingService) {
    super();
    this.applicant$ = applicantService.applicant;
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity() {
    this.englishProficiencyDetail.updateFormValidity();
  }

  public async update() {
    const { englishQualification } = await firstValueFrom(this.applicant$);

    if (!englishQualification.verified) {
      this.englishProficiencyDetail.update().subscribe({
        next: (applicant) => {
          this.log.info('updated the applicant successfully', applicant);
          this.next.emit();
        },
        error: (error) => {
          this.errors.emit(error);
          this.log.error('error updating the applicant');
        },
      });
    } else {
      this.next.emit();
    }
  }
}
