import { camelizeKeys } from '@shared/helpers/serialization';
import { Task } from '@shared/models/task';

export class Stage {
  public title: string;
  public code: string;
  public percentComplete: number;
  public path: string;
  public description: string;
  public tasks: Task[];
  public submitted: boolean;
  public requiresExplicitSubmit?: boolean;

  constructor(creationObject: any) {
    Object.assign(this, creationObject);
  }

  get firstIncompleteTask(): Task | null {
    return this.tasks.find((t) => t.percentComplete !== 100) || null;
  }

  get isStageComplete(): boolean {
    return this.percentComplete === 100;
  }

  getTask(taskCode: string): Task {
    return this.tasks.find((t) => t.code === taskCode);
  }

  static deserialize(payload: any, processPath: string): Stage {
    if (payload === null) {
      return null;
    }

    const stage: any = camelizeKeys(payload);
    stage.path = `${processPath}/${stage.code}`;
    stage.tasks = payload.tasks.map((task) => {
      return Task.deserialize(task, stage.path);
    });

    return new Stage(stage);
  }

  /**
   * returns true if the task exists on the stage and it is the last task in the stage
   *
   * @param taskCode string a valid task code for this stage
   */
  public isLastTask(taskCode: string): boolean {
    const taskIndex = this.tasks.map((t) => t.code).indexOf(taskCode);
    return taskIndex === this.tasks.length - 1;
  }
}
