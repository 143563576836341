import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { combineLatest, distinctUntilChanged, filter, firstValueFrom, map, of, ReplaySubject, switchMap } from 'rxjs';

import { UserActivityStrategyBase } from './user-activity-strategy-base.service';
import { UserActivity } from './user-activity.service';
import { Logger, LoggingService } from '../logging/logging.service';
import { UserService } from '../user/user.service';

const COLLECTION_PATH = '/userActivity';

@Injectable()
export class FirebaseUserActivityStrategyService extends UserActivityStrategyBase {
  private log: Logger;
  private documentPath = new ReplaySubject<string>(1);
  private document = new ReplaySubject<AngularFirestoreDocument<UserActivity>>(1);

  constructor(
    logger: LoggingService,
    userService: UserService,
    private db: AngularFirestore,
  ) {
    super(userService);

    this.log = logger.createLogger(this);
    this.log.info('Initializing FirebaseUserActivityStrategyService');

    this.generateDocumentPaths();
    this.getDocumentForCurrentUser();
    this.getActivityForCurrentUser();
  }

  private generateDocumentPaths() {
    this.userIdentifier.subscribe((identifier) =>
      this.documentPath.next(identifier ? `${COLLECTION_PATH}/${identifier}` : null),
    );
  }

  private getDocumentForCurrentUser() {
    this.documentPath
      .pipe(
        distinctUntilChanged(),
        map((documentPath) => {
          return documentPath ? this.db.doc<UserActivity>(documentPath) : null;
        }),
      )
      .subscribe((document) => {
        this.document.next(document);
        if (!document) {
          this.userActivity.next(null);
        }
      });
  }

  private getActivityForCurrentUser() {
    this.document
      .pipe(
        filter((document) => !!document),
        switchMap((document) => combineLatest([of(document), document.valueChanges()])),
        map(([document, activity]) => {
          const defaults = { taskSubmissions: [], viewedDocuments: [] };
          const withDefaults = { ...defaults, ...activity };
          if (!activity) {
            document.set(withDefaults);
          } else {
            return withDefaults;
          }
        }),
        filter((activity) => !!activity),
      )
      .subscribe({
        next: (activity) => this.userActivity.next(activity),
      });
  }

  protected async store(data: UserActivity): Promise<void> {
    const document = await firstValueFrom(this.document);
    await document.set(data);
  }
}
