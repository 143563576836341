<div class="date-selector" [class.hasValue]="hasValue" [class.has-icon]="!noIcon" [class.no-icon]="noIcon">
  <div *ngIf="!isDisabled" class="labels clearfix">
    <label class="day">Day</label>
    <label class="month">Month</label>
    <label class="year">Year</label>
  </div>
  <div *ngIf="isDisabled" class="labels clearfix">
    <label>Date</label>
  </div>
  <div *ngIf="!isDisabled" class="select-content" [formGroup]="dateForm">
    <select class="day" formControlName="dayControl" [attr.data-cy]="testSelectorId + '-day'">
      <option></option>
      <option *ngFor="let day of dayOptions" [value]="day">
        {{ day }}
      </option>
    </select>
    <div class="select-divider"></div>
    <select class="month" formControlName="monthControl" [attr.data-cy]="testSelectorId + '-month'">
      <option></option>
      <option *ngFor="let month of monthOptions" [value]="month.value">
        {{ month.text }}
      </option>
    </select>
    <div class="select-divider"></div>
    <select class="year" formControlName="yearControl" [attr.data-cy]="testSelectorId + '-year'">
      <option></option>
      <option *ngFor="let year of yearOptions" [value]="year">
        {{ year }}
      </option>
    </select>
    <uc-icon
      *ngIf="!noIcon"
      class="validator valid-icon"
      iconTitle="Valid"
      iconType="tick"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
    <uc-icon
      *ngIf="!noIcon"
      class="validator invalid-icon"
      iconTitle="Invalid"
      iconType="close"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"></uc-icon>
  </div>
  <uc-disabled-atom-state
    *ngIf="isDisabled"
    [value]="stringValue"
    [enableModal]="showDisabledExplanation"></uc-disabled-atom-state>
</div>
