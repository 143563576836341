import { Component, Input, OnChanges } from '@angular/core';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { AdminViewUser } from '@shared/models/admin-view-user';

@Component({
  selector: 'uc-account-details-card',
  templateUrl: './account-details-card.component.html',
  styleUrls: ['./account-details-card.component.scss'],
})
export class AccountDetailsCardComponent implements OnChanges {
  @Input() user: AdminViewUser;
  strings = strings.components.molecules.accountDetails;
  userCode: string;

  ngOnChanges(): void {
    const studyEmail: string = (get(this.user, 'applicant.studyContactDetail.emailAddress') as string) || '';
    if (studyEmail.match('uclive')) {
      this.userCode = studyEmail.split('@')[0];
    }
  }
}
