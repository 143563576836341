<uc-responsive-grid [breakpoints]="breakpoints">
  <mat-grid-list cols="4" rowHeight="260px">
    <mat-grid-tile colspan="2" rowspan="2">
      <!-- Extract into a component -->
      <div>
        <h2 style="font-weight: 700">Welcome back {{ currentUser?.displayName }}</h2>
        <span style="color: grey">Pick up where you left off</span>

        <div>
          <p class="course-list-title">My Courses</p>
          <div class="course-list-container">
            <div class="course-list-item">
              <img
                src="https://writersplot.org.nz/wp-content/uploads/sites/3/2022/03/expert-tips-for-writing-job-posts.jpg"
                alt="" />
              <div class="course-description">Shifting Business to Net Zero</div>
              <div class="course-progress">90%</div>
              <uc-button testSelector="resume-lesson" class="secondary">Continue</uc-button>
            </div>
            <div class="course-list-item">
              <img
                src="https://blog.kotobee.com/wp-content/uploads/2019/08/How-writing-every-day-can-make-you-a-better-writer-blog.jpg"
                alt="" />
              <div class="course-description">Introduce to academic writing</div>
              <div class="course-progress">0%</div>
              <uc-button testSelector="resume-lesson" class="secondary">Start</uc-button>
            </div>
          </div>
        </div>
        <div class="my-applications">
          <p class="course-list-title">My Applications</p>
          <div class="course-list-container">
            <div class="course-list-item">
              <img
                src="https://blog.kotobee.com/wp-content/uploads/2019/08/How-writing-every-day-can-make-you-a-better-writer-blog.jpg"
                alt="" />
              <div class="course-description">Introduce to academic writing</div>
              <div class="course-progress course-status">Draft</div>
              <uc-button testSelector="resume-lesson" class="secondary">Manage</uc-button>
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{ strings.enrolmentInfoLink }}">
        <uc-static-link-dashboard-tile icon="cap" header="Learning Achievements"></uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{ strings.codeOfConductLink }}">
        <uc-static-link-dashboard-tile icon="download" header="Resources"></uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{ strings.healthCentreLink }}">
        <uc-static-link-dashboard-tile icon="health" header="Support"></uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="1">
      <uc-tile tileLink="{{ externalUrls.myUCUrl }}">
        <uc-static-link-dashboard-tile icon="link-alt" header="My UC"></uc-static-link-dashboard-tile>
      </uc-tile>
    </mat-grid-tile>
  </mat-grid-list>
</uc-responsive-grid>
