<uc-form-template>
  <uc-form-header [title]="task.title">
    <uc-task-hints [task]="task"></uc-task-hints>
    <uc-message-banner *ngIf="!loading" type="info">
      <span [innerHTML]="messageBanner"></span>
    </uc-message-banner>
    <span *ngIf="changesPresent && !loading" class="coe-info" [innerHTML]="strings.checklist"></span>
  </uc-form-header>

  <uc-spinner *ngIf="loading" size="40" [useStaticPositioning]="true"></uc-spinner>

  <uc-section *ngIf="!loading">
    <uc-section-header [title]="strings.sectionHeader"></uc-section-header>
    <uc-subsection class="coe-diff-subsection">
      <uc-coe-diff *ngIf="changesPresent" [enrolmentChange]="fullEnrolmentChange"></uc-coe-diff>
      <div class="coe-no-diff-info" *ngIf="!changesPresent">
        <p>{{ strings.noChanges }}</p>
      </div>
    </uc-subsection>
  </uc-section>

  <uc-section class="align-award-section" [formGroup]="formGroup">
    <uc-yes-no
      [required]="true"
      [task]="task"
      path="/change_of_enrolments/will_complete_award"
      [questionString]="qualStrings.willCompleteAward"
      formControlName="willCompleteAward"
      testSelector="will-complete"></uc-yes-no>
  </uc-section>
</uc-form-template>

<uc-confirmation-modal
  [title]="strings.coeErrorModal.header"
  [message]="strings.coeErrorModal.body"
  [acceptText]="strings.coeErrorModal.ok"
  (accepted)="goToManageMyStudy()"></uc-confirmation-modal>
