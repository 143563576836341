import { Component, Input } from '@angular/core';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-ucsw-handoff',
  templateUrl: './ucsw-handoff.component.html',
  styleUrls: ['./ucsw-handoff.component.scss'],
})
export class UcswHandoffComponent extends AbstractBaseTask {
  @Input() task: Task;
  @Input() processFilter: string;
  @Input() applicationYear: string;
  isLoading = false;
  strings = strings.components.tasks.ucswHandoff;

  constructor() {
    super();
  }

  public updateFormValidity(_err: UCError) {}
}
