<div class="application-summary-row" (click)="goToManageMyStudy()">
  <div class="application-summary-row-left">
    <span class="application-year">{{ year }} {{ baseStrings.application }}</span>
    <div class="status-pill-wrapper">
      <uc-status-pill
        [summaryState]="state"
        [academicYear]="year"
        [showInfoModal]="false"
        [isImpersonated]="isImpersonated"></uc-status-pill>
      <uc-status-pill
        *ngIf="courseState"
        [summaryState]="courseState"
        [academicYear]="year"
        [showInfoModal]="false"
        [isImpersonated]="isImpersonated"
        [courseStatus]="true"></uc-status-pill>
    </div>
  </div>
  <div class="section section-primary-action">
    <uc-icon iconType="chevron-right" iconHeight="24" iconWidth="24" color="$colorMineShaft"></uc-icon>
  </div>
</div>
