<ngx-datatable
  [rows]="courses"
  [columnMode]="'flex'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="getRowHeight"
  [sorts]="defaultSort">
  <ngx-datatable-column
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="true"
    [resizeable]="true"
    [width]="30"></ngx-datatable-column>
  <ngx-datatable-column name="{{ customStrings.columns.courseCode }}" prop="courseOccurrenceCode" [flexGrow]="2">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a href="{{ row.href }}" target="_blank">{{ row.courseOccurrenceCode }}</a>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ customStrings.columns.courseTitle }}"
    prop="courseTitle"
    [flexGrow]="6"></ngx-datatable-column>
  <ngx-datatable-column name="{{ customStrings.columns.location }}" prop="site" [flexGrow]="2"></ngx-datatable-column>
  <ngx-datatable-column name="{{ customStrings.columns.level }}" prop="level" [flexGrow]="2">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <uc-course-level-pill [course]="row"></uc-course-level-pill>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ customStrings.columns.points }}"
    prop="coursePoints"
    [flexGrow]="2"></ngx-datatable-column>
  <ngx-datatable-column name="" prop="" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <uc-add-course
        (addCourse)="addACourse(row)"
        [courseCode]="row.courseOccurrenceCode"
        [selectedCourses]="selectedCourses"
        [cancelSpinner]="cancelSpinner"></uc-add-course>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
