import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import strings from '@constants/strings.constants';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() createAccount: string;
  @Input() required = false;
  @Input() testSelector = '';

  shortPassword = false;
  showPassword: boolean;
  inputControl: UntypedFormControl;
  shadowPassword = '';
  templateStrings = strings.labels.user;
  errorStrings = strings.errors;

  log: Logger;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-password`;
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    this.inputControl = this.form.get(this.controlName) as UntypedFormControl;
    this.inputControl.valueChanges.subscribe((value) => {
      this.shadowPassword = value;
    });

    const passwordControl = this.inputControl;
    passwordControl.statusChanges.subscribe((_status) => {
      const { errors } = passwordControl;
      this.shortPassword = !!(errors && errors.minlength);
    });
  }

  onShadowChange(e) {
    this.inputControl.setValue(e.target.value);
  }
}
