<uc-base-modal
  [closable]="true"
  [dismissable]="false"
  [loading]="loading"
  [useUnsetPosition]="useUnsetPosition"
>
  <span
    title
    *ngIf="title"
    [innerHTML]="title"
  ></span>

  <p
    *ngIf="!useEmbeddedChildren"
    body
    [innerHTML]="message"
  ></p>
  <ng-container
    *ngIf="useEmbeddedChildren"
    body
  >
    <ng-content></ng-content>
  </ng-container>

  <button
    footer
    [class]="acceptType"
    [innerHTML]="acceptText"
    (click)="accept()"
  ></button>
  <button
    footer
    *ngIf="declineText"
    [class]="declineType"
    [innerHTML]="declineText"
    (click)="decline()"
  ></button>
</uc-base-modal>
