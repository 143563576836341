import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { internalUrls } from '@shared/constants/internalUrls';

@Injectable()
export class OnlineApplicantModuleAccessGuard {
  constructor(private router: Router) {}

  // eslint-disable-next-line complexity
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Routes with this guard on must have a lockedRouteRegexs array which is an array of regexs
    let routes = next.data.lockedRoutes;
    routes =
      routes &&
      routes.filter((element) => {
        return state.url.match(new RegExp(`/${element}/?$`));
      });

    if (routes.length) {
      this.router.navigate(internalUrls.dashboard, { queryParams: next.queryParams });
    }

    return routes ? !routes.length : true;
  }
}
