<uc-form-template [formGroup]="onlineReviewMicroCredentialDetailsPage" class="uconline-profile">
  <uc-form-header title="Your details">
    <uc-task-hints [task]="task" *ngIf="!isDisabled"></uc-task-hints>
  </uc-form-header>

  <button [attr.data-cy]="'edit-your-profile'" *ngIf="isDisabled" class="edit-profile-link" (click)="editProfile()">
    {{ editProfileString }}
  </button>

  <div class="profile-review-section" *ngIf="isDisabled">
    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="First name">
          <uc-online-disabled-atom-state [value]="firstName"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Middle name">
          <uc-online-disabled-atom-state [value]="middleName"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Last name">
          <uc-online-disabled-atom-state [value]="surname"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Preferred name">
          <uc-online-disabled-atom-state [value]="preferredName"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Date of birth">
          <uc-online-disabled-atom-state [value]="dateOfBirth"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Gender">
          <uc-online-disabled-atom-state [value]="gender"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Email">
          <uc-online-disabled-atom-state [value]="email"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Mobile">
          <uc-online-disabled-atom-state [value]="mobileNum"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Preferred method of contact">
          <uc-online-disabled-atom-state [value]="preferredContactMethod"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Do you have an impairment, disability or long term medical condition(s)?">
          <uc-online-disabled-atom-state [value]="declarationDisability"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Ethnicity">
          <uc-online-disabled-atom-state [value]="ethnicity"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Iwi">
          <uc-online-disabled-atom-state [value]="iwi"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>

    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Country of citizenship">
          <uc-online-disabled-atom-state [value]="countryOfCitizenship"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Citizenship or residency category">
          <uc-online-disabled-atom-state [value]="citizenshipCategory"></uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>
    <div class="profile-form-row">
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Permanent address">
          <uc-online-disabled-atom-state>
            <div *ngIf="!!permanentAddressLine1">
              {{ permanentAddressLine1 }}
              <br />
              {{ permanentAddressLine2 || '-' }}
              <br />
              {{ permanentAddressCityTownPostcode || '-' }}
              <br />
              {{ permanentAddressRegion || '-' }}
              <br />
              {{ permanentAddressCountry || '-' }}
              <br />
            </div>
            <div *ngIf="!permanentAddressLine1">-</div>
          </uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
      <div class="profile-form-row-item">
        <uc-form-field-label labelText="Study address">
          <uc-online-disabled-atom-state>
            <div *ngIf="!!studyAddressLine1">
              {{ studyAddressLine1 }}
              <br />
              {{ studyAddressLine2 || '-' }}
              <br />
              {{ studyAddressCityTownPostcode || '-' }}
              <br />
              {{ studyAddressRegion || '-' }}
              <br />
              {{ studyAddressCountry || '-' }}
              <br />
            </div>
            <div *ngIf="!studyAddressLine1">-</div>
          </uc-online-disabled-atom-state>
        </uc-form-field-label>
      </div>
    </div>
  </div>

  <uc-section *ngIf="!isDisabled">
    <uc-section-header [title]="strings.identificationDetailsSection.header">
      <p>{{ strings.identificationDetailsSection.description[0] }}</p>
      <p>{{ strings.identificationDetailsSection.description[1] }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/birth_date"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.identificationDetailsSection.birthDateQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-date-selector
          [baseYear]="dateOfBirthYear"
          [range]="85"
          formControlName="birthDate"
          required="true"
          testSelector="dob"></uc-date-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-task-hint class="sub-section" [task]="task" path="/applicant/legal_name"></uc-task-hint>

    <uc-legal-name-declaration
      [task]="task"
      [applicationYear]="applicationYear"
      (nameAdd)="this.otherNames.push()"
      (nameRemove)="this.otherNames.removeAt($event)"
      [otherNamesLength]="otherNamesLength"
      [namesModel]="onlineReviewMicroCredentialDetailsPage.get('names')"></uc-legal-name-declaration>

    <uc-subsection>
      <uc-gender-selector
        [task]="task"
        [genderForm]="onlineReviewMicroCredentialDetailsPage.get('genderGroup')"></uc-gender-selector>
    </uc-subsection>
  </uc-section>

  <uc-section [formGroup]="onlineReviewMicroCredentialDetailsPage.get('citizenship')" *ngIf="!isDisabled">
    <uc-section-header [title]="strings.citizenshipSection.header"></uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/demographic/citizenship"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.citizenshipSection.categoryQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-reference-data-selector
          type="citizenship_category"
          formControlName="citizenCategory"
          required="true"></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection
      *ngIf="!isNzOrAus(this.onlineReviewMicroCredentialDetailsPage.get('citizenship.citizenCategory').value)">
      <uc-form-input>
        <uc-task-hint [task]="task" path="/applicant/demographic/passport_country"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.citizenshipSection.countryQuestion }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-reference-data-selector
          type="country"
          [filter]="['NZL', 'AUS']"
          formControlName="citizenCountry"
          required="true"></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="!isDisabled">
    <uc-section-header [title]="strings.identificationDetailsSection.labels.ethnicity"></uc-section-header>

    <uc-ethnicity-iwi-selectors [task]="task" [demographicElements]="demographicElements"></uc-ethnicity-iwi-selectors>
  </uc-section>

  <uc-section [formGroup]="onlineReviewMicroCredentialDetailsPage.get('contactDetails')" *ngIf="!isDisabled">
    <uc-section-header [title]="strings.contactDetailsSection.header">
      <p>{{ strings.contactDetailsSection.description }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-form-input>
        <uc-subsection-question>{{ strings.contactDetailsSection.emailSubsectionQuestion }}</uc-subsection-question>
        <uc-task-hint [task]="task" path="/applicant/contact_detail/email_address"></uc-task-hint>
        <uc-string-input
          testSelector="email"
          class="input-medium"
          [label]="strings.contactDetailsSection.emailLabel"
          formControlName="email"
          required="true"></uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-form-input>
        <uc-task-hint [task]="task" path="/applicant/contact_detail/contact_number"></uc-task-hint>
        <uc-subsection-question>{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
        <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          [required]="true"
          formControlName="mobileNum"
          [labelText]="phoneStrings.mobileLabel"
          path="/applicant/contact_detail/mobile_number"
          [task]="task"
          testSelector="contact-mobile"></uc-phone-selector>
      </uc-form-input>
    </uc-subsection>
    <uc-section-header [title]="strings.contactDetailsSection.preferredContactMethodLabel">
      <uc-form-input>
        <uc-radio-input-group
          groupName="preferred-contact-method"
          class="preferredContactMethod"
          formControlName="preferredContactMethod"
          ngDefaultControl
          [options]="preferredContactMethodOptions"
          [displayHorizontally]="true"
          label=""></uc-radio-input-group>
      </uc-form-input>
    </uc-section-header>
  </uc-section>

  <uc-section [formGroup]="onlineReviewMicroCredentialDetailsPage.get('contactDetails')" *ngIf="!isDisabled">
    <uc-section-header [title]="permanentAddressStrings.header"></uc-section-header>

    <uc-subsection>
      <uc-subsection-question>
        {{ strings.contactDetailsSection.addressQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.contactDetailsSection.addressPredictionHelp }}
      </uc-subsection-minor-text>
      <uc-form-input>
        <uc-address-selector testSelector="contact" [task]="task" formControlName="address"></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="!isDisabled">
    <uc-section-header [title]="learningNeedsStrings.header"></uc-section-header>
    <uc-fitness-to-teach
      [task]="task"
      [fitnessToTeachForm]="onlineReviewMicroCredentialDetailsPage.get('fitnessToTeachGroup')"
      [disabilityDetailRequired]="true"></uc-fitness-to-teach>
  </uc-section>

  <uc-section *ngIf="!isDisabled">
    <uc-section-header [title]="termsAndConditionsStrings.header"></uc-section-header>
    <uc-online-terms-and-conditions
      [task]="task"
      [termsAndConditionsForm]="onlineReviewMicroCredentialDetailsPage.get('termsAndConditionsGroup')"
      [showOptionalItem]="false"></uc-online-terms-and-conditions>
  </uc-section>
</uc-form-template>
