<div class="page__content" [formGroup]="currentQualForm">
  <uc-qualification-selector
    *ngIf="showQualificationSelector"
    [qualificationOptions]="qualificationOptions"
    [selectedQualification]="currentQualForm.get('code').value"
    (changedQualification)="qualificationUpdated($event)"
    (qualForm)="qualSelectorFormEmitter($event)"></uc-qualification-selector>
  <div *ngIf="!showQualificationSelector">
    <h3 class="subject-options-header" *ngIf="!hideTitle">
      {{ qualificationEnrolment.qualificationAnswer.title }} ({{ qualificationEnrolment.qualificationAnswer.code }})
    </h3>
  </div>
  <div *ngIf="currentQualOption?.subjectOptions">
    <uc-message-banner type="info" *ngIf="currentQualOption?.guidance">
      <p [innerHTML]="currentQualOption?.guidance"></p>
    </uc-message-banner>

    <ng-container *ngFor="let option of currentQualOption.subjectOptions">
      <div [formGroup]="currentQualForm">
        <uc-subject-option-question
          class="primaryOptions"
          [controls]="getControls(option.level)"
          [formGroup]="currentQualForm"
          [label]="option?.label"
          [subjectQuestions]="option.optionLists"></uc-subject-option-question>
      </div>
    </ng-container>
  </div>
</div>
