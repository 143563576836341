import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { IRefdataOptions } from '@shared/models/reference-data';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-multi-options-selector',
  templateUrl: './multi-options-selector.component.html',
  styleUrls: ['./multi-options-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiOptionsSelectorComponent),
      multi: true,
    },
  ],
})
export class MultiOptionsSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() multiOptionsDisplayLabel?: string;
  @Input() multiOptionsList$: Observable<IRefdataOptions[]>;
  @Input() testSelector = '';

  multiOptionsSelectorControl = new UntypedFormControl('');
  log: Logger;

  private propagateChange: (_: string) => void;
  private propagateTouch: (_: boolean) => void;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    if (!this.multiOptionsList$) {
      this.multiOptionsList$ = of([
        {
          labelText: 'Yes',
          value: 'Y',
        },
        {
          labelText: 'No',
          value: 'N',
        },
      ]);
    }

    this.multiOptionsSelectorControl.valueChanges.subscribe((value) => {
      this.propagateChange?.(value);
    });
  }

  writeValue(value: string): void {
    this.multiOptionsSelectorControl.setValue(value);
  }

  registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }
}
