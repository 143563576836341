import { Injectable } from '@angular/core';
import { Observable, Subject, combineLatest, of, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { APPLICATION_STATE, COE_STATES } from '@shared/constants/states.constants';
import {
  EnrolmentSummary,
  AcademicYearEnrolmentSummary,
  ArchivedEnrolmentSummary,
} from '@shared/models/enrolment-summary';
import { UCError } from '@shared/models/errors';
import { DataService } from '@shared/services/data-service';
import { mockData as mockEnrolmentSummaryResponse } from '@shared/services/enrolment-summary/enrolment-summary.data.mock';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Injectable()
export class EnrolmentSummaryService {
  private serviceUrl = `${environment.apiRoot}/enrolment-summary/`;
  private error$ = new Subject<UCError>();
  public summary$ = new BehaviorSubject<EnrolmentSummary>(null);
  public academicYearSummary$ = new BehaviorSubject<AcademicYearEnrolmentSummary[]>(null);
  private log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get enrolmentSummary(): Observable<EnrolmentSummary> {
    return this.summary$.asObservable();
  }

  get academicYearSummary(): Observable<AcademicYearEnrolmentSummary[]> {
    return this.academicYearSummary$.asObservable();
  }

  get embargoedyears(): Observable<string[]> {
    return this.summary$.asObservable().pipe(
      map(
        (summary) =>
          summary &&
          summary.academicYears.filter(
            (academicyear) =>
              academicyear.state === APPLICATION_STATE.NCEA_EMBARGO || academicyear.state === COE_STATES.NCEA_EMBARGO,
          ),
      ),
      map((embargoedYears) => embargoedYears && embargoedYears.map((el) => el.academicYear)),
    );
  }

  private sortByYear(arr: AcademicYearEnrolmentSummary[]) {
    return arr.sort((a, b) => parseInt(a.academicYear, 10) - parseInt(b.academicYear, 10));
  }

  getSummary(): Observable<EnrolmentSummary> {
    const url = this.serviceUrl;
    return this.dataService
      .fetch(url, {
        error$: this.error$,
        deserialize: EnrolmentSummary.deserialize,
      })
      .pipe(
        tap((summary) => {
          summary.academicYears = this.updateArchivedStatus(summary.academicYears, summary.archivedEnrolments);
          summary.academicYears = this.sortByYear(summary.academicYears);
          summary.archivedEnrolments = this.sortByYear(summary.archivedEnrolments);

          this.academicYearSummary$.next(summary.academicYears);
          this.summary$.next(summary);
        }),
      );
  }

  getSummaryByYear(year: string): Observable<AcademicYearEnrolmentSummary> {
    const url = `${this.serviceUrl}${year}`;
    return this.dataService.fetch(url, {
      error$: this.error$,
      deserialize: AcademicYearEnrolmentSummary.deserialize,
    });
  }

  updateSummaryYear(year: string) {
    combineLatest([this.getSummaryByYear(year), this.enrolmentSummary]).subscribe(
      ([newYearSummary, enrolmentSummary]) => {
        const summary = enrolmentSummary;
        newYearSummary.archived = !!summary.archivedEnrolments.find(
          (ae) => ae.academicYear === newYearSummary.academicYear,
        );
        const newYearInCurrentSummary = summary.academicYears.find(
          (el) => el.academicYear === newYearSummary.academicYear,
        );

        if (newYearInCurrentSummary) {
          summary.academicYears = summary.academicYears.filter((el) => el.academicYear !== newYearSummary.academicYear);
        }

        summary.academicYears.push(newYearSummary);
        summary.academicYears = this.sortByYear(summary.academicYears);
        this.summary$.next(summary);
      },
    );
  }

  updateArchivedStatus(
    academicYearSummary: AcademicYearEnrolmentSummary[],
    archivedEnrolments: ArchivedEnrolmentSummary[],
  ) {
    return academicYearSummary.map((as) => {
      as.archived = !!archivedEnrolments.find((ae) => ae.academicYear === as.academicYear);
      return as;
    });
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export class MockEnrolmentSummaryService {
  public error$ = new Subject<UCError>();
  public summary$ = new BehaviorSubject<EnrolmentSummary>(null);
  public mockEnrolmentSummaryData: EnrolmentSummary;
  public academicYearSummary$ = new BehaviorSubject<AcademicYearEnrolmentSummary[]>(null);

  constructor() {
    this.mockEnrolmentSummaryData = EnrolmentSummary.deserialize(mockEnrolmentSummaryResponse());
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get enrolmentSummary(): Observable<EnrolmentSummary> {
    return this.summary$.asObservable();
  }

  get academicYearSummary(): Observable<AcademicYearEnrolmentSummary[]> {
    return this.academicYearSummary$.asObservable();
  }

  get embargoedyears(): Observable<string[]> {
    return this.summary$.asObservable().pipe(
      map((summary) => summary && summary.academicYears.filter((ay) => ay.state === APPLICATION_STATE.NCEA_EMBARGO)),
      map((embargoedYears) => embargoedYears && embargoedYears.map((el) => el.academicYear)),
    );
  }

  private sortByYear(arr: AcademicYearEnrolmentSummary[]) {
    return arr.sort((a, b) => parseInt(a.academicYear, 10) - parseInt(b.academicYear, 10));
  }

  getSummary() {
    const { academicYears, archivedEnrolments } = this.mockEnrolmentSummaryData;
    this.mockEnrolmentSummaryData.academicYears = this.updateArchivedStatus(academicYears, archivedEnrolments);
    this.summary$.next(this.mockEnrolmentSummaryData);
    this.academicYearSummary$.next(this.mockEnrolmentSummaryData.academicYears);
    return of(this.mockEnrolmentSummaryData);
  }

  getSummaryByYear(year: string) {
    const firstMockYearSummary = this.mockEnrolmentSummaryData.academicYears[0];
    return of(firstMockYearSummary);
  }

  updateSummaryYear(year: string) {
    this.getSummaryByYear(year).subscribe((newYearSummary) => {
      const updatedMock = new EnrolmentSummary(this.mockEnrolmentSummaryData);
      updatedMock.academicYears = updatedMock.academicYears.filter(
        (el) => el.academicYear !== newYearSummary.academicYear,
      );
      updatedMock.academicYears.push(newYearSummary);

      updatedMock.academicYears = this.sortByYear(updatedMock.academicYears);
      this.summary$.next(updatedMock);
    });
  }

  updateArchivedStatus(
    academicYearSummary: AcademicYearEnrolmentSummary[],
    archivedEnrolments: ArchivedEnrolmentSummary[],
  ) {
    return academicYearSummary.map((as) => {
      const isArchived = !!archivedEnrolments.find((ae) => ae.academicYear === as.academicYear);
      as.archived = isArchived;
      return as;
    });
  }
}

export const enrolmentSummaryServiceFactory = (
  dataService,
  loggingService,
): EnrolmentSummaryService | MockEnrolmentSummaryService => {
  if (environment.useFakeBackend.enrolmentSummary) {
    return new MockEnrolmentSummaryService();
  } else {
    return new EnrolmentSummaryService(dataService, loggingService);
  }
};

export const enrolmentSummaryServiceProvider = {
  provide: EnrolmentSummaryService,
  useFactory: enrolmentSummaryServiceFactory,
  deps: [DataService, LoggingService],
};
