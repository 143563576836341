import { camelizeKeys } from '@shared/helpers/serialization';

export class SanctionType {
  code: string;
  description: string;
  mandatoryActions: {
    code: string;
    description: string;
    validFrom: string;
    validTo: string;
  }[];
  displayRemovedOnTranscript: boolean;
  validFrom: string;
  validTo: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Sanction[] {
    if (payload === null) {
      return null;
    }

    const localPayload = payload.sanction_types || payload;

    return localPayload.map((sanction) => {
      const localSanction = camelizeKeys(sanction);
      localSanction.mandatoryActions = localSanction.mandatoryActions.map((action) => camelizeKeys(action));

      return new SanctionType(localSanction);
    });
  }
}

export class Sanction {
  internalReference: string;
  externalReference: string;
  type: string;
  state: {
    code: string;
  };
  comments: string;
  validFrom: string;
  validTo: string;
  actions: [
    {
      code: string;
      description: string;
      validFrom: string;
      validTo: string;
    },
  ];
  restrictions: [
    {
      type: 'points' | 'qualification';
      points: string;
      qualification: string;
      code: string;
    },
  ];
  actionString?: string;
  restrictionString?: string;
  stateString?: string;
  validToString?: string;
  validFromString?: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Sanction[] {
    if (payload === null) {
      return null;
    }

    const localPayload = payload.sanctions || payload.sanction || payload;

    return localPayload.map((sanction) => {
      const localSanction = camelizeKeys(sanction);
      localSanction.restrictions = localSanction.restrictions.map((restriction) => camelizeKeys(restriction));

      return new Sanction(localSanction);
    });
  }
}
