<uc-form-template>
  <uc-form-header [title]="task.title">
    <uc-change-qualification
      *ngIf="!isMicroCredentialProcess"
      [applicationYear]="applicationYear"
      coursesClosed="true"></uc-change-qualification>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.sectionHeader }}"></uc-section-header>
    <uc-subsection>
      <span *ngIf="!isInternational" innerHTML="{{ strings.content(previousYear) }}"></span>
      <span *ngIf="isInternational" innerHTML="{{ strings.internationalContent(applicationYear) }}"></span>
    </uc-subsection>
  </uc-section>
</uc-form-template>
