<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-course-planner-header
  [qualification]="qualification"
  [otherQualCode]="otherQual?.code"
  [otherQualUrl]="otherQualUrl"
  [enrolledQualifications]="enrolledQualifications"
  [processName]="processName"
  [routeParams]="routeParams"
  (resetSearchResults)="resetResults()"></uc-course-planner-header>

<div id="main-content" class="content__container" tabindex="-1">
  <div class="page__container">
    <div class="page__content">
      <uc-course-search
        [options]="contentSearchOptions"
        [defaultCurriculumGroup]="defaultCurriculumGroup"
        [defaultSubjectGroup]="defaultSubjectGroup"
        [showBlips]="showBlips"
        [year]="routeParams.applicationYear"
        (searchResultCount)="updateSearchResultCount($event)"
        (resetSearchResults)="resetResults()"
        (courseGroupGuidanceText)="courseGroupGuidanceText = $event"></uc-course-search>

      <div class="search-filters__wrapper" [class.hide]="searchResultCount === null">
        <div class="search-count" *ngIf="searchResultCount !== null">
          {{ strings.searchResultCount(searchResultCount) }}
        </div>
        <button [attr.data-cy]="'search-filters'" class="search-filters__toggle" (click)="toggleFilters()">
          {{ filterText }}
        </button>
        <div class="search-filters__wrapper--right">
          <!-- make wrapper float right, add media queries, check mobile -->
          <uc-search-filters
            class="search-filters"
            [filterOrder]="filterOrder"
            [class.show-filters]="showFilters"></uc-search-filters>
          <uc-display-toggle
            class="search-filters__display-toggle"
            [displayType]="courseService.searchDisplayType$"></uc-display-toggle>
        </div>
      </div>

      <uc-message-banner *ngIf="qualification?.courseSelectionGuidanceText || courseGroupGuidanceText" type="info">
        <p
          *ngIf="qualification?.courseSelectionGuidanceText"
          [innerHTML]="qualification.courseSelectionGuidanceText"></p>
        <p *ngIf="courseGroupGuidanceText" [innerHTML]="courseGroupGuidanceText"></p>
      </uc-message-banner>

      <div class="error-guidance" *ngIf="noCoursesFound">
        <uc-icon
          class="error-guidance__icon"
          iconType="no-results"
          iconWidth="85"
          iconHeight="85"
          iconFill="$colorFireEngineRed"></uc-icon>
        <div class="error-guidance__content">
          <h2 class="error-guidance__heading">{{ strings.noCoursesFound }}</h2>
          <div class="error-guidance__body-text" [innerHTML]="strings.noCoursesFoundPara"></div>
        </div>
      </div>

      <div class="error-guidance" *ngIf="tooManyResults">
        <uc-icon
          class="error-guidance__icon"
          iconType="no-results"
          iconWidth="85"
          iconHeight="85"
          iconFill="$colorFireEngineRed"></uc-icon>
        <div class="error-guidance__content">
          <h2 class="error-guidance__heading">{{ strings.tooManyResults }}</h2>
          <div class="error-guidance__body-text" [innerHTML]="strings.tooManyResultsPara"></div>
        </div>
      </div>

      <div *ngIf="showGuidance && !noCoursesFound && !tooManyResults" class="guidance__wrapper">
        <div class="guidance__help-text">
          <h4 class="guidance__help-header">{{ strings.helpText.needHelp }}</h4>
          <span [innerHTML]="strings.helpText.helpHeader"></span>
          <ul class="red-list" [innerHTML]="strings.helpText.helpInfo"></ul>
          <span [innerHTML]="strings.helpText.contactAssistance"></span>
        </div>
      </div>

      <div *ngIf="courses && courses.length && !noCoursesFound" class="search__results">
        <div class="bucket__cards" *ngIf="(searchDisplayType | async) === displayType.GRID || isMobile">
          <uc-course-card
            *ngFor="let course of courses"
            [course]="course"
            (courseAdd)="addCourse($event)"
            (removeCourse)="removeCourse($event)"
            [selectedCourses]="selectedCourses"
            [errorTriggered]="errorTriggered"></uc-course-card>
        </div>

        <div *ngIf="(searchDisplayType | async) === displayType.TABLE && !isMobile">
          <uc-course-search-table-view
            [courses]="courses"
            (courseAdd)="addCourse($event)"
            (removeCourse)="removeCourse($event)"
            [selectedCourses]="selectedCourses"
            [errorTriggered]="errorTriggered"></uc-course-search-table-view>
        </div>
      </div>
    </div>
  </div>

  <uc-course-planner-review-panel
    [routeParams]="routeParams"
    [otherQualCode]="otherQual?.code"
    [teachingPeriods]="teachingPeriods"
    [processName]="processName"
    [errorTriggered]="errorTriggered"
    (removeCourse)="removeCourse($event)"
    (courseAdd)="addCourse($event)"
    (undoChange)="undoChange($event)"></uc-course-planner-review-panel>
</div>

<uc-confirmation-modal
  [title]="strings.modal.title"
  [acceptText]="strings.modal.close"
  (closed)="errorTriggered = false"></uc-confirmation-modal>
