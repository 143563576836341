<uc-section [formGroup]="personalDetails">
  <uc-section-header [title]="strings.sectionTitle"></uc-section-header>

  <uc-subsection>
    <uc-subsection-question>
      {{ strings.dateOfBirthQuestion }}
      <uc-required-field></uc-required-field>
    </uc-subsection-question>
    <uc-form-input>
      <uc-date-selector
        [baseYear]="dateOfBirthYear"
        [range]="85"
        formControlName="birthDate"
        required="yes"
        testSelector="dob"></uc-date-selector>
    </uc-form-input>
  </uc-subsection>

  <uc-legal-name-declaration
    (nameAdd)="this.otherNames.push()"
    (nameRemove)="this.otherNames.removeAt($event)"
    [otherNamesLength]="otherNamesLength"
    [hideDocUpload]="true"
    [namesModel]="personalDetails.get('names')"></uc-legal-name-declaration>

  <uc-subsection>
    <uc-gender-selector [genderForm]="personalDetails.get('genderGroup')"></uc-gender-selector>
  </uc-subsection>
  <uc-subsection>
    <uc-personal-pronouns-selector
      [pronounsGroup]="personalDetails.get('pronounsGroup')"></uc-personal-pronouns-selector>
  </uc-subsection>
  <uc-ethnicity-iwi-selectors [demographicElements]="demographicElements"></uc-ethnicity-iwi-selectors>
</uc-section>
